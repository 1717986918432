import { Injectable } from "@angular/core";
import { AlertService } from "../core-services/alert.service";
import { UIDatabase } from "./UIDatabase.interface";
import { EncryptionService } from "../core-services/encryption.service";

@Injectable({
  providedIn: "root",
})
export class IndexedDbStorageService implements UIDatabase {
  public databases: Map<string, IDBDatabase> = new Map<string, any>();
  public version: number | undefined;

  constructor(
    private alertService: AlertService,
    private encryptionService: EncryptionService
  ) {
    if (!window.indexedDB) {
      console.log(
        "Your browser doesn't support a stable version of IndexedDB. Such and such feature will not be available."
      );
    }
  }

  public openDatabase(
    dbName: string,
    successCallback?: any,
    errorCallback?: any,
    onupgradeneededCallback?: any
  ): void {
    let request: IDBOpenDBRequest = indexedDB.open(dbName, this.version);

    request.onerror = this.openDatabaseError.bind(this, errorCallback);
    request.onupgradeneeded = this.onUpgradeDatabaseSuccess.bind(this, dbName, onupgradeneededCallback);
    request.onsuccess = this.openDatabaseSuccess.bind(this, dbName, successCallback);
  }

  public openDatabaseError(errorCallback: any, event: any): void {
    this.alertService.error(event);

    if (errorCallback) {
      errorCallback();
    }
  }

  public openDatabaseSuccess(dbName: string, successCallback: any, event: any): void {
    if (this.databases === undefined || this.databases === null) {
      this.databases = new Map<string, any>();
    }

    this.databases.set(dbName, event.target.result);

    if (successCallback) {
      successCallback();
    }
  }

  public onUpgradeDatabaseSuccess(dbName: string, onUpgradeCallback: any, event: any): void {
    if (this.databases === undefined || this.databases === null) {
      this.databases = new Map<string, any>();
    }

    this.databases.set(dbName, event.target.result);

    if (onUpgradeCallback) {
      onUpgradeCallback();
    }
  }

  public createObjectStore(dbName: string, tableParameters: any): void {
    let db: IDBDatabase | undefined = this.databases.get(dbName);

    if (db) {
      let objectStore: IDBObjectStore;
      if (tableParameters.keyPath) {
        objectStore = db.createObjectStore(tableParameters.dbStoreName, {
          keyPath: tableParameters.keyPath,
        });
      } else {
        objectStore = db.createObjectStore(tableParameters.dbStoreName, { autoIncrement: true });
      }

      tableParameters.indexes.forEach(
        (index: any) => {
          objectStore.createIndex(index.indexName, index.indexName, { unique: index.unique });
        }
      );
    }
  }

  public onTransactionSuccess(successCallback: any, ev: Event): void {
    if (successCallback !== undefined && successCallback !== null) {
      successCallback(ev);
    }
  }

  public onTransactionError(data: any, errorCallback: any, ev: Event): void {
    this.alertService.error(data);
    this.alertService.error(ev);
    if (errorCallback !== undefined && errorCallback !== null) {
      errorCallback(ev);
    }
  }

  public async insertData(dbName: string, storeName: string, data: any, dataArray: boolean, successCallback?: any, errorCallback?: any): Promise<void> {
    let db: IDBDatabase | undefined = this.databases.get(dbName);

    if (!db) {
      this.alertService.showMessage("E_NO_UI_DB", "error");
      return;
    }

    if (dataArray) {
      if (Array.isArray(data)) {
        for (let idx = 0; idx < data.length; idx++) {
          data[idx].hash = await this.encryptionService.hash(data[idx]);
        }
      } else {
        data.hash = await this.encryptionService.hash(data);
      }
    } else {
      data.hash = await this.encryptionService.hash(data);
    }

    let transaction: IDBTransaction = db.transaction([storeName], "readwrite");
    transaction.oncomplete = this.onTransactionSuccess.bind(this, successCallback);
    transaction.onerror = this.onTransactionError.bind(this, data, errorCallback);

    let objectStore: IDBObjectStore = transaction.objectStore(storeName);

    if (dataArray) {
      if (Array.isArray(data)) {
        for (let idx = 0; idx < data.length; idx++) {
          objectStore.add(data[idx]);
        }
      } else {
        objectStore.add(data);
      }
    } else {
      objectStore.add(data);
    }
  }

  public updateDataByKey(dbName: string, storeName: string, key: any, data: any, keysToUpdate: string[], successCallback?: any, errorCallback?: any): void {
    let db: IDBDatabase | undefined = this.databases.get(dbName);

    if (!db) {
      this.alertService.showMessage("E_NO_UI_DB", "error");
      return;
    }

    let transaction: IDBTransaction = db.transaction([storeName], "readwrite");

    transaction.onerror = this.onTransactionError.bind(this, data, errorCallback);

    let objectStore: IDBObjectStore = transaction.objectStore(storeName);

    if (key !== undefined && key !== null) {
      let request: IDBRequest<any> = objectStore.get(key);
      request.onsuccess = this.onGotDataForUpdate.bind(this, objectStore, key, data, keysToUpdate, successCallback);
    } else {
      let request: IDBRequest<any> = objectStore.put(data);
      request.onsuccess = this.onTransactionSuccess.bind(this, successCallback);
    }
  }

  public onGotDataForUpdate(objectStore: IDBObjectStore, key: any, data: any, keysToUpdate: string[], successCallback: any, event: any): void {
    let dbData: any = event.target.result;

    keysToUpdate.forEach(
      key => {
        dbData[key] = data[key];
      }
    );

    let request: IDBRequest<any> = objectStore.put(dbData, key);
    request.onsuccess = this.onTransactionSuccess.bind(this, successCallback);
  }

  public fetchDataByKey(dbName: string, storeName: string, key: any, successCallback?: any, errorCallback?: any): void {
    let db: IDBDatabase | undefined = this.databases.get(dbName);

    if (!db) {
      this.alertService.showMessage("E_NO_UI_DB", "error");
      return;
    }

    let transaction: IDBTransaction = db.transaction([storeName]);
    transaction.onerror = this.onTransactionError.bind(this, key, errorCallback);

    let objectStore: IDBObjectStore = transaction.objectStore(storeName);
    let request: IDBRequest<any> = objectStore.get(key);
    request.onsuccess = this.onTransactionSuccess.bind(this, successCallback);
  }

  public deleteDataByKey(dbName: string, storeName: string, key: any, successCallback?: any, errorCallback?: any): void {
    let db: IDBDatabase | undefined = this.databases.get(dbName);

    if (!db) {
      this.alertService.showMessage("E_NO_UI_DB", "error");
      return;
    }

    let transaction: IDBTransaction = db.transaction([storeName], "readwrite");
    transaction.onerror = this.onTransactionError.bind(this, key, errorCallback);

    let objectStore: IDBObjectStore = transaction.objectStore(storeName);
    let request: IDBRequest<any> = objectStore.delete(key);
    request.onsuccess = this.onTransactionSuccess.bind(this, successCallback);
  }

  public fetchDataByIndex(dbName: string, storeName: string, indexName: string, key: any, successCallback?: any, errorCallback?: any): void {
    let db: IDBDatabase | undefined = this.databases.get(dbName);

    if (!db) {
      this.alertService.showMessage("E_NO_UI_DB", "error");
      return;
    }

    let transaction: IDBTransaction = db.transaction([storeName]);
    transaction.onerror = this.onTransactionError.bind(this, key, errorCallback);

    let objectStore: IDBObjectStore = transaction.objectStore(storeName);
    let index: IDBIndex = objectStore.index(indexName);
    let request: IDBRequest<any> = index.get(key);
    request.onsuccess = this.onTransactionSuccess.bind(this, successCallback);
  }

  public deleteAll(dbName: string, storeName: string, successCallback?: any, errorCallback?: any): void {
    let db: IDBDatabase | undefined = this.databases.get(dbName);

    if (!db) {
      this.alertService.showMessage("E_NO_UI_DB", "error");
      return;
    }

    let transaction: IDBTransaction = db.transaction([storeName], "readwrite");
    transaction.onerror = this.onTransactionError.bind(this, "deleteAll", errorCallback);

    let objectStore: IDBObjectStore = transaction.objectStore(storeName);
    let request: IDBRequest<any> = objectStore.clear();
    request.onsuccess = this.onTransactionSuccess.bind(this, successCallback);
  }

  public getCount(dbName: string, storeName: string, successCallback?: any, errorCallback?: any): void {
    let db: IDBDatabase | undefined = this.databases.get(dbName);

    if (!db) {
      this.alertService.showMessage("E_NO_UI_DB", "error");
      return;
    }

    let transaction: IDBTransaction = db.transaction([storeName], "readonly");
    transaction.onerror = this.onTransactionError.bind(this, "getCount", errorCallback);

    let objectStore: IDBObjectStore = transaction.objectStore(storeName);
    let request: IDBRequest<any> = objectStore.count();
    request.onsuccess = this.onTransactionSuccess.bind(this, successCallback);
  }

  public fetchAllData(dbName: string, storeName: string, successCallback?: any, errorCallback?: any): void {
    let db: IDBDatabase | undefined = this.databases.get(dbName);

    if (!db) {
      this.alertService.showMessage("E_NO_UI_DB", "error");
      return;
    }

    let transaction: IDBTransaction = db.transaction([storeName], "readonly");
    transaction.onerror = this.onTransactionError.bind(this, "getCount", errorCallback);

    let objectStore: IDBObjectStore = transaction.objectStore(storeName);
    let request: IDBRequest<any> = objectStore.getAllKeys();
    request.onsuccess = this.gotAllKeys.bind(this, dbName, storeName, successCallback, errorCallback);
  }

  public gotAllKeys(dbName: string, storeName: string, successCallback: any, errorCallback: any, event: any): void {
    let db: IDBDatabase | undefined = this.databases.get(dbName);

    if (!db) {
      this.alertService.showMessage("E_NO_UI_DB", "error");
      return;
    }

    let transaction: IDBTransaction = db.transaction([storeName], "readonly");
    transaction.onerror = this.onTransactionError.bind(this, "getCount", errorCallback);

    let objectStore: IDBObjectStore = transaction.objectStore(storeName);
    let responseData: any[] = [];

    if (Array.isArray(event.target.result)) {
      if (event.target.result.length > 0) {
        for (let idx = 0; idx < event.target.result.length; idx++) {
          let req: IDBRequest<any> = objectStore.get(event.target.result[idx]);
          req.onsuccess = this.gotKeyData.bind(this, responseData, successCallback, event.target.result[idx], event.target.result.length);
        }
      } else {
        if (successCallback !== undefined && successCallback !== null) {
          successCallback(event);
        }
      }
    } else {
      let req: IDBRequest<any> = objectStore.get(event.target.result);
      req.onsuccess = this.gotKeyData.bind(this, responseData, successCallback, event.target.result, 1);
    }
  }

  public gotKeyData(responseData: any[], successCallback: any, key: any, responseCount: number, event: any): void {
    let data = event.target.result;
    data.key = key;
    responseData.push(data);

    if (responseData.length === responseCount) {
      successCallback({ "target": { "result": responseData } });
    }
  }

  public fetchAllDataByIndex(dbName: string, storeName: string, indexName: string, indexValue: string, successCallback?: any, errorCallback?: any): void {
    let db: IDBDatabase | undefined = this.databases.get(dbName);

    if (!db) {
      this.alertService.showMessage("E_NO_UI_DB", "error");
      return;
    }

    let transaction: IDBTransaction = db.transaction([storeName], "readonly");
    transaction.onerror = this.onTransactionError.bind(this, "getCount", errorCallback);

    let objectStore: IDBObjectStore = transaction.objectStore(storeName);
    let index: IDBIndex = objectStore.index(indexName);
    let request: IDBRequest<any> = index.getAllKeys(indexValue);
    request.onsuccess = this.gotAllKeys.bind(this, dbName, storeName, successCallback, errorCallback);
  }
}
