import {ChangeDetectorRef, Component, ElementRef, Inject, Input, OnDestroy, OnInit, Optional, TemplateRef, ViewChild} from '@angular/core';
import {FormArray, FormControl} from '@angular/forms';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {JsonSchemaFormService} from '@finfra/ajsf-core';
import {AlertService, ConfigService, DeviceService, EventsService, FinfraMenuService, HttpService, UtilitiesService} from '@finfra/core-services';
import {Subscription} from 'rxjs';
import {v4} from 'uuid';
import {GenericEventModel} from '@finfra/core-models';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {HttpResponse} from '@angular/common/http';
import {AjsfCoreWidgetsFunctionsService} from '../../../asjf-core-widgets.functions';
import {HttpCallModel} from '@finfra/core-models';
import { MatTable } from '@angular/material/table';

declare let navigator: any;
declare let Camera: any;

@Component({
  selector: 'finfra-dms-widget-component-dev',
  templateUrl: './finfra-dms.component.html',
  styleUrls: ['./finfra-dms.component.scss']
})
export class FinfraDMSWidgetComponent implements OnInit, OnDestroy {

  widgetName = 'DMSWidgetComponent';
  formControl: FormArray | undefined;
  controlName: string | undefined;
  controlValue: any;
  controlDisabled = false;
  boundControl = false;
  options: any;
  subProperties: any;
  fullObject: any;
  validityControl: FormControl | undefined;

  @Input() layoutNode: any;
  @Input() layoutIndex: number[] | undefined;
  @Input() dataIndex: number[] | undefined;

  translateLabelsName = 'finfra-dms';
  translateLabelsLoaded = false;

  displayedFileTableColumns = ['fileName','status', 'purpose', 'documentType', 'delete'];
  
  jsonFormLoaded = false;

  public uploadStatusDescription: any = {
    'U': 'uploaded',
    'W': 'uploading',
    'E': 'error',
    'P': 'pending'
  };

  private functionId!: string | undefined;
  private applicationId: string | undefined;
  private applicationReferenceNumber: string | undefined;
  private transactionReferenceNumber: string | undefined;
  public filesToUpload: any = [];
  public documentTypeList: any;
  public purposeList: any;
  public mandatoryPurposeList: any;
  public docsCheckListData: any;
  private filesUploading = 0;
  private fileNameList: any = [];
  private eventSubscription: Subscription | undefined;
  @ViewChild('fileUploadInput', {static: false}) fileUploadInput: ElementRef | undefined;
  @ViewChild(MatTable) table: MatTable<any>;
  /* webcam properties */

  public webcamDialogRef: MatDialogRef<any> | undefined;
  public stream: any;
  public showVideo = false;
  public showPicture = false;
  public capturedImage: string | undefined;
  public capturedImageBase64!: string;
  public context: any;
  public width = 640;
  public height = 480;
  public recordAudio = false;
  public streaming = false;
  @ViewChild('webcamTemplate', {static: true}) webcamTemplate!: TemplateRef<any>;
  @ViewChild('webcamPicture', {static: false}) webcamPicture!: ElementRef;

  /* scanner properties */
  public documentScannerDialogRef: MatDialogRef<any> | undefined;
  public capturedDocument: any;
  public scannerList: any[] = [];
  public selectedScanner: any;
  public scanCallback: any = [];
  public loadingScannerList = false;
  public scannedFiles: any[] = [];
  public scanning = false;
  public gettingFile = false;
  public gettingFileCount = 0;
  public currentId: string | undefined;
  public needUpload = false;
  public purposeListScanner: any[] = [];
  public filesUploadingScanner = 0;
  @ViewChild('documentScannerTemplate', {static: true}) documentScannerTemplate!: TemplateRef<any>;

  constructor(
    @Inject(MAT_FORM_FIELD_DEFAULT_OPTIONS) @Optional() public matFormFieldDefaultOptions: any,
    private jsf: JsonSchemaFormService,
    private alertService: AlertService,
    public configService: ConfigService,
    private utilitiesService: UtilitiesService,
    private finfraMenuService: FinfraMenuService,
    public dialog: MatDialog,
    private deviceService: DeviceService,
    private eventsService: EventsService,
    private cdRef: ChangeDetectorRef,
    private httpService: HttpService,
    private ajsfCoreWidgetsFunctionsService: AjsfCoreWidgetsFunctionsService
  ) {
    this.eventSubscription = this.eventsService.listenEvents().subscribe(this.onEvent.bind(this));

  }

  onEvent(event: GenericEventModel): any {
    if (this.jsf === undefined) {
      return false;
    }

    this.alertService.debug('DMSWidget: got the event');
  }

  async ngOnInit(): Promise<any> {

    this.options = this.layoutNode.options || {};
    this.subProperties = this.layoutNode.subProperties || {};

    this.jsf.initializeControl(this);

    this.ajsfCoreWidgetsFunctionsService.loadLabels(this);

    if (this.options.validityPointer) {
      this.validityControl = this.jsf.getControlService(this, this.jsf.formGroup, this.options.validityPointer);
    } else {
      this.alertService.error(`finfraDmsWidgetComponent: validityPointer not defined in options`);
    }

    if (!this.options.notitle && !this.options.description && this.options.placeholder) {
      this.options.description = this.options.placeholder;
    }

    if (!this.initializeValues()) {
      return false;
    }

    this.initScaner();
    this.needUpload = true;

    if (this.options.readonly !== true) {
      this.getDocumentsRequiredForFunction();
    }

    this.filesToUpload = [];
    if (this.formControl?.value.length > 0) {
      /* get files by control value */
      this.getFilesDetailsByFileId();
    } else {
      /* get files by reference id */
      this.getFilesDetaisByReferenceId();
    }


    this.init();
  }

  ngOnDestroy() {
    this.jsf = <any>undefined;
    this.formControl = undefined;
    this.controlName = undefined;
    this.controlValue = undefined;
    this.controlDisabled = <any>undefined;
    this.boundControl = <any>undefined;
    this.options = undefined;

    this.eventSubscription?.unsubscribe();
    this.destroyScanner(<any>this.controlName);
    this.destroyWebcam();
  }

  public initializeValues(): boolean {
    if (this.formControl === undefined || this.formControl === null) {
      return false;
    }

    this.functionId = this.finfraMenuService.currentMenu?.id;
    this.applicationId = this.finfraMenuService.currentApplicationId;

    if (!this.options.applicationReferenceNumberPointer && !this.options.transactionReferenceNumberPointer) {
      this.applicationReferenceNumber = this.formControl?.parent?.value.applicationReferenceNumber;
      this.transactionReferenceNumber = this.formControl?.parent?.value.transactionReferenceNumber;
    } else {
      if (this.options.applicationReferenceNumberPointer && this.options.applicationReferenceNumberPointer !== '') {
        this.applicationReferenceNumber = this.jsf.getControlService(this, this.jsf.formGroup, this.options.applicationReferenceNumberPointer).value;
      } else {
        this.applicationReferenceNumber = this.formControl?.parent?.value.applicationReferenceNumber;
      }

      if (this.options.transactionReferenceNumberPointer && this.options.transactionReferenceNumberPointer !== '') {
        this.transactionReferenceNumber = this.jsf.getControlService(this, this.jsf.formGroup, this.options.transactionReferenceNumberPointer).value;
      } else {
        this.transactionReferenceNumber = this.formControl?.parent?.value.transactionReferenceNumber;
      }
    }
    // this.jsf.resetFormValues();
    return true;
  }

  public getDocumentsRequiredForFunction() {
    if (this.options.purposeList !== undefined && this.options.purposeList !== null && Array.isArray(this.options.purposeList) && this.options.purposeList.length > 0) {
      this.purposeList = this.options.purposeList;
      this.docsCheckListData = this.purposeList;

      this.mandatoryPurposeList = [];
      this.purposeList.forEach((purpose: any) => {
        purpose.docList.forEach((document: any) => {
          if (document.mandatory === true) {
            const mandatoryPurpose: any = new Object();
            mandatoryPurpose.purposeDescription = purpose.purposeDescription;
            mandatoryPurpose.purposeId = purpose.purposeId;
            mandatoryPurpose.uploaded = 0;
            this.mandatoryPurposeList.push(mandatoryPurpose);
          }
        });
      });

      this.mandatoryPurposeList = this.mandatoryPurposeList.filter((purpose: any, index: any) => {
        const _purpose = JSON.stringify(purpose);
        return index === this.mandatoryPurposeList.findIndex((obj: any) => {
          return JSON.stringify(obj) === _purpose;
        });
      });

      if (this.filesToUpload && this.filesToUpload.length && this.filesToUpload.length > 0) {
        this.filesToUpload.forEach((file: any) => {
          file.purposeList = this.purposeList;
          this.onPurposeChange(file);
        });
      }

      this.purposeListScanner = this.purposeList;
    } else {
      this.alertService.error('DMSWidget:= PurposeList is empty');
    }
  }

  private generateReferenceId() {
    if (Array.isArray(this.dataIndex) && this.dataIndex.length > 0) {
      let str: string | undefined = this.controlName;
      this.dataIndex.forEach((idx) => {
        str = str?.concat('/' + idx);
      });
      return str;
    } else {
      return this.controlName;
    }
  }


  public validateUpload(showError: boolean) {
    this.table?.renderRows();
    this.updateValidityControl(null);

    let proceed = true;

    this.filesToUpload.forEach((file: any) => {
      if (file.uploadStatus === 'P' || file.uploadStatus === 'E' || file.uploadStatus === 'W') {
        if (showError) {
          this.alertService.showMessage('E_FILE_PENDING', 'error');
        }

        proceed = false;
      }
    });

    if (this.mandatoryPurposeList !== undefined && this.mandatoryPurposeList !== null) {
      this.mandatoryPurposeList.forEach((purpose: any) => {
        if (purpose.uploaded === 0) {
          proceed = false;

          if (showError) {
            this.alertService.showMessage('E_FILE_MANDATORY', 'error');
          }
        }
      });
    }

    if (proceed) {
      this.updateValidityControl('Y');
    }
  }

  public updateValidityControl(value: string | null) {
    this.validityControl?.setValue(value);
  }

  public onPurposeChange(file: any) {
    file.documentTypeList = undefined;
    console.log('Purpose selected is ' + file.purposeId);

    file.purposeList.forEach((purpose: any) => {
      if (purpose.purposeId === file.purposeId) {
        file.documentTypeList = purpose.docList;
      }
    });

    this.mandatoryPurposeList.forEach((purpose: any) => {
      purpose.uploaded = 0;

    });
    this.filesToUpload.forEach((file: any) => {
      this.mandatoryPurposeList.forEach((purpose: any) => {
        if (purpose.purposeId === file.purposeId) {
          purpose.uploaded = purpose.uploaded + 1;
        }
      });
    });

    this.validateUpload(false);
  }

  public updateValue(value: any) {
    this.controlValue = value;
    this.jsf.updateValue(this, this.controlValue);
  }

  /**
   * get the file details by fileid in the controlValue array of all the uploaded files
   */
  public async getFilesDetailsByFileId() {
    if (this.formControl?.value && Array.isArray(this.formControl.value) && this.formControl.value.length > 0) {
      const promiseList: any = [];
      this.formControl.value.forEach((value: string) => {
        promiseList.push(this.getUrlForFileApi(<any>this.functionId, {fileId: value}));
      });
      const results: any = await Promise.all(promiseList).catch((error) => {
        this.alertService.showErrorMessage(error);
        this.validateUpload(false);
      });
      const respList: any = [];
      if (results && Array.isArray(results) && results.length > 0) {
        results.forEach((result) => {
          if (result && result instanceof HttpResponse && result.status === 200) {
            respList.push(result.body.finData);
          }
        });

        this.filesToUpload = respList;
        this.fileNameList = [];
        this.filesToUpload.forEach((file: any) => {
          file.uploadStatus = 'U';
          this.fileNameList.push(file.fileName);
          if (this.purposeList) {
            file.purposeList = this.purposeList;
            this.onPurposeChange(file);
          }
        });

      }
      this.validateUpload(false);
    } else {
      this.filesToUpload = [];
      this.fileNameList = [];
    }
    this.cdRef.markForCheck();
  }


  private async getFilesDetaisByReferenceId() {
    let result: any;
    try {
      result = await this.getFileDetailsByReferenceIdApi(
        <string>this.generateReferenceId(),
        <string>this.functionId,
        <string>this.transactionReferenceNumber,
        <string>this.applicationReferenceNumber
      );
    } catch (e) {
      this.alertService.error(e);
    }
    if (result && result.status === 200) {
      this.filesToUpload = result.body.finData;
      this.fileNameList = [];
      this.filesToUpload.forEach((file: any) => {
        file.uploadStatus = 'U';
        this.fileNameList.push(file.fileName);
        this.pushValues(file.fileId);
        if (this.purposeList) {
          file.purposeList = this.purposeList;
          this.onPurposeChange(file);
        }
      });
      this.validateUpload(false);
    }
  }

  /**
   * @param fileId : string
   * @returns boolean : true if push successful else false
   * @description
   * creates a new formControl by using fileId as value
   * and push it to the widget formArray
   */
  private pushValues(fileId: string): boolean {
    if (this.formControl && fileId) {
      this.formControl.push(new FormControl(fileId));
      this.updateValue(this.formControl.value);
      return true;
    } else {
      return false;
    }

  }

  init() {
    if (this.options.readonly === undefined) {
      this.options.readonly = false;
      this.formControl?.disable();
    }

    if (this.jsf.schema.required && Array.isArray(this.jsf.schema.required) && this.jsf.schema.required.length > 0 && this.options.validityControlName) {
      (this.jsf.schema.required as Array<string>).push(this.options.validityControlName);
    } else if (this.options.validityControlName) {
      this.jsf.schema.required = [this.options.validityControlName];
    }

    this.jsf.validateFormData = this.jsf.ajv.compile(this.jsf.schema);
    this.validateUpload(false);

    this.jsonFormLoaded = true;
  }

  public onPictureCapture() {
    const fileObject: any = {
      'fileBase64': this.capturedImageBase64,
      'lastModified': (new Date()).getTime(),
      'type': 'image/jpeg',
      'fileName': (new Date()).getTime() + '.png'
    };

    const file: any = new Object();
    file.clientFileId = v4();
    file.file = this.utilitiesService.base64ToFile(fileObject);
    file.fileName = file.clientFileId;
    file.fileType = 'image/jpeg';
    file.purposeList = this.purposeList;
    file.uploadStatus = 'P';
    file.originalVerified = false;
    this.filesToUpload.push(file);

    this.validateUpload(false);
    this.destroyWebcam();
  }


  public dropHandler(event: any) {
    console.log('drop handler');
    console.log(event);
    event.preventDefault();

    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      for (let idx = 0; idx < event.dataTransfer.files.length; idx++) {
        let flag = false;
        for (let innerIdx = 0; innerIdx < this.filesToUpload.length; innerIdx++) {
          if (this.filesToUpload[innerIdx].file === event.dataTransfer.files[idx]) {
            flag = true;
            break;
          }
        }

        if (!flag) {
          const file: any = {};
          file.clientFileId = v4();
          file.file = event.dataTransfer.files[idx];
          file.fileName = event.dataTransfer.files[idx].name;
          file.fileType = event.dataTransfer.files[idx].type;
          file.purposeList = this.purposeList;
          file.uploadStatus = 'P';
          file.originalVerified = false;
          this.checkingFileName(file);
        }
      }
    }
    this.validateUpload(false);
  }

  public dragOverHandler(event: any) {
    console.log('dragover handler');
    console.log(event);
    event.preventDefault();
  }

  public checkingFileName(file: any) {
    if (!this.fileNameList.includes(file.fileName)) {
      this.fileNameList.push(file.fileName);
      this.filesToUpload.push(file);
    } else {
      this.alertService.showMessage('E_SAME_FILE_NAME', 'error');
    }
  }

  public selectFile() {
    if (this.fileUploadInput) {
      this.fileUploadInput.nativeElement.value = null;
    }
    this.fileUploadInput?.nativeElement.click();
  }

  public manualFileSelected() {
    if (this.fileUploadInput?.nativeElement.files && this.fileUploadInput.nativeElement.files.length > 0) {
      for (let idx = 0; idx < this.fileUploadInput.nativeElement.files.length; idx++) {
        let flag = false;
        for (let innerIdx = 0; innerIdx < this.filesToUpload.length; innerIdx++) {
          if (this.filesToUpload[innerIdx] === this.fileUploadInput.nativeElement.files[idx]) {
            flag = true;
            break;
          }
        }

        if (!flag) {
          const file: any = {};
          file.clientFileId = v4();
          file.file = this.fileUploadInput.nativeElement.files[idx];
          file.fileName = this.fileUploadInput.nativeElement.files[idx].name;
          file.fileType = this.fileUploadInput.nativeElement.files[idx].type;
          file.purposeList = this.purposeList;
          file.uploadStatus = 'P';
          file.originalVerified = false;
          this.checkingFileName(file);
        }
      }
      this.fileUploadInput.nativeElement.value = null;
    }

    this.validateUpload(false);
  }

  public scanFile() {
    this.scanDocument((this.controlName as any));
    this.validateUpload(false);
  }

  public takePicture() {
    this.startWebcam();
  }


  public async uploadFiles() {
    this.validateUpload(false);

    let errorFlag = false;
    for (let idx = 0; idx < this.filesToUpload.length; idx++) {
      if (!this.filesToUpload[idx].purposeId || this.filesToUpload[idx].purposeId === '') {
        const parameter: Map<string, string> = new Map<string, string>();
        parameter.set('fileName', this.filesToUpload[idx].fileName);
        this.alertService.showMessage('E_FILE_PURPOSE', 'error', parameter);
        errorFlag = true;
      }

      if (!this.filesToUpload[idx].documentTypeId || this.filesToUpload[idx].documentTypeId === '') {
        const parameter: Map<string, string> = new Map<string, string>();
        parameter.set('fileName', this.filesToUpload[idx].fileName);
        this.alertService.showMessage('E_FILE_DOCUMENT_TYPE', 'error', parameter);
        errorFlag = true;
      }
    }

    this.mandatoryPurposeList.forEach((purpose: any) => {
      if (purpose.uploaded === 0) {
        errorFlag = true;
        const parameter: Map<string, string> = new Map<string, string>();
        parameter.set('purpose', purpose.purposeDescription);
        this.alertService.showMessage('E_FILE_PURPOSE_MANDATORY', 'error', parameter);
      }
    });

    if (errorFlag) {
      return;
    }

    this.filesUploading = 0;
    for (let idx = 0; idx < this.filesToUpload.length; idx++) {
      if (this.filesToUpload[idx].uploadStatus === 'U') {
        continue;
      }

      this.filesToUpload[idx].uploadStatus = 'W';
      this.filesUploading++;

      const finData = {
        tags: [
          this.transactionReferenceNumber,
          this.filesToUpload[idx].documentTypeId,
          this.filesToUpload[idx].documentTypeDescription,
          this.filesToUpload[idx].purposeId,
          this.filesToUpload[idx].purposeDescription
        ],
        purposeId: this.filesToUpload[idx].purposeId,
        purposeDescription: this.filesToUpload[idx].purposeDescription,
        documentTypeId: this.filesToUpload[idx].documentTypeId,
        documentTypeDescription: this.filesToUpload[idx].documentTypeDescription,
        clientFileId: this.filesToUpload[idx].clientFileId,
        transactionReferenceNumber: this.transactionReferenceNumber,
        fileReferenceId: this.generateReferenceId()
      };

      try {
        const results: any = await this.uploadFileApi(
          this.applicationId,
          <any>this.functionId,
          this.transactionReferenceNumber,
          this.applicationReferenceNumber,
          finData,
          [this.filesToUpload[idx].file],
        );
        if (results) {
          if (results.status === 200) {
            if (results.body.responseStatus === 'SUCCESS') {
              this.filesToUpload.forEach((file: any) => {
                if (file.clientFileId === results.body.finData.clientFileId) {
                  file.fileId = results.body.finData.fileId;
                  this.pushValues(results.body.finData.fileId);
                  file.uploadStatus = 'U';
                }
              });
            } else {
              this.filesToUpload.forEach((file: any) => {
                if (file.clientFileId === results.body.finData.clientFileId) {
                  file.uploadStatus = 'E';
                }
              });
            }
          }
        }
      } catch (e) {
        this.alertService.showErrorMessage(e);
      }

      this.filesUploading--;

      if (this.filesUploading <= 0) {
        this.filesToUpload.forEach((file: any) => {
          if (file.uploadStatus === 'W') {
            file.uploadStatus = 'E';
          }
        });

      }

    }
    this.validateUpload(true);
  }

  public deleteFile(row: any, idx: number) {
    this.fileNameList = this.fileNameList.filter((file: any) => {
      return file !== row.fileName;
    });
    if (row.uploadStatus === 'U') {
      this.deleteFileAfterUpload(row, idx);
    } else if (row.uploadStatus === 'P' || row.uploadStatus === 'E') {
      this.deleteFileBeforeUpload(row);
    }
  }

  private deleteFileBeforeUpload(row: any) {
    this.filesToUpload = this.filesToUpload.filter((obj: any) => obj !== row);

    this.mandatoryPurposeList.forEach((purpose: any) => {
      if (purpose.purposeId === row.purposeId) {
        purpose.uploaded = purpose.uploaded - 1;
      }
    });

    this.validateUpload(false);
  }

  private async deleteFileAfterUpload(row: any, idx: number) {
    const finData = {fileId: row.fileId, transactionReferenceNumber: this.transactionReferenceNumber};
    const results: any = await this.deleteFileApi(
      <any>this.functionId,
      finData
    ).catch(error => {
      this.alertService.showErrorMessage(error);
      this.validateUpload(false);
    });
    if (results) {
      if (results.status === 200 && results.body.responseStatus === 'SUCCESS') {
        this.filesToUpload = this.filesToUpload.filter((file: any) => file.fileId !== row.fileId);
        this.formControl?.removeAt(idx);
        this.updateValue(this.formControl?.value);
        this.mandatoryPurposeList.forEach((purpose: any) => {
          if (purpose.purposeId === row.purposeId) {
            purpose.uploaded = purpose.uploaded - 1;
          }
        });
      } else {
        this.alertService.showAllMessages(results.body);
      }
    }
    this.validateUpload(false);

  }


  /* Webcam methods */

  public destroyWebcam() {
    this.showVideo = false;
    this.capturedImage = undefined;

    if (this.webcamDialogRef) {
      this.webcamDialogRef.close();
    }
  }

  public startWebcam(): any {
    if (navigator.camera) {
      const options: any = {
        // Some common settings are 20, 50, and 100
        quality: 100,
        destinationType: Camera.DestinationType.FILE_URI,
        // In this app, dynamically set the picture source, Camera or photo gallery
        sourceType: Camera.PictureSourceType.CAMERA,
        encodingType: Camera.EncodingType.JPEG,
        mediaType: Camera.MediaType.PICTURE,
        allowEdit: true, // set true if edit allowed
        // targetHeight: 200,
        // targetWidth: 200,
        correctOrientation: true  // Corrects Android orientation quirks
      };

      navigator.camera.getPicture(this.cameraSuccess.bind(this), this.cameraError.bind(this), options);
    } else {
      try {
        this.showVideo = true;
        this.capturedImage = undefined;

        if (this.webcamDialogRef === undefined || this.webcamDialogRef === null) {
          this.webcamDialogRef = this.dialog.open(this.webcamTemplate, {});
          this.webcamDialogRef.afterOpened().subscribe(result => {
            this.initWebcam();
          });

          this.webcamDialogRef.afterClosed().subscribe(result => {
            this.stopVideo();

            this.stream = undefined;
            this.showVideo = false;
            this.showPicture = false;

            this.webcamDialogRef = undefined;
          });
        } else {
          this.initWebcam();
        }
      } catch (e) {
        this.alertService.error(e);
      }
    }
  }

  public cameraSuccess(imageUri: string) {
    this.capturedImage = imageUri;

    this.deviceService.getDiskFileAsBase64(imageUri, this.gotFile.bind(this));
  }

  public gotFile(fileObject: any) {
    this.capturedImageBase64 = fileObject.base64;
    this.onPictureCapture();
  }

  public cameraError(message: any) {
    this.alertService.error(message);
  }

  public initWebcam() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      this.streaming = false;

      const constraints: any = {
        audio: this.recordAudio,
        video: {
          width: this.width,
          height: this.height
        }
      };

      navigator.mediaDevices.getUserMedia(constraints).then((stream: any): any => {
        this.stream = stream;
        const webcamVideo: any = document.getElementById('webcamVideo');

        if (
          webcamVideo === undefined ||
          webcamVideo === null
        ) {
          this.stopVideo();
          return false;
        }

        webcamVideo.srcObject = this.stream;
        webcamVideo.play();

        webcamVideo.addEventListener('canplay', this.initVideo.bind(this), false);
      });
    }
  }

  public initVideo(event: any) {
    if (!this.streaming) {
      const webcamVideo: any = document.getElementById('webcamVideo');
      this.height =
        webcamVideo.videoHeight /
        (webcamVideo.videoWidth / this.width);

      webcamVideo.setAttribute('width', this.width);
      webcamVideo.setAttribute('height', this.height);
      webcamVideo.setAttribute('width', this.width);
      webcamVideo.setAttribute('height', this.height);
      this.streaming = true;
    }
  }

  public capture() {
    const webcamVideo: any = document.getElementById('webcamVideo');

    this.webcamPicture.nativeElement.width = this.width;
    this.webcamPicture.nativeElement.height = this.height;

    this.context = this.webcamPicture.nativeElement
      .getContext('2d')
      .drawImage(webcamVideo, 0, 0, this.width, this.height);

    this.capturedImage = this.webcamPicture.nativeElement.toDataURL('image/jpeg');
    this.capturedImageBase64 = <any>this.capturedImage;

    this.stopVideo();
    this.showVideo = false;
  }

  public confirmImageCapture() {
    this.onPictureCapture();
  }

  public stopWebcam() {
    this.webcamDialogRef?.close();
  }

  public stopVideo(): any {
    if (this.stream === undefined || this.stream === null) {
      return false;
    }

    const tracks: any = this.stream.getTracks();

    for (let i = 0; i < tracks.length; i++) {
      const track: any = tracks[i];
      track.stop();
    }

    const webcamVideo: any = document.getElementById('webcamVideo');
    if (webcamVideo !== undefined && webcamVideo !== null) {
      webcamVideo.src = undefined;
    }
  }

  /* Scanner methods */


  public destroyScanner(id: string) {
    this.applicationId = undefined;
    this.functionId = undefined;
    this.scannerList = [];
    this.scannedFiles = [];
    this.selectedScanner = undefined;
    this.transactionReferenceNumber = undefined;
    this.applicationReferenceNumber = undefined;
    this.needUpload = false;
    this.purposeList = [];
    this.filesUploadingScanner = 0;
    this.currentId = undefined;

    this.loadingScannerList = false;
    this.scanning = false;
    this.gettingFile = false;
    this.gettingFileCount = 0;

    this.scanCallback = this.scanCallback.filter((callback: any) => callback.id !== id);
  }

  public initScaner() {
    this.scannerList = [];
    this.scannedFiles = [];
    this.selectedScanner = undefined;
    this.currentId = undefined;

    this.loadingScannerList = false;
    this.scanning = false;
    this.gettingFile = false;
    this.gettingFileCount = 0;
    this.filesUploading = 0;
  }

  public dismissDialog() {
    if (this.documentScannerDialogRef !== undefined && this.documentScannerDialogRef !== null) {
      this.documentScannerDialogRef.close();
    }
  }

  public scanDocument(id: string) {
    this.documentScannerDialogRef = this.dialog.open(this.documentScannerTemplate, {
          width: '80vh'
    });
    this.documentScannerDialogRef.afterOpened().subscribe(result => {

    });

    this.documentScannerDialogRef.afterClosed().subscribe(result => {
      this.documentScannerDialogRef = undefined;
    });

    this.currentId = id;
    this.getScannerList();
  }

  public getScannerList() {
    this.loadingScannerList = true;
    this.deviceService.sendCommand('getScannerList', this.gotScannerList.bind(this));
  }

  public gotScannerList(scannerList: any) {
    this.scannerList = scannerList.devices;

    if (this.scannerList === undefined || this.scannerList === null || this.scannerList.length === 0) {
      this.alertService.showMessage('E_NO_SCANNERS', 'error');
      this.scannerList = [];
    }

    if (this.scannerList.length === 1) {
      this.selectedScanner = this.scannerList[0].Id;
    }

    this.loadingScannerList = false;
  }

  public startScan(): any {
    if (this.selectedScanner === undefined || this.selectedScanner === null) {
      this.alertService.showMessage('E_NO_SCANNER_SELECTED', 'error');
      return false;
    }

    if (this.scanning) {
      this.alertService.showMessage('E_SCAN_IN_PROGRESS', 'error');
      return false;
    }

    this.scanning = true;
    this.scannedFiles = [];
    this.gettingFileCount = 0;

    const additionalTags: any = {
      'deviceId': this.selectedScanner,
      'scanPath': this.configService.getConfig('global').scannedFilePath,
      'transactionReferenceNumber': this.transactionReferenceNumber,
      'deleteScannedFiles': false
    };

    this.deviceService.sendCommand('scanDocument', this.gotScannedDocument.bind(this), additionalTags);
  }

  public gotScannedDocument(scannedDocument: any) {
    this.scanning = false;

    if (scannedDocument.status) {
      this.scannedFiles = scannedDocument.files;

      this.getScannedFile();
    } else {
      this.alertService.showMessage('E_SCAN_FAILED', 'error');
    }
  }

  public getScannedFile() {
    this.gettingFile = true;
    this.scannedFiles.forEach(
      file => {
        file.fileId = v4();
        file.type = file.contentType;
        file.lastModifiedDate = new Date();
        file.lastModified = file.lastModifiedDate.getTime();
        file.part = 0;
        file.totalParts = 0;
        file.base64 = '';
        file.purposeList = this.purposeList;
        file.documentTypeList = [];
        file.purposeId = undefined;
        file.purposeDescription = undefined;
        file.documentTypeId = undefined;
        file.documentTypeDescription = undefined;

        this.gettingFileCount++;

        const additionalTags: any = {
          'fileId': file.fileId,
          'file': file.path,
          'part': 0,
          'deleteFile': false,
          'deleteFolder': false
        };

        this.getFilePart(additionalTags);
      }
    );
  }

  public gotFileScaner(scannedFile: any) {
    if (scannedFile.status) {
      for (let idx = 0; idx < this.scannedFiles.length; idx++) {
        if (this.scannedFiles[idx].fileId = scannedFile.fileId) {
          if (this.scannedFiles[idx].base64 === undefined || this.scannedFiles[idx].base64 === null) {
            this.scannedFiles[idx].base64 = '';
          }

          this.scannedFiles[idx].base64 = this.scannedFiles[idx].base64 + scannedFile.b64;
          this.scannedFiles[idx].part = scannedFile.part;
          this.scannedFiles[idx].totalParts = scannedFile.totalParts;

          if (scannedFile.part < scannedFile.totalParts) {
            const additionalTags: any = {
              'fileId': this.scannedFiles[idx].fileId,
              'file': this.scannedFiles[idx].path,
              'part': scannedFile.part,
              'deleteFile': false,
              'deleteFolder': false
            };

            if (scannedFile.part === scannedFile.totalParts - 1) {
              additionalTags.deleteFile = true;
              additionalTags.deleteFolder = true;
              this.gettingFileCount--;
            }

            this.getFilePart(additionalTags);
          } else {
            this.gettingFile = false;
            if (this.scanCallback) {
              this.scanCallback.forEach(
                (sc: any) => {
                  if (sc.id === this.currentId && sc.callback) {
                    sc.callback();
                  }
                }
              );
            }
          }
        }
      }
    } else {
      this.alertService.showMessage('E_DOCUMENT_FAILED', 'error');
    }
  }

  public async getFilePart(additionalTags: any) {
    await this.utilitiesService.delay(2000);
    this.deviceService.sendCommand('getDocument', this.gotFile.bind(this), additionalTags);
  }

  public onPurposeChangeScaner(file: any) {
    file.purposeList.forEach(
      (purpose: any) => {
        if (purpose.purposeId === file.purposeId) {
          file.documentTypeList = purpose.docList;
        }
      }
    );
  }


  public async uploadFileScaner() {
    let errorFlag = false;
    for (let idx = 0; idx < this.scannedFiles.length; idx++) {
      if (this.scannedFiles[idx].purposeId === undefined || this.scannedFiles[idx].purposeId === null || this.scannedFiles[idx].purposeId === '') {
        const parameter: Map<string, string> = new Map<string, string>();
        parameter.set('fileName', this.scannedFiles[idx].fileName);
        this.alertService.showMessage('E_FILE_PURPOSE', 'error', parameter);
        errorFlag = true;
      }

      if (this.scannedFiles[idx].documentTypeId === undefined || this.scannedFiles[idx].documentTypeId === null || this.scannedFiles[idx].documentTypeId === '') {
        const parameter: Map<string, string> = new Map<string, string>();
        parameter.set('fileName', this.scannedFiles[idx].fileName);
        this.alertService.showMessage('E_FILE_DOCUMENT_TYPE', 'error', parameter);
        errorFlag = true;
      }
    }

    if (errorFlag) {
      return;
    }

    this.filesUploadingScanner = 0;
    for (let idx = 0; idx < this.scannedFiles.length; idx++) {
      if (this.scannedFiles[idx].uploadStatus === 'U') {
        continue;
      }

      this.scannedFiles[idx].uploadStatus = 'W';
      this.filesUploadingScanner++;

      const finData = {
        tags: [
          this.transactionReferenceNumber,
          this.scannedFiles[idx].documentTypeId,
          this.scannedFiles[idx].documentTypeDescription,
          this.scannedFiles[idx].purposeId,
          this.scannedFiles[idx].purposeDescription
        ],
        purposeId: this.scannedFiles[idx].purposeId,
        purposeDescription: this.scannedFiles[idx].purposeDescription,
        documentTypeId: this.scannedFiles[idx].documentTypeId,
        documentTypeDescription: this.scannedFiles[idx].documentTypeDescription,
        clientFileId: this.scannedFiles[idx].fileId,
        transactionReferenceNumber: this.transactionReferenceNumber,
      };

      this.scannedFiles[idx].b64 = this.scannedFiles[idx].base64;
      const file: any = this.utilitiesService.base64ToFile(this.scannedFiles[idx]);
      try {
        const results = await this.uploadFileApi(
          this.applicationId,
          <any>this.functionId,
          this.transactionReferenceNumber,
          this.applicationReferenceNumber,
          finData,
          [file],
        );
        if (results) {
          if (results.status === 200) {
            if (results.body.responseStatus === 'SUCCESS') {
              this.scannedFiles.forEach(file => {
                if (file.clientFileId === results.body.finData.clientFileId) {
                  file.fileId = results.body.finData.fileId;
                  file.uploadStatus = 'U';
                }
              });
            } else {
              this.scannedFiles.forEach(file => {
                if (file.clientFileId === results.body.finData.clientFileId) {
                  file.uploadStatus = 'E';
                }
              });
            }
          }

          this.getFilesDetailsByFileId();
        }
      } catch (e) {
        this.alertService.showErrorMessage(e);
      }
      this.filesUploadingScanner--;

      if (this.filesUploadingScanner <= 0) {
        this.scannedFiles.forEach(file => {
          if (file.uploadStatus === 'W') {
            file.uploadStatus = 'E';
          }
        });
      }
    }
  }

  public base64ToFile(url: any, filename: any, mimeType: any) {
    return (fetch(url).then(function (res) {
        return res.arrayBuffer();
      })
        .then(function (buf) {
          return new File([buf], filename, {type: mimeType});
        })
    );
  }

  /* API's */

  public async uploadFileApi(
    applicationId: string | undefined,
    functionId: string,
    transactionReferenceNumber: string | undefined,
    applicationReferenceNumber: string | undefined,
    body: any,
    filetoupload: File[]): Promise<any> {
    /**TODO:- change to finfra */
    const path = this.configService.getConfig('global').configApi + this.configService.getConfig('workflow-api').uploadFile;

    const httpModel = new HttpCallModel(
      path,
      'POST',
      functionId,
      body,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      true,
      filetoupload,
      applicationId);

    httpModel.additionalFinfraHeaders = [{
      name: 'transactionReferenceNumber',
      value: transactionReferenceNumber
    }, {
      name: 'applicationReferenceNumber',
      value: applicationReferenceNumber
    }];
    return await this.httpService.callWS(httpModel).toPromise();
  }

  public async deleteFileApi(functionId: string, body: any): Promise<any> {
    const path = this.configService.getConfig('global').configApi + this.configService.getConfig('workflow-api').deleteFile;

    const httpModel = new HttpCallModel(
      path,
      'POST',
      functionId,
      body,
    );

    return await this.httpService.callWS(httpModel).toPromise();
  }

  public async getUrlForFileApi(functionId: string, body: any): Promise<any> {
    const path = this.configService.getConfig('global').configApi + this.configService.getConfig('workflow-api').getUrlForFile;
    const httpModel = new HttpCallModel(
      path,
      'POST',
      functionId,
      body,
    );
    return await this.httpService.callWS(httpModel).toPromise();

  }

  public async downloadFileApi(functionId: string, body: any): Promise<any> {
    const path = this.configService.getConfig('global').configApi + this.configService.getConfig('workflow-api').downloadFile;
    const httpModel = new HttpCallModel(
      path,
      'POST',
      functionId,
      body,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      true);
    return await this.httpService.callWS(httpModel).toPromise();
  }

  public async getFileDetailsByReferenceIdApi(referenceId: string, functionId: string, transactionReferenceNumber: string, applicationReferenceNumber: string) {

    const path = this.configService.getConfig('global').configApi + this.configService.getConfig('workflow-api').getFileInformation + '?fileReferenceId=' + referenceId;
    const httpModel = new HttpCallModel(
      path,
      'POST',
      functionId,
      undefined
    );

    httpModel.additionalFinfraHeaders = [
      {
        name: 'transactionReferenceNumber',
        value: transactionReferenceNumber
      },
      {
        name: 'applicationReferenceNumber',
        value: applicationReferenceNumber
      }
    ];

    return await this.httpService.callWS(httpModel).toPromise();

  }

  public getPurposeDescription(purposeId): string {
    for (const purpose of this.purposeList) {
      if (purpose.purposeId === purposeId) {
        return purpose.purposeDescription;
      }
    }

    return '';
  }

  public getDocumentTypeDescription(purposeId, documentTypeId): string {
    for (const purpose of this.purposeList) {
      if (purpose.purposeId === purposeId) {
        for (const documentType of purpose.docList) {
          if (documentType.id === documentTypeId) {
            return documentType.description;
          }
        }
      }
    }

    return '';
  }

  public setValues(file, type) {
    if (type === 'purpose') {
      for (const purpose of this.purposeList) {
        if (purpose.purposeId === file.purposeId) {
          file.purposeDescription = purpose.purposeDescription;
        }
      }
    } else if (type === 'documentType') {
      for (const purpose of this.purposeList) {
        if (purpose.purposeId === file.purposeId) {
          for (const documentType of purpose.docList) {
            if (documentType.id === file.documentTypeId) {
              file.documentTypeDescription = documentType.description;
            }
          }
        }
      }
    }
  }

}
