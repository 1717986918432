import { EWidget } from "./elements/widgets";
import { IArrayItemProperty, ICopyToProperty, IInitializeProperty, IWidgetProperty } from "./inner-models";

export enum ELocalStoreKeys {
  BPMN_FILE = 'bpmnFile',
  STAGES_TREE = 'stagesTree',
  NAME = 'name',
  WIDGETS = 'widgets',
  UI_STAGES = 'uiStages'
}

export enum EStoreEntities {
  MAIN = 'businessProcess',
  JSON = 'jsons'
}

export enum EStagesMode {
  VIEW = 'view',
  EDIT = 'edit'
}

export enum EDirection {
  UP = 'up',
  DOWN = 'down'
}

export enum EStageNodeType {
  STAGE = 'stage',
  MODE = 'mode',
  UISTAGE = 'uiStage',
  GATEWAY = 'gateway'
}

export enum BPMNStageNodeType {
  TASK = 'bpmn:UserTask',
  GATEWAY = 'bpmn:EventBasedGateway',
}

export const FINAL_STAGE = {
  id: 'COMPLETE',
  name: 'Complete',
  type: '',
  attr: {},
  showOnlyIfStageIsCurrent: false,
  children: {
    view: [],
    edit: []
  }
}

export enum EButtonType {
  STROKED = 'stroked',
  RAISED = 'raised',
  FLAT = 'flat'
}

export enum ESelectedElTypes {
  WIDGET = 'widget',
  LAYOUT = 'layout',
  BUTTON = 'toolbarButton'
}

export const PositionList = ['start', 'end', 'top'];
export const ButtonType = Object.values(EButtonType);


export enum EFormFieldType {
  TEXT = 'text',
  CHECKBOX = 'checkbox',
  LIST = 'list',
  NUMBER = 'number',
  ARRAY = 'array'
}

export enum ESpecificFormType {
  COPY_VALUE_TO = 'copyValueTo',
  COPY_TO = 'copyTo',
  DEFAULT = 'default',
  INITILIZE_FROM_PROPERTY = 'initializeFrom'
}

export enum ESection {
  LAYOUT = 'layout',
  PROPERTY = 'property'
}

export type WidgetPropertyType = string | boolean | number | IArrayItemProperty[] | string[] | ICopyToProperty[] | IInitializeProperty;

export type FormFieldType = EFormFieldType | ESpecificFormType;

export enum ESchemaItemType {
  OBJECT = 'object',
  STRING = 'string',
  ARRAY = 'array'
}

export enum EPrimitiveType {
  STRING = 'string',
  BOOLEAN = 'boolean',
  NUMBER = 'number'
}
