import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { JsonSchemaFormComponent } from './json-schema-form.component';
import { NoFrameworkModule } from './framework-library/no-framework.module';
import { WidgetLibraryModule } from './widget-library/widget-library.module';
import { MatFormFieldReadonlyDirective } from './mat-formfield-readonly/mat-formfield-readonly.directive';

@NgModule({
  imports: [
    CommonModule, FormsModule, ReactiveFormsModule,
    WidgetLibraryModule, NoFrameworkModule
  ],
  declarations: [JsonSchemaFormComponent, MatFormFieldReadonlyDirective],
  exports: [JsonSchemaFormComponent, WidgetLibraryModule, MatFormFieldReadonlyDirective]
})
export class JsonSchemaFormModule {
}
