import { Injectable } from "@angular/core";
import { HttpCallModel, PageModel, ParameterModel } from "@finfra/core-models";
import { ConfigService, HttpService } from "../core-services";

@Injectable({
    providedIn: 'root'
  })

export class FinfraAdviceService {

    public adviceWindow: any;
    constructor(
        private configService: ConfigService,
        private httpService: HttpService
    ){
       
    }
    public getAdvice(functionId: string, body: any, parameterModel: ParameterModel, transactionReferenceNumber: string): Promise<any> {
        const path = this.configService.getConfig("global").configApi + this.configService.getConfig("workflow-api").getAdvices;
        const queryParameters: ParameterModel = new ParameterModel();
        queryParameters.stringParameters = parameterModel.stringParameters;

        
        let pageModel = new PageModel(); 
        pageModel.currentPageNumber = 0;
        pageModel.recordsPerPage = 9999;

        let httpCallModel = new HttpCallModel(
                path,
                'POST',
                functionId,
                body,
                parameterModel,
                pageModel
              );

              httpCallModel.additionalFinfraHeaders = [
                {
                  "name": "transactionReferenceNumber",
                  "value": transactionReferenceNumber
                }
              ];
              return this.httpService.callWS(httpCallModel).toPromise();

    }

    public showAdvice(adviceContent: string) {
        if (this.configService.getConfig("global").showPresentationWindow === false) {
            
                this.adviceWindow = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
                this.adviceWindow.document.open();
                this.adviceWindow.document.write(`${adviceContent}`);
                this.adviceWindow.document.close();
            
        } else {
            this.adviceWindow = window.open('assets/advices/general-advice-wrapper.html', '_blank', 'top=0,left=0,height=100%,width=auto');
            this.adviceWindow.window.onload = this.onAdviceWindowLoad.bind(this, adviceContent);
        }
    }


    public onAdviceWindowLoad(adviceContent: string) {
        this.adviceWindow.loadContent(adviceContent);
        this.adviceWindow.initialize();
    }
}