export interface SelectItemModel {
  name?: string;
  value?: string;
}

export class SelectItemModel implements SelectItemModel {
  constructor(
    public name?: string,
    public value?: string,
  ) { }
}
