export interface GenericParameterModel {
  name?: string;
  value?: any;
}

export class GenericParameterModel implements GenericParameterModel {
  constructor(
    public name?: string,
    public value?: any,
  ) { }
}
