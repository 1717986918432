import { AbstractControl, FormControl } from '@angular/forms';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { getControl, JsonSchemaFormService } from '@finfra/ajsf-core';
import { AjsfCoreWidgetsFunctionsService } from '../../../asjf-core-widgets.functions';
import { AlertService } from '@finfra/core-services';
import { FileModel } from '@finfra/core-models';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'finfra-file-widget',
  templateUrl: './finfra-file.component.html',
  styleUrls: ['./finfra-file.component.scss'],
})
export class FinfraFileComponent implements OnInit, OnDestroy {
  widgetName = 'FinfraFileComponent';
  formControl: AbstractControl;
  controlName: string;
  controlValue: any;
  controlDisabled = false;
  boundControl = false;
  options: any;
  subProperties: any;
  fullObject: any;
  @Input() layoutNode: any;
  @Input() layoutIndex: number[];
  @Input() dataIndex: number[];

  constructor(
    private jsf: JsonSchemaFormService,
    private ajsfCoreWidgetsFunctionsService: AjsfCoreWidgetsFunctionsService,
    private alertService: AlertService,
  ) { }

  ngOnInit() {
    this.options = this.layoutNode.options || {};
    this.subProperties = this.layoutNode.subProperties || {};
    this.jsf.initializeControl(this);
  }

  ngOnDestroy(): void {
    this.jsf = undefined;
    this.formControl = undefined;
    this.controlName = undefined;
    this.controlValue = undefined;
    this.controlDisabled = undefined;
    this.boundControl = undefined;
    this.options = undefined;
    this.subProperties = undefined;
  }

  updateValue() {
    this.fullObject = this.controlValue;
    this.jsf.updateObjectValue(this, this.controlValue);
    this.ajsfCoreWidgetsFunctionsService.dispatchEvent(this);
  }

  fileSelected(event) {
    this.controlValue = new FileModel();
    this.controlValue.fileName = event.currentTarget.files[0].name;
    this.controlValue.fileType = event.currentTarget.files[0].type;
    this.controlValue.fileExtension = event.currentTarget.files[0].name.substr(event.currentTarget.files[0].name.lastIndexOf('.'));
    this.controlValue.fileUrl = event.currentTarget.files[0].name;
    this.controlValue.fileLastModified = event.currentTarget.files[0].lastModified;
	this.controlValue.fileLastModifiedDate = event.currentTarget.files[0].lastModifiedDate;
    this.controlValue.fileSize = event.currentTarget.files[0].size;
    this.controlValue.fileBase64 = null;
    this.updateValue();

    if (this.options.readAsText) {
      this.getText(event.currentTarget.files[0]).then(
        data => {
          this.controlValue.fileBase64 = data;
          this.updateValue();
        },
        error => {
          this.alertService.error('FinfraFileComponent: Error reading file content as text');
          this.alertService.error(error);
        }
      );
    } else {
      this.getBase64(event.currentTarget.files[0]).then(
        data => {
          this.controlValue.fileBase64 = data;
          this.updateValue();
        },
        error => {
          this.alertService.error('FinfraFileComponent: Error reading file content as base64');
          this.alertService.error(error);
        }
      );
    }
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  getText(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsText(file, 'UTF-8');
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  downloadFile() {

  }
}
