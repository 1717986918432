import {
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional, ViewChild
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { JsonSchemaFormService } from '@finfra/ajsf-core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import {CurrencyService, UtilitiesService} from '@finfra/core-services';
import { AjsfCoreWidgetsFunctionsService } from '../../../asjf-core-widgets.functions';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {ObjectChanges} from '@finfra/core-models';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'finfra-object-difference-widget',
  templateUrl: './finfra-object-difference.component.html',
  styleUrls: ['./finfra-object-difference.component.scss'],
})
export class FinfraObjectDifferenceComponent implements OnInit, OnDestroy {
  widgetName = 'FinfraObjectDifferenceComponent';
  formControl: AbstractControl;
  controlName: string;
  controlValue: any;
  controlDisabled = false;
  boundControl = false;
  options: any;
  subProperties: any;

  @Input() layoutNode: any;
  @Input() layoutIndex: number[];
  @Input() dataIndex: number[];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public datasource: MatTableDataSource<ObjectChanges>;
  public displayedColumns: string[];

  constructor(
    @Inject(MAT_FORM_FIELD_DEFAULT_OPTIONS) @Optional() public matFormFieldDefaultOptions,
    private jsf: JsonSchemaFormService,
    public currencyService: CurrencyService,
    private ajsfCoreWidgetsFunctionsService: AjsfCoreWidgetsFunctionsService,
    private utilitiesService: UtilitiesService
  ) { }

  ngOnInit(): void {
    this.options = this.layoutNode.options || {};
    this.subProperties = this.layoutNode.subProperties || {};
    this.jsf.initializeControl(this);

    if (!this.options.notitle && !this.options.description && this.options.placeholder) {
      this.options.description = this.options.placeholder;
    }

    this.displayedColumns = this.options.displayedColumns;
  }

  ngOnDestroy(): void {
    this.jsf = undefined;
    this.formControl = undefined;
    this.controlName = undefined;
    this.controlValue = undefined;
    this.controlDisabled = undefined;
    this.boundControl = undefined;
    this.options = undefined;
    this.subProperties = undefined;
  }

  public findDifference(): void {
    let data: ObjectChanges[] = [];
    this.options.dataPointers.forEach(
      dataPointer => {
        const object1Value = this.utilitiesService.deepCopy(this.jsf.getControlService(this, this.jsf.formGroup, dataPointer.oldDataPointer).value);
        const object2Value = this.utilitiesService.deepCopy(this.jsf.getControlService(this, this.jsf.formGroup, dataPointer.newDataPointer).value);

        const diffData = this.utilitiesService.findObjectDiff(object1Value, object2Value, true, dataPointer.arrayKey || this.options.arrayKey, '/', [], [], dataPointer.labels || this.options.labels, dataPointer.params || this.options.params) || [];

        data = [...data, ...diffData];
      }
    );

    this.datasource = new MatTableDataSource<ObjectChanges>(data);
    this.datasource.sort = this.sort;
    this.datasource.paginator = this.paginator;
  }
}
