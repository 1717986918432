import { InitializerService } from '@finfra/core-services';
import * as globalConfig from 'src/assets/configs/globalConfig.json';


export function initFInfra (
  appInitializeService: InitializerService,
) {
  return () => {
    return appInitializeService.initialize(globalConfig);
  };
}
