import { Injectable } from '@angular/core';
import { AlertService } from './alert.service';
import { DateService } from './date.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  public currentLocation: GeolocationPosition | undefined;
  public lastLocationFetchTime: Date | undefined;

  public geoLocationOption: PositionOptions = {
    maximumAge: Infinity,
    timeout: 5000,
    enableHighAccuracy: true
  };

  constructor(
    private alertService: AlertService,
    private dateService: DateService
  ) { }

  public fetchLocation(callback?: Function, force?: boolean): void {
    if (navigator.geolocation) {
      if (
        this.lastLocationFetchTime === undefined ||
        this.lastLocationFetchTime === null ||
        force === true
      ) {
        this.getLocation(callback);
      } else {
        const seconds: number = this.dateService.getSecondsDiff(
          new Date(),
          this.lastLocationFetchTime
        );

        if (seconds > 600) {
          this.getLocation(callback);
        } else if (callback) {
          callback(this.currentLocation);
        }
      }
    } else {
      this.alertService.showMessage('E_NO_LOCATION', 'error');

      if (callback) {
        callback(null);
      }
    }
  }

  private getLocation(callback?: Function): void {
    if (window.navigator && window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(
        position => {
          this.lastLocationFetchTime = new Date();
          this.currentLocation = position;

          if (callback) {
            callback(this.currentLocation);
          }
        },
        error => {
          this.showError(error);
          if (callback) {
            callback(null);
          }
        },
        this.geoLocationOption
      );
    }
  }

  private showError(error: GeolocationPositionError): void {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        this.alertService.error('E_POSITION_PERMISSION_DENIED');
        break;
      case error.POSITION_UNAVAILABLE:
        this.alertService.error('E_POSITION_POSITION_UNAVAILABLE');
        break;
      case error.TIMEOUT:
        this.alertService.error('E_POSITION_TIMEOUT');
        break;
      default:
        this.alertService.error('E_POSITION_UNKNOWN_ERROR');
        this.alertService.error(error.message);
        break;
    }
  }
}
