import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SecureGetPipe} from './secure-get.pipe';

@NgModule({
  declarations: [SecureGetPipe],
  imports: [CommonModule],
  exports: [SecureGetPipe]
})
export class SecureGetModule {}
