import {AbstractControl} from '@angular/forms';
import {Component, Inject, Input, OnDestroy, OnInit, Optional, TemplateRef, ViewChild} from '@angular/core';
import {JsonSchemaFormService} from '@finfra/ajsf-core';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {AlertService, HttpService} from '@finfra/core-services';
import {MatDialog} from '@angular/material/dialog';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import {AjsfCoreWidgetsFunctionsService} from '../../../asjf-core-widgets.functions';
import {Observable, Subscription} from 'rxjs';
import {HttpCallModel} from '@finfra/core-models';
import {HttpResponse} from '@angular/common/http';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'finfra-embed-widget',
  templateUrl: './finfra-embed.component.html',
  styleUrls: ['./finfra-embed.component.scss'],
})
export class FinfraEmbedComponent implements OnInit, OnDestroy {
  widgetName = 'FinfraMaterialEmbedComponent';
  functionId = 'finfra-ajsf-embed';
  formControl: AbstractControl;
  controlName: string;
  controlValue: any;
  controlDisabled = false;
  boundControl = false;
  options: any;
  subProperties: any;
  autoCompleteList: string[] = [];
  selectedIdx = 0;
  formControlChangeSubscription: Subscription;
  docType: any;
  selectedDoc: any;
  isArray = true;
  @Input() layoutNode: any;
  @Input() layoutIndex: number[];
  @Input() dataIndex: number[];

  @ViewChild('imageExpandDialog', {static: false}) imageExpandDialog: TemplateRef<any>;

  constructor(
    @Inject(MAT_FORM_FIELD_DEFAULT_OPTIONS) @Optional() public matFormFieldDefaultOptions,
    private jsf: JsonSchemaFormService,
    private alertService: AlertService,
    private dialog: MatDialog,
    private domSanitizer: DomSanitizer,
    private ajsfCoreWidgetsFunctionsService: AjsfCoreWidgetsFunctionsService,
    private httpService: HttpService
  ) {
  }

  ngOnInit() {
    this.options = this.layoutNode.options || {};
    this.subProperties = this.layoutNode.subProperties || {};
    this.jsf.initializeControl(this);
    if (!this.options.notitle && !this.options.description && this.options.placeholder) {
      this.options.description = this.options.placeholder;
    }

    this.formControlChangeSubscription = this.formControl.valueChanges
      .pipe(
        debounceTime(800),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
      )
      .subscribe(this.onFormChange.bind(this));
  }

  ngOnDestroy(): void {
    this.jsf = undefined;
    this.formControl = undefined;
    this.controlName = undefined;
    this.controlValue = undefined;
    this.controlDisabled = undefined;
    this.boundControl = undefined;
    this.options = undefined;
    this.subProperties = undefined;

    if (this.formControlChangeSubscription) {
      this.formControlChangeSubscription.unsubscribe();
    }
  }

  onFormChange(value: any) {
    if (Array.isArray(value)) {
      this.isArray = true;
    } else {
      this.isArray = false;
    }
    this.jsf.updateValue(this, value);
  }

  updateValue(event) {
    this.jsf.updateValue(this, event.target.value);
  }

  expandImage() {

    this.dialog.open(this.imageExpandDialog, {
      width: '90vh',
    });
  }

  onImageClick(event) {
    this.alertService.info('Finfra Embed: on select change ' + this.controlValue);
    this.alertService.info(event);
    this.selectedDoc = event;

    this.expandImage();
    this.ajsfCoreWidgetsFunctionsService.dispatchEvent(this);
  }

  async getImageObject(src: { image: string, fileType: string, fileName: string, fileBase64: string }): Promise<SafeUrl> {
    if (src === undefined || src === null) {
      return;
    }

    let image = src.image ? src.image : null;

    if (src.fileBase64 === null || src.fileBase64 === undefined && (image !== null || image !== undefined)) {
      const httpCallModel = new HttpCallModel(image, 'GET', this.functionId, undefined);
      httpCallModel.fetchFile = true;
      httpCallModel.responseType = 'blob';

      const response = await this.httpService.callWS(httpCallModel).toPromise().catch(
        error => {
          return undefined;
        }
      );

      if (response && response instanceof HttpResponse) {
        await new Promise(
          (resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(response.body);
            reader.onloadend = function() {
              src.fileBase64 = reader.result.toString();
              resolve(src.fileBase64);
            };
            reader.onerror = error => reject(error);
          }
        );

        image = src.fileBase64;
      } else {
        return '';
      }
    } else if (src.fileBase64.indexOf('data:') < 0 && src.fileBase64.indexOf('file:') < 0 && src.fileBase64.indexOf('content:') < 0) {
      image = 'data:image/png;base64,' + src.fileBase64;
    } else {
      image = src.fileBase64;
    }

    return this.domSanitizer.bypassSecurityTrustResourceUrl(image);
  }

  getDocType(src: { image: string, fileType: string, fileName: string, fileBase64: string, fileSize: number }) {
    if (src === undefined || src === null) {
      return undefined;
    }

    const fileType = src.fileType ? src.fileType : null;

    if (src.fileType === 'jpg' || src.fileType === 'png' || src.fileType === 'jpeg') {
      src.fileType = 'image/' + src.fileType;
    } else if (src.fileType === 'pdf' || src.fileType === 'application/pdf') {
      src.fileType = 'application/pdf';

      if (!!src.fileBase64 && src.fileSize > 2097152) {
        this.alertService.showMessage('I_BROWSER_RESTRICTION_TO_PREVIEW', 'info');
        return undefined;
      }
    } else if (src.fileType === 'txt') {
      src.fileType = 'text/plain';
    } else {
      src.fileType = src.fileType;
    }

    return src.fileType;
  }

}
