export interface AlertModel {
  alertCode?: string;
  alertType?: string;
  alertMessage?: string;
  duration?: number;
  interval?: any | undefined;
  showCode?: boolean;
  timestamp?: Date;
}

export class AlertModel implements AlertModel {
  constructor(
    public alertCode?: string,
    public alertType?: string,
    public alertMessage?: string,
    public duration?: number,
    public interval?: any | undefined,
    public showCode?: boolean,
    public timestamp?: Date,
  ) { }
}
