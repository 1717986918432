import {AbstractControl, FormArray, FormControl, FormGroup} from '@angular/forms';
import {
  Component,
  Inject,
  Input,
  OnInit,
  Optional,
  ViewChild,
  OnDestroy,
  TemplateRef, AfterViewInit
} from '@angular/core';
import {JsonSchemaFormService, isArray} from '@finfra/ajsf-core';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {AlertService, DateService} from '@finfra/core-services';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {trigger, state, style, animate, transition} from '@angular/animations';
import {DomSanitizer} from '@angular/platform-browser';
import {MatDialog} from '@angular/material/dialog';
import {AjsfCoreWidgetsFunctionsService} from '../../../asjf-core-widgets.functions';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Subscription} from 'rxjs';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'finfra-table-expandable-widget',
  templateUrl: './finfra-table-expandable.component.html',
  styleUrls: ['./finfra-table-expandable.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class FinfraTableExpandableComponent implements OnInit, OnDestroy, AfterViewInit {
  widgetName = 'FinfraTableExpandableComponent';
  formControl: FormArray;
  controlName: string;
  controlValue: any;
  dataSource: MatTableDataSource<any>;
  controlDisabled = false;
  boundControl = false;
  options: any;
  subProperties: any;
  items: any;
  selectList: any[] = [];
  isArray = isArray;
  selectData: any;
  dispatchEvents: any[];
  listenValue: Map<string, string>;
  listValues: any;
  displayedColumns: string[];
  detailedColumns: string[];
  displayedColumnsList: Map<string, any>;
  displayedColumnCount = 0;
  pagination: number[] = [5, 10, 25];
  selectedImage: any;
  allowMultiExpand = false;
  formControlChangeSubscription: Subscription;
  width = '';

  @ViewChild('imageExpandDialog', {static: false}) imageExpandDialog: TemplateRef<any>;

  @Input() layoutNode: any;
  @Input() layoutIndex: number[];
  @Input() dataIndex: number[];

  _paginator: MatPaginator;
  _sort: MatSort;

  @ViewChild(MatPaginator, {static: false})
  set paginator(paginator: MatPaginator) {
    this._paginator = paginator;

    if (this.dataSource) {
      this.dataSource.paginator = this._paginator;
    }
  }

  @ViewChild(MatSort, {static: false})
  set sort(sort: MatSort) {
    this._sort = sort;

    if (this.dataSource) {
      this.dataSource.sort = this._sort;
    }
  }

  constructor(
    @Inject(MAT_FORM_FIELD_DEFAULT_OPTIONS) @Optional() public matFormFieldDefaultOptions,
    private jsf: JsonSchemaFormService,
    public dateService: DateService,
    private alertService: AlertService,
    private domSanitizer: DomSanitizer,
    private dialog: MatDialog,
    private ajsfCoreWidgetsFunctionsService: AjsfCoreWidgetsFunctionsService
  ) {
  }

  ngOnInit() {
    this.alertService.info('FinfraMaterialTableExpandableEditableComponent:  in display table');

    this.options = this.layoutNode.options || {};
    this.subProperties = this.layoutNode.subProperties || {};
    this.items = this.layoutNode.items || {};

    if (this.options.showPages === undefined) {
      this.options.showPages = true;
    }

    if (this.options.pagination && this.options.pagination.pageSizes && this.options.pagination.pageSizes.length > 0) {
      this.pagination = this.options.pagination.pageSizes;
    }

    if (this.options.showFilter === undefined) {
      this.options.showFilter = true;
    }

    this.allowMultiExpand = this.options.allowMultiExpand;

    if (this.allowMultiExpand === undefined || this.allowMultiExpand === null) {
      this.allowMultiExpand = false;
    }

    this.jsf.initializeControl(this, !this.options.readonly);

    this.displayTable();

    this.formControlChangeSubscription = this.formControl.valueChanges
      .pipe(
        debounceTime(800),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
      )
      .subscribe(this.onControlChange.bind(this));
  }

  ngOnDestroy(): void {
    this.jsf = undefined;
    this.formControl = undefined;
    this.controlName = undefined;
    this.controlValue = undefined;
    this.controlDisabled = undefined;
    this.boundControl = undefined;
    this.options = undefined;
    this.subProperties = undefined;

    if (this.formControlChangeSubscription) {
      this.formControlChangeSubscription.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    this.width = document.getElementById('finfraTableExpandable' + this.layoutNode?._id).clientWidth + 'px';
  }

  onControlChange() {
    this.displayTable();
  }

  updateValue() {
    this.assignRowIndex();
    this.formControl.setValue(this.controlValue);
    this.ajsfCoreWidgetsFunctionsService.dispatchEvent(this);
  }

  assignRowIndex() {
    if (this.formControl.value && this.formControl.value.length > 0) {
      let idx = 0;
      this.formControl.controls.forEach(
        (rowControl: FormGroup) => {
          let skipRow = true;
          Object.keys(rowControl.value).forEach(
            (value) => {
              if (rowControl.value[value] !== null) {
                skipRow = false;
              }
            }
          );

          if (!skipRow) {
            let rowIndexControl: AbstractControl = rowControl.get('rowIndex');

            if (!rowIndexControl) {
              rowControl.addControl('rowIndex', new FormControl());
              rowIndexControl = rowControl.get('rowIndex');
            }

            rowIndexControl.setValue(idx);

            if (!rowControl.get('expanded')) {
              rowControl.addControl('expanded', new FormControl());
            }

            if (rowControl.get('expanded').value === undefined || rowControl.get('expanded').value === null) {
              rowControl.get('expanded').setValue(false);
            }

            idx++;
          }
        }
      );
    }
  }

  hasValidRow(data: any): boolean {
    let response = true;

    if (data === undefined || data === null || !data.length || data.length === 0) {
      return false;
    } else {
      data.forEach(
        row => {
          if (response) {
            Object.keys(row).forEach(
              (value) => {
                if (response) {
                  if (row[value] !== null) {
                    response = false;
                  }
                }
              }
            );
          }
        }
      );
    }

    return !response;
  }

  displayTable() {
    this.controlValue = this.formControl.value;

    if (this.hasValidRow(this.formControl.value)) {
      this.dataSource = new MatTableDataSource(this.formControl.value);
    } else {
      this.dataSource = new MatTableDataSource();
    }

    if (this.options.showPages === true) {
      this.dataSource.paginator = this._paginator;
    }

    this.dataSource.sort = this._sort;

    this.displayedColumns = [];
    this.detailedColumns = [];
    this.displayedColumnsList = new Map<string, any>();
    this.displayedColumnCount = 0;

    let items: any[];

    if (this.items[0].items) {
      items = this.items[0].items;
    } else {
      items = this.items;
    }

    items.forEach(item => {
      const displayedColumn: any = new Object();
      displayedColumn.id = item.name;
      displayedColumn.headerText = item.options.title;
      displayedColumn.type = item.type;
      displayedColumn.width = item.options.width;
      displayedColumn.labelFlex = item.options.labelFlex;
      displayedColumn.dataFlex = item.options.dataFlex;
      displayedColumn.required = item.required;
      displayedColumn.readonly = item.options.readonly;
      displayedColumn.min = item.options.min;
      displayedColumn.max = item.options.max;
      displayedColumn.titleMap = item.options.titleMap;
      displayedColumn.notitle = item.options.notitle;
      displayedColumn.expandButton = item.options.expandButton;

      if (displayedColumn.notitle === undefined) {
        displayedColumn.notitle = false;
      }

      if (displayedColumn.readonly === undefined) {
        displayedColumn.readonly = false;
      }

      if (displayedColumn.required === undefined) {
        displayedColumn.required = false;
      }

      if (displayedColumn.notitle === false) {
        displayedColumn.label = displayedColumn.headerText;
      }

      if (displayedColumn.expandButton === undefined) {
        displayedColumn.expandButton = false;
      }

      if (displayedColumn.width === undefined) {
        displayedColumn.width = '*';
      }

      if (item.options.detailed) {
        displayedColumn.detailed = true;
        this.detailedColumns.push(item.name);
      } else {
        this.displayedColumnCount++;
        displayedColumn.detailed = false;
        this.displayedColumns.push(item.name);
      }

      this.displayedColumnsList.set(item.name, displayedColumn);
    });

  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  modelChanged(event: any) {
    this.controlValue = this.dataSource.data;
    this.updateValue();
  }

  rowClicked(row) {
    this.alertService.info('Row clicked');
    this.alertService.info(row);

    if (this.formControl.value && this.formControl.value.length > 0) {
      this.formControl.controls.forEach(
        (rowControl: FormGroup) => {
          if (JSON.stringify(rowControl.value) === JSON.stringify(row)) {
            if (rowControl.get('expanded').value === true) {
              rowControl.get('expanded').setValue(false);
            } else {
              rowControl.get('expanded').setValue(true);
            }
          } else if (this.allowMultiExpand === false) {
            rowControl.get('expanded').setValue(false);
          }
        }
      );
    }

    this.dataSource.data = this.formControl.value;
    this.controlValue = this.formControl.value;
    this.updateValue();
  }

  getImageObject(src: string) {
    if (src === undefined || src === null) {
      return undefined;
    }

    if (src.indexOf('data:') < 0) {
      src = 'data:image/png;base64,' + src;
    }

    return this.domSanitizer.bypassSecurityTrustResourceUrl(src);
  }

  public expandImage() {
    this.dialog.open(this.imageExpandDialog, {
      width: '90vh',
    });
  }

  onImageClick(imageText: any) {
    this.alertService.info('FinfraMaterialTableExpandableEditableComponent: on select change ' + this.controlValue);
    this.alertService.info(event);

    this.selectedImage = imageText;

    this.expandImage();
  }

  dispatchEvent(row) {
    this.ajsfCoreWidgetsFunctionsService.dispatchEvent(this, row);
  }
}
