import { Component, Injectable, Type } from '@angular/core';
import { Route, Router, Routes } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RouterConfigService {

  constructor(
    private router: Router,
  ) {

  }

  public addLayout(layoutRouterConfig: Route): boolean {
    let routes: Routes = this.router.config;

    if (!routes) {
      routes = [];
    }

    routes = [...new Set([...routes,...[layoutRouterConfig]])]

    this.router.resetConfig(routes);

    return true;
  }

  public addRoute(childRouterConfig: Route, layoutComponentName: string): boolean {
    let routes: Routes = this.router.config;

    if (!routes) {
      routes = [];
    }

    let layoutPresent: boolean = false;
    for (let idx = 0; idx < routes.length; idx++) {
      let data = routes[idx].data;
      if (data && data.componentName && data.componentName === layoutComponentName) {
        layoutPresent = true;

        let children = routes[idx].children;

        if (!children) {
          children = []
        }

        routes[idx].children = [...new Set([...children, ...[childRouterConfig]])];

        break;
      }
    }

    this.router.resetConfig(routes);

    return true;
  }
}
