import { Injectable } from "@angular/core";
import { ConfigService } from "./config.service";
import { DatePipe } from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class ConvertObjectService {
  private dateRegex = /^(\d{4})-(\d{2})-(\d{2})$/;
  private dbTimeRegex = /^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2}(?:\.\d*(?:\+\d*)?)?)$/;
  private dateTimeRegex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*(?:\+\d*)?)?)$/;
  private utcDateRegex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*(?:\+\d*)?)?)Z$/;

  constructor(
    private configService: ConfigService,
    private datepipe: DatePipe,
  ) { }

  private formatDateServer(dt: Date): string | undefined | null {
    let df: string;

    if (this.configService.getConfig("global") === undefined ||
      this.configService.getConfig("global").dateFormatServer === undefined ||
      this.configService.getConfig("global").dateFormatServer === null ||
      this.configService.getConfig("global").dateFormatServer === "") {
      df = "yyyy-MM-dd";
    } else {
      df = this.configService.getConfig("global").dateFormatServer;
    }

    return this.datepipe.transform(dt, df);
  }

  public convertDates(object: any) {
    if (!object || !(object instanceof Object)) {
      return;
    }

    if (object instanceof Array) {
      for (const item of object) {
        this.convertDates(item);
      }
    }

    for (const key of Object.keys(object)) {
      const value = object[key];

      if (value instanceof Array) {
        for (const item of value) {
          this.convertDates(item);
        }
      }

      if (value instanceof Object) {
        this.convertDates(value);
      }

      if (value instanceof Date) {
        object[key] = this.formatDateServer(new Date(value));
      } else if (typeof value === 'string' && (this.dateTimeRegex.test(value) || this.utcDateRegex.test(value))) {
        object[key] = this.formatDateServer(new Date(value));
      }
    }
  }

  public convertStringToDates(object: any) {
    if (!object || !(object instanceof Object)) {
      return;
    }

    if (object instanceof Array) {
      for (const item of object) {
        this.convertStringToDates(item);
      }
    }

    for (const key of Object.keys(object)) {
      const value = object[key];

      if (value instanceof Array) {
        for (const item of value) {
          this.convertStringToDates(item);
        }
      }

      if (value instanceof Object) {
        this.convertStringToDates(value);
      }

      if (typeof value === 'string' && (this.dateRegex.test(value) || this.dateTimeRegex.test(value) || this.utcDateRegex.test(value) || this.dbTimeRegex.test(value))) {
        object[key] = new Date(value);
      }
    }
  }
}
