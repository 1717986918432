export interface HeaderModel {
  userId?: string | undefined;
  language?: string | undefined;
  legalEntityId?: string | undefined;
	legalEntityCode?: string | undefined;
  branchCode?: string | undefined;
  functionId?: string | undefined;
  applicationId?: string | undefined;
	applicationReferenceNumber?: string | undefined;
	transactionReferenceNumber?: string | undefined;
  applicationDate?: Date | undefined;
	sourceSystemUserId?: string | undefined;
	targetSystemUserId?: string | undefined;
	userAgent?: string | undefined;
	deviceId?: string | undefined;
	versionNo?: string | undefined;
  transactionDateTime?: string | undefined;
	offlineRequest?: boolean | undefined;
	location?: string | undefined;
	esbReferenceNumber?: string | undefined;
	additionalHeaders?: any;
    tenantId?: string | undefined;

}

export class HeaderModel implements HeaderModel {
  constructor(
    public userId?: string | undefined,
    public language?: string | undefined,
    public legalEntityId?: string | undefined,
    public legalEntityCode?: string | undefined,
    public branchCode?: string | undefined,
    public functionId?: string | undefined,
    public applicationId?: string | undefined,
    public applicationReferenceNumber?: string | undefined,
    public transactionReferenceNumber?: string | undefined,
    public applicationDate?: Date | undefined,
    public sourceSystemUserId?: string | undefined,
    public targetSystemUserId?: string | undefined,
    public userAgent?: string | undefined,
    public deviceId?: string | undefined,
    public versionNo?: string | undefined,
    public transactionDateTime?: string | undefined,
    public offlineRequest?: boolean | undefined,
    public location?: string | undefined,
    public esbReferenceNumber?: string | undefined,
    public additionalHeaders?: any,
    public tenantId?: string | undefined,
  ) { }
}
