import {AbstractControl} from '@angular/forms';
import {
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {JsonSchemaFormService} from '@finfra/ajsf-core';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {AlertService} from '@finfra/core-services';
import {MatDialog} from '@angular/material/dialog';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {AjsfCoreWidgetsFunctionsService} from '../../../asjf-core-widgets.functions';
import {Subscription} from 'rxjs';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'finfra-image-carousel-widget',
  templateUrl: './finfra-image-carousel.component.html',
  styleUrls: ['./finfra-image-carousel.component.scss'],
})
export class FinfraImageCarouselComponent implements OnInit, OnDestroy {
  widgetName: string = 'FinfraMaterialImageCarouselComponent';
  formControl: AbstractControl;
  controlName: string;
  controlValue: any;
  controlDisabled = false;
  boundControl = false;
  options: any;
  subProperties: any;
  autoCompleteList: string[] = [];
  isArray: boolean = true;
  selectedIdx: number = 0;
  formControlChangeSubscription: Subscription;
  @Input() layoutNode: any;
  @Input() layoutIndex: number[];
  @Input() dataIndex: number[];

  @ViewChild('imageExpandDialog', {static: false}) imageExpandDialog: TemplateRef<any>;

  constructor(
    @Inject(MAT_FORM_FIELD_DEFAULT_OPTIONS) @Optional() public matFormFieldDefaultOptions,
    private jsf: JsonSchemaFormService,
    private alertService: AlertService,
    private dialog: MatDialog,
    private domSanitizer: DomSanitizer,
    private ajsfCoreWidgetsFunctionsService: AjsfCoreWidgetsFunctionsService,
  ) {
  }

  ngOnInit() {
    this.options = this.layoutNode.options || {};
    this.subProperties = this.layoutNode.subProperties || {};
    this.jsf.initializeControl(this);
    if (!this.options.notitle && !this.options.description && this.options.placeholder) {
      this.options.description = this.options.placeholder;
    }

    this.formControlChangeSubscription = this.formControl.valueChanges
      .pipe(
        debounceTime(800),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
      )
      .subscribe(this.onFormChange.bind(this));
  }

  ngOnDestroy(): void {
    this.jsf = undefined;
    this.formControl = undefined;
    this.controlName = undefined;
    this.controlValue = undefined;
    this.controlDisabled = undefined;
    this.boundControl = undefined;
    this.options = undefined;
    this.subProperties = undefined;

    if (this.formControlChangeSubscription) {
      this.formControlChangeSubscription.unsubscribe();
    }
  }

  onFormChange(value: any) {
    if (Array.isArray(value)) {
      this.isArray = true;
    } else {
      this.isArray = false;
    }

    this.selectedIdx = 0;
  }

  updateValue(event) {
    this.jsf.updateValue(this, event.target.value);
  }

  navigateLeft() {
    if (this.selectedIdx === 0) {
      this.selectedIdx = this.formControl.value.length - 1;
    } else {
      this.selectedIdx--;
    }
  }

  navigateRight() {
    if (this.selectedIdx === this.formControl.value.length - 1) {
      this.selectedIdx = 0;
    } else {
      this.selectedIdx++;
    }
  }

  expandImage() {
    this.dialog.open(this.imageExpandDialog, {
      width: '90vh',
    });
  }

  onImageClick(event) {
    this.alertService.info('FinfraImageComponent: on select change ' + this.controlValue);
    this.alertService.info(event);

    this.expandImage();
    this.ajsfCoreWidgetsFunctionsService.dispatchEvent(this);
  }

  getImageObject(src: { image: string, text: string }): SafeUrl {

    if (src === undefined || src === null) {
      return undefined;
    }
    let image = src.image ? src.image : null;

    if(this.options.base64 && (image.indexOf('data:') < 0 && image.indexOf('file:') < 0 && image.indexOf('content:') < 0)){
      image = 'data:image/png;base64,' + image;
    }
    else{
      image = image;
    }

    return this.domSanitizer.bypassSecurityTrustResourceUrl(image);
  }

}
