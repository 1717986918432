import {AfterViewInit, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit, Optional, TemplateRef, ViewChild} from '@angular/core';
import {AbstractControl, FormArray, FormControl, FormGroup} from '@angular/forms';
import {FaceRecognitionModel, FileModel, HttpCallModel, ParameterModel} from '@finfra/core-models';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {AjsfCoreWidgetsFunctionsService} from '../../../asjf-core-widgets.functions';
import {AlertService, ConfigService, DateService, DeviceService, HttpService, UtilitiesService} from '@finfra/core-services';
import {DomSanitizer} from '@angular/platform-browser';
import {TranslateService} from '@finfra/core-decorators';
import {JsonSchemaFormService} from '@finfra/ajsf-core';
import {v4} from 'uuid';
import * as faceapi from 'face-api.js';
import {HttpResponse} from '@angular/common/http';

declare let navigator: any;
declare let Camera: any;

@Component({
  selector: 'finfra-face-recognition-widget',
  templateUrl: './finfra-face-recognition.component.html',
  styleUrls: ['./finfra-face-recognition.component.scss'],
})
export class FinfraFaceRecognitionComponent implements OnInit, OnDestroy, AfterViewInit {
  widgetName = 'FinfraFaceRecognitionComponent';
  formControl: FormArray;
  controlName: string;
  controlValue: FaceRecognitionModel[];
  controlDisabled = false;
  boundControl = false;
  options: any;
  subProperties: any;
  fullObject: any;
  autoCompleteList: string[] = [];
  @Input() layoutNode: any;
  @Input() layoutIndex: number[];
  @Input() dataIndex: number[];

  showVideo = false;
  width: number;
  height: number;
  stopVideoOnCapture = false;
  stream: any;
  streaming = false;
  context: any;
  webcamDialogRef: MatDialogRef<any>;
  imageExpandDialogRef: MatDialogRef<any>;

  @ViewChild('webcamTemplate', {static: true}) webcamTemplate: TemplateRef<any>;
  @ViewChild('imageExpandDialog', {static: true}) imageExpandDialog: TemplateRef<any>;

  translateLabelsName = 'finfra-face-recognition';
  translateLabelsLoaded = false;

  functionIdControl: AbstractControl;
  transactionReferenceControl: AbstractControl;
  inputParametersControl: AbstractControl;
  inputByControl: FormControl = new FormControl();
  uniqueIdControl: FormControl;

  @ViewChild('verifyDetailsDialog', {static: false}) verifyDetailsDialog: TemplateRef<any>;
  verifyDetails: any[];
  verifyDetailsDialogRef: MatDialogRef<any>;

  intervalId: any;
  detectionIntervalId: any;
  detectPositiveCount = 0;
  intervalMilliseconds = 100;
  faceDetected = false;
  detecting = false;
  selectedImage: any;

  constructor(
    @Inject(MAT_FORM_FIELD_DEFAULT_OPTIONS) @Optional() public matFormFieldDefaultOptions,
    private jsf: JsonSchemaFormService,
    private ajsfCoreWidgetsFunctionsService: AjsfCoreWidgetsFunctionsService,
    private dialog: MatDialog,
    private alertService: AlertService,
    private deviceService: DeviceService,
    private changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService,
    private httpService: HttpService,
    private configService: ConfigService,
    private utilitiesService: UtilitiesService,
    private domSanitizer: DomSanitizer,
    private dateService: DateService,
  ) {
    this.ajsfCoreWidgetsFunctionsService.loadLabels(this);
  }

  ngOnInit() {
    this.options = this.layoutNode.options || {};
    this.subProperties = this.layoutNode.subProperties || {};
    this.jsf.initializeControl(this);
    if (!this.options.notitle && !this.options.description && this.options.placeholder) {
      this.options.description = this.options.placeholder;
    }

    this.width = this.options.width || 640;
    this.height = this.options.height || 480;

    this.init();
  }

  ngOnDestroy() {
    this.stopVideoOnCapture = false;
    this.stopWebcam();
    this.showVideo = false;
    this.stream = undefined;

    this.closeWebcamDialog();

    this.jsf = undefined;
    this.formControl = undefined;
    this.controlName = undefined;
    this.controlValue = undefined;
    this.controlDisabled = undefined;
    this.boundControl = undefined;
    this.options = undefined;
    this.subProperties = undefined;

    if (this.verifyDetailsDialogRef) {
      this.verifyDetailsDialogRef.close();
    }

    if (this.imageExpandDialogRef) {
      this.imageExpandDialogRef.close();
    }
  }

  async ngAfterViewInit() {
    const MODEL_URL = '/assets/face-api-weights';
    await faceapi.loadTinyFaceDetectorModel(MODEL_URL);
  }

  updateValue() {
    this.controlValue = this.formControl.value;
    this.jsf.updateValue(this, this.controlValue);
    this.fullObject = this.controlValue;
    this.ajsfCoreWidgetsFunctionsService.dispatchEvent(this);
    this.changeDetectorRef.markForCheck();
  }

  public init() {
    if (this.options.functionIdPointer) {
      this.functionIdControl = this.jsf.getControlService(this, this.jsf.formGroup, this.options.functionIdPointer);
    }

    if (this.options.uniqueIdPointer) {
      this.uniqueIdControl = this.jsf.getControlService(this, this.jsf.formGroup, this.options.uniqueIdPointer);
    }

    if (this.options.transactionReferencePointer) {
      this.transactionReferenceControl = this.jsf.getControlService(this, this.jsf.formGroup, this.options.transactionReferencePointer);
    }

    if (this.options.inputParametersPointer) {
      this.inputParametersControl = this.jsf.getControlService(this, this.jsf.formGroup, this.options.inputParametersPointer);
    }

    if (this.options.onlineUploadRequired === undefined || this.options.onlineUploadRequired === null) {
      this.options.onlineUploadRequired = true;
    }

    if (this.options.onlineVerifyRequired === undefined || this.options.onlineVerifyRequired === null) {
      this.options.onlineVerifyRequired = true;
    }

    this.options.faceSize = +this.options.faceSize || 128;
    this.options.detectionScore = +this.options.detectionScore || 0.7;
    this.options.maxUploadAllowed = +this.options.maxUploadAllowed || 9999;
  }

  public clear(): void {
    this.formControl.clear();
    this.changeDetectorRef.markForCheck();
  }

  public async fileSelected(event: any): Promise<void> {
    if (event.target.files) {
      for (const file of event.target.files) {
        if (Array.isArray(this.formControl.value) && this.formControl.value.length >= +this.options.maxUploadAllowed) {
          break;
        }

        // First detect whether there is a face detected or not
        const image = await this.utilitiesService.fileToImageElement(file);
        const detections = await faceapi.detectSingleFace(
          image,
          new faceapi.TinyFaceDetectorOptions({inputSize: this.options.faceSize})
        );

        if (!detections) {
          this.alertService.showMessage('E_NO_FACE', 'error');
          continue;
        }

        const frm: FaceRecognitionModel = new FaceRecognitionModel();
        frm.uniqueId = this.uniqueIdControl.value;
        frm.catalogueId = this.inputParametersControl.get('catalogueId').value;
        frm.dataType = this.inputParametersControl.get('dataType').value;
        frm.externalFileId = v4();
        frm.filename = file.name;
        frm.metaData = {
          type: file.type,
          size: file.size,
          lastModified: file.lastModified,
          lastModifiedDate: file.lastModifiedDate
        };
        frm.fileBase64 = await this.utilitiesService.fileToBase64(file);
        frm.uploaded = false;
        frm.id = null;
        frm.valid = null;

        if ((this.options.frType === 'upload' && this.options.onlineUploadRequired) ||
          (this.options.frType === 'verify' && this.options.onlineVerifyRequired)) {
          frm.file = file;
        } else {
          frm.valid = true;
        }

        this.formControl.push(this.objectToGroup(frm));

        if (this.formControl.length >= this.options.maxAllowedItems) {
          break;
        }
      }
    }

    event.target.value = [];
    this.changeDetectorRef.markForCheck();
  }

  public async upload(): Promise<void> {
    if (!this.formControl || this.formControl.value === 0) {
      return;
    }

    const data: any = {
      datastoreArray: []
    };

    const httpCallModel: HttpCallModel = new HttpCallModel(
      this.configService.getConfig('global').configApi + this.options.urls.upload.url,
      this.options.urls.upload.method,
      this.functionIdControl ? this.functionIdControl.value : 'finfra-ajsf',
      data
    );

    httpCallModel.fileUpload = true;
    httpCallModel.fileList = [];

    let filePresent = false;
    for (const file of this.formControl.value) {
      if (!file || !file.file || file.fileUploaded) {
        continue;
      }

      filePresent = true;
      httpCallModel.fileList.push(file.file);

      const fileCopy = JSON.parse(JSON.stringify(file));
      fileCopy.file = undefined;
      fileCopy.fileBase64 = undefined;
      fileCopy.uploaded = undefined;
      fileCopy.id = undefined;
      fileCopy.valid = undefined;
      fileCopy.responseStatus = undefined;
      fileCopy.fileUrl = undefined;
      fileCopy.verified = undefined;
      fileCopy.deltaVerified = undefined;
      fileCopy.metaData = JSON.stringify(fileCopy.metaData);

      const tags = [];

      tags.push({
        tag: this.transactionReferenceControl.value
      });

      if (this.options.tags && this.options.tags.length > 0) {
        for (const tag of this.options.tags) {
          let control;
          if (tag.type === 'formGroup') {
            control = this.jsf.getControlService(this, this.jsf.formGroup, tag.pointer);
          } else if (tag.type === 'inputParametersGroup') {
            control = this.jsf.getControlService(this, this.inputParametersControl, tag.pointer);
          }

          if (control) {
            tags.push({
              tag: control.value
            });
          }
        }
      }

      fileCopy.tags = tags;

      data.datastoreArray.push(fileCopy);
    }

    if (!filePresent) {
      return;
    }

    this.alertService.debug('Now will go to upload');
    httpCallModel.requestBody = data;

    const results = await this.httpService.callWS(httpCallModel).toPromise().catch(
      error => {
        this.alertService.showErrorMessage(error);
      }
    );

    if (results) {
      if (results instanceof HttpResponse && results.status === 200) {
        if (results.body.responseStatus === 'SUCCESS') {
          this.alertService.showAllMessages(results.body);
          await this.fetchByTransactionReference(false);
          this.updateValue();
        } else {
          this.alertService.showAllMessages(results.body);
        }
      } else {
        this.alertService.showErrorMessage(results);
      }
    }
  }

  public async verify(): Promise<void> {
    if (!this.formControl || this.formControl.value === 0) {
      return;
    }

    const data: any = {
      datastoreArray: []
    };

    const httpCallModel: HttpCallModel = new HttpCallModel(
      this.configService.getConfig('global').configApi + this.options.urls.upload.url,
      this.options.urls.upload.method,
      this.functionIdControl ? this.functionIdControl.value : 'finfra-ajsf',
      data
    );

    httpCallModel.fileUpload = true;
    httpCallModel.fileList = [];

    let filePresent = false;
    for (const file of this.formControl.value) {
      if (!file || !file.file || file.fileUploaded) {
        continue;
      }

      filePresent = true;
      httpCallModel.fileList.push(file.file);

      const fileCopy = JSON.parse(JSON.stringify(file));
      fileCopy.file = undefined;
      fileCopy.fileBase64 = undefined;
      fileCopy.uploaded = undefined;
      fileCopy.id = undefined;
      fileCopy.valid = undefined;
      fileCopy.responseStatus = undefined;
      fileCopy.fileUrl = undefined;
      fileCopy.verified = undefined;
      fileCopy.deltaVerified = undefined;
      fileCopy.metaData = JSON.stringify(fileCopy.metaData);

      const tags = [];

      tags.push({
        tag: this.transactionReferenceControl.value
      });

      if (this.options.tags && this.options.tags.length > 0) {
        for (const tag of this.options.tags) {
          let control;
          if (tag.type === 'formGroup') {
            control = this.jsf.getControlService(this, this.jsf.formGroup, tag.pointer);
          } else if (tag.type === 'inputParametersGroup') {
            control = this.jsf.getControlService(this, this.inputParametersControl, tag.pointer);
          }

          if (control) {
            tags.push({
              tag: control.value
            });
          }
        }
      }

      fileCopy.tags = tags;

      data.datastoreArray.push(fileCopy);
    }

    if (!filePresent) {
      return;
    }

    this.alertService.debug('Now will go to upload');
    httpCallModel.requestBody = data;

    const results = await this.httpService.callWS(httpCallModel).toPromise().catch(
      error => {
        this.alertService.showErrorMessage(error);
      }
    );

    if (results) {
      if (results instanceof HttpResponse && results.status === 200) {
        if (results.body.responseStatus === 'SUCCESS') {
          this.alertService.showAllMessages(results.body.finData.pipelineResponse[0].esbResponse);
          await this.fetchByTransactionReference(true);
          this.updateValue();
        } else {
          this.alertService.showAllMessages(results.body);
        }
      } else {
        this.alertService.showErrorMessage(results);
      }
    }
  }

  public async fetchByTransactionReference(getPipelineResponse: boolean): Promise<void> {
    this.clear();
    let path: string = this.configService.getConfig('global').solrApi + this.options.urls.solrQuery.url;

    path = path + '?q=tags:' + this.transactionReferenceControl.value.toLowerCase();

    const httpCall = new HttpCallModel(
      path,
      this.options.urls.solrQuery.method,
      this.functionIdControl ? this.functionIdControl.value : 'finfra-ajsf',
      undefined
    );

    const results = await this.httpService.callWS(httpCall).toPromise().catch(
      error => {
        if (this.options.showHttpError) {
          this.alertService.showErrorMessage(error);
        }
      }
    );

    let verified = false;
    if (results && results.status === 200 && results instanceof HttpResponse) {
      const docs = results.body.response.docs;

      if (docs && docs.length > 0) {
        for (const doc of docs) {
          const frm: FaceRecognitionModel = new FaceRecognitionModel();
          frm.uniqueId = doc.uniqueId;
          frm.catalogueId = doc.catalogueId;
          frm.dataType = doc.dataType;
          frm.externalFileId = doc.externalFileId;
          frm.filename = doc.filename;
          frm.uploaded = true;
          frm.valid = undefined;
          frm.id = doc.datastoreId;

          const msPath = this.configService.getConfig('global').configApi + this.options.urls.msQuery.url;

          const httpMsCall = new HttpCallModel(
            msPath,
            this.options.urls.msQuery.method,
            this.functionIdControl ? this.functionIdControl.value : 'finfra-ajsf',
            undefined
          );

          httpMsCall.queryParameters = new ParameterModel();
          httpMsCall.queryParameters.stringParameters = new Map<string, string>();
          httpMsCall.queryParameters.stringParameters.set('datastoreId', doc.datastoreId);

          httpMsCall.queryParameters.booleanParameters = new Map<string, boolean>();
          httpMsCall.queryParameters.booleanParameters.set('appendPipelineResponse', !!getPipelineResponse);

          const msQueryResult = await this.httpService.callWS(httpMsCall).toPromise().catch(
            error => {
              if (this.options.showHttpError) {
                this.alertService.showErrorMessage(error);
              }
            }
          );

          if (msQueryResult && msQueryResult.status === 200 && msQueryResult instanceof HttpResponse && msQueryResult.body.responseStatus === 'SUCCESS') {
            frm.fileUrl = msQueryResult.body.finData[0].fileUrl;
            frm.tags = msQueryResult.body.finData[0].tags;
            frm.metaData = msQueryResult.body.finData[0].metaData;
            frm.verified = false;
            frm.deltaVerified = false;

            if (typeof frm.metaData === 'string') {
              frm.metaData = JSON.parse(frm.metaData);
            }

            if (msQueryResult.body.finData[0].pipelineResponse) {
              for (const pipelineResponse of msQueryResult.body.finData[0].pipelineResponse) {
                if (pipelineResponse.pipelineName === this.options.verifyPipelineName) {
                  if (pipelineResponse.esbResponse.finData.responseStatus === 'SUCCESS') {
                    frm.verified = true;

                    for (const verification of pipelineResponse.esbResponse.finData.verifications) {
                      if (verification.verified) {
                        frm.deltaVerified = verification.deltaVerified;
                      }
                    }
                  }
                }
              }
            }
          }

          if (this.options.frType === 'verify') {
            if (frm.verified) {
              verified = true;
            }
          }

          this.formControl.push(this.objectToGroup(frm));
        }
      }
    }

    if (verified) {
      for (const control of this.formControl.controls) {
        control.get('valid').setValue(true);
      }
    }

    this.updateValue();
    this.changeDetectorRef.markForCheck();
  }

  public async delete(image: any): Promise<void> {
    const msPath = this.configService.getConfig('global').configApi + this.options.urls.msDelete.url;

    const httpMsCall = new HttpCallModel(
      msPath,
      this.options.urls.msDelete.method,
      this.functionIdControl ? this.functionIdControl.value : 'finfra-ajsf',
      undefined
    );

    httpMsCall.queryParameters = new ParameterModel();
    httpMsCall.queryParameters.stringParameters = new Map<string, string>();
    httpMsCall.queryParameters.stringParameters.set('datastoreId', image.id);

    const msQueryResult = await this.httpService.callWS(httpMsCall).toPromise().catch(
      error => {
        if (this.options.showHttpError) {
          this.alertService.showErrorMessage(error);
        }
      }
    );

    if (msQueryResult && msQueryResult.status === 200 && msQueryResult instanceof HttpResponse && msQueryResult.body.responseStatus === 'SUCCESS') {
      await this.fetchByTransactionReference(false);
    }
  }

  public getImageObject(image: any) {
    if (!image || (!image.fileBase64 && !image.fileUrl)) {
      return undefined;
    }

    if (image.fileUrl) {
      return this.configService.getConfig('global').filesEndPoint + image.fileUrl;
    }

    if (image.fileBase64.indexOf('data:') < 0 && image.fileBase64.indexOf('file:') < 0 && image.fileBase64.indexOf('content:') < 0) {
      let fileType = '';

      if (image.fileType) {
        fileType = image.fileType;
      } else {
        fileType = 'image/png';
      }

      image.fileBase64 = 'data:' + fileType + ';base64,' + image.fileBase64;
    }

    return this.domSanitizer.bypassSecurityTrustResourceUrl(image.fileBase64);
  }

  public objectToGroup(frm: FaceRecognitionModel): FormGroup {
    if (typeof frm.metaData === 'string') {
      frm.metaData = JSON.parse(frm.metaData);
    }

    const fg = new FormGroup({
      'uniqueId': new FormControl(frm.uniqueId),
      'catalogueId': new FormControl(frm.catalogueId),
      'dataType': new FormControl(frm.dataType),
      'externalFileId': new FormControl(frm.externalFileId),
      'filename': new FormControl(frm.filename),
      'file': new FormControl(frm.file),
      'id': new FormControl(frm.id),
      'metaData': new FormGroup({
        type: new FormControl(frm.metaData.type),
        size: new FormControl(frm.metaData.size),
        lastModified: new FormControl(frm.metaData.lastModified),
        lastModifiedDate: new FormControl(frm.metaData.lastModifiedDate)
      }),
      'responseStatus': new FormControl(frm.responseStatus),
      'fileBase64': new FormControl(frm.fileBase64),
      'fileUrl': new FormControl(frm.fileUrl),
      'uploaded': new FormControl(frm.uploaded),
      'verified': new FormControl(frm.verified),
      'deltaVerified': new FormControl(frm.deltaVerified),
      'valid': new FormControl(frm.valid),
    });

    const tagsControl = new FormArray([]);

    if (frm.tags) {
      for (const tag of frm.tags) {
        const tagControl = new FormGroup({
          'tag': new FormControl(tag.tag),
        });

        tagsControl.push(tagControl);
      }

      fg.setControl('tags', tagsControl);
    }

    return fg;
  }

  public async showDetails(frm: FaceRecognitionModel) {
    this.verifyDetails = undefined;
    if (!frm || !frm.uploaded) {
      return;
    }

    this.verifyDetails = [];
    const msPath = this.configService.getConfig('global').configApi + this.options.urls.msQuery.url;

    const httpMsCall = new HttpCallModel(
      msPath,
      this.options.urls.msQuery.method,
      this.functionIdControl ? this.functionIdControl.value : 'finfra-ajsf',
      undefined
    );

    httpMsCall.queryParameters = new ParameterModel();
    httpMsCall.queryParameters.stringParameters = new Map<string, string>();
    httpMsCall.queryParameters.stringParameters.set('datastoreId', frm.id);

    httpMsCall.queryParameters.booleanParameters = new Map<string, boolean>();
    httpMsCall.queryParameters.booleanParameters.set('appendPipelineResponse', true);

    const msQueryResult = await this.httpService.callWS(httpMsCall).toPromise().catch(
      error => {
        if (this.options.showHttpError) {
          this.alertService.showErrorMessage(error);
        }
      }
    );

    if (msQueryResult && msQueryResult.status === 200 && msQueryResult instanceof HttpResponse && msQueryResult.body.responseStatus === 'SUCCESS') {
      if (msQueryResult.body.finData[0].pipelineResponse) {
        for (const pipelineResponse of msQueryResult.body.finData[0].pipelineResponse) {
          if (pipelineResponse.pipelineName === this.options.verifyPipelineName) {
            this.verifyDetails = pipelineResponse.esbResponse.finData.verifications;
            for (const verification of this.verifyDetails) {
              const httpVerifyCall = new HttpCallModel(
                msPath,
                this.options.urls.msQuery.method,
                this.functionIdControl ? this.functionIdControl.value : 'finfra-ajsf',
                undefined
              );

              httpVerifyCall.queryParameters = new ParameterModel();
              httpVerifyCall.queryParameters.stringParameters = new Map<string, string>();
              httpVerifyCall.queryParameters.stringParameters.set('datastoreId', verification.verifyImage.datastoreId);

              httpVerifyCall.queryParameters.booleanParameters = new Map<string, boolean>();
              httpVerifyCall.queryParameters.booleanParameters.set('appendPipelineResponse', false);

              const verifyQueryResult = await this.httpService.callWS(httpVerifyCall).toPromise().catch(
                error => {
                  if (this.options.showHttpError) {
                    this.alertService.showErrorMessage(error);
                  }
                }
              );

              if (verifyQueryResult && verifyQueryResult.status === 200 && verifyQueryResult instanceof HttpResponse && verifyQueryResult.body.responseStatus === 'SUCCESS') {
                verification.fileUrl = verifyQueryResult.body.finData[0].fileUrl;
              }
            }
          }
        }
      }
    }

    this.verifyDetailsDialogRef = this.dialog.open(this.verifyDetailsDialog, {
      height: '90vh',
      width: '90vw'
    });

    this.verifyDetailsDialogRef.afterClosed().subscribe(
      d => {
        this.verifyDetails = undefined;
      }
    );
  }


  public showWebcam(): void {
    if (navigator.camera) {
      // This means its android
      const options: any = {
        // Some common settings are 20, 50, and 100
        quality: 100,
        destinationType: Camera.DestinationType.FILE_URI,
        // In this app, dynamically set the picture source, Camera or photo gallery
        sourceType: Camera.PictureSourceType.CAMERA,
        encodingType: Camera.EncodingType.PNG,
        mediaType: Camera.MediaType.PICTURE,
        allowEdit: true, // set true if edit allowed
        // targetHeight: 200,
        // targetWidth: 200,
        correctOrientation: true  // Corrects Android orientation quirks
      };

      navigator.camera.getPicture(this.cameraSuccess.bind(this), this.cameraError.bind(this), options);
    } else {
      // This means its browser
      this.webcamDialogRef = this.dialog.open(this.webcamTemplate, {
        disableClose: true
      });
      this.webcamDialogRef.afterOpened().subscribe(result => {
        this.initCamera();
      });
      this.webcamDialogRef.afterClosed().subscribe(
        result => {
          if (this.detectionIntervalId !== undefined && this.detectionIntervalId !== null) {
            clearInterval(this.detectionIntervalId);
          }

          if (this.intervalId !== undefined && this.intervalId !== null) {
            clearInterval(this.detectionIntervalId);
          }

          this.detectionIntervalId = undefined;
          this.faceDetected = false;
          this.detectPositiveCount = 0;
          this.detecting = false;
        }
      );
    }
  }

  public closeWebcamDialog(): void {
    if (this.webcamDialogRef) {
      this.webcamDialogRef.close();
    }

    this.webcamDialogRef = undefined;
  }

  public cameraSuccess(imageUri: string) {
    const frm: FaceRecognitionModel = new FaceRecognitionModel();
    frm.uniqueId = this.uniqueIdControl.value;
    frm.catalogueId = this.inputParametersControl.get('catalogueId').value;
    frm.dataType = this.inputParametersControl.get('dataType').value;
    frm.externalFileId = v4();
    frm.filename = frm.externalFileId;
    frm.fileUrl = imageUri;
    frm.metaData = {
      lastModified: (new Date()).getTime(),
      lastModifiedDate: new Date()
    };
    frm.uploaded = false;
    frm.id = null;
    frm.valid = null;

    this.deviceService.getDiskFileAsBase64(imageUri, this.gotFile.bind(this, frm));
  }

  public cameraError(message: any) {
    this.alertService.error(message);
  }

  public gotFile(frm: FaceRecognitionModel, fileObject: any) {
    frm.fileBase64 = fileObject.base64;

    this.formControl.push(this.objectToGroup(frm));
    this.updateValue();
  }

  initCamera() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      this.showVideo = true;
      this.streaming = false;

      const constraints: any = {
        audio: false,
        video: {
          width: this.width,
          height: this.height
        }
      };

      navigator.mediaDevices.getUserMedia(constraints).then(stream => {
        this.stream = stream;
        const webcamVideo: any = document.getElementById('webcamVideo' + this.controlName + this.layoutNode?._id);
        const webcamSetting: any = this.stream.getVideoTracks()[0].getSettings();
        this.intervalMilliseconds = 1000 / Math.trunc(webcamSetting.frameRate);

        if (
          webcamVideo === undefined ||
          webcamVideo === null
        ) {
          this.stopVideo();
          return false;
        }

        webcamVideo.srcObject = this.stream;
        webcamVideo.play();

        webcamVideo.addEventListener('canplay', this.initVideo.bind(this), false);
      });
    }
  }

  public capture() {
    if (!this.faceDetected) {
      return;
    }

    if (this.intervalId !== undefined && this.intervalId !== null) {
      clearInterval(this.intervalId);
      this.intervalId = undefined;
    }

    if (this.detectionIntervalId !== undefined && this.detectionIntervalId !== null) {
      clearInterval(this.detectionIntervalId);
      this.detectionIntervalId = undefined;
    }

    if (Array.isArray(this.formControl.value) && this.formControl.value.length >= +this.options.maxUploadAllowed) {
      this.stopWebcam();
      this.faceDetected = false;
      this.detectPositiveCount = 0;
      this.detecting = false;
      return;
    }

    const webcamVideo: any = document.getElementById('webcamVideo' + this.controlName + this.layoutNode?._id);
    const webcamPicture: any = document.getElementById('webcamPicture' + this.controlName + this.layoutNode?._id);

    if (!webcamVideo || !webcamPicture) {
      return;
    }

    webcamPicture.width = this.width;
    webcamPicture.height = this.height;

    this.context = webcamPicture.getContext('2d').drawImage(webcamVideo, 0, 0, this.width, this.height);
    const img = webcamPicture.toDataURL('image/png');

    const frm: FaceRecognitionModel = new FaceRecognitionModel();
    frm.uniqueId = this.uniqueIdControl.value;
    frm.catalogueId = this.inputParametersControl.get('catalogueId').value;
    frm.dataType = this.inputParametersControl.get('dataType').value;
    frm.externalFileId = v4();
    frm.filename = frm.externalFileId + '.png';
    frm.metaData = {
      type: 'image/png',
      lastModified: (new Date()).getTime(),
      lastModifiedDate: new Date()
    };
    frm.uploaded = false;
    frm.id = null;
    frm.valid = null;
    frm.fileBase64 = img;

    if ((this.options.frType === 'upload' && this.options.onlineUploadRequired) ||
      (this.options.frType === 'verify' && this.options.onlineVerifyRequired)) {
      const file: FileModel = new FileModel();
      file.fileName = frm.filename;
      file.fileBase64 = frm.fileBase64;
      file.fileType = frm.metaData.type;
      file.fileLastModified = frm.metaData.lastModified;
      file.fileLastModifiedDate = frm.metaData.lastModifiedDate;

      frm.file = this.utilitiesService.base64ToFile(file);
    } else {
      frm.valid = true;
    }

    this.formControl.push(this.objectToGroup(frm));

    if (this.stopVideoOnCapture) {
      this.stopVideo();
      this.showVideo = false;
    }

    this.updateValue();
    this.stopWebcam();
    this.faceDetected = false;
    this.detectPositiveCount = 0;
    this.detecting = false;
  }

  public stopWebcam() {
    const stream: any = document.getElementById('webcamVideo' + this.controlName + this.layoutNode?._id);

    if (stream === undefined || stream === null) {
      return false;
    }

    try {
      const tracks = stream.srcObject.getTracks();

      if (tracks === undefined || tracks === null) {
        return false;
      }

      for (let i = 0; i < tracks.length; i++) {
        const track = tracks[i];
        track.stop();
      }

      stream.srcObject = null;
    } catch (e) { }

    this.closeWebcamDialog();
  }

  public stopVideo() {
    if (this.stream === undefined || this.stream === null) {
      return false;
    }

    const tracks: any = this.stream.getTracks();

    for (let i = 0; i < tracks.length; i++) {
      const track: any = tracks[i];
      track.stop();
    }

    const webcamVideo: any = document.getElementById('webcamVideo' + this.controlName + this.layoutNode?._id);
    if (webcamVideo !== undefined && webcamVideo !== null) {
      webcamVideo.src = undefined;
    }
  }

  public async initVideo(): Promise<void> {
    const video: any = document.getElementById('webcamVideo' + this.controlName + this.layoutNode?._id);
    const webcamPicture: any = document.getElementById('webcamPicture' + this.controlName + this.layoutNode?._id);

    if (webcamPicture) {
      webcamPicture.width = this.width;
      webcamPicture.height = this.height;
    }

    const overlayCanvas = document.getElementById('overlay' + this.controlName + this.layoutNode?._id);

    if (overlayCanvas) {
      overlayCanvas.style.position = 'absolute';
      overlayCanvas.style.left = video.offsetLeft + 'px';
      overlayCanvas.style.top = video.offsetTop + 'px';
    }

    await this.startDetection();
  }

  public async startDetection() {
    if (this.detectionIntervalId !== undefined && this.detectionIntervalId !== null) {
      clearInterval(this.detectionIntervalId);
      this.detectionIntervalId = undefined;
    }

    this.detecting = false;
    const video: any = document.getElementById('webcamVideo' + this.controlName + this.layoutNode?._id);

    this.detectPositiveCount = 0;
    this.faceDetected = false;

    this.intervalId = setInterval(this.detect.bind(this, video, false), this.options.intervalMilliseconds);
  }

  public async startCapture() {
    if (this.intervalId !== undefined && this.intervalId !== null) {
      clearInterval(this.intervalId);
      this.intervalId = undefined;
    }

    this.detecting = true;
    const video: any = document.getElementById('webcamVideo' + this.controlName + this.layoutNode?._id);

    this.detectPositiveCount = 0;
    this.faceDetected = false;

    this.detectionIntervalId = setInterval(this.detect.bind(this, video, true), this.options.intervalMilliseconds);
  }

  public async detect(video: any, capture: boolean) {
    const detections = await faceapi.detectSingleFace(video, new faceapi.TinyFaceDetectorOptions({inputSize: this.options.faceSize}));

    const overlayCanvas: any = document.getElementById('overlay' + this.controlName + this.layoutNode?._id);

    if (overlayCanvas && detections) {
      const dims = faceapi.matchDimensions(overlayCanvas, video, true);
      this.drawDetections(overlayCanvas, faceapi.resizeResults(detections, dims), +this.options.detectionScore);

      overlayCanvas.style.display = 'block';
      overlayCanvas.style.position = 'absolute';
      overlayCanvas.style.left = video.offsetLeft + 'px';
      overlayCanvas.style.top = video.offsetTop + 'px';
    } else if (overlayCanvas) {
      overlayCanvas.style.display = 'none';
    }

    if (detections && detections.score >= this.options.detectionScore) {
      // I will make it positive only when I get > 10 positives
      if (this.detectPositiveCount > 10) {
        this.faceDetected = true;

        if (capture) {
          this.capture();
        }
      } else {
        this.detectPositiveCount++;
      }
    } else {
      this.faceDetected = false;
      this.detectPositiveCount = 0;
    }
  }

  public expandImage(image: any): void {
    this.selectedImage = image;
    this.imageExpandDialogRef = this.dialog.open(this.imageExpandDialog, {
      height: '90vh'
    });
  }

  public drawDetections(
    canvasArg: string | HTMLCanvasElement,
    detections: any,
    detectionScore: number
  ) {
    const detectionsArray = Array.isArray(detections) ? detections : [detections];
    detectionsArray.forEach((det) => {
      const score = det instanceof faceapi.FaceDetection
        ? det.score
        : (faceapi.isWithFaceDetection(det) ? det.detection.score : undefined);
      const box = det instanceof faceapi.FaceDetection
        ? det.box
        : (faceapi.isWithFaceDetection(det) ? det.detection.box : new faceapi.Box(det));
      const label = score ? '' + faceapi.utils.round(score) : undefined;

      let boxColor = '#00ff00';
      if (score < detectionScore) {
        boxColor = '#ff0000';
      }
      new faceapi.draw.DrawBox(box, { label: label, boxColor: boxColor }).draw(canvasArg);
    });
  }
}
