import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EncryptionService {
  public subtelCrypto: SubtleCrypto = window.crypto.subtle;
  public encoder: TextEncoder = new TextEncoder();
  public decoder: TextDecoder = new TextDecoder();

  constructor() { }

  async hash(value: any): Promise<string | undefined> {
    if (value === undefined || value === null || value === '') {
      return value;
    }

    let hashBuffer: ArrayBuffer;

    if (typeof value === 'string') {
      hashBuffer = await this.subtelCrypto.digest('SHA-512', this.encoder.encode(value));
    } else {
      hashBuffer = await this.subtelCrypto.digest('SHA-512', this.encoder.encode(JSON.stringify(value)));
    }

    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

    return hashHex;
  }

  async encrypt(value: string, key: string): Promise<string | undefined> {
    if (value === undefined || value === null || value === '') {
      return value;
    }

    const iv = new Uint16Array(key.length);
    for (let i = 0; i < iv.length; i++) {
      iv[i] = key.charCodeAt(i);
    }

    const keyMaterial = await this.subtelCrypto.importKey(
      'raw',
      this.encoder.encode(key),
      'PBKDF2',
      false,
      ['deriveBits', 'deriveKey']
    );

    const derivedKey = await this.subtelCrypto.deriveKey(
      {
        name: 'PBKDF2',
        iterations: 100000,
        salt: iv,
        hash: 'SHA-256'
      },
      keyMaterial,
      { name: 'AES-GCM', length: 256 },
      true,
      ['encrypt', 'decrypt']
    );

    const valueArray = this.encoder.encode(value);

    const encryptedBuffer = await window.crypto.subtle.encrypt(
      {
        name: 'AES-GCM',
        iv
      },
      derivedKey,
      valueArray
    );

    const encryptedArray = Array.from(new Uint8Array(encryptedBuffer));
    const encryptedHexString = encryptedArray.map(b => b.toString(16).padStart(2, '0')).join('');

    return encryptedHexString;
  }

  async decrypt(value: string, key: string): Promise<string | undefined> {
    if (value === undefined || value === null || value === '') {
      return value;
    }

    const iv = new Uint16Array(key.length);
    for (let i = 0; i < iv.length; i++) {
      iv[i] = key.charCodeAt(i);
    }

    const keyMaterial = await this.subtelCrypto.importKey(
      'raw',
      this.encoder.encode(key),
      'PBKDF2',
      false,
      ['deriveBits', 'deriveKey']
    );

    const derivedKey = await this.subtelCrypto.deriveKey(
      {
        name: 'PBKDF2',
        iterations: 100000,
        salt: iv,
        hash: 'SHA-256'
      },
      keyMaterial,
      { name: 'AES-GCM', length: 256 },
      true,
      ['encrypt', 'decrypt']
    );

    const regexMatchArray = value.match(/.{1,2}/g);

    if (regexMatchArray === null) {
      return undefined;
    }

    const valueArray = new Uint8Array(regexMatchArray.map(byte => parseInt(byte, 16)));

    const decryptedBuffer = await window.crypto.subtle.decrypt(
      {
        name: 'AES-GCM',
        iv
      },
      derivedKey,
      valueArray
    );

    const decryptedHexString = this.decoder.decode(decryptedBuffer);

    return decryptedHexString;
  }
}
