import { Injectable } from "@angular/core";
import { InjectedServicesModel } from "@finfra/core-models";

@Injectable({
  providedIn: "root",
})
export class InjectionService {
  public injectedServices: Map<string, InjectedServicesModel[]>;

  constructor() {
    this.injectedServices = new Map<string, InjectedServicesModel[]>();
  }

  public injectService(
    serviceType: string,
    serviceName: string,
    service: any,
    entryFunctionName?: string
  ): void {
    let serviceObjectArray: InjectedServicesModel[] | undefined = this.injectedServices.get(
      serviceType
    );

    if (serviceObjectArray === undefined || serviceObjectArray === null) {
      serviceObjectArray = [];
    }

    let serviceObject: InjectedServicesModel = new InjectedServicesModel();
    serviceObject.serviceName = serviceName;
    serviceObject.service = service;
    serviceObject.entryFunctionName = entryFunctionName;

    serviceObjectArray = serviceObjectArray.filter(
      (service) => service.serviceName !== serviceName
    );
    serviceObjectArray.push(serviceObject);

    this.injectedServices.set(serviceType, serviceObjectArray);
  }

  public getInjectedServiceByType(serviceType: string): InjectedServicesModel[] | undefined {
    return this.injectedServices.get(serviceType);
  }

  public getInjectedServiceByTypeAndName(serviceType: string, serviceName: string): InjectedServicesModel | undefined {
    let serviceObjectArray: InjectedServicesModel[] | undefined = this.injectedServices.get(serviceType);
    let service: InjectedServicesModel | undefined = undefined;

    if (serviceObjectArray !== undefined) {
      for (let idx = 0; idx < serviceObjectArray.length; idx++) {
        if (serviceObjectArray[idx].serviceName === serviceName) {
          service = serviceObjectArray[idx];
          break;
        }
      }
    }

    return service;
  }
}
