export interface User {
  userId?: string | undefined;
  userName?: string | undefined;
  legalEntityId?: string | undefined;
  currentLegalEntityId?: string | undefined;
  firstName?: string | undefined;
  middleName?: string | undefined;
  lastName?: string | undefined;
  email?: string | undefined;
  phone?: number | undefined;
  userLang?: string | undefined;
  staffRestriction?: string | undefined;
  branchCode?: string | undefined;
  branchName?: string | undefined;
  currentBranchCode?: string | undefined;
  currentBranchName?: string | undefined;
  enabled?: boolean | undefined;
  locked?: boolean | undefined;
  passwordExpired?: boolean | undefined;
  legalEntityCode?: string | undefined;
  currentLegalEntityCode?: string | undefined;
  legalEntityName?: string | undefined;
  currentLegalEntityName?: string | undefined;
  lastLoginDate?: Date | undefined;
  userImage?: string | undefined;
  entitlementsForUserModel?: any;
  deviceId?: string | undefined;
  agentId?: string | undefined;
  userType?: string | undefined;
  forcePasswordUpdate?: boolean | undefined;
  offlineEnabled?: boolean | undefined;
}

export class User implements User {
  constructor(
    public userId?: string | undefined,
    public userName?: string | undefined,
    public legalEntityId?: string | undefined,
    public currentLegalEntityId?: string | undefined,
    public firstName?: string | undefined,
    public middleName?: string | undefined,
    public lastName?: string | undefined,
    public email?: string | undefined,
    public phone?: number | undefined,
    public userLang?: string | undefined,
    public staffRestriction?: string | undefined,
    public branchCode?: string | undefined,
    public branchName?: string | undefined,
    public currentBranchCode?: string | undefined,
    public currentBranchName?: string | undefined,
    public enabled?: boolean | undefined,
    public locked?: boolean | undefined,
    public passwordExpired?: boolean | undefined,
    public legalEntityCode?: string | undefined,
    public currentLegalEntityCode?: string | undefined,
    public legalEntityName?: string | undefined,
    public currentLegalEntityName?: string | undefined,
    public lastLoginDate?: Date | undefined,
    public userImage?: string | undefined,
    public entitlementsForUserModel?: any,
    public deviceId?: string | undefined,
    public agentId?: string | undefined,
    public userType?: string | undefined,
    public forcePasswordUpdate?: boolean | undefined,
    public offlineEnabled?: boolean | undefined,
  ) { }
}
