import { Component, Inject, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { JsonSchemaFormService } from '@finfra/ajsf-core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { AjsfCoreWidgetsFunctionsService } from '../../../asjf-core-widgets.functions';
import { DateService } from '@finfra/core-services';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'finfra-material-daterange-widget',
  templateUrl: './finfra-material-daterange.component.html',
  styleUrls: ['./finfra-material-daterange.component.scss'],
})
export class FinfraMaterialDaterangeComponent implements OnInit, OnDestroy {
  widgetName = 'FinfraMaterialDaterangeComponent';
  formControl: FormGroup;
  controlName: string;
  controlValue: any;
  dateValue: any;
  controlDisabled = false;
  boundControl = false;
  options: any;
  subProperties: any;
  autoCompleteList: string[] = [];
  @Input() layoutNode: any;
  @Input() layoutIndex: number[];
  @Input() dataIndex: number[];

  maximumControl: AbstractControl = new FormControl();
  minimumControl: AbstractControl = new FormControl();

  constructor(
    @Inject(MAT_FORM_FIELD_DEFAULT_OPTIONS) @Optional() public matFormFieldDefaultOptions,
    private jsf: JsonSchemaFormService,
    private ajsfCoreWidgetsFunctionsService: AjsfCoreWidgetsFunctionsService,
    private dateService: DateService,
  ) { }

  ngOnInit() {
    this.options = this.layoutNode.options || {};
    this.subProperties = this.layoutNode.subProperties || {};
    this.jsf.initializeControl(this, !this.options.readonly);

    if (!this.controlValue) {
      this.controlValue = {
        'startDate': null,
        'endDate': null
      };

      this.setDateRange();
    }

    if (!this.options.notitle && !this.options.description && this.options.placeholder) {
      this.options.description = this.options.placeholder;
    }

    if (this.options.maximum) {
      if (this.options.maximum === 'today') {
        this.maximumControl.setValue(new Date(new Date().toDateString()));
      } else if (this.options.maximum === 'todayDiff') {
        const dt: Date = new Date(new Date().toDateString());
        dt.setDate(dt.getDate() - +this.options.maximumDifferenceDays);
        this.maximumControl.setValue(dt);
      } else if (this.options.maximum === 'applicationDate') {
        this.maximumControl.setValue(new Date(this.dateService.getTodayDate().toDateString()));
      } else if (this.options.maximum === 'applicationDateDiff') {
        const dt: Date = new Date(this.dateService.getTodayDate().toDateString());
        dt.setDate(dt.getDate() - +this.options.maximumDifferenceDays);
        this.maximumControl.setValue(dt);
      } else if (this.options.maximum === 'pointer') {
        this.maximumControl = this.jsf.getControlService(this, this.jsf.formGroup, this.options.maximumPointer);
      } else {
        this.maximumControl.setValue(new Date(this.options.maximum));
      }
    }

    if (this.options.minimum) {
      if (this.options.minimum === 'today') {
        this.minimumControl.setValue(new Date(new Date().toDateString()));
      } else if (this.options.minimum === 'todayDiff') {
        const dt: Date = new Date(new Date().toDateString());
        dt.setDate(dt.getDate() - +this.options.minimumDifferenceDays);
        this.minimumControl.setValue(dt);
      } else if (this.options.minimum === 'applicationDate') {
        this.minimumControl.setValue(new Date(this.dateService.getTodayDate().toDateString()));
      } else if (this.options.minimum === 'applicationDateDiff') {
        const dt: Date = new Date(this.dateService.getTodayDate().toDateString());
        dt.setDate(dt.getDate() - +this.options.minimumDifferenceDays);
        this.minimumControl.setValue(dt);
      } else if (this.options.minimum === 'pointer') {
        this.minimumControl = this.jsf.getControlService(this, this.jsf.formGroup, this.options.minimumPointer);
      } else {
        this.minimumControl.setValue(new Date(this.options.minimum));
      }
    }
  }

  ngOnDestroy(): void {
    this.jsf = undefined;
    this.formControl = undefined;
    this.controlName = undefined;
    this.controlValue = undefined;
    this.controlDisabled = undefined;
    this.boundControl = undefined;
    this.options = undefined;
    this.subProperties = undefined;
  }

  updateValue(event: MatDatepickerInputEvent<any, any>) {
    this.options.showErrors = true;

    if (this.formControl.get('startDate').value && this.formControl.get('startDate').value._d) {
      this.formControl.get('startDate').setValue(this.formControl.get('startDate').value._d);
    }

    if (this.formControl.get('endDate').value && this.formControl.get('endDate').value._d) {
      this.formControl.get('endDate').setValue(this.formControl.get('endDate').value._d);
    }

    this.formControl.get('startDate').setValue(this.dateService.formatDateServer(this.formControl.get('startDate').value));
    this.formControl.get('endDate').setValue(this.dateService.formatDateServer(this.formControl.get('endDate').value));

    this.controlValue = this.formControl.value;
    this.ajsfCoreWidgetsFunctionsService.dispatchEvent(this);
  }

  setDateRange() {
    this.controlValue.startDate = this.dateService.formatDateServer(this.controlValue.startDate);
    this.controlValue.endDate = this.dateService.formatDateServer(this.controlValue.endDate);

    this.formControl.setValue(this.controlValue);

    this.ajsfCoreWidgetsFunctionsService.dispatchEvent(this);
  }
}
