import { AbstractControl } from '@angular/forms';
import { Component, Inject, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { JsonSchemaFormService } from '@finfra/ajsf-core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { AjsfCoreWidgetsFunctionsService } from '../../../asjf-core-widgets.functions';
import { ReferenceNumbersModel } from '@finfra/core-models';
import { Subscription } from 'rxjs';
import { AlertService } from '@finfra/core-services';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'finfra-reference-numbers-widget',
  templateUrl: './finfra-reference-numbers.component.html',
  styleUrls: ['./finfra-reference-numbers.component.scss'],
})
export class FinfraReferenceNumbersComponent implements OnInit, OnDestroy {
  widgetName = 'FinfraReferenceNumbersComponent';
  formControl: AbstractControl;
  transactionReferenceNumberFormControl: AbstractControl;
  applicationReferenceNumberFormControl: AbstractControl;
  controlName: string;
  controlValue: ReferenceNumbersModel;
  controlDisabled = false;
  boundControl = false;
  options: any;
  subProperties: any;
  autoCompleteList: string[] = [];
  subscriptionArr = [];
  @Input() layoutNode: any;
  @Input() layoutIndex: number[];
  @Input() dataIndex: number[];

  constructor(
    @Inject(MAT_FORM_FIELD_DEFAULT_OPTIONS) @Optional() public matFormFieldDefaultOptions,
    private jsf: JsonSchemaFormService,
    private alertService: AlertService,
    private ajsfCoreWidgetsFunctionsService: AjsfCoreWidgetsFunctionsService,

  ) {
  }

  ngOnInit() {
    this.options = this.layoutNode.options || {};
    this.subProperties = this.layoutNode.subProperties || {};
    this.jsf.initializeControl(this);
    if (!this.options.notitle && !this.options.description && this.options.placeholder) {
      this.options.description = this.options.placeholder;
    }

    if (!this.controlValue) {
      this.controlValue = new ReferenceNumbersModel();
      this.controlValue.transactionReferenceNumber = null;
      this.controlValue.applicationReferenceNumber = null;

      this.updateValue();
    }

    this.transactionReferenceNumberFormControl = this.formControl.get('transactionReferenceNumber');
    this.applicationReferenceNumberFormControl = this.formControl.get('applicationReferenceNumber');
    this.linkControls();
  }

  linkControls() {
    try {
      const pointerArr = this.options.initializeFrom.initializeObjectPointer;
      pointerArr.forEach((value: any) => {
        const targetFormControl = this.formControl.get(value.targetControl);
        const subscription = targetFormControl.valueChanges.subscribe((data) => {
          const sourceFormControl = this.jsf.getControlService(this, this.jsf.formGroup, value.sourcePointer);
          sourceFormControl.setValue(data);
        });
        this.subscriptionArr.push(subscription);
      });
    } catch (e) {
      this.alertService.error(e);
    }
  }

  ngOnDestroy(): void {
    this.jsf = undefined;
    this.formControl = undefined;
    this.controlName = undefined;
    this.controlValue = undefined;
    this.controlDisabled = undefined;
    this.boundControl = undefined;
    this.options = undefined;
    this.subProperties = undefined;
    this.subscriptionArr.forEach((sub: Subscription) => {
      sub.unsubscribe();
    });
  }

  updateValue() {
    this.jsf.updateValue(this, this.controlValue);
    this.ajsfCoreWidgetsFunctionsService.dispatchEvent(this);
  }

  validate(event) {
    if (this.options.readonly !== undefined && this.options.readonly === true) {
      return;
    }

    if (this.boundControl) {
      this.controlValue = this.formControl.value;
    }

    this.updateValue();
  }
}
