import {Injectable, Type, ViewContainerRef, ViewRef} from '@angular/core';
import {GenericEventModel, SelectItemModel, GenericParameterModel} from '@finfra/core-models';
import {EventsService} from '../core-services';


@Injectable({
  providedIn: 'root'
})
export class FinfraSidenavService {
  public sidenavOpenStatus = false;
  public endSidenavOpenStatus = false;
  public sidenavComponent: any;
  public endSidenavComponent: any;

  public endSideNavHeader: string | undefined;

  public injectedWidgets: Map<string, ViewRef> = new Map<string, ViewRef>();
  public injectedEndWidgets: Map<SelectItemModel, ViewRef> = new Map<SelectItemModel, ViewRef>();

  constructor(
    private eventsService: EventsService
  ) {

  }

  public destroy(): void {
    this.sidenavComponent = undefined;
    this.removeAllWidgets();
  }

  public openSidenav(): void {
    this.sidenavOpenStatus = true;
    this.throwEvent();
  }

  public closeSidenav(): void {
    this.sidenavOpenStatus = false;
    this.throwEvent();
  }

  public toggleSidenav(): void {
    this.sidenavOpenStatus = !this.sidenavOpenStatus;
    this.throwEvent();
  }

  public openEndSidenav(): void {
    this.endSidenavOpenStatus = true;
    this.throwEvent(true);
  }

  public closeEndSidenav(): void {
    this.endSidenavOpenStatus = false;
    this.throwEvent(true);
  }

  public toggleEndSidenav(): void {
    this.endSidenavOpenStatus = !this.endSidenavOpenStatus;
    this.throwEvent(true);
  }

  public destroyEndSidenav(): void {
    this.endSidenavComponent = undefined;
    this.resetEndSidenav();
  }

  public resetEndSidenav(): void {
    this.endSideNavHeader = undefined;
    this.removeAllEndWidgets();
  }

  private throwEvent(end?: boolean): void {
    if (end) {
      const eventObject = new GenericEventModel();

      eventObject.eventSource = 'finfra-portal-app';
      eventObject.eventName = 'finfra-sidenav';
      eventObject.eventObjectName = 'finfra-sidenav';
      eventObject.value = this.sidenavOpenStatus;
      eventObject.eventObject = this.sidenavOpenStatus;

      this.eventsService.fireEvent(eventObject);
    } else {
      const eventObject = new GenericEventModel();

      eventObject.eventSource = 'finfra-portal-app';
      eventObject.eventName = 'finfra-sidenav';
      eventObject.eventObjectName = 'finfra-sidenav-end';
      eventObject.value = this.endSidenavOpenStatus;
      eventObject.eventObject = this.endSidenavOpenStatus;

      this.eventsService.fireEvent(eventObject);
    }
  }

  public addWidget(component: Type<any>): void {
    if (this.sidenavComponent) {
      const componentFactory = this.sidenavComponent.componentFactoryResolver.resolveComponentFactory(component);

      if (this.sidenavComponent.componentInject) {
        const viewContainerRef = this.sidenavComponent.componentInject.viewContainerRef;
        const componentRef = viewContainerRef.createComponent(componentFactory);
        this.injectedWidgets.set(component.name, componentRef.hostView);
        this.sidenavComponent.changeDetectorRef.markForCheck();
      }
    }
  }

  public removeWidget(component: Type<any>): void {
    if (this.sidenavComponent) {
      const viewRef = this.injectedWidgets.get(component.name);

      if (this.sidenavComponent.componentInject && viewRef) {
        const viewContainerRef = this.sidenavComponent.componentInject.viewContainerRef;
        const componentIndex = viewContainerRef.indexOf(viewRef);
        viewContainerRef.remove(componentIndex);
        this.sidenavComponent.changeDetectorRef.markForCheck();
      }
    }

    this.injectedWidgets.delete(component.name);
  }

  public removeAllWidgets(): void {
    if (this.endSidenavComponent) {
      this.injectedWidgets.forEach(
        (value: ViewRef, key: string) => {
          if (this.sidenavComponent?.componentInject && value) {
            const viewContainerRef = this.sidenavComponent.componentInject.viewContainerRef;
            const componentIndex = viewContainerRef.indexOf(value);
            viewContainerRef.remove(componentIndex);
            this.sidenavComponent.changeDetectorRef.markForCheck();
          }
        }
      );

      this.injectedWidgets.clear();
    }
  }

  public addEndWidget(component: Type<any>, position: string, inputParameters?: GenericParameterModel[], outputParameters?: GenericParameterModel[]): void {
    if (this.endSidenavComponent) {
      const componentFactory = this.endSidenavComponent.componentFactoryResolver.resolveComponentFactory(component);

      if (this.endSidenavComponent.componentInject) {
        let viewContainerRef: ViewContainerRef | undefined;

        if (position === 'top') {
          viewContainerRef = this.endSidenavComponent.componentInject.get(0)?.viewContainerRef;
        } else if (position === 'bottom') {
          viewContainerRef = this.endSidenavComponent.componentInject.get(1)?.viewContainerRef;
        }

        if (viewContainerRef) {
          const componentRef = viewContainerRef.createComponent(componentFactory);

          if (componentRef.instance) {
            if (inputParameters && inputParameters.length > 0) {
              const instance: any = componentRef.instance;
              inputParameters.forEach(
                inputParameter => {
                  if (inputParameter.name) {
                    instance[inputParameter.name] = inputParameter.value;
                  }
                }
              );
            }

            if (outputParameters && outputParameters.length > 0) {
              const instance: any = componentRef.instance;
              outputParameters.forEach(
                outputParameter => {
                  if (outputParameter.name) {
                    outputParameter.value = instance[outputParameter.name];
                  }
                }
              );
            }
          }

          this.injectedEndWidgets.set({ name: component.name, value: position}, componentRef.hostView);
          this.endSidenavComponent.changeDetectorRef.markForCheck();
        }
      }
    }
  }

  public removeEndWidget(component: Type<any>, position: string): void {
    if (this.endSidenavComponent) {
      const viewRef = this.injectedEndWidgets.get({ name: component.name, value: position});

      if (this.endSidenavComponent.componentInject && viewRef) {
        let viewContainerRef: ViewContainerRef | undefined;

        if (position === 'top') {
          viewContainerRef = this.endSidenavComponent.componentInject.get(0)?.viewContainerRef;
        } else if (position === 'bottom') {
          viewContainerRef = this.endSidenavComponent.componentInject.get(1)?.viewContainerRef;
        }

        if (viewContainerRef) {
          const componentIndex = viewContainerRef.indexOf(viewRef);
          viewContainerRef.remove(componentIndex);
          this.endSidenavComponent.changeDetectorRef.markForCheck();
        }
      }

      this.injectedEndWidgets.delete({ name: component.name, value: position});
    }
  }

  public removeAllEndWidgets(): void {
    if (this.endSidenavComponent) {
      this.injectedEndWidgets.forEach(
        (value: ViewRef, key: SelectItemModel) => {
          if (this.endSidenavComponent?.componentInject && value) {
            let viewContainerRef: ViewContainerRef | undefined;

            if (key.value === 'top') {
              viewContainerRef = this.endSidenavComponent.componentInject.get(0)?.viewContainerRef;
            } else if (key.value === 'bottom') {
              viewContainerRef = this.endSidenavComponent.componentInject.get(1)?.viewContainerRef;
            }

            if (viewContainerRef) {
              const componentIndex = viewContainerRef.indexOf(value);
              viewContainerRef.remove(componentIndex);
              this.endSidenavComponent.changeDetectorRef.markForCheck();
            }
          }
        }
      );
    }

    this.injectedEndWidgets.clear();
  }
}
