import { PageModel } from '../message/Page.model';
import { ParameterModel } from '../message/Parameter.model';
import { WorkflowModel } from '../message/Workflow.model';
import { SelectItemModel } from './SelectItem.model';

export interface HttpCallModel {
  url: string;
  method: string;
  functionId: string;
  requestBody: any;
  queryParameters?: ParameterModel | undefined;
  pageModel?: PageModel | undefined;
  workflowModel?: WorkflowModel | undefined;
  additionalHttpHeaders?: SelectItemModel[] | undefined;
  additionalFinfraHeaders?: SelectItemModel[] | undefined;
  backgroundProcess?: boolean;
  fetchFile?: boolean | undefined;
  fileUpload?: boolean | undefined;
  fileList?: File[] | undefined;
  applicationId?: string | undefined;
  legalEntityId?: string | undefined;
  tenantId?: string | undefined;
  responseType?: string | undefined;
}

export class HttpCallModel implements HttpCallModel {
  constructor(
    public url: string,
    public method: string,
    public functionId: string,
    public requestBody: any,
    public queryParameters?: ParameterModel | undefined,
    public pageModel?: PageModel | undefined,
    public workflowModel?: WorkflowModel | undefined,
    public additionalHttpHeaders?: SelectItemModel[] | undefined,
    public additionalFinfraHeaders?: SelectItemModel[] | undefined,
    public backgroundProcess?: boolean | undefined,
    public fetchFile?: boolean | undefined,
    public fileUpload?: boolean | undefined,
    public fileList?: File[] | undefined,
    public applicationId?: string | undefined,
    public legalEntityId?: string | undefined,
    public tenantId?: string | undefined,
    public responseType?: string | undefined,
  ) { }
}
