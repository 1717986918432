export interface ReferenceNumbersModel {
  transactionReferenceNumber?: string | undefined | null;
  applicationReferenceNumber?: string | undefined | null;
}

export class ReferenceNumbersModel implements ReferenceNumbersModel {
  constructor(
    public transactionReferenceNumber?: string | undefined | null,
    public applicationReferenceNumber?: string | undefined | null,
  ) { }
}
