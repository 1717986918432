import {AbstractControl, Form, FormArray, FormControl, FormGroup} from '@angular/forms';
import {
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  AfterViewInit,
  Optional
} from '@angular/core';
import {JsonSchemaFormService} from '@finfra/ajsf-core';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {AjsfCoreWidgetsFunctionsService} from '../../../asjf-core-widgets.functions';
import {distinctUntilChanged} from 'rxjs/operators';
import {AlertService, ConfigService, EventsService, UtilitiesService} from '@finfra/core-services';
import {GenericEventModel} from '@finfra/core-models';

export interface CoOrdinates {
  row: number;
  column: number;
  tabIndex: number;
  colsUtilized: number;
  rowsUtilized: number;
}

@Component({
  selector: 'finfra-datagrid-widget',
  templateUrl: './finfra-datagrid-lite.component.html',
  styleUrls: ['./finfra-datagrid-lite.component.scss'],
})
export class FinfraDatagridLiteComponent implements OnInit, OnDestroy, AfterViewInit {
  widgetName = 'FinfraDatagridLiteComponent';
  formControl: AbstractControl;
  controlName: string;
  controlValue: any[];
  controlDisabled = false;
  boundControl = false;
  options: any;
  subProperties: any;
  items: any;
  @Input() layoutNode: any;
  @Input() layoutIndex: number[];
  @Input() dataIndex: number[];

  showGrid = false;
  depth = 0;
  sumControl: any;
  width = '';

  public datagridConfig: any = {
    cols: undefined,
    rows: undefined,
    columnNumbers: [],
    rowNumbers: [],
    jsonToGrid: false,
    showArrayIndices: false,
    useColSpan: false,
    showLabels: false,
    align: 'row',
    customAlign: {
      tags: {}
    },
    defaultFormatting: {
      number: {
        dataTypeLocale: 'en-US',
        dataTypeOptions: {
          minimumFractionDigits: 2
        }
      }
    },
    columnFormatting: {},
    rowFormatting: {},
    specificFormatting: {},
    sum: {
      row: [],
      col: []
    },
    calculated: {
      calculatedLocale: 'en-US',
      calculatedOptions: {
        minimumFractionDigits: 2
      }
    },
    firstRowHeader: true,
    gridLayout: {}
  };

  public gridControls: any = {};

  constructor(
    @Inject(MAT_FORM_FIELD_DEFAULT_OPTIONS) @Optional() public matFormFieldDefaultOptions,
    private jsf: JsonSchemaFormService,
    private ajsfCoreWidgetsFunctionsService: AjsfCoreWidgetsFunctionsService,
    private alertService: AlertService,
    private configService: ConfigService,
    private eventsService: EventsService,
    private utilitiesService: UtilitiesService
  ) {
  }

  ngOnInit() {
    this.options = this.layoutNode.options || {};
    this.subProperties = this.layoutNode.subProperties || {};
    this.items = this.layoutNode.items || {};

    this.jsf.initializeControl(this);
    if (!this.options.notitle && !this.options.description && this.options.placeholder) {
      this.options.description = this.options.placeholder;
    }

    if (this.options.datagridConfigPointer) {
      const datagridConfigControl = this.jsf.getControlService(this, this.jsf.formGroup, this.options.datagridConfigPointer);
      datagridConfigControl.valueChanges.pipe(
        distinctUntilChanged()
      ).subscribe(this.initDatagridOptions.bind(this));
    }

    this.initDatagridOptions();
  }

  ngOnDestroy(): void {
    this.jsf = undefined;
    this.formControl = undefined;
    this.controlName = undefined;
    this.controlValue = undefined;
    this.controlDisabled = undefined;
    this.boundControl = undefined;
    this.options = undefined;
    this.subProperties = undefined;
    this.items = undefined;
  }

  ngAfterViewInit() {
    this.width = document.getElementById('containerGrid' + this.layoutNode?._id).clientWidth + 'px';
    this.showGrid = true;
    this.dispatchEvent('datagrid-after-view-init', undefined);
  }

  public initDatagridOptions() {
    const datagridConfigControl = this.jsf.getControlService(this, this.jsf.formGroup, this.options.datagridConfigPointer);
    const datagridConfigValue: any = this.utilitiesService.deepCopy(datagridConfigControl.value);

    if (typeof datagridConfigValue.columnFormatting === 'string') {
      datagridConfigValue.columnFormatting = JSON.parse(datagridConfigValue.columnFormatting);
    }

    if (Array.isArray(datagridConfigValue.columnFormatting)) {
      const columnFormatting: any = this.utilitiesService.deepCopy(datagridConfigValue.columnFormatting);
      datagridConfigValue.columnFormatting = {};

      for (let idx = 0; idx < columnFormatting.length; idx++) {
        datagridConfigValue.columnFormatting[idx] = columnFormatting[idx];
      }
    }

    if (typeof datagridConfigValue.gridLayout === 'string') {
      datagridConfigValue.gridLayout = JSON.parse(datagridConfigValue.gridLayout);
    }

    if (Array.isArray(datagridConfigValue.gridLayout)) {
      const gridLayout: any = this.utilitiesService.deepCopy(datagridConfigValue.gridLayout);
      datagridConfigValue.gridLayout = {};

      for (let row = 0; row < gridLayout.length; row++) {
        datagridConfigValue.gridLayout[row] = {};

        for (let column = 0; column < gridLayout[row].length; column++) {
          datagridConfigValue.gridLayout[row][column] = gridLayout[row][column];
        }
      }
    }

    this.datagridConfig = this.utilitiesService.deepMerge(this.datagridConfig, datagridConfigValue);

    this.init();
  }

  public init() {
    if (this.options.datagridConfig) {
      this.datagridConfig = this.utilitiesService.deepMerge(this.datagridConfig, this.options.datagridConfig);
    }

    if (this.options.readonly) {
      this.datagridConfig.readonly = true;
    }

    if (this.controlValue === undefined ||
      this.controlValue === null ||
      this.controlValue === [] ||
      this.controlValue === [[]] ||
      (Array.isArray(this.controlValue) && this.controlValue.length === 0) ||
      this.options.forceReplaceControls
    ) {
      const derivedControl: AbstractControl = this.objectToControl(this.controlValue);
      const parent = this.formControl.parent;

      if (Array.isArray(parent.value)) {
        (parent as FormArray).setControl(this.dataIndex[this.dataIndex.length - 1], derivedControl);
      } else {
        (parent as FormGroup).setControl(this.controlName, derivedControl);
      }

      this.formControl = derivedControl;
    } else if (Array.isArray(this.controlValue) &&
      this.datagridConfig.rows !== undefined &&
      this.datagridConfig.rows !== null &&
      this.formControl.value.length !== this.datagridConfig.rows
    ) {
      const derivedControl: FormArray = (this.objectToControl(undefined) as FormArray);
      for (let idx = 0; idx < this.formControl.value.length; idx++) {
        const arrayElement = derivedControl.at(idx) as FormArray;
        const existingElement = (this.formControl as FormArray).at(idx) as FormArray;

        for (let colIdx = 0; colIdx < existingElement.controls.length; colIdx++) {
          arrayElement.setControl(colIdx, existingElement.at(colIdx));
        }
      }

      const parent = this.formControl.parent;

      if (Array.isArray(parent.value)) {
        (parent as FormArray).setControl(this.dataIndex[this.dataIndex.length - 1], derivedControl);
      } else {
        (parent as FormGroup).setControl(this.controlName, derivedControl);
      }

      this.formControl = derivedControl;
    }

    this.depth = this.getDepth(this.formControl.value);
    const coords: CoOrdinates = {row: 0, column: 0, tabIndex: 0, colsUtilized: 0, rowsUtilized: 0};

    if (this.datagridConfig.jsonToGrid) {
      this.jsonToGridData(this.formControl, coords, this.depth, this.datagridConfig.align);
    } else {
      this.dataToGridData(this.formControl, coords, this.depth);
    }

    Object.keys(this.gridControls).forEach(
      controlRow => {
        if (this.gridControls[controlRow] === {} || Object.keys(this.gridControls[controlRow]).length === 0) {
          delete this.gridControls[controlRow];
          this.datagridConfig.rows--;
        }
      }
    );

    this.setFormatting();
    this.setGlobalFormulas();

    if (!this.datagridConfig.cols) {
      this.datagridConfig.cols = 10;
    }

    if (!this.datagridConfig.rows) {
      this.datagridConfig.rows = 10;
    }

    this.datagridConfig.columnNumbers = [];
    let prefix = '';
    let previousPrefix = '';
    for (let idx = 0, n = 0, p = 65; idx < this.datagridConfig.cols; idx++, n++) {
      this.datagridConfig.columnNumbers.push({label: prefix + String.fromCharCode(65 + n), idx});

      if ((65 + n) === 90) {
        prefix = previousPrefix + String.fromCharCode(p);
        p++;
        n = -1;

        if (p > 90) {
          p = 65;
          previousPrefix = prefix;
        }
      }
    }

    this.datagridConfig.rowNumbers = [];
    for (let idx = 0; idx < this.datagridConfig.rows; idx++) {
      this.datagridConfig.rowNumbers.push({label: idx + 1, idx});
    }

    this.dispatchEvent('datagrid-initialized', undefined);
  }

  public dataToGridData(control: AbstractControl | null, coords: CoOrdinates, depth: number): void {
    if (!control) {
      return;
    }

    if (!coords.tabIndex) {
      coords.tabIndex = 0;
    }

    let firstLoop = false;
    if (coords.row === 0 && coords.column === 0) {
      firstLoop = true;
    }

    let row = 0;
    this.initGrid(row);
    if (this.datagridConfig.firstRowHeader) {
      Object.keys(this.datagridConfig.gridLayout[row]).forEach(
        (column: any) => {
          coords.row = +row;
          coords.column = +column;
          this.gridControls[coords.row][coords.column] = this.datagridConfig.gridLayout[row][column];
          this.gridControls[coords.row][coords.column].row = coords.row;
          this.gridControls[coords.row][coords.column].column = coords.column;
          this.gridControls[coords.row][coords.column].edit = false;
          this.gridControls[coords.row][coords.column].selected = false;
          this.gridControls[coords.row][coords.column].tabIndex = coords.tabIndex;
          this.gridControls[coords.row][coords.column].control = undefined;
          this.gridControls[coords.row][coords.column].coords = coords;

          coords.tabIndex++;
        }
      );
    }

    while (row < this.datagridConfig.rows) {
      row++;
      this.initGrid(row);

      let column = 0;
      while (column < this.datagridConfig.cols) {
        coords.row = +row;
        coords.column = +column;
        this.gridControls[coords.row][coords.column] = {};
        this.gridControls[coords.row][coords.column].row = coords.row;
        this.gridControls[coords.row][coords.column].column = coords.column;
        this.gridControls[coords.row][coords.column].edit = false;
        this.gridControls[coords.row][coords.column].selected = false;
        this.gridControls[coords.row][coords.column].tabIndex = coords.tabIndex;
        this.gridControls[coords.row][coords.column].control = this.formControl.get('' + (row - 1))?.get('' + column);
        this.gridControls[coords.row][coords.column].coords = coords;
        this.gridControls[coords.row][coords.column].type = 'value';
        this.gridControls[coords.row][coords.column].depth = 1;

        coords.tabIndex++;
        column++;
      }
    }
  }

  public jsonToGridData(control: AbstractControl | null, coords: CoOrdinates, depth: number, align: string): void {
    if (!control) {
      return;
    }

    if (!coords.tabIndex) {
      coords.tabIndex = 0;
    }

    let firstLoop = false;
    if (coords.row === 0 && coords.column === 0) {
      firstLoop = true;

      if (this.datagridConfig.firstRowHeader && this.datagridConfig.align === 'column') {
        this.initGrid(coords.row);
        Object.keys(this.datagridConfig.gridLayout[0]).forEach(
          (column: any) => {
            coords.row = 0;
            coords.column = +column;
            this.gridControls[coords.row][coords.column] = this.datagridConfig.gridLayout[0][column];
            this.gridControls[coords.row][coords.column].row = coords.row;
            this.gridControls[coords.row][coords.column].column = coords.column;
            this.gridControls[coords.row][coords.column].edit = false;
            this.gridControls[coords.row][coords.column].selected = false;
            this.gridControls[coords.row][coords.column].tabIndex = coords.tabIndex;
            this.gridControls[coords.row][coords.column].control = undefined;
            this.gridControls[coords.row][coords.column].coords = coords;

            coords.tabIndex++;
          }
        );

        coords.row++;
        coords.column = 0;
      } else {
        this.initGrid(coords.row);
        Object.keys(this.datagridConfig.gridLayout[0]).forEach(
          (column: any) => {
            coords.row = coords.row;
            coords.column = 0;
            this.gridControls[coords.row][coords.column] = this.datagridConfig.gridLayout[0][column];
            this.gridControls[coords.row][coords.column].row = coords.row;
            this.gridControls[coords.row][coords.column].column = coords.column;
            this.gridControls[coords.row][coords.column].edit = false;
            this.gridControls[coords.row][coords.column].selected = false;
            this.gridControls[coords.row][coords.column].tabIndex = coords.tabIndex;
            this.gridControls[coords.row][coords.column].control = undefined;
            this.gridControls[coords.row][coords.column].coords = coords;

            coords.tabIndex++;
            coords.row++;
            this.initGrid(coords.row);
          }
        );

        coords.row = 0;
        coords.column++;
      }
    }

    this.initGrid(coords.row);

    if (Array.isArray(control.value)) {
      const fa: FormArray = control as FormArray;
      for (const loopControl in fa.controls) {
        if (!fa.controls.hasOwnProperty(loopControl)) {
          continue;
        }

        if (this.datagridConfig.showArrayIndices) {
          this.gridControls[coords.row][coords.column] = {
            control: fa.get(loopControl),
            row: coords.row,
            column: coords.column,
            depth,
            label: loopControl,
            edit: false,
            selected: false,
            tabIndex: coords.tabIndex
          };
          coords.tabIndex++;

          if (typeof fa.get(loopControl)?.value === 'object') {
            this.gridControls[coords.row][coords.column].type = 'label';

            if (align === 'row') {
              const row = coords.row;
              coords.column = coords.column + 1;
              coords.row = coords.row + 1;
              this.jsonToGridData(fa.get(loopControl), coords, depth - 1, align);
              this.setColRowUtilized(coords);
              coords.row = row;
            } else {
              const column = coords.column;
              coords.row = coords.row + 1;
              coords.column = coords.column + 1;
              this.jsonToGridData(fa.get(loopControl), coords, depth - 1, align);
              this.setColRowUtilized(coords);
              coords.column = column;
            }

            if (align === 'row' && coords.colsUtilized >= coords.column) {
              coords.column = coords.colsUtilized;
            } else if (align === 'column' && coords.rowsUtilized >= coords.row) {
              coords.row = coords.rowsUtilized;
              this.initGrid(coords.row);
            }
          } else {
            this.gridControls[coords.row][coords.column].type = 'value';
          }

          if (align === 'row') {
            coords.column++;
          } else {
            coords.row++;
            this.initGrid(coords.row);
          }

          this.setColRowUtilized(coords);
        } else {
          if (typeof fa.get(loopControl)?.value === 'object') {
            if (align === 'row') {
              const row = coords.row;

              if (!firstLoop) {
                coords.column = coords.column + 1;
              }

              this.jsonToGridData(fa.get(loopControl), coords, depth - 1, align);
              this.setColRowUtilized(coords);
              coords.row = row;
            } else {
              const column = coords.column;

              if (!firstLoop) {
                coords.row = coords.row + 1;
              }

              this.jsonToGridData(fa.get(loopControl), coords, depth - 1, align);
              this.setColRowUtilized(coords);
              coords.column = column;
            }

            if (align === 'row' && coords.colsUtilized >= coords.column) {
              coords.column = +coords.colsUtilized;
            } else if (align === 'column' && coords.rowsUtilized >= coords.row) {
              coords.row = +coords.rowsUtilized;
              this.initGrid(coords.row);
            }
          } else {
            this.gridControls[coords.row][coords.column] = {
              control: fa.get(loopControl),
              row: coords.row,
              column: coords.column,
              depth,
              label: loopControl,
              edit: false,
              selected: false,
              tabIndex: coords.tabIndex,
              type: 'value'
            };
            coords.tabIndex++;
          }
        }

        firstLoop = false;
      }
    } else if (typeof control.value === 'object') {
      const fg: FormGroup = control as FormGroup;
      for (const loopControl in fg.controls) {
        if (!fg.controls.hasOwnProperty(loopControl)) {
          continue;
        }

        this.gridControls[coords.row][coords.column] = {
          control: fg.get(loopControl),
          row: coords.row,
          column: coords.column,
          depth,
          label: loopControl,
          edit: false,
          selected: false,
          tabIndex: coords.tabIndex
        };
        coords.tabIndex++;

        let childAlign = align;
        if (this.datagridConfig.customAlign.tags[loopControl]) {
          childAlign = this.datagridConfig.customAlign.tags[loopControl];

          if (childAlign !== align) {
            if (Array.isArray(fg.get(loopControl)?.value)) {
              this.gridControls[coords.row][coords.column].depth = (fg.get(loopControl) as FormArray)?.controls.length + 1;
            } else if (typeof fg.get(loopControl)?.value === 'object') {
              this.gridControls[coords.row][coords.column].depth = Object.keys((fg.get(loopControl) as FormGroup)?.controls).length + 1;
            }
          }
        }

        if (typeof fg.get(loopControl)?.value === 'object') {
          if (this.datagridConfig.showLabels) {
            this.gridControls[coords.row][coords.column].type = 'label';
          } else {
            this.gridControls[coords.row][coords.column].type = 'value';
          }

          if (align === 'row') {
            const column = coords.column;

            if (this.datagridConfig.showLabels) {
              coords.column = coords.column + 1;
            }

            coords.row = coords.row + 1;
            this.jsonToGridData(fg.get(loopControl), coords, depth - 1, childAlign);
            this.setColRowUtilized(coords);
            coords.column = column;
          } else {
            const row = coords.row;

            if (this.datagridConfig.showLabels) {
              coords.row = coords.row + 1;
            }

            coords.column = coords.column + 1;
            this.jsonToGridData(fg.get(loopControl), coords, depth - 1, childAlign);
            this.setColRowUtilized(coords);
            coords.row = row;
          }
        } else {
          if (this.datagridConfig.showLabels) {
            this.gridControls[coords.row][coords.column].type = 'label';
          } else {
            this.gridControls[coords.row][coords.column].type = 'value';
          }

          if (align === 'row') {
            if (this.datagridConfig.showLabels) {
              coords.column++;
            }

            this.gridControls[coords.row][coords.column] = {
              control: fg.get(loopControl),
              row: coords.row,
              column: coords.column,
              depth: 1,
              label: loopControl,
              edit: false,
              selected: false,
              tabIndex: coords.tabIndex
            };
            this.gridControls[coords.row][coords.column].type = 'value';
            coords.tabIndex++;
            this.setColRowUtilized(coords);

            if (this.datagridConfig.showLabels) {
              coords.column--;
            }
          } else {
            if (this.datagridConfig.showLabels) {
              coords.row++;
            }

            this.initGrid(coords.row);
            this.gridControls[coords.row][coords.column] = {
              control: fg.get(loopControl),
              row: coords.row,
              column: coords.column,
              depth: 1,
              label: loopControl,
              edit: false,
              selected: false,
              tabIndex: coords.tabIndex
            };
            this.gridControls[coords.row][coords.column].type = 'value';
            coords.tabIndex++;
            this.setColRowUtilized(coords);

            if (this.datagridConfig.showLabels) {
              coords.row--;
            }
          }
        }

        if (align === 'row') {
          coords.row++;
          this.initGrid(coords.row);
        } else {
          coords.column++;
        }

        if (childAlign !== align) {
          if (childAlign === 'row') {
            coords.column++;
          } else {
            coords.row++;
            this.initGrid(coords.row);
          }
        }

        this.setColRowUtilized(coords);

        firstLoop = false;
      }

      if (align === 'row') {
        coords.row--;
      } else {
        coords.column--;
      }
    }

    this.datagridConfig.rows = Object.keys(this.gridControls).length;
    this.datagridConfig.cols = 0;
    Object.keys(this.gridControls).forEach(
      (row: any) => {
        Object.keys(this.gridControls[row]).forEach(
          (column: any) => {
            if (this.datagridConfig.cols < +column + 1) {
              this.datagridConfig.cols = +column + 1;
            }
          }
        );
      }
    );
  }

  public setColRowUtilized(coords: CoOrdinates): void {
    if (coords.colsUtilized < coords.column) {
      coords.colsUtilized = coords.column;
    }

    if (coords.rowsUtilized < coords.row) {
      coords.rowsUtilized = coords.row;
    }
  }

  public setFormatting(): void {
    const coOrds: any[] = [-1, -1];
    Object.keys(this.gridControls).forEach(
      row => {
        coOrds[0]++;
        let rowDataType: any;
        if (this.datagridConfig.rowFormatting && this.datagridConfig.rowFormatting[row]) {
          rowDataType = this.datagridConfig.rowFormatting[row];
        }

        if (!this.datagridConfig.firstRowHeader || +row > 0) {
          let colIdx = 0;
          coOrds[1] = undefined;
          Object.keys(this.gridControls[row]).forEach(
            col => {
              if (coOrds[1] === undefined || this.datagridConfig.align === 'column') {
                coOrds[1] = +this.gridControls[row][col].column;
              }

              this.gridControls[row][col].coOrdinates = JSON.parse(JSON.stringify(coOrds));
              if (this.datagridConfig.align === 'row') {
                coOrds[1] = coOrds[1] + +(this.gridControls[row][col].depth || 1);
              }

              if (this.datagridConfig.align === 'column') {
                colIdx = +col;
              }

              if (!this.gridControls[row][col].dataType && this.gridControls[row][col].control) {
                if (this.datagridConfig.columnFormatting && this.datagridConfig.columnFormatting[colIdx]) {
                  this.gridControls[row][col].dataType = this.datagridConfig.columnFormatting[colIdx].dataType;
                  this.gridControls[row][col].throwEvent = this.datagridConfig.columnFormatting[colIdx].throwEvent;
                  this.gridControls[row][col].skipSum = this.datagridConfig.columnFormatting[colIdx].skipSum;
                  this.gridControls[row][col].dataTypeLocale = this.datagridConfig.columnFormatting[colIdx].dataTypeLocale;
                  this.gridControls[row][col].dataTypeOptions = this.datagridConfig.columnFormatting[colIdx].dataTypeOptions;
                } else if (this.datagridConfig.specificFormatting && this.datagridConfig.specificFormatting[row] && this.datagridConfig.specificFormatting[row][colIdx]) {
                  this.gridControls[row][col].dataType = this.datagridConfig.specificFormatting[row][colIdx].dataType;
                  this.gridControls[row][col].throwEvent = this.datagridConfig.specificFormatting[row][colIdx].throwEvent;
                  this.gridControls[row][col].skipSum = this.datagridConfig.specificFormatting[row][colIdx].skipSum;
                  this.gridControls[row][col].dataTypeLocale = this.datagridConfig.specificFormatting[row][colIdx].dataTypeLocale;
                  this.gridControls[row][col].dataTypeOptions = this.datagridConfig.specificFormatting[row][colIdx].dataTypeOptions;
                } else if (rowDataType) {
                  this.gridControls[row][col].dataType = rowDataType.dataType;
                  this.gridControls[row][col].throwEvent = rowDataType.throwEvent;
                  this.gridControls[row][col].skipSum = rowDataType.skipSum;
                  this.gridControls[row][col].dataTypeLocale = rowDataType.dataTypeLocale;
                  this.gridControls[row][col].dataTypeOptions = rowDataType.dataTypeOptions;
                } else {
                  const value = this.gridControls[row][col].control?.value;

                  if (value !== undefined && value !== null) {
                    if (typeof (value) === 'number' || typeof (value) === 'bigint' || (!isNaN(value) && !(value instanceof Date))) {
                      this.gridControls[row][col].dataType = 'number';
                      this.gridControls[row][col].skipSum = false;
                      this.gridControls[row][col].control.setValue(+this.gridControls[row][col].control.value);

                      if (this.datagridConfig.defaultFormatting.number) {
                        this.gridControls[row][col].dataTypeLocale = this.datagridConfig.defaultFormatting.number.dataTypeLocale;
                        this.gridControls[row][col].dataTypeOptions = this.datagridConfig.defaultFormatting.number.dataTypeOptions;
                      }
                    } else if (typeof (value) === 'string' && value.indexOf('%') > -1) {
                      this.gridControls[row][col].dataType = 'string';
                      this.gridControls[row][col].skipSum = true;

                      if (this.datagridConfig.defaultFormatting.string) {
                        this.gridControls[row][col].dataTypeLocale = this.datagridConfig.defaultFormatting.string.dataTypeLocale;
                        this.gridControls[row][col].dataTypeOptions = this.datagridConfig.defaultFormatting.string.dataTypeOptions;
                      }
                    } else if (value instanceof Date || !isNaN(new Date(value).getDate())) {
                      this.gridControls[row][col].dataType = 'datetime-local';
                      this.gridControls[row][col].skipSum = true;

                      if (this.datagridConfig.defaultFormatting.date) {
                        this.gridControls[row][col].dataTypeLocale = this.datagridConfig.defaultFormatting.date.dataTypeLocale;
                        this.gridControls[row][col].dataTypeOptions = this.datagridConfig.defaultFormatting.date.dataTypeOptions;
                      }

                      if (typeof (value) === 'string') {
                        const dt = new Date(value);

                        if (value.indexOf(':') > -1) {
                          this.gridControls[row][col].control.setValue(
                            dt.getFullYear() + '-' +
                            ('' + (dt.getMonth() + 1)).padStart(2, '0') + '-' +
                            ('' + dt.getDate()).padStart(2, '0')
                            + 'T' +
                            ('' + dt.getHours()).padStart(2, '0') + ':' +
                            ('' + dt.getMinutes()).padStart(2, '0') + ':' +
                            ('' + dt.getSeconds()).padStart(2, '0')
                          );
                        } else {
                          this.gridControls[row][col].dataType = 'date';
                          this.gridControls[row][col].control.setValue(
                            dt.getFullYear() + '-' +
                            ('' + (dt.getMonth() + 1)).padStart(2, '0') + '-' +
                            ('' + dt.getDate()).padStart(2, '0')
                          );
                        }
                      }
                    } else {
                      this.gridControls[row][col].dataType = 'string';
                      this.gridControls[row][col].skipSum = false;

                      if (this.datagridConfig.defaultFormatting.string) {
                        this.gridControls[row][col].dataTypeLocale = this.datagridConfig.defaultFormatting.string.dataTypeLocale;
                        this.gridControls[row][col].dataTypeOptions = this.datagridConfig.defaultFormatting.string.dataTypeOptions;
                      }
                    }
                  } else {

                  }
                }
              } else if (!this.gridControls[row][col].control) {
                this.gridControls[row][col].type = 'label';
              }

              if (this.gridControls[row][col].throwEvent && this.gridControls[row][col].control) {
                this.gridControls[row][col].control.valueChanges.pipe(
                  distinctUntilChanged()
                ).subscribe(this.dispatchEvent.bind(this, 'datagrid-control-changed', this.gridControls[row][col].control));
              }

              if (this.datagridConfig.align === 'row') {
                if (colIdx === 0 && +col > 0) {
                  colIdx = +col;
                }

                colIdx = colIdx + this.gridControls[row][col].depth;
              }
            }
          );
        }
      }
    );
  }

  public initGrid(x: number): void {
    if (!this.gridControls[x]) {
      this.gridControls[x] = {};
    }
  }

  public objectToControl(obj: any): AbstractControl {
    if (obj === undefined || obj === null || obj === [] || obj === [[]] || (Array.isArray(obj) && obj.length === 0)) {
      const control = new FormArray([]);

      let rows = this.datagridConfig.rows;

      if (this.datagridConfig.firstRowHeader) {
        rows--;
      }

      for (let row = 0; row < rows; row++) {
        let rowControl: any;
        if (Array.isArray(this.datagridConfig.arrayKeyStructure) && this.datagridConfig.arrayKeyStructure.length > 0) {
          let arrayKeyStructure = this.datagridConfig.arrayKeyStructure;

          if (typeof arrayKeyStructure === 'string') {
            arrayKeyStructure = JSON.parse(arrayKeyStructure);
          }

          rowControl = new FormGroup({});
          arrayKeyStructure.forEach(
            key => {
              (rowControl as FormGroup).setControl(key, new FormControl(null));
            }
          );
        } else {
          rowControl = new FormArray([]);
          for (let col = 0; col < this.datagridConfig.cols; col++) {
            (rowControl as FormArray).push(new FormControl(null));
          }
        }

        control.push(rowControl);
      }

      return control;
    }

    if (Array.isArray(obj)) {
      const control = new FormArray([]);
      obj.forEach(
        key => {
          if (typeof (key) === 'object') {
            control.push(this.objectToControl(key));
          } else {
            control.push(new FormControl(key));
          }
        }
      );

      return control;
    } else if (obj === null) {
      return new FormControl(null);
    } else {
      const control = new FormGroup({});
      Object.keys(obj).forEach(
        key => {
          if (typeof (obj[key]) === 'object') {
            control.addControl(key, this.objectToControl(obj[key]));
          } else {
            control.addControl(key, new FormControl(obj[key]));
          }
        }
      );

      return control;
    }
  }

  public getJson(value: any): string {
    if (value) {
      return JSON.stringify(value, null, '\t');
    }

    return '--';
  }

  public getDepth(obj: any): any {
    if (!obj || obj.length === 0 || typeof (obj) !== 'object') {
      return 0;
    }
    const keys = Object.keys(obj);
    let depth = 0;
    keys.forEach(key => {
      const tmpDepth = this.getDepth(obj[key]);
      if (tmpDepth > depth) {
        depth = tmpDepth;
      }
    });
    return depth + 1;
  }

  public edit(elem: any, event: any): void {
    elem.edit = true;

    if (event.currentTarget.type && event.currentTarget.type === 'text') {
      event.currentTarget.setSelectionRange(-1, -1);
    } else if (event.currentTarget.type && event.currentTarget.type === 'number') {

    }
  }

  public enter(elem: any): void {
    elem.edit = !elem.edit;

    if (!elem.edit) {
      this.validate(elem);
    }
  }

  public escape(elem: any): void {
    elem.edit = false;
    this.validate(elem);
  }

  public focus(elem: any): void {
    elem.selected = true;
  }

  public focusout(elem: any): void {
    elem.edit = false;
    elem.selected = false;
    this.validate(elem);
  }

  public copy(elem: any): void {
    if (elem.type === 'label') {
      navigator.clipboard.writeText(elem.label).then();
    } else if (elem.type === 'value') {
      navigator.clipboard.writeText(elem.control.value).then();
    }
  }

  public paste(elem: any, event: any): void {
    if (elem.type === 'label') {
      return;
    } else if (elem.type === 'value') {
      if (elem.edit) {
        return;
      }

      this.edit(elem, event);
      const clipboardData = event.clipboardData || event.originalEvent.clipboardData;
      elem.control.setValue(clipboardData?.getData('text'));
      this.enter(elem);
    }

    event.preventDefault();
  }

  public arrowRight(elem: any, event: any): void {
    if (elem.edit) {
      return;
    }

    const nextElem = document.getElementById(elem.row + '' + (elem.column + 1));

    if (nextElem) {
      nextElem.focus();
    }
  }

  public arrowLeft(elem: any, event: any): void {
    if (elem.edit) {
      return;
    }

    const nextElem = document.getElementById(elem.row + '' + (elem.column - 1));

    if (nextElem) {
      nextElem.focus();
    }
  }

  public arrowUp(elem: any, event: any): void {
    if (elem.edit) {
      return;
    }

    const nextElem = document.getElementById((elem.row - 1) + '' + elem.column);

    if (nextElem) {
      nextElem.focus();
    }
  }

  public arrowDown(elem: any, event: any): void {
    if (elem.edit) {
      return;
    }

    const nextElem = document.getElementById((elem.row + 1) + '' + elem.column);

    if (nextElem) {
      nextElem.focus();
    }
  }

  public validate(elem: any): void {
    if (this.datagridConfig.readonly) {
      return;
    }

    if (elem.dataType === 'number') {
      if (elem.dataTypeOptions && elem.dataTypeOptions.minimumFractionDigits !== undefined && elem.control.value) {
        elem.control.setValue(parseFloat(elem.control.value).toFixed(elem.dataTypeOptions.minimumFractionDigits));
      }
    } else if (elem.dataType === 'select') {
      let found = false;
      for (const option of elem.dataTypeOptions.options) {
        if (option.value === elem.control.value) {
          found = true;
          break;
        }
      }

      if (!found) {
        for (const option of elem.dataTypeOptions.options) {
          if (option.label === elem.control.value) {
            elem.control.setValue(option.value);
            found = true;
            break;
          }
        }
      }

      if (!found) {
        elem.control.setValue(null);
      }
    }
  }

  public isColumnPresent(elem: any, rowIdx: number, colIdx: number): boolean {
    if (this.datagridConfig.align === 'column' || !this.datagridConfig.useColSpan) {
      return true;
    }

    for (let idx = colIdx; idx <= this.datagridConfig.cols; idx++) {
      if (elem[idx]) {
        return true;
      }
    }

    let colspan = 0;
    for (let idx = 0; idx <= colIdx; idx++) {
      if (elem[idx]) {
        colspan = colspan + elem[idx].depth;
      } else {
        colspan = colspan + 1;
      }
    }

    if (colspan <= this.datagridConfig.cols) {
      this.initGrid(rowIdx);
      this.gridControls[rowIdx][colIdx] = {
        control: undefined,
        row: rowIdx,
        column: colIdx,
        depth: 1,
        label: undefined,
        edit: false,
        selected: false,
        type: 'label'
      };
      return true;
    }

    return false;
  }

  public lettersToNumber(letters: string): number {
    let n = 0;

    for (const alphabet of letters) {
      n = alphabet.charCodeAt(0) - 64 + n * 26;
    }

    return n;
  }

  public setGlobalFormulas(): void {
    this.sumControl = {
      row: {},
      col: {}
    };

    if (this.datagridConfig.sum) {
      if (this.datagridConfig.sum.col && this.datagridConfig.sum.col.length > 0) {
        this.initGrid(this.datagridConfig.rows);
        for (const col of this.datagridConfig.sum.col) {
          this.gridControls[this.datagridConfig.rows][col] = {
            control: new FormControl(0),
            row: this.datagridConfig.rows,
            column: col,
            coOrdinates: [this.datagridConfig.rows, col],
            depth: 1,
            label: undefined,
            edit: false,
            selected: false,
            type: 'calculated'
          };

          Object.keys(this.gridControls).forEach(
            row => {
              Object.keys(this.gridControls[row]).forEach(
                column => {
                  if (this.gridControls[row][column].type !== 'label' && this.gridControls[row][column].type !== 'calculated' && this.gridControls[row][column].coOrdinates && +this.gridControls[row][column].coOrdinates[1] === +col) {
                    if (!this.sumControl.col[col]) {
                      this.sumControl.col[col] = {
                        control: this.gridControls[this.datagridConfig.rows][col].control,
                        dependentControls: []
                      };
                    }

                    if (
                      (
                        typeof (this.gridControls[row][column].control.value) === 'number' ||
                        typeof (this.gridControls[row][column].control.value) === 'bigint' ||
                        (
                          !isNaN(this.gridControls[row][column].control.value) &&
                          !(this.gridControls[row][column].control.value instanceof Date)
                        )
                      ) && !this.gridControls[row][column].skipSum
                    ) {
                      this.sumControl.col[col].dependentControls.push(this.gridControls[row][column].control);
                      this.sumControl.col[col].control.setValue(+this.sumControl.col[col].control.value + +this.gridControls[row][column].control.value);

                      this.gridControls[row][column].control.valueChanges.subscribe(
                        (value: any) => {
                          let sum = 0;

                          this.sumControl.col[col].dependentControls.forEach(
                            (control: AbstractControl) => {
                              if (typeof (control.value) === 'number' || typeof (control.value) === 'bigint' || (!isNaN(control.value) && !(control.value instanceof Date))) {
                                sum = sum + +control.value;
                              }
                            }
                          );

                          this.sumControl.col[col].control.setValue(sum);
                        }
                      );
                    }
                  }
                }
              );
            }
          );
        }

        this.datagridConfig.rows++;
      }

      if (this.datagridConfig.sum.row && this.datagridConfig.sum.row.length > 0) {
        for (const sumRow of this.datagridConfig.sum.row) {
          let lastColumnObject: any;
          let lastCol: any;
          Object.keys(this.gridControls[sumRow]).forEach(
            col => {
              lastColumnObject = this.gridControls[sumRow][col];
              lastCol = +col;
            }
          );

          this.gridControls[sumRow][lastCol + 1] = {
            control: new FormControl(0),
            row: sumRow,
            column: lastColumnObject.column + 1,
            depth: 1,
            label: undefined,
            edit: false,
            selected: false,
            type: 'calculated',
            coOrdinates: [sumRow, lastColumnObject.coOrdinates[1] + 1]
          };

          Object.keys(this.gridControls).forEach(
            row => {
              if (+row === +sumRow) {
                if (!this.sumControl.row[row]) {
                  this.sumControl.row[row] = {
                    control: this.gridControls[sumRow][lastCol + 1].control,
                    dependentControls: []
                  };
                }

                Object.keys(this.gridControls[row]).forEach(
                  column => {
                    if (this.gridControls[row][column].type !== 'label' && this.gridControls[row][column].type !== 'calculated') {
                      if (
                        (
                          typeof (this.gridControls[row][column].control.value) === 'number' ||
                          typeof (this.gridControls[row][column].control.value) === 'bigint' ||
                          (
                            !isNaN(this.gridControls[row][column].control.value) &&
                            !(this.gridControls[row][column].control.value instanceof Date)
                          )
                        ) && !this.gridControls[row][column].skipSum
                      ) {
                        this.sumControl.row[row].dependentControls.push(this.gridControls[row][column].control);
                        this.sumControl.row[row].control.setValue(+this.sumControl.row[row].control.value + +this.gridControls[row][column].control.value);

                        this.gridControls[row][column].control.valueChanges.subscribe(
                          (value: any) => {
                            let sum = 0;

                            this.sumControl.row[row].dependentControls.forEach(
                              (control: AbstractControl) => {
                                if (typeof (control.value) === 'number' || typeof (control.value) === 'bigint' || (!isNaN(control.value) && !(control.value instanceof Date))) {
                                  sum = sum + +control.value;
                                }
                              }
                            );

                            this.sumControl.row[row].control.setValue(sum);
                          }
                        );
                      }
                    }
                  }
                );
              }
            }
          );

          if (this.gridControls[sumRow][lastCol + 1].coOrdinates[1] >= +this.datagridConfig.cols) {
            this.datagridConfig.cols++;
          }
        }
      }
    }
  }

  public dispatchEvent(eventName: string, value: any) {
    const eventObject: GenericEventModel = new GenericEventModel();

    eventObject.eventSource = 'finfra-ajsf';
    eventObject.formControl = this.formControl;
    eventObject.formName = this.jsf.ajsfFormName;
    eventObject.value = value;
    eventObject.jsonSchema = this.layoutNode;
    eventObject.eventObjectName = this.controlName;
    eventObject.eventObject = this.formControl.value;
    eventObject.eventName = this.widgetName + '-' + eventName;
    eventObject.arrayIndex = this.dataIndex[0];

    this.eventsService.fireEvent(eventObject);
  }
}
