import { FinfraMaterialInputComponent } from './level-1/finfra-material-input/finfra-material-input.component';
import { FinfraMaterialNumberComponent } from './level-1/finfra-material-number/finfra-material-number.component';
import { FinfraMaterialTextareaComponent } from './level-1/finfra-material-textarea/finfra-material-textarea.component';
import { FinfraMaterialButtonComponent } from './level-1/finfra-material-button/finfra-material-button.component';
import { FinfraMaterialButtonGroupComponent } from './level-1/finfra-material-button-group/finfra-material-button-group.component';
import { FinfraMaterialCheckboxComponent } from './level-1/finfra-material-checkbox/finfra-material-checkbox.component';
import { FinfraMaterialCheckboxesComponent } from './level-1/finfra-material-checkboxes/finfra-material-checkboxes.component';
import { FinfraAmountComponent } from './level-2/finfra-amount/finfra-amount.component';
import { FinfraImageComponent } from './level-2/finfra-image/finfra-image.component';
import { FinfraImageCarouselComponent } from './level-2/finfra-image-carousel/finfra-image-carousel.component';
import { FinfraMaterialSelectComponent } from './level-1/finfra-material-select/finfra-material-select.component';
import { FinfraFileComponent } from './level-2/finfra-file/finfra-file.component';
import { FinfraLabelComponent } from './level-2/finfra-label/finfra-label.component';
import { FinfraMaterialRadiosComponent } from './level-1/finfra-material-radios/finfra-material-radios.component';
import { FinfraMaterialDatepickerComponent } from './level-1/finfra-material-datepicker/finfra-material-datepicker.component';
import { FinfraTableExpandableComponent } from './level-2/finfra-table-expandable/finfra-table-expandable.component';
import { FinfraMaterialAutocompleteComponent } from './level-1/finfra-material-autocomplete/finfra-material-autocomplete.component';
import { FinfraSolrSearchComponent } from './level-2/finfra-solr-search/finfra-solr-search.component';
import { FinfraWebcamComponent } from './level-2/finfra-webcam/finfra-webcam.component';
import { FinfraDocumentScannerComponent } from './level-2/finfra-document-scanner/finfra-document-scanner.component';
import { FinfraReferenceNumbersComponent } from './level-2/finfra-reference-numbers/finfra-reference-numbers.component';
import { FinfraOtpComponent } from './level-2/finfra-otp/finfra-otp.component';
import { FinfraMaterialDaterangeComponent } from './level-1/finfra-material-daterange/finfra-material-daterange.component';
import { FinfraMaterialPasswordComponent } from './level-1/finfra-material-password/finfra-material-password.component';
import { FinfraDatagridComponent } from './level-2/finfra-datagrid/finfra-datagrid.component';
import { FinfraDatagridLiteComponent } from './level-2/finfra-datagrid-lite/finfra-datagrid-lite.component';
import { FinfraObjectDifferenceComponent } from './level-2/finfra-object-difference/finfra-object-difference.component';
import { FinfraDMSWidgetComponent } from './level-2/finfra-dms/finfra-dms.component';
import { FinfraSubFunctionComponent } from './level-2/finfra-sub-function/finfra-sub-function.component';
import { FinfraFaceRecognitionComponent } from './level-2/finfra-face-recognition/finfra-face-recognition.component';
import { FinfraEmbedComponent } from './level-2/finfra-embed/finfra-embed.component';
import { FinfraWysiwygComponent } from './level-2/finfra-wysiwyg-editor/finfra-wysiwyg-editor.component';



export const FINFRA_CORE_WIDGETS_COMPONENTS = [
  FinfraMaterialInputComponent,
  FinfraMaterialNumberComponent,
  FinfraMaterialTextareaComponent,
  FinfraMaterialButtonComponent,
  FinfraMaterialButtonGroupComponent,
  FinfraMaterialCheckboxComponent,
  FinfraMaterialCheckboxesComponent,
  FinfraAmountComponent,
  FinfraImageComponent,
  FinfraImageCarouselComponent,
  FinfraMaterialSelectComponent,
  FinfraFileComponent,
  FinfraLabelComponent,
  FinfraMaterialRadiosComponent,
  FinfraMaterialDatepickerComponent,
  FinfraMaterialDaterangeComponent,
  FinfraTableExpandableComponent,
  FinfraMaterialAutocompleteComponent,
  FinfraSolrSearchComponent,
  FinfraWebcamComponent,
  FinfraDocumentScannerComponent,
  FinfraReferenceNumbersComponent,
  FinfraOtpComponent,
  FinfraMaterialPasswordComponent,
  FinfraDatagridComponent,
  FinfraDatagridLiteComponent,
  FinfraObjectDifferenceComponent,
  FinfraDMSWidgetComponent,
  FinfraSubFunctionComponent,
  FinfraFaceRecognitionComponent,
  FinfraWysiwygComponent,
  FinfraEmbedComponent
];


export { FinfraMaterialInputComponent } from './level-1/finfra-material-input/finfra-material-input.component';
export { FinfraMaterialNumberComponent } from './level-1/finfra-material-number/finfra-material-number.component';
export { FinfraMaterialTextareaComponent } from './level-1/finfra-material-textarea/finfra-material-textarea.component';
export { FinfraMaterialButtonComponent } from './level-1/finfra-material-button/finfra-material-button.component';
export { FinfraMaterialButtonGroupComponent } from './level-1/finfra-material-button-group/finfra-material-button-group.component';
export { FinfraMaterialCheckboxComponent } from './level-1/finfra-material-checkbox/finfra-material-checkbox.component';
export { FinfraMaterialCheckboxesComponent } from './level-1/finfra-material-checkboxes/finfra-material-checkboxes.component';
export { FinfraAmountComponent } from './level-2/finfra-amount/finfra-amount.component';
export { FinfraImageComponent } from './level-2/finfra-image/finfra-image.component';
export { FinfraImageCarouselComponent } from './level-2/finfra-image-carousel/finfra-image-carousel.component';
export { FinfraMaterialSelectComponent } from './level-1/finfra-material-select/finfra-material-select.component';
export { FinfraFileComponent } from './level-2/finfra-file/finfra-file.component';
export { FinfraLabelComponent } from './level-2/finfra-label/finfra-label.component';
export { FinfraMaterialRadiosComponent } from './level-1/finfra-material-radios/finfra-material-radios.component';
export { FinfraMaterialDatepickerComponent } from './level-1/finfra-material-datepicker/finfra-material-datepicker.component';
export { FinfraTableExpandableComponent } from './level-2/finfra-table-expandable/finfra-table-expandable.component';
export { FinfraMaterialAutocompleteComponent } from './level-1/finfra-material-autocomplete/finfra-material-autocomplete.component';
export { FinfraSolrSearchComponent } from './level-2/finfra-solr-search/finfra-solr-search.component';
export { FinfraWebcamComponent } from './level-2/finfra-webcam/finfra-webcam.component';
export { FinfraDocumentScannerComponent } from './level-2/finfra-document-scanner/finfra-document-scanner.component';
export { FinfraReferenceNumbersComponent } from './level-2/finfra-reference-numbers/finfra-reference-numbers.component';
export { FinfraOtpComponent } from './level-2/finfra-otp/finfra-otp.component';
export { FinfraMaterialDaterangeComponent } from './level-1/finfra-material-daterange/finfra-material-daterange.component';
export { FinfraMaterialPasswordComponent } from './level-1/finfra-material-password/finfra-material-password.component';
export { FinfraDatagridComponent } from './level-2/finfra-datagrid/finfra-datagrid.component';
export { FinfraDatagridLiteComponent } from './level-2/finfra-datagrid-lite/finfra-datagrid-lite.component';
export { FinfraObjectDifferenceComponent } from './level-2/finfra-object-difference/finfra-object-difference.component';
export { FinfraDMSWidgetComponent } from './level-2/finfra-dms/finfra-dms.component';
export { FinfraSubFunctionComponent } from './level-2/finfra-sub-function/finfra-sub-function.component';
export { FinfraFaceRecognitionComponent } from './level-2/finfra-face-recognition/finfra-face-recognition.component';
export { FinfraEmbedComponent } from './level-2/finfra-embed/finfra-embed.component';
export { FinfraWysiwygComponent } from './level-2/finfra-wysiwyg-editor/finfra-wysiwyg-editor.component';
