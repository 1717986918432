import { Injectable } from '@angular/core';
import { GenericEventModel } from "@finfra/core-models";
import { Subject } from 'rxjs';

@Injectable({
  providedIn: "root",
})
export class EventsService {
  private eventEmitter: Subject<GenericEventModel> = new Subject<GenericEventModel>();

  constructor(

  ) {

  }

  public fireEvent(event: GenericEventModel) {
    this.eventEmitter.next(event);
  }

  public listenEvents(): Subject<GenericEventModel> {
    return this.eventEmitter;
  }
}
