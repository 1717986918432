import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { JsonSchemaFormService, dateToString } from '@finfra/ajsf-core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'material-datepicker-widget',
  templateUrl: './material-datepicker.component.html',
  styleUrls: ['./material-datepicker.component.scss'],
})
export class MaterialDatepickerComponent implements OnInit {
  formControl: AbstractControl;
  controlName: string;
  controlValue: string;
  dateValue: any;
  controlDisabled = false;
  boundControl = false;
  options: any;
  autoCompleteList: string[] = [];
  @Input() layoutNode: any;
  @Input() layoutIndex: number[];
  @Input() dataIndex: number[];

  constructor(
    @Inject(MAT_FORM_FIELD_DEFAULT_OPTIONS) @Optional() public matFormFieldDefaultOptions,
    private jsf: JsonSchemaFormService
  ) { }

  ngOnInit() {
    this.options = this.layoutNode.options || {};
    this.jsf.initializeControl(this, !this.options.readonly);
    if (this.controlValue) {
      this.formControl.setValue(dateToString(this.controlValue, this.options));
    }
    if (!this.options.notitle && !this.options.description && this.options.placeholder) {
      this.options.description = this.options.placeholder;
    }
  }

  updateValue(event: MatDatepickerInputEvent<Date>) {
    this.options.showErrors = true;
    if (event.value) {
      this.formControl.setValue(dateToString(event.value, this.options));
    }
  }
}
