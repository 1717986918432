import { Component, Inject, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { JsonSchemaFormService } from '@finfra/ajsf-core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { CurrencyService } from '@finfra/core-services';
import { AjsfCoreWidgetsFunctionsService } from '../../../asjf-core-widgets.functions';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'finfra-amount-widget',
  templateUrl: './finfra-amount.component.html',
  styleUrls: ['./finfra-amount.component.scss'],
})
export class FinfraAmountComponent implements OnInit, OnDestroy {
  widgetName = 'FinfraMaterialAmountComponent';
  formControl: AbstractControl;
  controlName: string;
  controlValue: any;
  controlDisabled = false;
  boundControl = false;
  options: any;
  subProperties: any;
  allowNegative = true;
  allowDecimal = true;
  allowExponents = false;
  lastValidNumber = '';
  currencyControl: AbstractControl;
  amountInWordsController: AbstractControl;
  formattedAmountController: AbstractControl;
  @Input() layoutNode: any;
  @Input() layoutIndex: number[];
  @Input() dataIndex: number[];

  constructor(
    @Inject(MAT_FORM_FIELD_DEFAULT_OPTIONS) @Optional() public matFormFieldDefaultOptions,
    private jsf: JsonSchemaFormService,
    public currencyService: CurrencyService,
    private ajsfCoreWidgetsFunctionsService: AjsfCoreWidgetsFunctionsService
  ) { }

  ngOnInit() {
    this.options = this.layoutNode.options || {};
    this.subProperties = this.layoutNode.subProperties || {};
    this.jsf.initializeControl(this);
    if (this.layoutNode.dataType === 'integer') { this.allowDecimal = false; }
    if (!this.options.notitle && !this.options.description && this.options.placeholder) {
      this.options.description = this.options.placeholder;
    }

    if (this.options.formattedAmountPointer) {
      this.formattedAmountController = this.jsf.getControlService(this, this.jsf.formGroup, this.options.formattedAmountPointer);
    }

    if (this.options.amountInWordsPointer) {
      this.amountInWordsController = this.jsf.getControlService(this, this.jsf.formGroup, this.options.amountInWordsPointer);
    }

    this.assignCurrencyControl();
  }

  ngOnDestroy(): void {
    this.jsf = undefined;
    this.formControl = undefined;
    this.controlName = undefined;
    this.controlValue = undefined;
    this.controlDisabled = undefined;
    this.boundControl = undefined;
    this.options = undefined;
    this.subProperties = undefined;
  }

  updateValue() {
    this.jsf.updateValue(this, this.controlValue);

    if (this.formattedAmountController) {
      this.formattedAmountController.setValue(this.currencyService.formatAmount(this.formControl.value, this.currencyControl.value));
    }

    if (this.amountInWordsController) {
      this.amountInWordsController.setValue(this.currencyService.amountToWords(this.formControl.value, this.currencyControl.value));
    }
  }

  assignCurrencyControl() {
    this.currencyControl = this.jsf.getControlService(this, this.jsf.formGroup, this.options.currencyPointer);
  }

  validate(event): boolean {
    if (this.options.readonly !== undefined && this.options.readonly === true) {
      return false;
    }

    this.controlValue = event.target.value;

    if (this.currencyControl.value === undefined || this.currencyControl.value === null) {
      this.controlValue = null;
    }

    if (this.controlValue !== undefined && this.controlValue !== null) {
      if (this.options.maximum && this.controlValue > this.options.maximum) {
        this.controlValue = null;
        this.jsf.updateValue(this, null);
        return false;
      }

      if (this.options.minimum && this.controlValue < this.options.minimum) {
        this.controlValue = null;
        this.jsf.updateValue(this, null);
        return false;
      }

      this.controlValue = this.currencyService.roundAmount(this.controlValue, this.currencyControl.value);
      this.updateValue();
    } else {
      this.updateValue();
    }

    this.ajsfCoreWidgetsFunctionsService.dispatchEvent(this);
    return true;
  }
}
