import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { JsonSchemaFormService } from '@finfra/ajsf-core';
import { AjsfCoreWidgetsFunctionsService } from '../../../asjf-core-widgets.functions';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'finfra-material-checkbox-widget',
  templateUrl: './finfra-material-checkbox.component.html',
  styleUrls: ['./finfra-material-checkbox.component.scss'],
})
export class FinfraMaterialCheckboxComponent implements OnInit, OnDestroy {
  widgetName: string = "FinfraMaterialCheckboxComponent";
  formControl: AbstractControl;
  controlName: string;
  controlValue: any;
  controlDisabled = false;
  boundControl = false;
  options: any;
  subProperties: any;
  trueValue: any = true;
  falseValue: any = false;
  showSlideToggle = false;
  @Input() layoutNode: any;
  @Input() layoutIndex: number[];
  @Input() dataIndex: number[];

  constructor(
    private jsf: JsonSchemaFormService,
    private ajsfCoreWidgetsFunctionsService: AjsfCoreWidgetsFunctionsService
  ) { }

  ngOnInit() {
    this.options = this.layoutNode.options || {};
    this.subProperties = this.layoutNode.subProperties || {};
    this.jsf.initializeControl(this, !this.options.readonly);
    if (this.controlValue === null || this.controlValue === undefined) {
      this.controlValue = false;
      this.jsf.updateValue(this, this.falseValue);
    }
    if (this.layoutNode.type === 'slide-toggle' ||
      this.layoutNode.format === 'slide-toggle'
    ) {
      this.showSlideToggle = true;
    }
  }

  ngOnDestroy(): void {
    this.jsf = undefined;
    this.formControl = undefined;
    this.controlName = undefined;
    this.controlValue = undefined;
    this.controlDisabled = undefined;
    this.boundControl = undefined;
    this.options = undefined;
    this.subProperties = undefined;
  }

  updateValue(event) {
    this.options.showErrors = true;
    this.jsf.updateValue(this, event.checked ? this.trueValue : this.falseValue);
    this.ajsfCoreWidgetsFunctionsService.dispatchEvent(this);
  }

  get isChecked() {
    return this.jsf.getFormControlValue(this) === this.trueValue;
  }
  
   dispatchEvent(){
    this.ajsfCoreWidgetsFunctionsService.dispatchEvent(this);
  }
}
