export interface ObjectChanges {
  key: string;
  oldValue: any;
  newValue: any;
  arrayPointer?: string;
}

export class ObjectChanges implements ObjectChanges {
  constructor(
    public key: string,
    public oldValue: any,
    public newValue: any,
    public arrayPointer?: string,
  ) { }
}
