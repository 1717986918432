import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  Inject,
  Optional,
  ChangeDetectorRef
} from '@angular/core';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {FormControl, FormGroup} from '@angular/forms';
import {JsonSchemaFormService} from '@finfra/ajsf-core';
import {AlertService, ConfigService, DeviceService, UtilitiesService} from '@finfra/core-services';
import {AjsfCoreWidgetsFunctionsService} from '../../../asjf-core-widgets.functions';
import {v4 as uuidv4} from 'uuid';
import {FileModel} from '@finfra/core-models';

@Component({
  selector: 'finfra-document-scanner-widget-component',
  templateUrl: './finfra-document-scanner.component.html',
  styleUrls: ['./finfra-document-scanner.component.scss'],
})
export class FinfraDocumentScannerComponent implements OnInit, OnDestroy {
  widgetName: string = 'FinfraDocumentScannerComponent';
  formControl: FormGroup;
  controlName: string;
  controlValue: any;
  controlDisabled = false;
  boundControl = false;
  options: any;
  subProperties: any;
  fullObject: any;
  @Input() layoutNode: any;
  @Input() layoutIndex: number[];
  @Input() dataIndex: number[];

  translateLabelsName: string = 'finfra-document-scanner';
  translateLabelsLoaded: boolean = false;

  loadingScannerList: boolean = false;
  scanning: boolean = false;
  gettingFile: boolean = false;
  scannerList: any[];
  selectedScanner: any;
  scannedFiles: any[];
  gettingFileCount: number = 0;
  transactionReferenceController: FormControl;

  constructor(
    @Inject(MAT_FORM_FIELD_DEFAULT_OPTIONS) @Optional() public matFormFieldDefaultOptions,
    private jsf: JsonSchemaFormService,
    private alertService: AlertService,
    private deviceService: DeviceService,
    private configService: ConfigService,
    public utilitiesService: UtilitiesService,
    private ajsfCoreWidgetsFunctionsService: AjsfCoreWidgetsFunctionsService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
	this.ajsfCoreWidgetsFunctionsService.loadLabels(this);
  }

  ngOnInit() {
    this.options = this.layoutNode.options || {};
    this.subProperties = this.layoutNode.subProperties || {};
    this.jsf.initializeControl(this);
    if (!this.options.notitle && !this.options.description && this.options.placeholder) {
      this.options.description = this.options.placeholder;
    }


    if (this.options.transactionReferencePointer) {
      this.transactionReferenceController = this.jsf.getControlService(this, this.jsf.formGroup, this.options.transactionReferencePointer);
    }

    this.options.refreshLoaderColour = this.options.refreshLoaderColour || this.configService.getConfig('global').loaderColor;

    this.getScannerList();
  }

  ngOnDestroy(): void {
    this.jsf = undefined;
    this.formControl = undefined;
    this.controlName = undefined;
    this.controlValue = undefined;
    this.controlDisabled = undefined;
    this.boundControl = undefined;
    this.options = undefined;
    this.subProperties = undefined;
  }

  updateValue() {
    this.controlValue = [];

    this.scannedFiles.forEach(
      file => {
        let scannedFile = new FileModel();
        scannedFile.fileBase64 = file.base64;
        scannedFile.fileExtension = file.fileType;
        scannedFile.fileName = file.name;
        scannedFile.fileType = file.contentType;
        scannedFile.fileUrl = file.path;
        scannedFile.fileLastModified = file.lastModified;

        this.controlValue.push(scannedFile);
      }
    );

    this.fullObject = this.controlValue;
    this.jsf.updateValue(this, this.controlValue);
    this.ajsfCoreWidgetsFunctionsService.dispatchEvent(this);
  }

  public reset() {
    this.scannedFiles = [];
    this.updateValue();
    this.getScannerList();
  }

  public getScannerList() {
    this.loadingScannerList = true;
    this.deviceService.sendCommand('getScannerList', this.gotScannerList.bind(this));
  }

  public gotScannerList(scannerList: any) {
    this.scannerList = scannerList.devices;

    if (this.scannerList === undefined || this.scannerList === null || this.scannerList.length === 0) {
      this.alertService.showMessage('E_NO_SCANNERS', 'error');
      this.scannerList = [];
    }

    if (this.scannerList.length === 1) {
      this.selectedScanner = this.scannerList[0].Id;
    }

    this.loadingScannerList = false;
    this.changeDetectorRef.markForCheck();
  }

  public startScan() {
    if (this.selectedScanner === undefined || this.selectedScanner === null) {
      this.alertService.showMessage('E_NO_SCANNER_SELECTED', 'error');
      return false;
    }

    if (this.scanning) {
      this.alertService.showMessage('E_SCAN_IN_PROGRESS', 'error');
      return false;
    }

    this.scanning = true;
    this.scannedFiles = [];
    this.gettingFileCount = 0;

    let additionalTags: any = {
      'deviceId': this.selectedScanner,
      'scanPath': this.configService.getConfig('global').scannedFilePath,
      'transactionReferenceNumber': this.transactionReferenceController.value,
      'deleteScannedFiles': false
    };

    this.deviceService.sendCommand('scanDocument', this.gotScannedDocument.bind(this), additionalTags);
  }

  public gotScannedDocument(scannedDocument: any) {
    this.scanning = false;

    if (scannedDocument.status) {
      this.scannedFiles = scannedDocument.files;

      this.getScannedFile();
    } else {
      this.alertService.showMessage('E_SCAN_FAILED', 'error');
    }

    this.changeDetectorRef.markForCheck();
  }

  public getScannedFile() {
    this.gettingFile = true;
    this.scannedFiles.forEach(
      file => {
        file.fileId = uuidv4();
        file.type = file.contentType;
        file.lastModifiedDate = new Date();
        file.lastModified = file.lastModifiedDate.getTime();
        file.part = 0;
        file.totalParts = 0;
        file.base64 = '';

        this.gettingFileCount++;

        let additionalTags: any = {
          'fileId': file.fileId,
          'file': file.path,
          'part': 0,
          'deleteFile': false,
          'deleteFolder': false
        };

        this.getFilePart(additionalTags);
      }
    );

    this.changeDetectorRef.markForCheck();
  }

  public gotFile(scannedFile: any) {
    if (scannedFile.status) {
      for (let idx: number = 0; idx < this.scannedFiles.length; idx++) {
        if (this.scannedFiles[idx].fileId === scannedFile.fileId) {
          if (this.scannedFiles[idx].base64 === undefined || this.scannedFiles[idx].base64 === null) {
            this.scannedFiles[idx].base64 = '';
          }

          this.scannedFiles[idx].base64 = this.scannedFiles[idx].base64 + scannedFile.b64;
          this.scannedFiles[idx].part = scannedFile.part;
          this.scannedFiles[idx].totalParts = scannedFile.totalParts;

          if (scannedFile.part < scannedFile.totalParts) {
            let additionalTags: any = {
              'fileId': this.scannedFiles[idx].fileId,
              'file': this.scannedFiles[idx].path,
              'part': scannedFile.part,
              'deleteFile': false,
              'deleteFolder': false
            };

            if (scannedFile.part === scannedFile.totalParts - 1) {
              additionalTags.deleteFile = true;
              additionalTags.deleteFolder = true;
              this.gettingFileCount--;
            }

            this.getFilePart(additionalTags);
          } else {
            if (this.gettingFileCount === 0) {
              this.gettingFile = false;
              this.updateValue();
            }
          }
        }
      }
    } else {
      this.alertService.showMessage('E_DOCUMENT_FAILED', 'error');
    }

    this.changeDetectorRef.markForCheck();
  }

  public async getFilePart(additionalTags: any) {
    await this.utilitiesService.delay(2000);
    this.deviceService.sendCommand('getDocument', this.gotFile.bind(this), additionalTags);
  }
}
