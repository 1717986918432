import { ILayout } from "../inner-models";
import { EWidget } from "./widgets";

export enum ELayout {
  GRID = 'grid',
  BUTTON_GROUP = 'buttonGroup',
  CHECKBOX_GROUP = 'checkboxGroup',
  RADIO_GROUP = 'radioGroup'
}

export const LayoutsList: Array<ILayout> = [
  {
    key: ELayout.GRID,
    label: '2 Column grid',
    isContainer: true,
    elements: null
  }
]

export const DEFAULT_LAYOUT = {
  type: "flex",
  "flex-flow": "row wrap",
  fxLayoutGap: "2%"
}