import { AbstractControl } from '@angular/forms';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { JsonSchemaFormService, buildTitleMap } from '@finfra/ajsf-core';
import { AjsfCoreWidgetsFunctionsService } from '../../../asjf-core-widgets.functions';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'finfra-material-button-group-widget',
  templateUrl: './finfra-material-button-group.component.html',
  styleUrls: ['./finfra-material-button-group.component.scss'],
})
export class FinfraMaterialButtonGroupComponent implements OnInit, OnDestroy {
  widgetName: string = "FinfraMaterialButtonGroupComponent";
  formControl: AbstractControl;
  controlName: string;
  controlValue: any;
  controlDisabled = false;
  boundControl = false;
  options: any;
  subProperties: any;
  radiosList: any[] = [];
  vertical = false;
  @Input() layoutNode: any;
  @Input() layoutIndex: number[];
  @Input() dataIndex: number[];

  constructor(
    private jsf: JsonSchemaFormService,
    private ajsfCoreWidgetsFunctionsService: AjsfCoreWidgetsFunctionsService
  ) { }

  ngOnInit() {
    this.options = this.layoutNode.options || {};
    this.subProperties = this.layoutNode.subProperties || {};
    this.radiosList = buildTitleMap(
      this.options.titleMap || this.options.enumNames,
      this.options.enum, true
    );
    this.jsf.initializeControl(this);
  }

  ngOnDestroy(): void {
    this.jsf = undefined;
    this.formControl = undefined;
    this.controlName = undefined;
    this.controlValue = undefined;
    this.controlDisabled = undefined;
    this.boundControl = undefined;
    this.options = undefined;
    this.subProperties = undefined;
  }

  updateValue(value) {
    this.options.showErrors = true;
    this.jsf.updateValue(this, value);
    this.ajsfCoreWidgetsFunctionsService.dispatchEvent(this);
  }
}
