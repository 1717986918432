import { AbstractControl } from '@angular/forms';
import { Component, Inject, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { JsonSchemaFormService, buildTitleMap, isArray } from '@finfra/ajsf-core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { AjsfCoreWidgetsFunctionsService } from '../../../asjf-core-widgets.functions';
import { ConfigService } from '@finfra/core-services';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'finfra-material-select-widget',
  templateUrl: './finfra-material-select.component.html',
  styleUrls: ['./finfra-material-select.component.scss'],
})
export class FinfraMaterialSelectComponent implements OnInit, OnDestroy {
  widgetName = 'FinfraMaterialSelectComponent';
  formControl: AbstractControl;
  controlName: string;
  controlValue: any;
  controlDisabled = false;
  boundControl = false;
  options: any;
  subProperties: any;
  selectList: any[] = [];
  selectListControl: AbstractControl;
  isArray = isArray;
  nameTag: string;
  valueTag: string;
  @Input() layoutNode: any;
  @Input() layoutIndex: number[];
  @Input() dataIndex: number[];

  constructor(
    @Inject(MAT_FORM_FIELD_DEFAULT_OPTIONS) @Optional() public matFormFieldDefaultOptions,
    private jsf: JsonSchemaFormService,
    private ajsfCoreWidgetsFunctionsService: AjsfCoreWidgetsFunctionsService,
    private configService: ConfigService,
  ) { }

  ngOnInit() {
    this.options = this.layoutNode.options || {};
    this.subProperties = this.layoutNode.subProperties || {};
    this.selectList = buildTitleMap(
      this.options.titleMap || this.options.enumNames,
      this.options.enum, !!this.options.required, !!this.options.flatList
    );
    this.jsf.initializeControl(this, !this.options.readonly);
    if (!this.options.notitle && !this.options.description && this.options.placeholder) {
      this.options.description = this.options.placeholder;
    }

    if (this.configService.getConfig('global') && this.configService.getConfig('global').useNativeSelect) {
      this.options.nativeSelect = true;
    }

    if (this.options.nativeSelect === undefined || this.options.nativeSelect === null) {
      this.options.nativeSelect = false;
    }

    this.nameTag = this.options.nameTag || 'name';
    this.valueTag = this.options.valueTag || 'value';

    if (this.options.stringBased) {
      this.nameTag = undefined;
      this.valueTag = undefined;
    }

    this.assignSelectListControl();
  }

  ngOnDestroy(): void {
    this.jsf = undefined;
    this.formControl = undefined;
    this.controlName = undefined;
    this.controlValue = undefined;
    this.controlDisabled = undefined;
    this.boundControl = undefined;
    this.options = undefined;
    this.subProperties = undefined;
  }

  assignSelectListControl() {
    if (this.options.selectListPointer) {
      this.selectListControl = this.jsf.getControlService(this, this.jsf.formGroup, this.options.selectListPointer);
    }
  }

  updateValue(event) {
    this.options.showErrors = true;
    let value: any = event;
    if (this.options.nativeSelect) {
      value = event.target.value;
    } else {
      value = event.value;
    }
    this.jsf.updateValue(this, value);
  }

  validate(event) {
    if (this.options.readonly !== undefined && this.options.readonly === true) {
      return;
    }

    if (this.boundControl) {
      this.updateValue(event);
    }

    this.ajsfCoreWidgetsFunctionsService.dispatchEvent(this);
  }
}
