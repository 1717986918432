import { Injectable } from '@angular/core';
import { GenericEventModel, HttpCallModel, User } from '@finfra/core-models';
import { HttpService } from '../core-services/http.service';
import { SessionService } from '../core-services/session.service';
import { ConfigService } from '../core-services/config.service';
import { EventsService } from '../core-services/events.service';


@Injectable({
  providedIn: 'root'
})
export class FinfraDashboardService {
  public filters: Map<string, string> = new Map<string, string>();
  public extraFilters: Map<string, string> = new Map<string, string>();
  public globalFilters: Map<string, string> = new Map<string, string>();

  public applicationFilter: any[] | undefined;
  public selectedApplications: any[] | undefined;

  public functionIdConfig: any;
  public functionId: string | undefined;
  public startInitialize = false;
  public initialized = false;

  public queueList: string[] = [];
  public selectedQueue: string | undefined;
  public dontSetQueue = false;
  public dontSetTranstionStatus = false;

  public transactionStatus: string | undefined;

  constructor(
    private eventsService: EventsService,
    private configService: ConfigService,
    private httpService: HttpService,
    private sessionService: SessionService
  ) {

  }

  public init(): void {
    this.startInitialize = true;
    this.initialized = false;
    let dashboardConfig = this.configService.getConfig('dashboard-config');

    if (this.functionId !== undefined && dashboardConfig !== undefined) {
      if (typeof dashboardConfig === 'string') {
        dashboardConfig = JSON.parse(dashboardConfig);
      }

      this.initialized = true;

      if (dashboardConfig.functionIdConfig[this.functionId] === undefined) {
        this.functionIdConfig = dashboardConfig.functionIdConfig.all;
      } else {
        this.functionIdConfig = dashboardConfig.functionIdConfig[this.functionId];
      }

      if (this.dontSetTranstionStatus) {
        this.transactionStatus = undefined;
      } else {
        this.transactionStatus = this.functionIdConfig.defaultTransactionStatus;
      }

      this.queueList = this.functionIdConfig.queueList;

      if (this.dontSetQueue) {
        this.selectedQueue = undefined;
      } else {
        if (this.configService.getConfig('global').defaultDashboardQueue) {
          this.selectedQueue = this.configService.getConfig('global').defaultDashboardQueue;
        } else {
          this.selectedQueue = this.functionIdConfig.defaultQueue;
        }

		if(this.globalFilters !== null || this.globalFilters !==undefined){
			this.globalFilters = new Map<string, string>();
		}
      }

      this.selectedQueueChanged();

      this.resetFilter();
    }
  }

  public destroy(): void {
    this.filters = new Map<string, string>();
    this.extraFilters = new Map<string, string>();

    this.applicationFilter = undefined;
    this.selectedApplications = undefined;

    this.functionIdConfig = undefined;
    this.queueList = [];
    this.selectedQueue = undefined;

    this.startInitialize = false;
    this.initialized = false;
    this.transactionStatus = undefined;
  }

  public selectedQueueChanged(): void {
    const eventObject = new GenericEventModel();

    eventObject.eventSource = 'finfra-dashboard';
    eventObject.eventName = 'finfra-dashboard-selected-queue-changed';
    eventObject.eventObjectName = 'finfra-dashboard-selected-queue-changed';
    eventObject.eventType = 'selected-queue-changed';
    eventObject.value = this.selectedQueue;
    eventObject.eventObject = this.selectedQueue;

    this.eventsService.fireEvent(eventObject);
  }

  public applyAllFilters(): void {
    const eventObject = new GenericEventModel();

    eventObject.eventSource = 'finfra-dashboard';
    eventObject.eventName = 'finfra-dashboard-apply-all-filters';
    eventObject.eventObjectName = 'finfra-dashboard-apply-all-filters';
    eventObject.eventType = 'apply-all-filters';

    this.eventsService.fireEvent(eventObject);
  }

  public applyTransactionSearchFilter(): void {
    const eventObject = new GenericEventModel();

    eventObject.eventSource = 'finfra-dashboard';
    eventObject.eventName = 'finfra-dashboard-transaction-search';
    eventObject.eventObjectName = 'finfra-dashboard-transaction-search';
    eventObject.eventType = 'transaction-search';

    this.eventsService.fireEvent(eventObject);
  }

  public fireScroll(): void {
    const eventObject = new GenericEventModel();

    eventObject.eventSource = 'finfra-dashboard';
    eventObject.eventName = 'finfra-dashboard-scroll';
    eventObject.eventObjectName = 'finfra-dashboard-scroll';
    eventObject.eventType = 'scroll';

    this.eventsService.fireEvent(eventObject);
  }

  public resetFilter(): boolean {
    if (this.functionId === undefined) {
      return false;
    }

    if (this.functionIdConfig === undefined) {
      this.init();
    }

    this.filters.clear();
    this.extraFilters.clear();

    if (this.functionIdConfig.resetApplication === true) {
      this.selectedApplications = undefined;
      this.applicationFilter = undefined;
    }

    this.applyApplicationFilter(false);

    if (this.dontSetQueue) {
      this.selectedQueue = undefined;
    } else {
      if (this.configService.getConfig('global').defaultDashboardQueue) {
        this.selectedQueue = this.configService.getConfig('global').defaultDashboardQueue;
      } else {
        this.selectedQueue = this.functionIdConfig.defaultQueue;
      }

      this.selectedQueueChanged();
    }

    this.dontSetQueue = false;
    this.applyAllFilters();

    return true;
  }

  public applyApplicationFilter(applyAllFilters: boolean): boolean {
    if (this.selectedApplications === undefined || this.selectedApplications === null || this.selectedApplications.length === 0) {
      this.filters.delete('applicationId');

      if (applyAllFilters === undefined || applyAllFilters === true) {
        this.applyAllFilters();
      }

      return true;
    }

    let applicationFilterString = '(';

    if (Array.isArray(this.selectedApplications)) {
      this.selectedApplications.forEach(
        selectedApplication => {
          if (selectedApplication) {
            if (applicationFilterString !== '(') {
              applicationFilterString = applicationFilterString + ' OR ';
            }

            if (Array.isArray(this.applicationFilter)) {
              this.applicationFilter.forEach(
                application => {
                  if (application && application.value === selectedApplication) {
                    applicationFilterString = applicationFilterString + application.value;
                  } else if (typeof application === 'string' && application === selectedApplication) {
                    applicationFilterString = applicationFilterString + application;
                  }
                }
              );
            }
          }
        }
      );
    } else {
      applicationFilterString = applicationFilterString + this.selectedApplications;
    }

    applicationFilterString = applicationFilterString + ')';

    if (applicationFilterString && applicationFilterString !== '' && applicationFilterString !== '()') {
      this.filters.set('applicationId', applicationFilterString);
    } else {
      this.filters.delete('applicationId');
    }

    if (applyAllFilters === undefined || applyAllFilters === true) {
      this.applyAllFilters();
    }

    return true;
  }

  public async fetchCount(functionId: string, pivotField: string[], applyFilter?: boolean, temporaryFilters?: Map<string, string>, backgroundProcess?: boolean): Promise<any> {
    if (this.initialized === false) {
      return undefined;
    }

    const currUser: User | undefined = this.sessionService.getUser();

    if (!currUser) {
      return undefined;
    }

    let currUserGroup: any[] = this.sessionService.getSessionData('userGroups');

    if (typeof currUserGroup === 'string') {
      currUserGroup = JSON.parse(currUserGroup);
    }

    let groupList = '';

    if (currUserGroup !== undefined && currUserGroup !== null) {
      currUserGroup.forEach(
        group => {
          if (groupList !== '') {
            groupList = groupList + ' OR ';
          }

          groupList = groupList + group.groupId;
        }
      );
    }

    groupList = '(' + groupList + ')';

    if (applyFilter === undefined) {
      applyFilter = true;
    }

    let path: string = this.configService.getConfig('global').solrApi + this.configService.getConfig('iam-api').transactionDetails;
    path = path + 'rows=0&start=0';

    if (this.functionIdConfig.branchFilter === false) {
      path = path + '&fq=branchCode:' + currUser.currentBranchCode;
    }

    if (this.functionIdConfig.legalEntityFilter === false) {
      path = path + '&fq=legalEntityId:' + currUser.currentLegalEntityId;
    }
    path = path + '&fl=*%2C%5Bchild%5D&fq=recordType:transaction';
    path = path + '&facet=on&facet.limit=-1&facet.mincount=1';

    if (this.transactionStatus === 'active') {
      path = path + '&fq=allStagesComplete:false';
    } else if (this.transactionStatus === 'inactive') {
      path = path + '&fq=allStagesComplete:true';
    }

    switch (this.selectedQueue) {
      case 'mine': path = path + '&fq=lockedBy:' + currUser.userId; break;
      case 'initiated': path = path + '&fq=createdBy:' + currUser.userId; break;
      case 'group': path = path + '&fq=currentStageUserId:' + currUser.userId; break;
      case 'actionedByMe': path = path + '&fq=actionedUsers:' + currUser.userId; break;
      case 'actionedByGroup': path = path + '&fq=actionedGroups:' + groupList; break;
    }

    pivotField.forEach(
      pivot => {
        path = path + '&facet.field=' + pivot + '&facet.pivot=' + pivot;
      }
    );

    path = path + '&q=*:*';

    const finalFilters: Map<string, string> = new Map<string, string>();

    if (applyFilter === true && this.filters !== undefined && this.filters !== null && this.filters.size > 0) {
      this.filters.forEach(
        (value: string, key: string) => {
          finalFilters.set(key, value);
        }
      );
    }

    if (applyFilter === true && this.globalFilters !== undefined && this.globalFilters !== null && this.globalFilters.size > 0) {
      this.globalFilters.forEach(
        (value: string, key: string) => {
          finalFilters.set(key, value);
        }
      );
    }

    if (temporaryFilters !== undefined && temporaryFilters !== null && temporaryFilters.size > 0) {
      temporaryFilters.forEach(
        (value: string, key: string) => {
          finalFilters.set(key, value);
        }
      );
    }

    if (finalFilters !== undefined && finalFilters !== null && finalFilters.size > 0) {
      finalFilters.forEach(
        (value: string, key: string) => {
          path = path + '&fq=' + key + ':' + value;
        }
      );
    }

    const httpCall: HttpCallModel = new HttpCallModel(
      path,
      'GET',
      functionId,
      undefined
    );

    if (backgroundProcess) {
      httpCall.backgroundProcess = true;
    } else {
      httpCall.backgroundProcess = false;
    }

    return this.httpService.callWS(httpCall).toPromise();
  }

  public async fetchTransactions(functionId: string, start: number, searchString: string, sortString?: string, backgroundProcess?: boolean, temporaryFilters?: Map<string, string>): Promise<any> {
    if (!this.initialized) {
      return undefined;
    }

    const currUser: User | undefined = this.sessionService.getUser();

    if (!currUser) {
      return undefined;
    }

    let currUserGroup: any[] = this.sessionService.getSessionData('userGroups');

    if (typeof currUserGroup === 'string') {
      currUserGroup = JSON.parse(currUserGroup);
    }

    let groupList = '';

    if (currUserGroup !== undefined && currUserGroup !== null) {
      currUserGroup.forEach(
        group => {
          if (groupList !== '') {
            groupList = groupList + ' OR ';
          }

          groupList = groupList + group.groupId;
        }
      );
    }

    groupList = '(' + groupList + ')';

    let path: string = this.configService.getConfig('global').solrApi + this.configService.getConfig('iam-api').transactionDetails;
    path = path + 'rows=10&start=' + start;
    path = path + '&fq=branchCode:' + currUser.currentBranchCode;
    path = path + '&fq=legalEntityId:' + currUser.currentLegalEntityId;
    path = path + '&fl=*%2C%5Bchild%5D&fq=recordType:transaction';

    if (this.transactionStatus === 'active') {
      path = path + '&fq=allStagesComplete:false';
    } else if (this.transactionStatus === 'inactive') {
      path = path + '&fq=allStagesComplete:true';
    }

    switch (this.selectedQueue) {
      case 'mine': path = path + '&fq=lockedBy:' + currUser.userId; break;
      case 'initiated': path = path + '&fq=createdBy:' + currUser.userId; break;
      case 'group': path = path + '&fq=currentStageUserId:' + currUser.userId; break;
      case 'actionedByMe': path = path + '&fq=actionedUsers:' + currUser.userId; break;
      case 'actionedByGroup': path = path + '&fq=actionedGroups:' + groupList; break;
    }

    if (searchString === undefined || searchString === null) {
      path = path + '&q=solrSearchText:*';
    } else {
      path = path + '&q=solrSearchText:*' + searchString + '*';
    }

    if (this.filters !== undefined && this.filters !== null && this.filters.size > 0) {
      this.filters.forEach(
        (value: string, key: string) => {
          path = path + '&fq=' + key + ':' + value;
        }
      );
    }

    if (this.globalFilters !== undefined && this.globalFilters !== null && this.globalFilters.size > 0) {
      this.globalFilters.forEach(
        (value: string, key: string) => {
          path = path + '&fq=' + key + ':' + value;
        }
      );
    }

    if (temporaryFilters !== undefined && temporaryFilters !== null && temporaryFilters.size > 0) {
      temporaryFilters.forEach(
        (value: string, key: string) => {
          path = path + '&fq=' + key + ':' + value;
        }
      );
    }

    if (sortString) {
      path = path + '&sort=' + sortString;
    } else {
      path = path + '&sort=currentStageArrivalDate desc';
    }

    const httpCall: HttpCallModel = new HttpCallModel(
      path,
      'GET',
      functionId,
      undefined
    );

    if (backgroundProcess) {
      httpCall.backgroundProcess = true;
    } else {
      httpCall.backgroundProcess = false;
    }

    return this.httpService.callWS(httpCall).toPromise();
  }

  public async fetchTransactionsByQuery(functionId: string, start: number, queryString: string, sortString?: string, backgroundProcess?: boolean, temporaryFilters?: Map<string, string>): Promise<any> {
    if (!this.initialized) {
      return undefined;
    }

    const currUser: User | undefined = this.sessionService.getUser();

    if (!currUser) {
      return undefined;
    }

    let currUserGroup: any[] = this.sessionService.getSessionData('userGroups');

    if (typeof currUserGroup === 'string') {
      currUserGroup = JSON.parse(currUserGroup);
    }

    let groupList = '';

    if (currUserGroup !== undefined && currUserGroup !== null) {
      currUserGroup.forEach(
        group => {
          if (groupList !== '') {
            groupList = groupList + ' OR ';
          }

          groupList = groupList + group.groupId;
        }
      );
    }

    groupList = '(' + groupList + ')';

    let path: string = this.configService.getConfig('global').solrApi + this.configService.getConfig('iam-api').transactionDetails;
    path = path + 'rows=10&start=' + start;
    path = path + '&fq=branchCode:' + currUser.currentBranchCode;
    path = path + '&fq=legalEntityId:' + currUser.currentLegalEntityId;
    path = path + '&fl=*%2C%5Bchild%5D&fq=recordType:transaction';

    if (this.transactionStatus === 'active') {
      path = path + '&fq=allStagesComplete:false';
    } else if (this.transactionStatus === 'inactive') {
      path = path + '&fq=allStagesComplete:true';
    }

    switch (this.selectedQueue) {
      case 'mine': path = path + '&fq=lockedBy:' + currUser.userId; break;
      case 'initiated': path = path + '&fq=createdBy:' + currUser.userId; break;
      case 'group': path = path + '&fq=currentStageUserId:' + currUser.userId; break;
      case 'actionedByMe': path = path + '&fq=actionedUsers:' + currUser.userId; break;
      case 'actionedByGroup': path = path + '&fq=actionedGroups:' + groupList; break;
    }

    if (queryString === undefined || queryString === null) {
      path = path + '&q=*:*';
    } else {
      path = path + '&q=' + queryString;
    }

    if (this.filters !== undefined && this.filters !== null && this.filters.size > 0) {
      this.filters.forEach(
        (value: string, key: string) => {
          path = path + '&fq=' + key + ':' + value;
        }
      );
    }

    if (this.globalFilters !== undefined && this.globalFilters !== null && this.globalFilters.size > 0) {
      this.globalFilters.forEach(
        (value: string, key: string) => {
          path = path + '&fq=' + key + ':' + value;
        }
      );
    }

    if (temporaryFilters !== undefined && temporaryFilters !== null && temporaryFilters.size > 0) {
      temporaryFilters.forEach(
        (value: string, key: string) => {
          path = path + '&fq=' + key + ':' + value;
        }
      );
    }

    if (sortString) {
      path = path + '&sort=' + sortString;
    } else {
      path = path + '&sort=currentStageArrivalDate desc';
    }

    const httpCall: HttpCallModel = new HttpCallModel(
      path,
      'GET',
      functionId,
      undefined
    );

    if (backgroundProcess) {
      httpCall.backgroundProcess = true;
    } else {
      httpCall.backgroundProcess = false;
    }

    return this.httpService.callWS(httpCall).toPromise();
  }
}
