import { APP_INITIALIZER, CompilerFactory, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { SystemModule } from './system/system.module';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { initFInfra } from './init-app.function';
import { InitializerService } from '@finfra/core-services';
import { FinfraCoreAjsfWidgetsModule } from '@finfra/ajsf-core-widgets';
import { MaterialDesignFrameworkModule } from '@finfra/ajsf-material';


export function createCompiler(compilerFactory: CompilerFactory) {
  return compilerFactory.createCompiler();
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NoopAnimationsModule,
    HttpClientModule,

    FinfraCoreAjsfWidgetsModule,
    MaterialDesignFrameworkModule,
    SystemModule
  ],
  providers: [
    HttpClient,
    DatePipe,
    { provide: APP_INITIALIZER, useFactory: initFInfra, deps: [InitializerService], multi: true },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline'} }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
