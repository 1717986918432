export interface ParameterModel {
  stringParameters?: Map<string, string | undefined | null> | undefined | null;
  longParameters?: Map<string, number | undefined | null> | undefined | null;
  dateParameters?: Map<string, Date | undefined | null> | undefined | null;
  booleanParameters?: Map<string, boolean | undefined | null> | undefined | null;
}

export class ParameterModel implements ParameterModel {
  constructor(
    public stringParameters?: Map<string, string | undefined | null> | undefined | null,
    public longParameters?: Map<string, number | undefined | null> | undefined | null,
    public dateParameters?: Map<string, Date | undefined | null> | undefined | null,
    public booleanParameters?: Map<string, boolean | undefined | null> | undefined | null,
  ) { }
}
