import { Injectable } from "@angular/core";

import { ConfigService } from "../core-services/config.service";

import { IndexedDbStorageService } from "../storage-services/indexeddb.service";
import { UIDatabase } from "../storage-services/UIDatabase.interface";
import { InjectionService } from "../core-services/injection.service";

@Injectable({
  providedIn: 'root',
})
export class UIDatabaseService {
  public uiDbType: string | undefined;
  public dbService: UIDatabase | undefined;

  constructor(
    private configService: ConfigService,
    private injectionService: InjectionService,
    private indexedDbStorageService: IndexedDbStorageService,
  ) {

  }

  public init() {
    this.uiDbType = this.configService.getConfig("global").uiDatabase;

    switch (this.uiDbType) {
      case "IndexedDb": this.dbService = this.indexedDbStorageService; break;
      case "Others": this.dbService = this.injectionService.getInjectedServiceByTypeAndName("ui-db-service", "ui-db-service")?.service;
    }

    if (this.dbService) {
      this.dbService.version = +this.configService.getConfig("global").indexedDbVersion.replace(/\./g, "");
    }
  }

  public openDatabase(
    dbName: string,
    successCallback?: any,
    errorCallback?: any,
    onupgradeneededCallback?: any
  ) {
    if (this.dbService) {
      this.dbService.openDatabase(
        dbName,
        successCallback,
        errorCallback,
        onupgradeneededCallback
      );
    }
  }

  public insertData(
    dbName: string,
    storeName: string,
    data: any,
    dataArray: boolean,
    successCallback?: any,
    errorCallback?: any
  ) {
    if (this.dbService) {
      this.dbService.insertData(
        dbName,
        storeName,
        data,
        dataArray,
        successCallback,
        errorCallback
      );
    }
  }

  public fetchDataByKey(
    dbName: string,
    storeName: string,
    key: any,
    successCallback?: any,
    errorCallback?: any
  ) {
    if (this.dbService) {
      this.dbService.fetchDataByKey(
        dbName,
        storeName,
        key,
        successCallback,
        errorCallback
      );
    }
  }

  public deleteDataByKey(
    dbName: string,
    storeName: string,
    key: any,
    successCallback?: any,
    errorCallback?: any
  ) {
    if (this.dbService) {
      this.dbService.deleteDataByKey(
        dbName,
        storeName,
        key,
        successCallback,
        errorCallback
      );
    }
  }

  public updateDataByKey(
    dbName: string,
    storeName: string,
    key: any,
    data: any,
    keysToUpdate: string[],
    successCallback?: any,
    errorCallback?: any
  ) {
    if (this.dbService) {
      this.dbService.updateDataByKey(
        dbName,
        storeName,
        key,
        data,
        keysToUpdate,
        successCallback,
        errorCallback
      );
    }
  }

  public fetchDataByIndex(
    dbName: string,
    storeName: string,
    indexName: string,
    key: any,
    successCallback?: any,
    errorCallback?: any
  ) {
    if (this.dbService) {
      this.dbService.fetchDataByIndex(
        dbName,
        storeName,
        indexName,
        key,
        successCallback,
        errorCallback
      );
    }
  }

  public deleteAll(
    dbName: string,
    storeName: string,
    successCallback?: any,
    errorCallback?: any
  ) {
    if (this.dbService) {
      this.dbService.deleteAll(
        dbName,
        storeName,
        successCallback,
        errorCallback
      );
    }
  }

  public getCount(
    dbName: string,
    storeName: string,
    successCallback?: any,
    errorCallback?: any
  ) {
    if (this.dbService) {
      this.dbService.getCount(
        dbName,
        storeName,
        successCallback,
        errorCallback
      );
    }
  }

  fetchAllData(
    dbName: string,
    storeName: string,
    successCallback?: any,
    errorCallback?: any
  ) {
    if (this.dbService) {
      this.dbService.fetchAllData(
        dbName,
        storeName,
        successCallback,
        errorCallback
      );
    }
  }

  fetchAllDataByIndex(
    dbName: string,
    storeName: string,
    indexName: string,
    indexValue: string,
    successCallback?: any,
    errorCallback?: any
  ) {
    if (this.dbService) {
      this.dbService.fetchAllDataByIndex(
        dbName,
        storeName,
        indexName,
        indexValue,
        successCallback,
        errorCallback
      );
    }
  }

  public createObjectStore(dbName: string, tableParameters: any) {
    if (this.dbService) {
      this.dbService.createObjectStore(dbName, tableParameters);
    }
  }
}
