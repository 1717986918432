import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';


@Injectable({
  providedIn: 'root'
})
export class CurrencyService {
  private numNames: any = [];
  private tensNames: string[] = [];
  private ccy: any;
  private configData: any;

  constructor(private configService: ConfigService) {

  }

  public init(data: any) {
    this.ccy = data.currencyList;
    this.tensNames = data.tensNames;
    this.numNames = data.numNames;
    this.configData = data;
  }

  public getDecimals(ccyCode: string) {
    if (ccyCode === undefined) {
      return ".01";
    }

    let decimal: string = ".01";

    for (let idx = 0; idx < this.ccy.length; idx++) {
      if (this.ccy[idx].ccyCode === ccyCode) {
        if (this.ccy[idx].noOfDecimals === 0) {
          decimal = "1";
        } else {
          decimal = ".";

          for (let idx = 0; idx < +this.ccy[idx].noOfDecimals - 1; idx++) {
            decimal = decimal + "0";
          }
          decimal = decimal + "1";
        }

        break;
      }
    }

    return decimal;
  }

  public roundAmount(num: number, ccy?: string): number | undefined {
    if (num === undefined || num === null || isNaN(num)) {
      return undefined;
    }

    let selCcy: any;

    if (ccy != undefined) {
      for (let idx = 0; idx < this.ccy.length; idx++) {
        if (this.ccy[idx].ccyCode === ccy) {
          selCcy = this.ccy[idx];
          break;
        }
      }
    }

    let amt: string;
    if (selCcy) {
      amt = (num).toLocaleString(this.configService.getConfig("global").locale, { maximumFractionDigits: +selCcy.noOfDecimals, minimumFractionDigits: +selCcy.noOfDecimals });
    } else {
      amt = (num).toLocaleString(this.configService.getConfig("global").locale, { maximumFractionDigits: this.configService.getConfig("global").defaultFractionDigits, minimumFractionDigits: this.configService.getConfig("global").defaultFractionDigits });
    }

    let regex = new RegExp(this.configService.getConfig("global").amountFormatCharacter, "gi");
    amt = amt.replace(regex, "");
    regex = new RegExp(this.configService.getConfig("global").decimalCharacter, "gi");
    amt = amt.replace(regex, ".");

    return parseFloat(amt);
  }

  public formatAmount(num: number, ccy?: string): string | undefined {
    if (num === undefined || num === null || isNaN(num)) {
      return undefined;
    }

    num = +num;

    let selCcy: any;

    if (ccy !== undefined) {
      for (let idx = 0; idx < this.ccy.length; idx++) {
        if (this.ccy[idx].ccyCode === ccy) {
          selCcy = this.ccy[idx];
          break;
        }
      }
    }

    if (num === 0) {
      if (selCcy) {
        let str: string = "0.";

        for (let i = 0; i < +selCcy.noOfDecimals; i++) {
          str = str + "0";
        }

        return str;
      } else {
        return "0.00";
      }
    }

    if (selCcy) {
      return (num).toLocaleString(this.configService.getConfig("global").locale, { maximumFractionDigits: +selCcy.noOfDecimals, minimumFractionDigits: +selCcy.noOfDecimals });
    } else {
      return (num).toLocaleString(this.configService.getConfig("global").locale, { maximumFractionDigits: +this.configService.getConfig("global").defaultFractionDigits, minimumFractionDigits: +this.configService.getConfig("global").defaultFractionDigits });
    }
  }

  public amountToWords(num: number, ccy?: string): string | undefined {
    if (num === undefined || num === null || isNaN(num)) {
      return undefined;
    }

    num = +num;

    let word: string = '';
    let negative: boolean = false;
    let selCcy: any;

    if (num < 0) { negative = true; }

    num = Math.abs(num);

    for (let idx = 0; idx < this.ccy.length; idx++) {
      if (this.ccy[idx].ccyCode === ccy) {
        selCcy = this.ccy[idx];
        break;
      }
    }

    if (selCcy === null || selCcy === undefined) {
      selCcy = new Object();
      selCcy.ccyCode = "";
      selCcy.singularCcyName = "";
      selCcy.pluralCcyName = "";
      selCcy.singularDecimalName = "";
      selCcy.pluralDecimalName = "";
      selCcy.noOfDecimals = 2;
    }

    if (num === 0) { return this.configData.zero + ' ' + selCcy.singularCcyName; }

    if (negative) { word = this.configData.negative + ' ('; }
    let integer: number = Math.trunc(num);

    if (integer > 0) {
      word = word + this.convertInteger(integer);

      if (integer === 1) {
        word = word + ' ' + selCcy.singularCcyName;
      } else {
        word = word + ' ' + selCcy.pluralCcyName;
      }
    }

    let decimal = +this.rpad((num + "").split(".")[1], +selCcy.noOfDecimals);
    if (decimal > 0) {
      word = word + ' ' + this.configData.and + ' ';
      word = word + this.convertInteger(decimal);

      if (decimal === 1) {
        word = word + ' ' + selCcy.singularDecimalName;
      } else {
        word = word + ' ' + selCcy.pluralDecimalName;
      }
    }

    if (negative) { word = word + ')'; }

    return word.replace('^\\s+', '').replace('\\b\\s{2,}\\b', ' ').replace(/\s+/g, ' ').trim();
  }

  // Converting Amount To Words
  private convertLessThanOneThousand(number: number) {
    let soFar: string = '';
    if ((Math.floor(number)) % 100 < 20) {
      soFar = this.numNames[(Math.floor(number)) % 100].value;
      number = Math.floor(number) / 100;

    }
    else {
      soFar = this.numNames[(Math.floor(number)) % 10].value;
      number = Math.floor(number) / 10;

      soFar = this.tensNames[(Math.floor(number)) % 10] + soFar;
      number = Math.floor(number) / 10;
    }
    if (Math.floor(number) == 0) return soFar;
    return this.numNames[Math.floor(number)].value + ' ' + this.configData.hundred + ' ' + soFar;
  }

  private lpad(num: string, size: number): string {
    let s = num + '';
    while (s.length < size) s = '0' + s;
    return s;
  }

  private rpad(num: string, size: number): string {
    let s = num + '';
    while (s.length < size) s = s + '0';
    return s;
  }

  private convertInteger(integer: number) {
    // 0 to 999 999 999 999 999
    if (integer === 0) { return this.configData.zero; }

    let snumber: any;
    // pad with '0'
    snumber = this.lpad(integer.toString(), 15);

    // XXXnnnnnnnnnnnn
    let trillions = Number.parseInt(snumber.substring(0, 3));
    // nnnXXXnnnnnnnnn
    let billions = Number.parseInt(snumber.substring(3, 6));
    // nnnnnnXXXnnnnnn
    let millions = Number.parseInt(snumber.substring(6, 9));
    // nnnnnnnnnXXXnnn
    let hundredThousands = Number.parseInt(snumber.substring(9, 12));
    // nnnnnnnnnnnnXXX
    let thousands = Number.parseInt(snumber.substring(12, 15));

    let tradTrillions;
    switch (trillions) {
      case 0:
        tradTrillions = '';
        break;
      case 1:
        tradTrillions = this.convertLessThanOneThousand(trillions)
          + ' ' + this.configData.trillion + ' ';
        break;
      default:
        tradTrillions = this.convertLessThanOneThousand(trillions)
          + ' ' + this.configData.trillion + ' ';
    }
    let result = tradTrillions;

    let tradBillions;
    switch (billions) {
      case 0:
        tradBillions = '';
        break;
      case 1:
        tradBillions = this.convertLessThanOneThousand(billions)
          + ' ' + this.configData.billion + ' ';
        break;
      default:
        tradBillions = this.convertLessThanOneThousand(billions)
          + ' ' + this.configData.billion + ' ';
    }
    result = result + tradBillions;

    let tradMillions;
    switch (millions) {
      case 0:
        tradMillions = '';
        break;
      case 1:
        tradMillions = this.convertLessThanOneThousand(millions)
          + ' ' + this.configData.million + ' ';
        break;
      default:
        tradMillions = this.convertLessThanOneThousand(millions)
          + ' ' + this.configData.million + ' ';
    }
    result = result + tradMillions;

    let tradHundredThousands;
    switch (hundredThousands) {
      case 0:
        tradHundredThousands = '';
        break;
      case 1:
        tradHundredThousands = this.configData.one + ' ' + this.configData.thousand + ' ';
        break;
      default:
        tradHundredThousands = this.convertLessThanOneThousand(hundredThousands)
          + ' ' + this.configData.thousand + ' ';
    }
    result = result + tradHundredThousands;

    let tradThousand;
    tradThousand = this.convertLessThanOneThousand(thousands);
    result = result + tradThousand;
    // remove extra spaces!
    return result.replace('^\\s+', '').replace('\\b\\s{2,}\\b', ' ').replace(/\s+/g, ' ').trim();
  }
}
