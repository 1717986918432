import { Directive, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[componentInjectDirective]'
})
export class ComponentInjectDirective implements OnInit, OnDestroy {
  constructor(public viewContainerRef: ViewContainerRef) { }

  ngOnInit() {

  }

  ngOnDestroy() {

  }
}
