import { Injectable } from "@angular/core";
import { UIDatabaseService } from "./uidatabase.service";
import { OpenUIDatabaseService } from "./open-ui-database.service";
import { AlertService } from "../core-services/alert.service";
import { OfflineStatusService } from "./offline-status.service";
import { HttpService } from "../core-services/http.service";
import { Subject } from "rxjs";
import { HttpHeaders } from "@angular/common/http";
import { SessionService } from "../core-services/session.service";
import { Router } from "@angular/router";
import { SpinnerService } from "../core-services/spinner.service";
import { UtilitiesService } from "../core-services/utilities.service";
import { DeviceService } from "../device-services/device.service";

declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class OfflineSyncService {
  public syncing: boolean = false;
  public syncedRecord: Subject<any> = new Subject<any>();
  public wipRecord: any;
  public offlineData: any[] = [];
  public recordIdx: number | undefined;

  constructor(
    private uiDatabaseService: UIDatabaseService,
    private openUIDatabaseService: OpenUIDatabaseService,
    private alertService: AlertService,
    private offlineStatusService: OfflineStatusService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private router: Router,
    private spinnerService: SpinnerService,
    private utilitiesService: UtilitiesService,
    private deviceService: DeviceService
  ) {

  }

  public syncAll(errorCallback: any) {
    if (this.offlineStatusService.offlineStatus) {
      return;
    }

    this.syncing = true;

    this.uiDatabaseService.fetchAllData(
      this.openUIDatabaseService.transactionDbName,
      this.openUIDatabaseService.transactionDbStore,
      this.gotData.bind(this, errorCallback),
      this.fetchError.bind(this, errorCallback)
    );
  }

  public gotData(errorCallback: any, event: any) {
    this.offlineData = event.target.result;

    if (this.offlineData == undefined || this.offlineData === null) {
      this.offlineData = [];
    }

    this.offlineData.forEach(
      data => {
        data.requestBody.finSuccessDetails = undefined;
        data.requestBody.responseStatus = undefined;
      }
    );

    this.offlineData = this.offlineData.sort(
      (a, b) => {
        if (a.key > b.key) {
          return 1;
        }

        if (a.key < b.key) {
          return -1;
        }

        return 0;
      }
    );

    this.recordIdx = 0;
    this.processRecord(errorCallback);
  }

  public processRecord(errorCallback: any) {
    if (this.recordIdx === undefined) {
      return;
    }

    if (this.recordIdx >= this.offlineData.length) {
      this.syncing = false;
      this.spinnerService.decrementSpinnerCount();
      this.syncedRecord.next(null);
      return;
    }

    this.spinnerService.incrementSpinnerCount();
    this.wipRecord = this.offlineData[this.recordIdx];

    if (this.wipRecord.requestType === "normalHttp") {
      this.processCallWs(this.wipRecord, errorCallback);
    } else if (this.wipRecord.requestType === "filePartUpload") {
      this.processCallFileWs(this.wipRecord, errorCallback);
    }

    this.recordIdx++;
  }

  public processedRecord(data: any, errorCallback: any, event: any) {
    this.syncedRecord.next(data);
    this.processRecord(errorCallback);
    this.spinnerService.decrementSpinnerCount();
  }

  public fetchError(errorCallback: any, event: any) {
    this.alertService.error(event.target.error);

    if (errorCallback) {
      event.errorType = "FetchError";
      errorCallback(event);
    }
  }

  public processCallWs(data: any, errorCallback: any) {
    data.requestBody.headerModel.offlineRequest = true;

    let header = new HttpHeaders();

    header = header.append("Content-Type", "application/json");
    header = header.append("Accept", "application/json");

    header = header.append("Access-Control-Allow-Origin", "*");
    header = header.append(
      "Access-Control-Allow-Methods",
      "GET, POST, OPTIONS, DELETE"
    );
    header = header.append(
      "Access-Control-Allow-Headers",
      "Accept,Accept-Language,Content-Language,Content-Type"
    );
    header = header.append(
      "Access-Control-Expose-Headers",
      "Content-Length,Content-Range"
    );

    if (
      this.sessionService.getAuthToken() !== undefined &&
      this.sessionService.getAuthToken() !== null
    ) {
      header = header.append(
        "X-Auth-Token",
        this.sessionService.getAuthToken()
      );
    } else if (!this.offlineStatusService.offlineStatus) {
      this.router.navigate(["/login"]);
    }

    data.httpOptions.headers = header;

    this.httpService.offlineSyncCall(
      data.url,
      data.method,
      data.httpOptions,
      data.requestBody,
      false
    ).subscribe(
      result => {
        if (result && result.status === 200 && result.body.responseStatus === "SUCCESS") {
          this.uiDatabaseService.deleteDataByKey(
            this.openUIDatabaseService.transactionDbName,
            this.openUIDatabaseService.transactionDbStore,
            data.key,
            this.processedRecord.bind(this, data, errorCallback)
          );
        } else {
          if (errorCallback) {
            let event: any = { "errorType": "CallWsError", "data": data, "result": result };
            errorCallback(event);
          }

          this.syncing = false;
          this.spinnerService.decrementSpinnerCount();
          this.alertService.showAllMessages(result.body);
          this.updateRetryCount(data);
        }
      },
      error => {
        if (errorCallback) {
          let event: any = { "errorType": "CallWsError", "data": data, "result": error };
          errorCallback(event);
        }

        this.syncing = false;
        this.spinnerService.decrementSpinnerCount();
        this.alertService.showErrorMessage(error);
        this.updateRetryCount(data);
      }
    );
  }

  public processCallFileWs(data: any, errorCallback: any) {
    data.requestBody.headerModel.offlineRequest = true;

    let requestBody: any = JSON.parse(JSON.stringify(data.requestBody.finData));

    if (window.cordova && requestBody.files.indexOf("file://") > -1) {
      requestBody.files = this.deviceService.getDiskFileAsBase64(requestBody.files, this.gotFileBase64.bind(this, data, requestBody, errorCallback));
    } else {
      this.gotFileBase64(data, requestBody, errorCallback, { "base64": this.utilitiesService.base64ToFile(requestBody.files) });
    }
  }

  public gotFileBase64(data: any, requestBody: any, errorCallback: any, fileObject: any) {
    requestBody.files = fileObject.base64;

    requestBody.headerModel = JSON.parse(requestBody.headerModel);
    requestBody.headerModel = JSON.stringify(requestBody.headerModel);

    data.requestBody = new FormData();

    Object.keys(requestBody).forEach(
      key => {
        data.requestBody.append(key, requestBody[key]);
      }
    );

    data.httpOptions.body = data.requestBody;

    let header = new HttpHeaders();
    header = header.append("Access-Control-Allow-Origin", "*");
    header = header.append(
      "Access-Control-Allow-Methods",
      "GET, POST, OPTIONS, DELETE"
    );
    header = header.append(
      "Access-Control-Allow-Headers",
      "Accept,Accept-Language,Content-Language,Content-Type"
    );
    header = header.append(
      "Access-Control-Expose-Headers",
      "Content-Length,Content-Range"
    );

    if (this.sessionService.getAuthToken() !== null) {
      header = header.append(
        "X-Auth-Token",
        this.sessionService.getAuthToken()
      );
    }

    data.httpOptions.headers = header;

    this.httpService.offlineSyncCall(
      data.url,
      data.method,
      data.httpOptions,
      data.requestBody,
      false
    ).subscribe(
      result => {
        if (result && result.status === 200 && result.body.responseStatus === "SUCCESS") {
          this.uiDatabaseService.deleteDataByKey(
            this.openUIDatabaseService.transactionDbName,
            this.openUIDatabaseService.transactionDbStore,
            data.key,
            this.processedRecord.bind(this, data, errorCallback)
          );
        } else {
          if (errorCallback) {
            let event: any = { "errorType": "CallFileWsError", "data": data, "result": result };
            errorCallback(event);
          }

          this.syncing = false;
          this.spinnerService.decrementSpinnerCount();
          this.alertService.showAllMessages(result.body);
          this.updateRetryCount(data);
        }
      },
      error => {
        if (errorCallback) {
          let event: any = { "errorType": "CallFileWsError", "data": data, "result": error };
          errorCallback(event);
        }

        this.syncing = false;
        this.spinnerService.decrementSpinnerCount();
        this.alertService.showErrorMessage(error);
        this.updateRetryCount(data);
      }
    );
  }

  public updateRetryCount(data: any) {
    data.retryCount = data.retryCount + 1;

    this.uiDatabaseService.updateDataByKey(
      this.openUIDatabaseService.transactionDbName,
      this.openUIDatabaseService.transactionDbStore,
      data.key,
      data,
      ["retryCount"]
    );
  }

  public moveTransactionToEnd(transactionReferenceNumber: string) {
    this.uiDatabaseService.fetchAllDataByIndex(
      this.openUIDatabaseService.transactionDbName,
      this.openUIDatabaseService.transactionDbStore,
      "transactionReferenceNumber",
      transactionReferenceNumber,
      this.onMoveTransactionToEndGotData.bind(this)
    );
  }

  public onMoveTransactionToEndGotData(event: any) {
    let dataArray: any[] = event.target.result;

    dataArray.forEach(
      data => {
        if (data.originalRetryCount === undefined || data.originalRetryCount === null) {
          data.originalRetryCount = 0;
        }

        data.originalRetryCount = data.originalRetryCount + data.retryCount;
        data.retryCount = 0;

        this.uiDatabaseService.deleteDataByKey(
          this.openUIDatabaseService.transactionDbName,
          this.openUIDatabaseService.transactionDbStore,
          data.key
        );

        this.uiDatabaseService.insertData(
          this.openUIDatabaseService.transactionDbName,
          this.openUIDatabaseService.transactionDbStore,
          data,
          false
        );
      }
    );
  }
}
