import {Injectable} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {FileModel, FinUIException, ObjectChanges} from '@finfra/core-models';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {
  constructor(
    private domSanitizer: DomSanitizer
  ) {
  }

  public isObject(item: any): boolean {
    return (item && typeof item === 'object' && !Array.isArray(item) && item !== null);
  }

  public deepCopy(obj: object): object {
    return JSON.parse(JSON.stringify(obj));
  }

  public deepMerge(target: any, source: any): any {
    if (this.isObject(target) && this.isObject(source)) {
      for (const key in source) {
        if (this.isObject(source[key])) {
          if (!target[key]) {
            Object.assign(target, { [key]: {} });
          }
          this.deepMerge(target[key], source[key]);
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }

    return target;
  }

  public deepIndexOf(arr: any, obj: any): number {
    return arr.findIndex(
      (cur: any) => {
        return Object.keys(obj).every(
          key => {
            return obj[key] === cur[key];
          }
        );
      }
    );
  }

  public sortBy(arr: any[], col: string, order?: string): any[] {
    if (order === undefined) {
      order = 'A';
    }

    const retArr = arr.sort((v1, v2) => {
      try {
        if (typeof v1[col] === 'string' || typeof v1[col] === 'number') {
          if (order === 'A') {
            if (v1[col] > v2[col]) {
              return 1;
            }

            if (v1[col] < v2[col]) {
              return -1;
            }
          } else if (order === 'D') {
            if (v1[col] > v2[col]) {
              return -1;
            }

            if (v1[col] < v2[col]) {
              return 1;
            }
          }
        } else {
          if (order === 'A') {
            // added new for temp
            if (v1[col] || v2[col]) {
              if (v1[col].getTime() || v2[col].getTime()) {
                if (v1[col].getTime() > v2[col].getTime()) {
                  return 1;
                }

                if (v1[col].getTime() < v2[col].getTime()) {
                  return -1;
                }
              }
            }
          } else if (order === 'D') {
            if (v1[col] || v2[col]) {
              if (v1[col].getTime() || v2[col].getTime()) {
                if (v1[col].getTime() > v2[col].getTime()) {
                  return -1;
                }

                if (v1[col].getTime() < v2[col].getTime()) {
                  return 1;
                }
              }
            }
          }
        }
      } catch (e) {
        console.error('Failed in array sort');
        console.error(e);
      }

      return 0;
    });

    return retArr;
  }

  public stringMapToObj(stringMap: any): any {
    if (!stringMap) {
      return undefined;
    }

    const obj = Object.create(null);

    stringMap.forEach((value: string, key: string) => {
      obj[key] = value;
    });

    return obj;
  }

  public longMapToObj(longMap: any): any {
    if (!longMap) {
      return undefined;
    }

    const obj = Object.create(null);

    longMap.forEach((value: number, key: string) => {
      obj[key] = value;
    });

    return obj;
  }

  public dateMapToObj(dateMap: any): any {
    if (!dateMap) {
      return undefined;
    }

    const obj = Object.create(null);

    dateMap.forEach((value: Date, key: string) => {
      obj[key] = value;
    });

    return obj;
  }

  public booleanMapToObj(booleanMap: any): any {
    if (!booleanMap) {
      return undefined;
    }

    const obj = Object.create(null);

    booleanMap.forEach((value: boolean, key: string) => {
      obj[key] = value;
    });

    return obj;
  }

  public btoaUnicode(str: string): string | undefined {
    if (str === undefined || str === null || str === '') {
      return undefined;
    }

    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => {
      return String.fromCharCode(parseInt(p1, 16));
    }));
  }

  public atobUnicode(str: string): string | undefined {
    if (str === undefined || str === null || str === '') {
      return undefined;
    }

    return decodeURIComponent(Array.prototype.map.call(atob(str), (c) => {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  }

  public isJson(str: string): boolean {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  public padLeft(text: string, padChar: string, size: number): string {
    return (String(padChar).repeat(size) + text).substr((size * -1), size);
  }

  public base64ToFile(fileObject: FileModel): File | null {
    if (fileObject.fileBase64 === undefined || fileObject.fileBase64 === null || fileObject.fileName === undefined || fileObject.fileName === null) {
      return null;
    }

    if (fileObject.fileBase64.indexOf(';') > -1) {
      const block: string[] = fileObject.fileBase64.split(';');
      const realData: string = block[1].split(',')[1];

      fileObject.fileBase64 = realData;
    }

    fileObject.fileType = fileObject.fileType || '';
    const sliceSize = 512;

    const byteCharacters = atob(fileObject.fileBase64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    return new File(byteArrays, fileObject.fileName, {
      type: fileObject.fileType,
      lastModified: fileObject.fileLastModified
    });
  }

  public fileToBase64(file: File): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  public fileToImageElement(file: File): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      if (!(file instanceof Blob)) {
        return reject('bufferToImage - expected buf to be of type: Blob');
      }

      const reader = new FileReader();
      reader.onload = () => {
        if (typeof reader.result !== 'string') {
          return reject('bufferToImage - expected reader.result to be a string, in onload');
        }

        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = reader.result;
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  public delay(ms: number): Promise<number> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  public getSanitizedObject(src: string): SafeResourceUrl | undefined {
    if (src === undefined || src === null) {
      return undefined;
    }

    if (src.indexOf('data:') < 0 && src.indexOf('file:') < 0 && src.indexOf('content:') < 0) {
      src = 'data:image/png;base64,' + src;
    }

    return this.domSanitizer.bypassSecurityTrustResourceUrl(src);
  }

  // Object difference starts
  private getLabel(str: string, labels: any): string {
    if (!labels) {
      return str;
    } else if (labels[str]) {
      return labels[str];
    } else {
      if (str.indexOf('/') === 0) {
        if (labels[str.slice(1)]) {
          return '/' + labels[str.slice(1)];
        }
      }

      if (str.indexOf('/') === str.length - 1) {
        if (labels[str.slice(0, -1)]) {
          return labels[str.slice(0, -1)] + '/';
        }
      }

      if (str.indexOf('/') === 0 && str.lastIndexOf('/') === str.length - 1) {
        if (labels[str.slice(0, -1).slice(1)]) {
          return '/' + labels[str.slice(0, -1).slice(1)] + '/';
        }
      }
    }

    return str;
  }

  private populatePK(array: any[], arrayKey: any, currentPointer: string): any[] {
    let pks: string[] = [];

    for (const key of arrayKey) {
      if (key.arrayPointer === currentPointer) {
        pks = key.primaryKeys;
        break;
      }
    }

    if (pks === []) {
      throw new FinUIException('E_NO_PRIMARY_KEY', 'Primary key not present for the array', {
        arrayKey,
        currentPointer
      });
    }

    for (const item of array) {
      let key: string | undefined;
      for (const pk of pks) {
        if (!key) {
          key = item[pk];
        } else if (item[pk] !== undefined) {
          key = key + '~' + item[pk];
        } else {
          key = key;
        }
      }

      item.arrayKey = key;
      item.pks = pks;
    }

    return array;
  }

  private arrayDiff(o1: any, a2: any, oldValue: boolean, currentPointer: string, returnObject: ObjectChanges[], keysProcessed: any[], labels: any, params: any): void {
    if (o1.arrayKey === undefined || o1.arrayKey === null) {
      return;
    }

    if (currentPointer !== '/') {
      currentPointer = currentPointer + '/';
    }

    let processed = false;
    for (const item of a2) {
      if (keysProcessed.indexOf(currentPointer + o1.arrayKey + '-') === -1) {
        if (item.arrayKey === o1.arrayKey) {

          Object.keys(o1).forEach(
            key => {
              if (item[key] !== o1[key]) {
                const diffObject = {
                  key: params.prefix + currentPointer + this.getLabel(o1.arrayKey, labels) + '/' + this.getLabel(key, labels),
                  arrayPointer: currentPointer + this.getLabel(o1.arrayKey, labels),
                  oldValue: o1[key] === undefined || o1[key] === null ? '' : o1[key],
                  newValue: item[key] === undefined || item[key] === null ? '' : item[key]
                };

                returnObject.push(diffObject);
              }
            }
          );

          processed = true;
          keysProcessed.push(currentPointer + o1.arrayKey + '-');
          break;
        }
      } else {
        processed = true;
      }
    }

    if (!processed) {
      let diffObject: ObjectChanges;
      let difference = '';

      if (!params || params.arrayDifferenceReturnFullObject || params.arrayDifferenceReturnFullObject === undefined || params.arrayDifferenceReturnFullObject === null) {
        difference = JSON.stringify(o1);
      } else {
        if (params.arrayDifferenceKeys) {
          for (const arrayKey of params.arrayDifferenceKeys) {
            if (JSON.stringify(arrayKey.key) === JSON.stringify(o1.pks)) {
              difference = o1[arrayKey.value];
              break;
            }
          }
        }
      }

      if (oldValue) {
        diffObject = {
          key: params.prefix + this.getLabel(o1.arrayKey, labels),
          arrayPointer: currentPointer + this.getLabel(o1.arrayKey, labels),
          newValue: '',
          oldValue: difference
        };
      } else {
        diffObject = {
          key: params.prefix + this.getLabel(o1.arrayKey, labels),
          arrayPointer: currentPointer + this.getLabel(o1.arrayKey, labels),
          oldValue: '',
          newValue: difference
        };
      }

      returnObject.push(diffObject);
      keysProcessed.push(currentPointer + o1.arrayKey + '-');
    }
  }

  private processArraysDiff(o1: any, o2: any, oldValue: boolean, arrayKey: any, currentPointer: string, returnObject: ObjectChanges[], keysProcessed: any[], labels: any, params: any): void {
    o1 = this.populatePK(o1, arrayKey, currentPointer);
    o2 = this.populatePK(o2, arrayKey, currentPointer);

    o1.forEach(
      (item: any) => {
        this.arrayDiff(item, o2, oldValue, currentPointer, returnObject, keysProcessed, labels, params);
      }
    );

    o2.forEach(
      (item: any) => {
        this.arrayDiff(item, o1, !oldValue, currentPointer, returnObject, keysProcessed, labels, params);
      }
    );
  }

  public findObjectDiff(o1: any, o2: any, oldValue: boolean, arrayKey: any, currentPointer: string, returnObject: ObjectChanges[] = [], keysProcessed: any[] = [], labels: any, params: any): ObjectChanges[] {
    if (currentPointer !== '/') {
      currentPointer = currentPointer + '/';
    }

    if (!params) {
      params = {};
    }

    if (!params.prefix) {
      params.prefix = '';
    }

    if (Array.isArray(o1)) {
      this.processArraysDiff(o1, o2, oldValue, arrayKey, currentPointer, returnObject, keysProcessed, labels, params);
    } else if (o1 && o2) {
      Object.keys(o1).forEach(
        key => {
          if (keysProcessed.indexOf(currentPointer + key) === -1) {
            if (JSON.stringify(o1[key]) !== JSON.stringify(o2[key])) {
              if (o2[key] === null || o2[key] === undefined) {
                const diffObject = {
                  key: params.prefix + currentPointer + this.getLabel(key, labels),
                  oldValue: o1[key] === undefined || o1[key] === null ? '' : o1[key],
                  newValue: o2[key] === undefined || o2[key] === null ? '' : o2[key],
                  arrayPointer: ''
                };

                returnObject.push(diffObject);
                keysProcessed.push(currentPointer + key);
              } else {
                if (typeof o1[key] === 'object' && typeof o1[key]?.getMonth !== 'function') {
                  if (Array.isArray(o1[key])) {
                    this.processArraysDiff(o1[key], o2[key], oldValue, arrayKey, currentPointer + key, returnObject, keysProcessed, labels, params);
                  } else {
                    this.findObjectDiff(o1[key], o2[key], oldValue, arrayKey, currentPointer + key, returnObject, keysProcessed, labels, params);
                  }
                } else {
                  if (o2[key] !== o1[key]) {
                    const diffObject = {
                      key: params.prefix + currentPointer + this.getLabel(key, labels),
                      oldValue: o1[key] === undefined || o1[key] === null ? '' : o1[key],
                      newValue: o2[key] === undefined || o2[key] === null ? '' : o2[key],
                      arrayPointer: ''
                    };

                    returnObject.push(diffObject);
                    keysProcessed.push(currentPointer + key);
                  }
                }
              }
            }
          }
        }
      );

      Object.keys(o2).forEach(
        key => {
          if (keysProcessed.indexOf(currentPointer + key) === -1) {

            if (JSON.stringify(o1[key]) !== JSON.stringify(o2[key])) {
              if (o1[key] === null || o1[key] === undefined) {
                const diffObject = {
                  key: params.prefix + currentPointer + this.getLabel(key, labels),
                  oldValue: o1[key] === undefined || o1[key] === null ? '' : o1[key],
                  newValue: o2[key] === undefined || o2[key] === null ? '' : o2[key],
                  arrayPointer: ''
                };

                returnObject.push(diffObject);
                keysProcessed.push(currentPointer + key);
              } else {
                if (typeof o2[key] === 'object' && typeof o2[key]?.getMonth !== 'function') {
                  if (Array.isArray(o2[key])) {
                    this.processArraysDiff(o2[key], o1[key], !oldValue, arrayKey, currentPointer + key, returnObject, keysProcessed, labels, params);
                  } else {
                    this.findObjectDiff(o1[key], o2[key], !oldValue, arrayKey, currentPointer + key, returnObject, keysProcessed, labels, params);
                  }
                } else {
                  if (o2[key] !== o1[key]) {
                    const diffObject = {
                      key: params.prefix + currentPointer + this.getLabel(key, labels),
                      oldValue: o1[key] === undefined || o1[key] === null ? '' : o1[key],
                      newValue: o2[key] === undefined || o2[key] === null ? '' : o2[key],
                      arrayPointer: ''
                    };

                    returnObject.push(diffObject);
                    keysProcessed.push(currentPointer + key);
                  }
                }
              }
            }
          }
        }
      );
    } else {
      if (currentPointer.substr(currentPointer.length - 1) === '/') {
        currentPointer = currentPointer.slice(0, -1);
      }

      if (!o1) {
        const diffObject = {
          key: params.prefix + this.getLabel(currentPointer, labels),
          oldValue: '',
          newValue: o2,
          arrayPointer: ''
        };

        returnObject.push(diffObject);
        keysProcessed.push(currentPointer);
      } else {
        const diffObject = {
          key: params.prefix + this.getLabel(currentPointer, labels),
          oldValue: o1,
          newValue: '',
          arrayPointer: ''
        };

        returnObject.push(diffObject);
        keysProcessed.push(currentPointer);
      }
    }

    return returnObject;
  }

  // Object difference ends

  public hasDuplicates(pkFields: string[], arrayObject: any[]): boolean {
    if (!Array.isArray(pkFields) || !Array.isArray(arrayObject)) {
      return true;
    }

    const uniqueValues = new Set(
      arrayObject.map(
        value => {
          let str = '';

          pkFields.forEach(
            pk => {
              str = str + value[pk] + '!';
            }
          );

          return str;
        }
      )
    );

    if (uniqueValues.size < arrayObject.length) {
      return false;
    }

    return true;
  }

  public screenSize(): string {
    const width = window.innerWidth;

    if (width >= 1920) {
      return 'xl';
    }

    if (width >= 1280) {
      return 'lg';
    }

    if (width >= 960) {
      return 'md';
    }

    if (width >= 600) {
      return 'sm';
    }

    return 'xs';
  }

  public isScreenSizeLt(size: string): boolean {
    const width = window.innerWidth;

    switch (size) {
      case 'xl':
        if (width < 1920) {
          return true;
        } else {
          return false;
        }
      case 'lg':
        if (width < 1280) {
          return true;
        } else {
          return false;
        }
      case 'md':
        if (width < 960) {
          return true;
        } else {
          return false;
        }
      case 'sm':
        if (width < 600) {
          return true;
        } else {
          return false;
        }
      default: return false;
    }
  }

  public isScreenSizeGt(size: string): boolean {
    const width = window.innerWidth;

    switch (size) {
      case 'lg':
        if (width >= 1920) {
          return true;
        } else {
          return false;
        }
      case 'md':
        if (width >= 1280) {
          return true;
        } else {
          return false;
        }
      case 'sm':
        if (width >= 960) {
          return true;
        } else {
          return false;
        }
      case 'xs':
        if (width >= 600) {
          return true;
        } else {
          return false;
        }
      default: return false;
    }
  }
}
