import {AbstractControl, FormGroup} from '@angular/forms';
import {
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {JsonSchemaFormService} from '@finfra/ajsf-core';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {AjsfCoreWidgetsFunctionsService} from '../../../asjf-core-widgets.functions';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatStepper} from '@angular/material/stepper';
import {TranslateService} from '@finfra/core-decorators';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {
  AlertService,
  ConfigService,
  EventsService,
  HttpService,
  SessionService,
  SpinnerService
} from '@finfra/core-services';
import {HttpCallModel} from '@finfra/core-models';

@Component({
  selector: 'finfra-sub-function-widget',
  templateUrl: './finfra-sub-function.component.html',
  styleUrls: ['./finfra-sub-function.component.scss'],
})
export class FinfraSubFunctionComponent implements OnInit, OnDestroy {
  widgetName = 'FinfraSubFunctionComponent';
  formControl: AbstractControl;
  controlName: string;
  controlValue: string;
  controlDisabled = false;
  boundControl = false;
  options: any;
  subProperties: any;
  autoCompleteList: string[] = [];

  functionId: string;
  jsonFormObject: any;
  jsonFormLoaded = false;
  jsonFormValue: any = new Object();
  screenData: Object;
  formErrors: any;
  formElement: any;

  public translateSubFunctionLabelsName = 'finfra-sub-function';
  public translateSubFunctionFolder = 'labels/finfra-widgets/';
  public translateSubFunctionLabelsLoaded = false;

  public translateLabelsName = 'finfra-sub-function';
  public translateFolder = 'labels/screens/';
  public translateLabelsLoaded = false;

  public uiStages: any = [];
  public uiStagesDetails!: any;
  public currentUIStageIndex = 0;
  public currentUiStage: any;
  public lastIdx: number;
  public functionDetails: any;
  public jsonSchemaProperties: any;
  public subFunctionDialogRef: MatDialogRef<any>;

  @Input() layoutNode: any;
  @Input() layoutIndex: number[];
  @Input() dataIndex: number[];

  @ViewChild('uiStageStepper') uiStageStepper: MatStepper;
  @ViewChild('subFunctionDialog', {static: true}) subFunctionDialog: TemplateRef<any>;

  constructor(
    @Inject(MAT_FORM_FIELD_DEFAULT_OPTIONS) @Optional() public matFormFieldDefaultOptions,
    private jsf: JsonSchemaFormService,
    private ajsfCoreWidgetsFunctionsService: AjsfCoreWidgetsFunctionsService,
    private translateService: TranslateService,
    private configService: ConfigService,
    private alertService: AlertService,
    private sessionService: SessionService,
    private httpService: HttpService,
    private spinnerService: SpinnerService,
    private dialog: MatDialog,
    private eventsService: EventsService
  ) {
    this.translateService.load(this.translateSubFunctionLabelsName, this.translateSubFunctionFolder + this.translateSubFunctionLabelsName, this.loadSubFunctionLabel.bind(this));
  }

  ngOnInit() {
    this.options = this.layoutNode.options || {};
    this.subProperties = this.layoutNode.subProperties || {};
    this.jsf.initializeControl(this);
    if (!this.options.notitle && !this.options.description && this.options.placeholder) {
      this.options.description = this.options.placeholder;
    }
    this.functionId = this.options.functionId;

    this.init();
  }

  ngOnDestroy(): void {
    this.jsf = undefined;
    this.formControl = undefined;
    this.controlName = undefined;
    this.controlValue = undefined;
    this.controlDisabled = undefined;
    this.boundControl = undefined;
    this.options = undefined;
    this.subProperties = undefined;

    if (this.subFunctionDialogRef) {
      this.subFunctionDialogRef.close();
    }
  }

  updateValue() {
    this.jsf.updateObjectValue(this, this.controlValue);
    this.ajsfCoreWidgetsFunctionsService.dispatchEvent(this);
  }


  public async init() {

    this.translateLabelsName = this.functionId;
    this.translateService.loadLabels(this);

    this.functionDetails = await this.getStageInformationForFunction(this.functionId);

    await this.readJsonSchemaProperty(this.functionId);
    this.screenData = this.functionDetails.body.stages;

    this.uiStagesDetails = this.screenData;
    this.uiStages = this.uiStagesDetails?.sort(((s1, s2) => {
      if (+s1.uiStageSerialNumber < +s2.uiStageSerialNumber) {
        return -1;
      } else {
        return 1;
      }
    }));
    this.setCurrentJsonSchema();
    this.setCurrentStage();

    this.jsonFormValue = this.controlValue;

    if (this.jsonFormValue === undefined) {
      this.jsonFormValue = new Object();
    }

    if (this.formControl === undefined) {
      return false;
    }
    this.jsonFormValue.transactionReferenceNumber = this.jsf.getControlService(this, this.jsf.formGroup, 'transactionReferenceNumber').value;
    this.jsonFormValue.applicationReferenceNumber = this.jsf.getControlService(this, this.jsf.formGroup, 'applicationReferenceNumber').value;

    this.jsonFormLoaded = true;
    (this.jsf.formGroup as FormGroup).markAllAsTouched();
    this.updateValue();
  }

  // To fetch json property file
  public async readJsonSchemaProperty(functionId: string): Promise<boolean> {
    if (!functionId) {
      return false;
    }

    const result = await this.getJsonSchema(
      functionId,
      this.functionDetails.body.propertyFile,
    ).catch(
      error => {
        this.alertService.showErrorMessage(error);
        return undefined;
      }
    );

    if (result && result.status === 200 && result instanceof HttpResponse) {
      this.jsonSchemaProperties = result.body.properties;
    }

    return true;
  }

  public getJsonSchema(functionId: string,
                       jsonSchemaFilePath: string): Promise<HttpResponse<any> | HttpErrorResponse> {
    let path = this.configService.getConfig('global').globalAssetsPath + this.configService.getConfig('workflow-api').jsonSchemaPath;
    path = path + '/' + jsonSchemaFilePath;

    const currUser = this.sessionService.getUser();
    const re = new RegExp('\\$userLang\\$', 'g');

    if (currUser) {
      path = path.replace(re, currUser.userLang);
    } else {
      path = path.replace(re, 'en');
    }

    const httpCall: HttpCallModel = new HttpCallModel(
      path,
      'GET',
      functionId,
      undefined);

    httpCall.backgroundProcess = true;
    return this.httpService.callWS(httpCall).toPromise();
  }

  // To get the ui stages details

  private getStageInformationForFunction(functionId: string): Promise<HttpResponse<any> | HttpErrorResponse> {
    const path = this.configService.getConfig('global').globalAssetsPath
      + this.configService.getConfig('workflow-api').getStageInformationForFunction
      + '/' + functionId + '.json';

    const httpCall: HttpCallModel = new HttpCallModel(
      path,
      'GET',
      functionId,
      undefined);

    return this.httpService.callWS(httpCall).toPromise();
  }

  private validateForm() {
    if (this.formElement && this.formElement.nativeElement) {
      this.formElement.nativeElement.requestSubmit();
      this.formElement.nativeElement.reportValidity();
    }
  }

  public validationErrors(error: any) {
    this.formErrors = error;
  }

  public setAjsfFormElement(form: any) {
    this.formElement = form;
  }

  public loadSubFunctionLabel() {
    this.translateSubFunctionLabelsLoaded = true;
  }

  public onUIStageChange(event: any) {
    this.currentUIStageIndex = event.selectedIndex;
  }

  private setCurrentJsonSchema() {
    this.uiStagesDetails.forEach(
      (stage) => {
        if (stage.stageSerialNumber === 1) {
          if (this.options.readonly !== undefined && this.options.readonly === true) {
            this.uiStages = stage.uiStages.view;
          } else {
            this.uiStages = stage.uiStages.edit;
            this.lastIdx = stage.uiStages.edit?.length;

          }
        }
      }
    );
    this.uiStages = this.uiStages?.sort((s1, s2) => {
      if (+s1.uiStageSerialNumber < +s2.uiStageSerialNumber) {
        return -1;
      } else {
        return 1;
      }
    });
    let uiStageIdx = 0;
    this.uiStages?.forEach(
      (uiStage: any) => {
        uiStage.completed = false;
        this.fetchJsonSchema(uiStage, uiStageIdx);
        uiStageIdx++;
      }
    );
  }

  private setCurrentStage() {
    if (this.options.readonly !== undefined && this.options.readonly === true) {
      this.currentUiStage = null;
    } else {
      this.uiStages?.forEach(
        (uiStage: any) => {
          if (+uiStage['uiStageSerialNumber'] === 1) {
            this.currentUiStage = uiStage;
          }
        }
      );
    }
  }

  private async fetchJsonSchema(uiStage: any, uiStageIdx: number): Promise<boolean> {
    if (!uiStage) {
      return false;
    }


    let idx = 0;
    let jsonFormSchemaIdx: number;
    const executionArray: Promise<any>[] = [];
    const userAgent = this.configService.getConfig('global').userAgent;

    if (uiStage[userAgent]) {
      if (uiStage[userAgent].jsonFormSchemaFile) {
        executionArray.push(this.getJsonSchema(this.functionId, uiStage.jsonFormSchemaFile));
        jsonFormSchemaIdx = idx;
        idx++;
      }

    } else {
      if (uiStage.jsonFormSchemaFile) {
        executionArray.push(this.getJsonSchema(this.functionId, uiStage.jsonFormSchemaFile));
        jsonFormSchemaIdx = idx;
        idx++;
      }

    }

    const responses = await Promise.all(executionArray).catch(
      error => {
        this.alertService.showErrorMessage(error);
        return undefined;
      }
    );

    if (responses && this.uiStages) {
      if (jsonFormSchemaIdx !== undefined && responses[jsonFormSchemaIdx]) {
        this.uiStages[uiStageIdx].jsonFormSchema = responses[jsonFormSchemaIdx].body;

        this.uiStages[uiStageIdx].jsonFormSchema.schema.properties = this.jsonSchemaProperties;
      }
    }

    return true;
  }

  public navigateNext() {
    if (this.formErrors) {
      this.alertService.showMessage('E_FORM_ERROR', 'error');
      this.validateForm();
      return false;
    }

    let proceed = false;
    let breakLoop = false;

    this.uiStages?.forEach(
      (uiStage: any) => {
        if (breakLoop) {
          return false;
        }

        if (proceed) {
          this.currentUiStage = uiStage;
          breakLoop = true;
          return false;
        }

        if (+uiStage['uiStageSerialNumber'] === +this.currentUiStage['uiStageSerialNumber']) {
          uiStage.completed = true;
          proceed = true;
        }
        return true;
      }
    );
    this.uiStageStepper?.next();
    this.uiStageStepper?.animationDone.subscribe(
      (v: any) => {
        this.spinnerService.decrementSpinnerCount();
      }
    );
    this.ajsfCoreWidgetsFunctionsService.dispatchEvent(this);
    return true;
  }

  public navigateBefore() {
    let breakLoop = false;
    const currentIdx: number = +this.currentUiStage['uiStageSerialNumber'];

    this.uiStages.forEach(
      (uiStage: any) => {
        if (breakLoop) {
          return false;
        }

        if (+uiStage['uiStageSerialNumber'] === currentIdx) {
          breakLoop = true;
          return false;
        }

        this.currentUiStage = uiStage;
        return true;
      }
    );
    this.ajsfCoreWidgetsFunctionsService.dispatchEvent(this);
    this.uiStageStepper?.previous();
    this.uiStageStepper?.animationDone.subscribe(
      (v: any) => {
        this.spinnerService.decrementSpinnerCount();
      }
    );

  }

  public save() {
    if (this.formErrors) {
      this.alertService.showMessage('E_FORM_ERROR', 'error');
      return false;
    }

    this.controlValue = this.jsonFormValue;
    this.validateForm();
    this.updateValue();
    this.subFunctionDialogRef.close();

    return true;
  }

  public openSubFunction() {
    this.subFunctionDialogRef = this.dialog.open(this.subFunctionDialog,
      {
        width: '95vw',
        height: '95vh',
        disableClose: true
      }
    );
    this.ajsfCoreWidgetsFunctionsService.dispatchEvent(this);
    this.currentUIStageIndex = 0;
    this.setCurrentStage();
    this.subFunctionDialogRef.afterClosed().subscribe(
      data => {

      }
    );
  }

  public closeSubFunction() {
    this.subFunctionDialogRef.close();
  }

}
