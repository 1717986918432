import {FormArray} from '@angular/forms';
import {
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  AfterViewInit,
  Optional
} from '@angular/core';
import {JsonSchemaFormService} from '@finfra/ajsf-core';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {AjsfCoreWidgetsFunctionsService} from '../../../asjf-core-widgets.functions';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import jspreadsheet from 'jspreadsheet-ce';
import {AlertService, ConfigService, EventsService} from '@finfra/core-services';
import {GenericEventModel} from '@finfra/core-models';

@Component({
  selector: 'finfra-datagrid-widget',
  templateUrl: './finfra-datagrid.component.html',
  styleUrls: ['./finfra-datagrid.component.scss'],
})
export class FinfraDatagridComponent implements OnInit, OnDestroy, AfterViewInit {
  widgetName = 'FinfraDatagridComponent';
  formControl: FormArray;
  controlName: string;
  controlValue: any[];
  controlDisabled = false;
  boundControl = false;
  options: any;
  items: any;
  subProperties: any;
  autoCompleteList: string[] = [];
  displayedColumns: string[] = [];
  detailedColumns: string[] = [];
  displayedColumnsList: Map<string, any> = new Map<string, any>();
  displayedColumnCount = 0;
  @Input() layoutNode: any;
  @Input() layoutIndex: number[];
  @Input() dataIndex: number[];

  dataGrid: any;
  dataGridOptions = {
    search: true,
    pagination: 10,
    tableOverflow: true,
    columns: [],
    columnSorting: true,
    columnResize: true,
    columnDrag: true,
    rowResize: true,
    rowDrag: true,
    editable: true,
    lazyLoading: true,
    filters: false,
    paginationOptions: [],
    onchange: this.onTableDataChange.bind(this),
    onchangepage: this.onChangePage.bind(this),
    onselection: this.onSelection.bind(this)
  };

  constructor(
    @Inject(MAT_FORM_FIELD_DEFAULT_OPTIONS) @Optional() public matFormFieldDefaultOptions,
    private jsf: JsonSchemaFormService,
    private ajsfCoreWidgetsFunctionsService: AjsfCoreWidgetsFunctionsService,
    private alertService: AlertService,
    private configService: ConfigService,
    private eventsService: EventsService
  ) {
  }

  ngOnInit() {
    this.options = this.layoutNode.options || {};
    this.subProperties = this.layoutNode.subProperties || {};
    this.items = this.layoutNode.items || {};

    this.jsf.initializeControl(this);
    if (!this.options.notitle && !this.options.description && this.options.placeholder) {
      this.options.description = this.options.placeholder;
    }

    this.formControl.valueChanges
      .pipe(
        debounceTime(800),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
      )
      .subscribe(this.onControlChange.bind(this));
  }

  ngOnDestroy(): void {
    this.jsf = undefined;
    this.formControl = undefined;
    this.controlName = undefined;
    this.controlValue = undefined;
    this.controlDisabled = undefined;
    this.boundControl = undefined;
    this.options = undefined;
    this.subProperties = undefined;
    this.items = undefined;
    jspreadsheet.destroy(document.getElementById('control' + this.layoutNode?._id));
  }

  ngAfterViewInit() {
    this.init();
    this.displayTable();
  }

  updateValue(event) {
    this.jsf.updateValue(this, event.target.value);
  }

  validate(event) {
    if (this.options.readonly !== undefined && this.options.readonly === true) {
      return;
    }

    if (this.boundControl) {
      this.updateValue(event);
    }

    this.ajsfCoreWidgetsFunctionsService.dispatchEvent(this);
  }

  init() {
    if (!Array.isArray(this.items)) {
      return;
    }

    this.dataGridOptions.columns = [];
    let items: any[];

    if (this.items[0].items) {
      items = this.items[0].items;
    } else {
      items = this.items;
    }

    for (let idx = 0; idx < items.length; idx++) {
      const displayedColumn: any = new Object();
      displayedColumn.id = items[idx].name;
      displayedColumn.headerText = items[idx].options.title;
      displayedColumn.type = items[idx].type;
      displayedColumn.width = items[idx].options.width;
      displayedColumn.labelFlex = items[idx].options.labelFlex;
      displayedColumn.dataFlex = items[idx].options.dataFlex;
      displayedColumn.required = items[idx].required;
      displayedColumn.readonly = items[idx].options.readonly;
      displayedColumn.min = items[idx].options.min;
      displayedColumn.max = items[idx].options.max;
      displayedColumn.titleMap = items[idx].options.titleMap;
      displayedColumn.notitle = items[idx].options.notitle;
      displayedColumn.title = items[idx].options.title;
      displayedColumn.mask = items[idx].options.mask;
      displayedColumn.expandButton = items[idx].options.expandButton;
      displayedColumn.minDimensions = items[idx].options.minDimensions;
      displayedColumn.wordWrap = items[idx].options.wordWrap;
      displayedColumn.autocomplete = items[idx].options.autocomplete;
      displayedColumn.multiple = items[idx].options.multiple;
      displayedColumn.align = items[idx].options.align;

      if (displayedColumn.notitle === undefined) {
        displayedColumn.notitle = false;
      }

      if (displayedColumn.readonly === undefined) {
        displayedColumn.readonly = false;
      }

      if (displayedColumn.required === undefined) {
        displayedColumn.required = false;
      }

      if (displayedColumn.notitle === false) {
        displayedColumn.label = displayedColumn.headerText;
      }

      if (displayedColumn.expandButton === undefined) {
        displayedColumn.expandButton = false;
      }

      if (displayedColumn.width === undefined) {
        displayedColumn.width = '*';
      }

      if (items[idx].options.detailed) {
        displayedColumn.detailed = true;
        this.detailedColumns.push(items[idx].name);
      } else {
        this.displayedColumnCount++;
        displayedColumn.detailed = false;
        this.displayedColumns.push(items[idx].name);
      }

      this.displayedColumnsList.set(items[idx].name, displayedColumn);

      const column = {
        type: displayedColumn.type,
        title: displayedColumn.title ? displayedColumn.title : displayedColumn.name,
        readOnly: this.options.readonly ? this.options.readonly : !!displayedColumn.readonly,
        name: displayedColumn.id,
        align: displayedColumn.align || 'left'
      };

      if (displayedColumn.width && displayedColumn.width !== '*') {
        column['width'] = displayedColumn.width;
      }

      if (displayedColumn.mask) {
        column['mask'] = displayedColumn.mask;
      }

      if (displayedColumn.wordWrap) {
        column['wordWrap'] = displayedColumn.wordWrap;
      }

      if (displayedColumn.titleMap) {
        column['source'] = displayedColumn.titleMap;
      }

      if (displayedColumn.autocomplete) {
        column['autocomplete'] = displayedColumn.autocomplete;
      }

      if (displayedColumn.multiple) {
        column['multiple'] = displayedColumn.multiple;
      }

      if(displayedColumn.type === 'select'){
        column['type'] = 'dropdown';
      }

      if (column.type === 'calendar') {
        column['options'] = {
          format: this.configService.getConfig('global').dateFormat
        };
      }

      this.dataGridOptions.columns.push(column);
    }

    this.dataGridOptions['data'] = this.formControl.value || [[]];
    this.dataGridOptions['allowInsertRow'] = !!this.options.allowInsertRow;
    this.dataGridOptions['allowInsertColumn'] = !!this.options.allowInsertColumn;
    this.dataGridOptions['allowDeleteRow'] = !!this.options.allowDeleteRow;
    this.dataGridOptions['allowDeleteColumn'] = !!this.options.allowDeleteColumn;
    this.dataGridOptions['allowRenameColumn'] = !!this.options.allowRenameColumn;
    this.dataGridOptions['filters'] = !!this.options.filters;
    this.dataGridOptions['lazyLoading'] = !!this.options.lazyLoading;
    this.dataGridOptions['pagination'] = +this.options.pagination;

    if (this.options.tableHeight) {
      this.dataGridOptions['tableHeight'] = this.options.tableHeight;
    }

    if (this.options.tableWidth) {
      this.dataGridOptions['tableWidth'] = this.options.tableWidth;
    }

    if (this.options.minDimensions) {
      this.dataGridOptions['minDimensions'] = this.options.minDimensions;
    }

    if (this.options.pagination.pageSizes) {
      this.dataGridOptions.paginationOptions = this.options.pagination.pageSizes;
    }

    if (this.options.readonly) {
      this.dataGridOptions.editable = false;
    } else {
      this.dataGridOptions.editable = true;
    }

    this.dataGrid = jspreadsheet(
      document.getElementById('control' + this.layoutNode?._id),
      this.dataGridOptions
    );

    if (this.dataGrid && Array.isArray(this.options.hideColumns)) {
      this.options.hideColumns.forEach(
        col => {
          this.dataGrid.hideColumn(col);
        }
      );
    }
  }

  onControlChange() {
    this.displayTable();
  }

  displayTable() {
    if (!this.dataGrid) {
      return;
    }

    this.dataGrid.setData(this.formControl.value);
  }

  onTableDataChange(instance, cell, x, y, value) {
    if (!this.formControl.value) {
      this.formControl.setValue(this.dataGrid.getData());
    } else {
      this.formControl.get([+y]).get(this.displayedColumns[+x]).setValue(value);
    }
  }

  onSelection(instance, x1, y1, x2, y2, origin) {
    if ((x2-x1 + 1) === this.dataGridOptions.columns.length) {
      this.dispatchEvent('on-row-selection', {x1: x1, y1: y1, x2: x2, y2: y2});
    } else {
      this.dispatchEvent('on-cell-selection', {x1: x1, y1: y1, x2: x2, y2: y2});
    }
  }

  onChangePage(instance, pageNumber, oldPageNumber) {
    this.dispatchEvent('on-change-page', {pageNumber: pageNumber, oldPageNumber: oldPageNumber});
  }

  public dispatchEvent(eventName: string, value: any) {
    const eventObject: GenericEventModel = new GenericEventModel();

    eventObject.eventSource = 'finfra-ajsf';
    eventObject.formControl = this.formControl;
    eventObject.formName = this.jsf.ajsfFormName;
    eventObject.value = value;
    eventObject.jsonSchema = this.layoutNode;
    eventObject.eventObjectName = this.controlName;
    eventObject.eventObject = this.formControl.value;
    eventObject.eventName = this.widgetName + '-' + eventName;
    eventObject.arrayIndex = this.dataIndex[0];

    this.eventsService.fireEvent(eventObject);
  }
}
