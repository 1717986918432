import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {HttpService} from '@finfra/core-services';
import {Observable} from 'rxjs';
import {HttpCallModel} from '@finfra/core-models';
import {map} from 'rxjs/operators';
import {HttpResponse} from "@angular/common/http";

@Pipe({
  name: 'secureGet'
})
export class SecureGetPipe implements PipeTransform {
  constructor(private httpService: HttpService, private sanitizer: DomSanitizer) {
  }

  async transform(url: string, functionId: string): Promise<any> {
    const httpCallModel = new HttpCallModel(url, 'GET', functionId, undefined);
    httpCallModel.responseType = 'blob';
    httpCallModel.fetchFile = true;

    const response = await this.httpService.callWS(httpCallModel).toPromise().catch(
      error => {
        return undefined;
      }
    );

    if (response && response instanceof HttpResponse) {
      const base64: string = await new Promise(
        (resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(response.body);
          reader.onloadend = () => {
            if (reader.result) {
              resolve(reader.result.toString());
            } else {
              resolve('');
            }
          };
          reader.onerror = error => reject(error);
        }
      );

      return this.sanitizer.bypassSecurityTrustResourceUrl(base64);
    } else {
      return '';
    }
  }

}
