export interface FinUIException {
  errorCode: string;
  errorMessage?: string;
  exception?: any;
}

export class FinUIException implements FinUIException {
  constructor(
    public errorCode: string,
    public errorMessage?: string,
    public exception?: any,
  ) { }
}
