import { Injectable } from '@angular/core';
import { HttpService, ConfigService, SessionService, AlertService } from '@finfra/core-services';
import { HttpCallModel, User } from '@finfra/core-models';
import { HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {
  private labels: Map<string, any>;
  private labelsConsolidated: Map<string, string>;
  private labelsLoading: Map<string, Function[]> = new Map<string, Function[]>();

  constructor(
    private httpService: HttpService,
    private configService: ConfigService,
    private sessionService: SessionService,
    private alertService: AlertService,
  ) {
    this.labels = new Map<string, any>();
    this.labelsConsolidated = new Map<string, string>();
  }

  private parseFile(obj: any, mapObject?: Map<string, string>, prefix?: string): Map<string, string> {
    if (mapObject === undefined) {
      mapObject = new Map<string, string>();
    }

    if (prefix === undefined) {
      prefix = '';
    }

    for (const key of Object.keys(obj)) {
      const value: any = obj[key];

      if (typeof value === 'string') {
        mapObject.set(prefix + key, value);
      } else if (value instanceof Array) {
        for (const item of value) {
          const tempMap: Map<string, string> = this.parseFile(item, mapObject, key + '.');
          tempMap.forEach((value, key) => mapObject?.set(key, value));
        }
      } else if (value instanceof Object) {
        const tempMap: Map<string, string> = this.parseFile(value, mapObject, key + '.');
        tempMap.forEach((value, key) => mapObject?.set(key, value));
      }
    }

    return mapObject;
  }

  public load(name: string, url: string, callback?: Function): void {
    if (this.labels.get(name)) {
      if (callback) {
        callback();
      }

      return;
    }

    if (this.labelsLoading.get(name) !== undefined) {
      if (callback) {
        const callbackArray: Function[] | undefined = this.labelsLoading.get(name);

        if (callbackArray) {
          callbackArray.push(callback);
          this.labelsLoading.set(name, callbackArray);
        }
      }

      return;
    } else {
      const callbackArray: Function[] = [];

      if (callback) {
        callbackArray.push(callback);
      }

      this.labelsLoading.set(name, callbackArray);
    }

    let language: string | null = 'en';
    const currUser: User | undefined = this.sessionService.getUser();

    if (currUser !== undefined && currUser.userLang !== undefined) {
      language = currUser.userLang;
    }

    this.httpService.callWS(
      new HttpCallModel(
        this.configService.getConfig('global').labelUrl + language + '/' + url + '.json',
        'GET',
        'finfra-core',
        undefined)
    ).subscribe(
      result => {
        if (result.status === 200 && result instanceof HttpResponse) {
          const file: any = result.body;

          const map: Map<string, string> = this.parseFile(file, undefined);

          this.labels.set(name, map);
          map.forEach((value, key) => this.labelsConsolidated.set(key, value));

          if (this.labelsLoading.get(name) !== undefined) {
            const callbackArray: Function[] | undefined = this.labelsLoading.get(name);

            if (callbackArray) {
              callbackArray.forEach(callback => {
                if (callback) {
                  callback();
                }
              });
            }
          } else {
            if (callback) {
              callback();
            }
          }
        } else {
          this.alertService.showErrorMessage(result);
        }

        this.labelsLoading.delete(name);
      },
      error => {
        this.alertService.error('Failed while getting label');
        this.alertService.error(error);
        this.labelsLoading.delete(name);
      }
    );
  }

  public get(name: string, label: string): string | undefined {
    if (name === undefined) {
      if (this.labelsConsolidated.get(label)) {
        return this.labelsConsolidated.get(label);
      } else {
        return label;
      }
    } else {
      const map: Map<string, string> = this.labels.get(name);

      if (map === undefined) {
        if (this.labelsConsolidated.get(label)) {
          return this.labelsConsolidated.get(label);
        } else {
          return label;
        }
      } else {
        if (map.get(label)) {
          return map.get(label);
        } else {
          if (this.labelsConsolidated.get(label)) {
            return this.labelsConsolidated.get(label);
          } else {
            return label;
          }
        }
      }
    }
  }

  public getAllLabelsForName(name: string): any {
    if (name === undefined || name === null) {
      return undefined;
    }

    if (this.labels === undefined) {
      return undefined;
    }

    return this.labels.get(name);
  }

  public getAllLabels(): Map<string, string> {
    return this.labelsConsolidated;
  }

  public clearLabel(name: string): void {
    this.labels.delete(name);
  }

  public clearAllLabels(): void {
    this.labels.clear();
    this.labelsConsolidated.clear();
  }

  public loadLabels(componentObject: any, callbackFunction?: any): void {
    if (!componentObject.translateLabelsName) {
      return;
    }

    componentObject.translateLabelsLoaded = false;

    this.load(
      componentObject.translateLabelsName,
      componentObject.translateFolder + componentObject.translateLabelsName,
      this.afterLoadLabels.bind(this, componentObject, callbackFunction)
    );
  }

  public afterLoadLabels(componentObject: any, callbackFunction?: any): void {
    componentObject.translateLabelsLoaded = true;

    if (callbackFunction) {
      callbackFunction();
    }

    if (componentObject.changeDetectorRef) {
      componentObject.changeDetectorRef.markForCheck();
    }
  }
}
