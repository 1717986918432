import { EFormFieldType, EPrimitiveType, ESection, ESpecificFormType } from "../consts";
import { IConfigurableWidget } from "../inner-models";

export enum EWidget {
  AUTOCOMPLETE = 'autocomplete',
  BUTTON = 'button',
  BUTTON_GROUP = 'button-group',
  CHECKBOX = 'checkbox',
  CHECKBOXES = 'checkboxes',
  DATE_PICKER = 'date',
  DATE_RANGE = 'date-range',
  INPUT = 'text',
  INPUT_NUMBER = 'number',
  PASSWORD = 'password',
  RADIOS = 'radios',
  SELECT = 'select',
  TEXTAREA = 'textarea',
  // secon level widgets
  AMOUNT = 'amount',
  DMS = 'dms'
}

export const BOOLEAN_DEFAULT_WIDGETS = [
  EWidget.CHECKBOX,
  EWidget.CHECKBOXES
];

export const NUMBER_DEFAULT_WIDGETS = [
  EWidget.AMOUNT,
  EWidget.INPUT_NUMBER
]

export const ConfigurableWidgetList: IConfigurableWidget[] = [
  {
    name: 'Autocomplete',
    ajsfName: EWidget.AUTOCOMPLETE,
    properties: [
      {
        name: 'selectListPointer',
        formFieldType: EFormFieldType.TEXT,
        autoGenerated: false,
        mandatory: true,
        modifiable: true,
        section: ESection.LAYOUT,
      },
      {
        name: 'displayName',
        formFieldType: EFormFieldType.CHECKBOX,
        autoGenerated: false,
        mandatory: false,
        modifiable: true,
        section: ESection.LAYOUT,
      },
      {
        name: 'nameTag',
        formFieldType: EFormFieldType.TEXT,
        autoGenerated: false,
        mandatory: true,
        modifiable: true,
        section: ESection.LAYOUT,
      },
      {
        name: 'valueTag',
        formFieldType: EFormFieldType.TEXT,
        autoGenerated: false,
        mandatory: true,
        modifiable: true,
        section: ESection.LAYOUT,
      },
    ]
  },
  {
    name: 'Button',
    icon: 'smart_button',
    ajsfName: EWidget.BUTTON,
    properties: [
      {
        name: 'icon',
        autoGenerated: false,
        modifiable: true,
        mandatory: false,
        section: ESection.LAYOUT,
        formFieldType: EFormFieldType.TEXT,
      },
      {
        name: 'color',
        autoGenerated: false,
        modifiable: true,
        mandatory: false,
        section: ESection.LAYOUT,
        formFieldType: EFormFieldType.TEXT,
      },
      {
        name: 'buttonType',
        formFieldType: EFormFieldType.LIST,
        autoGenerated: false,
        mandatory: true,
        modifiable: true,
        section: ESection.LAYOUT,
        currentValue: 'flat',
        options: [
          {key: 'flat', value: 'flat'},
          {key: 'raised', value: 'raised'},
          {key: 'stroked', value: 'stroked'}
        ]
      }
    ]
  },
  {
    name: 'Button Group',
    ajsfName: EWidget.BUTTON_GROUP,
    properties: [
      {
        name: 'titleMap',
        formFieldType: EFormFieldType.ARRAY,
        autoGenerated: false,
        mandatory: true,
        modifiable: true,
        section: ESection.LAYOUT,
        currentValue: []
      }
    ]
  },
  {
    name: 'Checkbox',
    icon: 'check_box',
    ajsfName: EWidget.CHECKBOX,
    properties: [
      {
        name: 'color',
        autoGenerated: false,
        modifiable: true,
        mandatory: false,
        section: ESection.LAYOUT,
        formFieldType: EFormFieldType.TEXT,
      }
    ]
  },
  {
    name: 'Checkboxes',
    icon: 'library_add_check',
    ajsfName: EWidget.CHECKBOXES,
    properties: [
      {
        name: 'titleMap',
        formFieldType: EFormFieldType.ARRAY,
        autoGenerated: false,
        mandatory: true,
        modifiable: true,
        section: ESection.LAYOUT,
        currentValue: [],
        defaultValueType: EPrimitiveType.BOOLEAN
      }
    ]
  },
  {
    name: 'Date-picker',
    icon: 'calendar_today',
    ajsfName: EWidget.DATE_PICKER,
    properties: [
      {
        name: 'maxDifferenceDays',
        formFieldType: EFormFieldType.TEXT,
        autoGenerated: false,
        mandatory: false,
        modifiable: true,
        section: ESection.LAYOUT,
      },
      {
        name: 'minDifferenceDays',
        formFieldType: EFormFieldType.TEXT,
        autoGenerated: false,
        mandatory: false,
        modifiable: true,
        section: ESection.LAYOUT,
      },
      {
        name: 'minimum',
        formFieldType: EFormFieldType.TEXT,
        autoGenerated: false,
        mandatory: false,
        modifiable: true,
        section: ESection.LAYOUT,
      },
      {
        name: 'maximum',
        formFieldType: EFormFieldType.TEXT,
        autoGenerated: false,
        mandatory: false,
        modifiable: true,
        section: ESection.LAYOUT,
      }
    ]
  },
  {
    name: 'Date-range',
    icon: 'date_range',
    ajsfName: EWidget.DATE_RANGE,
    properties: [
      {
        name: 'maxDifferenceDays',
        formFieldType: EFormFieldType.TEXT,
        autoGenerated: false,
        mandatory: false,
        modifiable: true,
        section: ESection.LAYOUT,
      },
      {
        name: 'minDifferenceDays',
        formFieldType: EFormFieldType.TEXT,
        autoGenerated: false,
        mandatory: false,
        modifiable: true,
        section: ESection.LAYOUT,
      },
      {
        name: 'minimum',
        formFieldType: EFormFieldType.TEXT,
        autoGenerated: false,
        mandatory: false,
        modifiable: true,
        section: ESection.LAYOUT,
      },
      {
        name: 'maximum',
        formFieldType: EFormFieldType.TEXT,
        autoGenerated: false,
        mandatory: false,
        modifiable: true,
        section: ESection.LAYOUT,
      }
    ]
  },
  {
    name: 'Input',
    icon: 'text_fields',
    ajsfName: EWidget.INPUT,
    properties: [
      {
        name: 'maxLength',
        formFieldType: EFormFieldType.NUMBER,
        autoGenerated: false,
        mandatory: false,
        modifiable: true,
        section: ESection.PROPERTY,
      },
      {
        name: 'minLength',
        formFieldType: EFormFieldType.NUMBER,
        autoGenerated: false,
        mandatory: false,
        modifiable: true,
        section: ESection.PROPERTY,
      },
      {
        name: 'pattern',
        formFieldType: EFormFieldType.TEXT,
        autoGenerated: false,
        mandatory: false,
        modifiable: true,
        section: ESection.PROPERTY,
      }
    ]
  },
  {
    name: 'Number',
    icon: 'looks_one',
    ajsfName: EWidget.INPUT_NUMBER,
    properties: [
      {
        name: 'maximum',
        formFieldType: EFormFieldType.NUMBER,
        autoGenerated: false,
        mandatory: false,
        modifiable: true,
        section: ESection.LAYOUT,
      },
      {
        name: 'minimum',
        formFieldType: EFormFieldType.NUMBER,
        autoGenerated: false,
        mandatory: false,
        modifiable: true,
        section: ESection.LAYOUT,
      }
    ]
  },
  {
    name: 'Password',
    icon: 'password',
    ajsfName: EWidget.PASSWORD,
    properties: [
      {
        name: 'maxLength',
        formFieldType: EFormFieldType.NUMBER,
        autoGenerated: false,
        mandatory: false,
        modifiable: true,
        section: ESection.PROPERTY,
      },
      {
        name: 'minLength',
        formFieldType: EFormFieldType.NUMBER,
        autoGenerated: false,
        mandatory: false,
        modifiable: true,
        section: ESection.PROPERTY,
      },
      {
        name: 'pattern',
        formFieldType: EFormFieldType.TEXT,
        autoGenerated: false,
        mandatory: false,
        modifiable: true,
        section: ESection.PROPERTY,
      }
    ]
  },
  {
    name: 'Radios',
    icon: 'radio_button_checked',
    ajsfName: EWidget.RADIOS,
    properties: [
      {
        name: 'titleMap',
        formFieldType: EFormFieldType.ARRAY,
        autoGenerated: false,
        mandatory: true,
        modifiable: true,
        section: ESection.LAYOUT,
        currentValue: []
      }
    ]
  },
  {
    name: 'Select',
    ajsfName: EWidget.SELECT,
    properties: [
      {
        name: 'titleMap',
        formFieldType: EFormFieldType.ARRAY,
        autoGenerated: false,
        mandatory: true,
        modifiable: true,
        section: ESection.LAYOUT,
        currentValue: []
      },
      {
        name: 'nativeSelect',
        formFieldType: EFormFieldType.CHECKBOX,
        autoGenerated: false,
        mandatory: true,
        modifiable: true,
        section: ESection.LAYOUT,
      },
      {
        name: 'stringBased',
        formFieldType: EFormFieldType.CHECKBOX,
        autoGenerated: false,
        mandatory: true,
        modifiable: true,
        section: ESection.LAYOUT,
      },
      {
        name: 'nameTag',
        formFieldType: EFormFieldType.TEXT,
        autoGenerated: false,
        mandatory: true,
        modifiable: true,
        section: ESection.LAYOUT,
      },
      {
        name: 'valueTag',
        formFieldType: EFormFieldType.TEXT,
        autoGenerated: false,
        mandatory: true,
        modifiable: true,
        section: ESection.LAYOUT,
      },
      {
        name: 'selectListPointer',
        formFieldType: EFormFieldType.TEXT,
        autoGenerated: false,
        mandatory: true,
        modifiable: true,
        section: ESection.LAYOUT,
      },
    ]
  },
  {
    name: 'Textarea',
    icon: 'article',
    ajsfName: EWidget.TEXTAREA,
    properties: [
      {
        name: 'maxLength',
        formFieldType: EFormFieldType.NUMBER,
        autoGenerated: false,
        mandatory: false,
        modifiable: true,
        section: ESection.PROPERTY,
      },
      {
        name: 'minLength',
        formFieldType: EFormFieldType.NUMBER,
        autoGenerated: false,
        mandatory: false,
        modifiable: true,
        section: ESection.PROPERTY,
      },
      {
        name: 'pattern',
        formFieldType: EFormFieldType.TEXT,
        autoGenerated: false,
        mandatory: false,
        modifiable: true,
        section: ESection.PROPERTY,
      }
    ]
  },
  {
    name: 'Amount',
    icon: 'money',
    ajsfName: EWidget.AMOUNT,
    properties: [
      {
        name: 'currencyPointer',
        formFieldType: EFormFieldType.TEXT,
        autoGenerated: false,
        mandatory: true,
        modifiable: true,
        section: ESection.LAYOUT,
      },
      {
        name: 'showAmountInWords',
        formFieldType: EFormFieldType.CHECKBOX,
        autoGenerated: false,
        mandatory: true,
        modifiable: true,
        section: ESection.LAYOUT,
      },
      {
        name: 'showFormattedAmount',
        formFieldType: EFormFieldType.CHECKBOX,
        autoGenerated: false,
        mandatory: true,
        modifiable: true,
        section: ESection.LAYOUT,
      },
      {
        name: 'formattedAmountPointer',
        formFieldType: EFormFieldType.TEXT,
        autoGenerated: false,
        mandatory: true,
        modifiable: true,
        section: ESection.LAYOUT,
      },
      {
        name: 'amountInWordsPointer',
        formFieldType: EFormFieldType.TEXT,
        autoGenerated: false,
        mandatory: true,
        modifiable: true,
        section: ESection.LAYOUT,
      },
      {
        name: 'maximum',
        formFieldType: EFormFieldType.NUMBER,
        autoGenerated: false,
        mandatory: true,
        modifiable: true,
        section: ESection.LAYOUT,
      },
      {
        name: 'minimum',
        formFieldType: EFormFieldType.NUMBER,
        autoGenerated: false,
        mandatory: true,
        modifiable: true,
        section: ESection.LAYOUT,
      }
    ]
  },
  {
    name: 'DMS',
    ajsfName: EWidget.DMS,
    properties: [
      {
        name: 'applicationReferenceNumberPointer',
        formFieldType: EFormFieldType.TEXT,
        autoGenerated: false,
        mandatory: true,
        modifiable: true,
        section: ESection.LAYOUT,
      },
      {
        name: 'transactionReferenceNumberPointer',
        formFieldType: EFormFieldType.TEXT,
        autoGenerated: false,
        mandatory: true,
        modifiable: true,
        section: ESection.LAYOUT,
      },
      {
        name: 'validityPointer',
        formFieldType: EFormFieldType.TEXT,
        autoGenerated: false,
        mandatory: true,
        modifiable: true,
        section: ESection.LAYOUT,
      },
      {
        name: 'validityControlName',
        formFieldType: EFormFieldType.TEXT,
        autoGenerated: false,
        mandatory: true,
        modifiable: true,
        section: ESection.LAYOUT,
      },
      {
        name: 'purposeList',
        formFieldType: EFormFieldType.ARRAY,
        autoGenerated: false,
        mandatory: true,
        modifiable: true,
        section: ESection.LAYOUT,
        currentValue: []
      }
    ]
  }
]