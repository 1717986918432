export interface InjectedServicesModel {
  serviceName?: string;
  service?: any;
  entryFunctionName?: string;
}

export class InjectedServicesModel implements InjectedServicesModel {
  constructor(
    public serviceName?: string,
    public service?: any,
    public entryFunctionName?: string,
  ) {}
}
