import { Component, HostListener, OnInit } from '@angular/core';
import { fromEvent } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ELocalStoreKeys } from 'src/models/consts';
import { BpmnService } from '../services/bpmn.service';
import { DataSaverService } from '../services/data-saver.service';
import { LocalstorageService } from '../services/localstorage.service';

@Component({
  selector: 'app-system',
  templateUrl: './system.component.html',
  styleUrls: ['./system.component.scss']
})
export class SystemComponent implements OnInit {

  constructor(
    private localstoreService: LocalstorageService,
    private dataSaver: DataSaverService
  ) { }

  ngOnInit(): void {
    const bpmnFile = this.localstoreService.getItem(ELocalStoreKeys.BPMN_FILE);
    const stages = this.localstoreService.getItemParsed(ELocalStoreKeys.STAGES_TREE);
    const name = this.localstoreService.getItemParsed(ELocalStoreKeys.NAME);

    if (!!bpmnFile) {
      if (!!stages) {
        this.dataSaver.saveStagesTree(stages);
        this.dataSaver.saveName(name || 'Workflow');
        this.dataSaver.setUiStages();
      }
    } else {
      this.localstoreService.clear();
    }
  }

}
