import { Injectable } from "@angular/core";
import { UIDatabaseService } from "./uidatabase.service";
import { InjectionService } from "../core-services/injection.service";

import { OpenUIDatabaseService } from "./open-ui-database.service";
import { EncryptionService } from "../core-services/encryption.service";

@Injectable({
  providedIn: "root",
})
export class OfflineHttpCacheService {
  public functionId: string = "OFFLINE-SERVICE";
  public cacheStatus: Map<string, string> = new Map<string, string>();

  public cacheHttpConfig: Map<string, any> = new Map<string, any>();

  private cacheDbName: string = "finfra-offline-cache";
  private getCacheDbStore: string = "get-cache-store";
  private mappingStore: string = "mapping-store";
  private postCacheDbStore: string = "post-cache-store";

  constructor(
    private uiDatabaseService: UIDatabaseService,
    private injectionService: InjectionService,
    private openUIDatabaseService: OpenUIDatabaseService,
    private encryptionService: EncryptionService,
  ) {
    this.openUIDatabaseService.openAllDatabases();
  }

  public async cacheHttpData(
    path: string,
    method: string,
    request: any,
    response: any
  ): Promise<boolean> {
    let config: any = this.cacheHttpConfig.get(path);

    if (config !== undefined) {
      if (config.filters && config.filters.length > 0) {
        let proceed: boolean = false;
        config.filters.forEach(
          (filter: any) => {
            let filterData: any = request;

            if (filter.tag.indexOf("#") > -1) {
              filter.tag.split("#").forEach(
                (tag: any) => {
                  if (tag.indexOf("~") > -1) {
                    let subTags: string[] = tag.split("~");

                    if (subTags[1] === "number") {
                      filterData = filterData[+subTags[0]];
                    } else {
                      filterData = filterData[subTags[0]];
                    }
                  } else {
                    filterData = filterData[tag];
                  }
                }
              )
            } else {
              filterData = filterData[filter.tag];
            }

            if (typeof filterData !== "string") {
              filterData = JSON.stringify(filterData);
            }

            if (filterData === filter.filterData) {
              proceed = true;
            }
          }
        );

        if (!proceed) {
          return false;
        }
      }

      let data: any = new Object();
      data.storeType = config.storeType;
      data.addBodyToPath = config.addBodyToPath;
      data.nullifyFunctionId = config.nullifyFunctionId;
      data.valueTag = config.valueTag;
      data.originalPath = path;

      if (config.addBodyToPath) {
        let body: any = request;
        body.headerModel.applicationDate = undefined;
        body.headerModel.versionNo = undefined;
        body.headerModel.tillId = undefined;
        body.headerModel.transactionReferenceNumber = undefined;
        body.headerModel.applicationReferenceNumber = undefined;
        body.headerModel.customerCbsId = undefined;
        body.headerModel.txnStatus = undefined;
        body.headerModel.deviceId = undefined;
        body.headerModel.transactionDateTime = undefined;
        body.headerModel.location = undefined;

        if (config.nullifyFunctionId) {
          body.headerModel.functionId = undefined;
        }

        let hash = await this.encryptionService.hash(body);
        data.path = path + hash;
      } else {
        data.path = path;
      }

      data.method = method;
      data.request = JSON.stringify(request);
      data.data = JSON.stringify(response);

      if (config.storeType === "httpDataStore") {
        this.uiDatabaseService.deleteDataByKey(this.cacheDbName, this.getCacheDbStore, data.path, this.insertHttpData.bind(this, this.cacheDbName, this.getCacheDbStore, data, config));
      } else if (config.storeType === "httpDataPermanentStore") {
        this.uiDatabaseService.deleteDataByKey(this.cacheDbName, this.postCacheDbStore, data.path, this.insertHttpData.bind(this, this.cacheDbName, this.postCacheDbStore, data, config));
      }
    }

    return true;
  }

  public insertHttpData(dbName: string, dbStore: string, data: any, config: any) {
    this.uiDatabaseService.insertData(dbName, dbStore, data, false, this.callInjectedService.bind(this, data, config));
    this.uiDatabaseService.deleteDataByKey(this.cacheDbName, this.mappingStore, data.path, this.storeMapping.bind(this, data, dbStore));
  }

  public callInjectedService(data: any, config: any, event: any) {
    if (config.injectedService) {
      let serviceObject: any = this.injectionService.getInjectedServiceByTypeAndName("cache-service", config.injectedService);
      serviceObject.service[config.injectedServiceFunctionName](data);
    }
  }

  public storeMapping(data: any, dbStore: string, event: any) {
    let mappingObject: any = new Object();
    mappingObject.path = data.path;
    mappingObject.store = dbStore;
    mappingObject.addBodyToPath = data.addBodyToPath || false;
    mappingObject.nullifyFunctionId = data.nullifyFunctionId || false;
    mappingObject.originalPath = data.originalPath;
    mappingObject.valueTag = data.valueTag;
    this.uiDatabaseService.insertData(this.cacheDbName, this.mappingStore, mappingObject, false);
  }
}
