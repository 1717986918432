export interface FinResponseCode {
  code?: string | undefined | null;
  message?: string | undefined | null;
  type?: string | undefined | null;
  show?: boolean | undefined | null;
}

export class FinResponseCode implements FinResponseCode {
  constructor(
    public code?: string | undefined | null,
    public message?: string | undefined | null,
    public type?: string | undefined | null,
    public show?: boolean | undefined | null,
  ) { }
}
