import { Injectable } from "@angular/core";
import { ConfigService } from "../core-services/config.service";
import { UIDatabaseService } from "./uidatabase.service";
import { HttpService } from "../core-services/http.service";
import { OfflineStatusService } from "./offline-status.service";
import { AlertService } from "../core-services/alert.service";
import { InjectionService } from "../core-services/injection.service";
import { UtilitiesService } from "../core-services/utilities.service";

import { SessionService } from "../core-services/session.service";
import { OpenUIDatabaseService } from "./open-ui-database.service";
import { HttpCallModel, User } from '@finfra/core-models';
import { HttpResponse } from '@angular/common/http';


@Injectable({
  providedIn: "root",
})
export class OfflineCacheService {
  public functionId: string = "OFFLINE-SERVICE";
  public cacheStatus: Map<string, string> = new Map<string, string>();

  private cacheDbName: string = "finfra-offline-cache";
  private getCacheDbStore: string = "get-cache-store";
  private mappingStore: string = "mapping-store";
  private postCacheDbStore: string = "post-cache-store";

  constructor(
    private configService: ConfigService,
    private uiDatabaseService: UIDatabaseService,
    private httpService: HttpService,
    private offlineStatusService: OfflineStatusService,
    private alertService: AlertService,
    private injectionService: InjectionService,
    private utilitiesService: UtilitiesService,
    private sessionService: SessionService,
    private openUIDatabaseService: OpenUIDatabaseService,
  ) {
    this.openUIDatabaseService.openAllDatabases();
  }

  public deleteFullCache(successCallback: any, errorCallback: any) {
    this.uiDatabaseService.deleteAll(this.cacheDbName, this.getCacheDbStore, successCallback, errorCallback);
    this.uiDatabaseService.deleteAll(this.cacheDbName, this.mappingStore, successCallback, errorCallback);
    this.uiDatabaseService.deleteAll(this.cacheDbName, this.postCacheDbStore, successCallback, errorCallback);
  }

  public deleteCache(successCallback: any, errorCallback: any) {
    this.uiDatabaseService.deleteAll(this.cacheDbName, this.getCacheDbStore, successCallback, errorCallback);
  }

  public cacheData(): void {
    if (this.offlineStatusService.offlineStatus === true) {
      this.alertService.showMessage("E_OFFLINE", "error");
      return;
    }

    let cacheConfigArray: any[] = this.configService.getConfig("offline-get-cache");
    this.cacheStatus = new Map<string, string>();

    if (cacheConfigArray && cacheConfigArray !== null && cacheConfigArray.length > 0) {
      cacheConfigArray.forEach((cacheConfig) => {
        cacheConfig.forEach(
          (config: any) => {
            if (config.type === "solr") {
              this.cacheStatus.set(config.name, "DELETING");
              this.deleteSolrCacheData(config);
            } else if (config.type === "configFiles") {
              this.cacheStatus.set(config.name, "DELETING");
              this.deleteConfigCacheData(config);
            } else if (config.type === "tagsConfigFiles") {
              this.cacheStatus.set(config.name, "DELETING");
              this.deleteTagsConfigCacheData(config);
            } else if (config.type === "injectedService") {
              this.cacheStatus.set(config.name, "STARTING");
              let serviceObject: any = this.injectionService.getInjectedServiceByTypeAndName("cache-service", config.serviceName);

              serviceObject.service[config.functionName](this);
            }
          }
        );
      });
    }
  }

  public errorCallback(config: any, event: any) {
    let parameters: Map<string, string> = new Map<string, string>();
    parameters.set("configName", config.name);

    this.alertService.showMessage("E_CACHE_FAILED", "error", parameters);
    this.cacheStatus.set(config.name, "ERROR");
  }

  public storeToCache(config: any, data: any) {
    this.cacheStatus.set(config.name, "CACHING");

    if (config.valueTag && config.valueTag !== "") {
      let tags: string[] = config.valueTag.split("#");

      tags.forEach(
        tag => {
          if (tag.indexOf("~") > -1) {
            let subTags: string[] = tag.split("~");

            if (subTags[1] === "number") {
              data = data[+subTags[0]];
            } else {
              data = data[subTags[0]];
            }
          } else {
            data = data[tag];
          }
        }
      )
    }

    let dbData: any = new Object();
    dbData.path = config.keyPath;
    dbData.storeType = config.storeType;
    dbData.addBodyToPath = config.addBodyToPath;
    dbData.nullifyFunctionId = config.nullifyFunctionId;
    dbData.valueTag = config.valueTag;
    dbData.data = data;

    this.uiDatabaseService.insertData(this.cacheDbName, this.getCacheDbStore, dbData, false, this.cacheSuccess.bind(this, config), this.cacheError.bind(this, config));
    this.uiDatabaseService.deleteDataByKey(this.cacheDbName, this.mappingStore, dbData.path, this.storeMapping.bind(this, dbData, this.getCacheDbStore));
  }

  public storeMapping(data: any, dbStore: string, event: any) {
    let mappingObject: any = new Object();
    mappingObject.path = data.path;
    mappingObject.store = dbStore;
    mappingObject.addBodyToPath = data.addBodyToPath || false;
    mappingObject.nullifyFunctionId = data.nullifyFunctionId || false;
    mappingObject.valueTag = data.valueTag;
    mappingObject.originalPath = data.path;
    this.uiDatabaseService.insertData(this.cacheDbName, this.mappingStore, mappingObject, false);
  }

  public cacheSuccess(config: any, event: any) {
    this.cacheStatus.set(config.name, "COMPLETED");

    if (config.successCallbackService) {
      let callbackService: any = this.injectionService.getInjectedServiceByType(config.successCallbackService);

      callbackService();
    }
  }

  public cacheError(config: any, event: any) {
    this.cacheStatus.set(config.name, "ERROR");

    if (config.errorCallbackService) {
      let callbackService: any = this.injectionService.getInjectedServiceByType(config.errorCallbackService);

      callbackService();
    }
  }

  public deleteSolrCacheData(config: any) {
    this.uiDatabaseService.deleteDataByKey(this.cacheDbName, this.getCacheDbStore, config.keyPath, this.cacheSolrData.bind(this, config), this.errorCallback.bind(this, config));
  }

  public deleteConfigCacheData(config: any) {
    this.uiDatabaseService.deleteDataByKey(this.cacheDbName, this.getCacheDbStore, config.keyPath, this.cacheConfigData.bind(this, config), this.errorCallback.bind(this, config));
  }

  public deleteTagsConfigCacheData(config: any) {
    this.uiDatabaseService.deleteDataByKey(this.cacheDbName, this.getCacheDbStore, config.keyPath, this.cacheTagsConfigData.bind(this, config), this.errorCallback.bind(this, config));
  }

  public cacheSolrData(config: any, event?: any) {
    this.cacheStatus.set(config.name, "FETCHING");
    this.httpService.callWS(
      new HttpCallModel(
        config.path,
        config.method,
        this.functionId,
        undefined)
    ).subscribe(
      (result) => {
        if (result.status === 200 && result instanceof HttpResponse) {
          this.storeToCache(config, result.body);
        } else {
          this.alertService.showErrorMessage(result);
        }
      },
      (error) => {
        this.alertService.showErrorMessage(error);
        this.cacheStatus.set(config.name, "ERROR");
      }
    );
  }

  public cacheConfigData(config: any, event?: any) {
    this.cacheStatus.set(config.name, "FETCHING");

    config.configFiles.forEach(
      (file: any) => {
        let fileConfig: any = this.utilitiesService.deepCopy(config);
        fileConfig.path = config.path + file;
        fileConfig.keyPath = config.keyPath + file;
        fileConfig.keyTag = file;

        this.httpService.callWS(
          new HttpCallModel(
            fileConfig.path,
            fileConfig.method,
            this.functionId,
            undefined)
        ).subscribe(
          (result) => {
            if (result.status === 200 && result instanceof HttpResponse) {
              this.storeToCache(fileConfig, result.body);
            } else {
              this.alertService.showErrorMessage(result);
            }
          },
          (error) => {
            this.alertService.showErrorMessage(error);
          }
        );
      }
    );
  }

  public cacheTagsConfigData(config: any, event?: any) {
    this.cacheStatus.set(config.name, "FETCHING");
    let currUser: User | undefined = this.sessionService.getUser();

    config.configFiles.forEach(
      (file: any) => {
        if (currUser === undefined) {
          return;
        }

        let fileConfig: any = this.utilitiesService.deepCopy(config);
        fileConfig.path = config.path + file;
        fileConfig.keyPath = config.keyPath + file;
        fileConfig.keyTag = file;

        let re: any = new RegExp("\\$language\\$", "g");
        fileConfig.path = fileConfig.path.replace(re, currUser.userLang);
        fileConfig.keyPath = fileConfig.keyPath.replace(re, currUser.userLang);

        re = new RegExp("\\$userId\\$", "g");
        fileConfig.path = fileConfig.path.replace(re, currUser.userId);
        fileConfig.keyPath = fileConfig.keyPath.replace(re, currUser.userId);

        re = new RegExp("\\$userName\\$", "g");
        fileConfig.path = fileConfig.path.replace(re, currUser.userName);
        fileConfig.keyPath = fileConfig.keyPath.replace(re, currUser.userName);

        re = new RegExp("\\$branchCode\\$", "g");
        fileConfig.path = fileConfig.path.replace(re, currUser.branchCode);
        fileConfig.keyPath = fileConfig.keyPath.replace(re, currUser.branchCode);

        re = new RegExp("\\$staffRestriction\\$", "g");
        fileConfig.path = fileConfig.path.replace(re, currUser.staffRestriction);
        fileConfig.keyPath = fileConfig.keyPath.replace(re, currUser.staffRestriction);

        if (fileConfig.path.indexOf("$userBranchSolr$") > -1) {
          let branchCondition: string = "(";
          currUser.entitlementsForUserModel.allowedBranchesForUser.forEach(
            (branch: any) => {
              if (branchCondition === "(") {
                branchCondition = branchCondition + fileConfig.solrBranchTag + ":" + branch;
              } else {
                branchCondition = branchCondition + " OR " + fileConfig.solrBranchTag + ":" + branch;
              }
            }
          );

          branchCondition = branchCondition + ")";
          re = new RegExp("\\$userBranchSolr\\$", "g");
          fileConfig.path = fileConfig.path.replace(re, branchCondition);
          fileConfig.keyPath = fileConfig.keyPath.replace(re, branchCondition);
        }

        this.httpService.callWS(
          new HttpCallModel(
            fileConfig.path,
            fileConfig.method,
            this.functionId,
            undefined)
        ).subscribe(
          (result) => {
            if (result.status === 200 && result instanceof HttpResponse) {
              this.storeToCache(fileConfig, result.body);
            } else {
              this.alertService.showErrorMessage(result);
            }
          },
          (error) => {
            this.alertService.showErrorMessage(error);
          }
        );
      }
    );
  }
}
