import { Injectable } from '@angular/core';
import { EFormFieldType, ESchemaItemType, ESection } from 'src/models/consts';
import { IArrayItemProperty, IConfigurableWidget, ILayout, IUIStageNodeExtended, IUiStageStructure, IWidgetProperty, IWorkflowStructure } from 'src/models/inner-models';
import { IPropertiesJSON } from '../../models/json-templates/models-jsf';
import { IBaseJSON, ISchemaFile, IToolbarFile } from '../../models/json-templates/base-json';
import { StructurePrepareHelper } from '../helpers/structure-prepare-helper';
import * as JSZip from 'jszip';
import * as FileSaver from 'file-saver';
import { DEFAULT_LAYOUT } from 'src/models/elements/layouts';
import { EWidget } from 'src/models/elements/widgets';

@Injectable({
  providedIn: 'root'
})
export class ParseToJsonsService {

  private data: IWorkflowStructure;

  private mainJSON: IBaseJSON | null;
  private propertiesJSON: IPropertiesJSON | null;
  private uiStagesSchema: ISchemaFile[] = [];
  private toolbarsJsons: IToolbarFile[] = [];

  constructor( ) { }

  parseData(data: IWorkflowStructure, withExport: boolean) {
    this.clearData();
    this.data = {
      ...data,
      name: this.removeSpaces(data.name)
    };
    this.propertiesJSON = {
      properties: {}
    };
    this.mainJSON = {
      propertyFile: `${this.data.name}-Properties.json`,
      stages: this.data.stagesTree.map((stage, index) => {
        return {
          stageSerialNumber: index + 1,
          stageId: stage.id,
          showOnlyIfStageIsCurrent: !!stage.showOnlyIfStageIsCurrent,
          uiStages: {
            edit: stage.children.edit?.map((uistage, uiStageIndex) => this.uiStagePacking(uistage, uiStageIndex)),
            view: stage.children.view?.map((uistage, uiStageIndex) => this.uiStagePacking(uistage, uiStageIndex))
          }
        }
      })
    };

    withExport && this.exportZipFiles();
  }

  get MainJson() {
    return this.mainJSON;
  }

  get PropertyFile() {
    return this.propertiesJSON;
  }

  getUIStageData(fileName: string) {
    return {
      schema: this.uiStagesSchema.find((file) => file.fileName === fileName),
      toolbar: this.toolbarsJsons.find((file) => file.fileName === fileName)
    }
  }

  clearData() {
    this.mainJSON = null;
    this.propertiesJSON = null;
    this.uiStagesSchema = [];
    this.toolbarsJsons = [];
  }

  uiStagePacking(stage: IUIStageNodeExtended, index: number) {
    const uiStageNamedId = this.getUiId(stage.id);
    const uiStage = this.findUiStageData(stage.id);

    if (uiStage) {
      this.createToolbar(uiStage, `${this.data.name}-${uiStageNamedId}-Toolbar.json`);
      this.createSchema(uiStage, `${this.data.name}-${uiStageNamedId}.json`, stage.uiStageNamedId);
    } else {
      this.uiStagesSchema.push({
        fileName: `${this.data.name}-${uiStageNamedId}.json`,
        fileContent: {
          schema: {
            type: ESchemaItemType.OBJECT,
            title: uiStageNamedId,
            properties: {},
            required: []
          },
          form: [],
          layout: []
        }
      });
    }

    return {
      uiStageSerialNumber: index + 1,
      uiStageId: uiStageNamedId,
      jsonFormSchemaFile: `${this.data.name}-${uiStageNamedId}.json`,
      toolbarJsonFile: `${this.data.name}-${uiStageNamedId}-Toolbar.json`,
      viewMultiPurposePanel: !!stage.viewMultiPuprosePanel
    }
  }

  findUiStageData(fullPath: string) {
    return this.data.uiStagesStructure.find(({path}) => fullPath === path);
  }

  createToolbar(uiStage: IUiStageStructure, fileName: string) {
    const file = this.toolbarsJsons?.find((item) => item.fileName === fileName);
    if (file) {
      return;
    }
    this.toolbarsJsons.push({
      fileName,
      fileContent: {
        toolbar: uiStage.toolbar
      }
    });
  }

  createSchema(uiStage: IUiStageStructure, fileName: string, uiStageNamedId: string) {
    const file = this.uiStagesSchema.find((item) => item.fileName === fileName);
    if (file) {
      return;
    }

    this.uiStagesSchema.push({
      fileName,
      fileContent: {
        schema: {
          type: ESchemaItemType.OBJECT,
          title: uiStageNamedId,
          properties: {},
          required: []
        },
        form: [],
        layout: this.parseUiStage(uiStage.elements)
      }
    });
    
  }

  parseUiStage(uiStageElements: Array<IConfigurableWidget | ILayout>): Array<any> {
    const layoutSection = [];
    for (let element of uiStageElements) {
      if ('isContainer' in element) {
        const layout = element as ILayout;
        layoutSection.push({
          ...DEFAULT_LAYOUT,
          items: layout.elements ? this.parseUiStage(layout.elements) : []
        })
      } else {
        const widget = element as IConfigurableWidget;
        const layoutProperties = StructurePrepareHelper.parseProperties(
          widget.properties.filter(({section, currentValue}) => section === ESection.LAYOUT && !!currentValue?.toString())
        );
        const properties = widget.properties.filter(({section}) => section === ESection.PROPERTY);
        const schemaProperties = StructurePrepareHelper.parseProperties(
          properties
        );
        this.propertiesJsonEdit(widget.properties, schemaProperties.key || widget.id, widget.ajsfName);

        layoutSection.push({
          type: widget.ajsfName,
          key: schemaProperties.key || widget.id,
          ...layoutProperties
        });
      }
    }
    return layoutSection;
  }

  propertiesJsonEdit(props: IWidgetProperty[], key: string, widgetType?: EWidget) {
    const schemaProperties = StructurePrepareHelper.parseProperties(
      props.filter(({section, currentValue}) => section === ESection.PROPERTY && !!currentValue?.toString())
    );
    delete schemaProperties.key;

    let propertyWrapper: any = {};
   
    if (this.propertiesJSON) {
      if (widgetType === EWidget.CHECKBOXES) {
        propertyWrapper['type'] = ESchemaItemType.ARRAY;
        propertyWrapper['items'] = this.propertyObjectPrepare(props, schemaProperties);
      } else {
        propertyWrapper = {
          type: ESchemaItemType.STRING,
          ...this.propertyObjectPrepare(props, schemaProperties)
        }
      }

      this.propertiesJSON.properties[key] = {
        ...propertyWrapper
      }
    }
  }

  propertyObjectPrepare(props: IWidgetProperty[], schemaProperties: any) {

    let property: any = {
      type: ESchemaItemType.STRING,
      ...schemaProperties
    };
    if (!schemaProperties.notitle) {
      property['title'] = schemaProperties.title;
      delete property['notitle'];
    } else {
      property['notitle'] = true;
      delete property['title'];
    }
    const valuesList = props.find((item) => item.formFieldType === EFormFieldType.ARRAY);
    if (valuesList) {
      const arr = (valuesList.currentValue as Array<IArrayItemProperty>); // add type checking for more difficult arrays
      const isEnum = arr.length && arr.every(({name, value}) => name === value);
      if (isEnum) {
        property['enum'] = arr.map(({value}) => value);
      }
      // else {
      //   type = ESchemaItemType.ARRAY;
      //   property['items'] = arr;
      // }
    }
    return property;
  }

  exportZipFiles() {
    var zip = new JSZip();
    zip.file(`${this.data.name}.json`, JSON.stringify(this.mainJSON));
    zip.file(`${this.data.name}-Properties.json`, JSON.stringify(this.propertiesJSON));

    [...this.toolbarsJsons, ...this.uiStagesSchema].forEach((fileData) => {
      zip.file(fileData.fileName, JSON.stringify(fileData.fileContent))
    });

    zip.generateAsync({ type: 'blob' })
      .then((content) => {
        FileSaver.saveAs(content, `${this.data.name}.zip`);
      });
  }

  removeSpaces(name: string) {
    return name.replace(/\s/g, '');
  }

  getUiId(uiId: string) {
    return uiId.split('.').reverse()[0].replace(/\s/g, '-');
  }
}
