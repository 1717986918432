import { AbstractControl } from '@angular/forms';
import { ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit, Optional, TemplateRef, ViewChild } from '@angular/core';
import { JsonSchemaFormService } from '@finfra/ajsf-core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { AjsfCoreWidgetsFunctionsService } from '../../../asjf-core-widgets.functions';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertService, DeviceService } from '@finfra/core-services';
import { DomSanitizer } from '@angular/platform-browser';
import { FileModel } from '@finfra/core-models';
import { v4 as uuidv4 } from 'uuid';
import { ImageTransform, Dimensions, ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { TranslateService } from '@finfra/core-decorators';

declare let navigator: any;
declare let Camera: any;

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'finfra-webcam-widget',
  templateUrl: './finfra-webcam.component.html',
  styleUrls: ['./finfra-webcam.component.scss'],
})
export class FinfraWebcamComponent implements OnInit, OnDestroy {
  widgetName = 'FinfraWebcamComponent';
  formControl: AbstractControl;
  controlName: string;
  controlValue: FileModel;
  controlDisabled = false;
  boundControl = false;
  options: any;
  subProperties: any;
  fullObject: any;
  autoCompleteList: string[] = [];
  @Input() layoutNode: any;
  @Input() layoutIndex: number[];
  @Input() dataIndex: number[];

  showVideo = false;
  width: number;
  height: number;
  stopVideoOnCapture = false;
  stream: any;
  streaming = false;
  context: any;
  webcamDialogRef: MatDialogRef<any>;

  selectedImage: any;
  cropperFileBase64: string;
  editImage = false;
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  transform: ImageTransform = {};
  imageExpandDialogRef: MatDialogRef<any> | undefined;

  @ViewChild('webcamTemplate', { static: true }) webcamTemplate: TemplateRef<any>;
  @ViewChild('imageExpandDialog', { static: false }) imageExpandDialog: TemplateRef<any>;

  translateLabelsName = 'finfra-webcam';
  translateLabelsLoaded = false;

  constructor(
    @Inject(MAT_FORM_FIELD_DEFAULT_OPTIONS) @Optional() public matFormFieldDefaultOptions,
    private jsf: JsonSchemaFormService,
    private ajsfCoreWidgetsFunctionsService: AjsfCoreWidgetsFunctionsService,
    private dialog: MatDialog,
    private alertService: AlertService,
    private domSanitizer: DomSanitizer,
    private deviceService: DeviceService,
    private changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService
  ) {
    this.ajsfCoreWidgetsFunctionsService.loadLabels(this);
  }

  ngOnInit() {
    this.options = this.layoutNode.options || {};
    this.subProperties = this.layoutNode.subProperties || {};
    this.jsf.initializeControl(this);
    if (!this.options.notitle && !this.options.description && this.options.placeholder) {
      this.options.description = this.options.placeholder;
    }

    this.width = this.options.width || 640;
    this.height = this.options.height || 480;
  }

  ngOnDestroy() {
    this.stopVideoOnCapture = false;
    this.stopWebcam();
    this.showVideo = false;
    this.stream = undefined;

    this.closeWebcamDialog();

    this.jsf = undefined;
    this.formControl = undefined;
    this.controlName = undefined;
    this.controlValue = undefined;
    this.controlDisabled = undefined;
    this.boundControl = undefined;
    this.options = undefined;
    this.subProperties = undefined;

    if (this.imageExpandDialogRef) {
      this.imageExpandDialogRef.close();
    }
  }

  updateValue() {
    this.fullObject = this.controlValue;
    this.jsf.updateValue(this, this.controlValue);
    this.ajsfCoreWidgetsFunctionsService.dispatchEvent(this);
    this.changeDetectorRef.markForCheck();
  }

  showWebcam() {
    if (navigator.camera) {
      // This means its android
      const options: any = {
        // Some common settings are 20, 50, and 100
        quality: 100,
        destinationType: Camera.DestinationType.FILE_URI,
        // In this app, dynamically set the picture source, Camera or photo gallery
        sourceType: Camera.PictureSourceType.CAMERA,
        encodingType: Camera.EncodingType.PNG,
        mediaType: Camera.MediaType.PICTURE,
        allowEdit: true, // set true if edit allowed
        // targetHeight: 200,
        // targetWidth: 200,
        correctOrientation: true  // Corrects Android orientation quirks
      };

      navigator.camera.getPicture(this.cameraSuccess.bind(this), this.cameraError.bind(this), options);
    } else {
      // This means its browser
      this.webcamDialogRef = this.dialog.open(this.webcamTemplate, {});
      this.webcamDialogRef.afterOpened().subscribe(result => {
        this.init();
      });
    }
  }

  closeWebcamDialog() {
    if (this.webcamDialogRef) {
      this.webcamDialogRef.close();
    }

    this.webcamDialogRef = undefined;
  }

  init() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      this.showVideo = true;
      this.streaming = false;

      const constraints: any = {
        audio: false,
        video: {
          width: this.width,
          height: this.height
        }
      };

      navigator.mediaDevices.getUserMedia(constraints).then(stream => {
        this.stream = stream;
        const webcamVideo: any = document.getElementById('webcamVideo' + this.controlName + this.layoutNode?._id);

        if (
          webcamVideo === undefined ||
          webcamVideo === null
        ) {
          this.stopVideo();
          return false;
        }

        webcamVideo.srcObject = this.stream;
        webcamVideo.play();

        webcamVideo.addEventListener('canplay', this.initVideo.bind(this), false);
      });
    }
  }

  public capture() {
    const webcamVideo: any = document.getElementById('webcamVideo' + this.controlName + this.layoutNode?._id);
    const webcamPicture: any = document.getElementById('webcamPicture' + this.controlName + this.layoutNode?._id);

    if (!webcamVideo || !webcamPicture) {
      return;
    }

    webcamPicture.width = this.width;
    webcamPicture.height = this.height;

    this.context = webcamPicture.getContext('2d').drawImage(webcamVideo, 0, 0, this.width, this.height);

    this.controlValue = new FileModel();
    this.controlValue.fileName = uuidv4();
    this.controlValue.fileType = 'image/png';
    this.controlValue.fileExtension = '.png';
    this.controlValue.fileUrl = this.controlValue.fileName + '.png';
    this.controlValue.fileLastModified = (new Date()).getTime();
    this.controlValue.fileBase64 = webcamPicture.toDataURL('image/png');
    this.controlValue.fileSize = null;

    if (this.stopVideoOnCapture) {
      this.stopVideo();
      this.showVideo = false;
    }

    this.updateValue();
    this.stopWebcam();
  }

  public stopWebcam() {
    const stream: any = document.getElementById('webcamVideo' + this.controlName + this.layoutNode?._id);

    if (stream === undefined || stream === null) {
      return false;
    }

    try {
      const tracks = stream.srcObject.getTracks();

      if (tracks === undefined || tracks === null) {
        return false;
      }

      for (let i = 0; i < tracks.length; i++) {
        const track = tracks[i];
        track.stop();
      }

      stream.srcObject = null;
    } catch (e) { }

    this.closeWebcamDialog();
  }

  public stopVideo() {
    if (this.stream === undefined || this.stream === null) {
      return false;
    }

    const tracks: any = this.stream.getTracks();

    for (let i = 0; i < tracks.length; i++) {
      const track: any = tracks[i];
      track.stop();
    }

    const webcamVideo: any = document.getElementById('webcamVideo' + this.controlName + this.layoutNode?._id);
    if (webcamVideo !== undefined && webcamVideo !== null) {
      webcamVideo.src = undefined;
    }
  }

  public initVideo(event) {
    if (!this.streaming) {
      const webcamVideo: any = document.getElementById('webcamVideo' + this.controlName + this.layoutNode?._id);
      this.height =
        webcamVideo.videoHeight /
        (webcamVideo.videoWidth / this.width);

      webcamVideo.setAttribute('width', this.width);
      webcamVideo.setAttribute('height', this.height);
      webcamVideo.setAttribute('width', this.width);
      webcamVideo.setAttribute('height', this.height);
      this.streaming = true;
    }
  }

  onImageClick(event) {
    this.alertService.info('FinfraImageComponent: on select change ' + this.controlValue);
    this.alertService.info(event);
    this.expandImage();
    this.ajsfCoreWidgetsFunctionsService.dispatchEvent(this);
  }

  getImageObject(image: any) {
    if (!image) {
      return undefined;
    }

    if (image.fileBase64.indexOf('data:') < 0 && image.fileBase64.indexOf('file:') < 0 && image.fileBase64.indexOf('content:') < 0) {
      let fileType = '';

      if (image.fileType) {
        fileType = image.fileType;
      } else {
        fileType = 'image/png';
      }

      image.fileBase64 = 'data:' + fileType + ';base64,' + image.fileBase64;
    }

    return this.domSanitizer.bypassSecurityTrustResourceUrl(image.fileBase64);
  }

  expandImage() {
    this.selectedImage = JSON.parse(JSON.stringify(this.formControl.value));
    this.cropperFileBase64 = this.selectedImage.fileBase64;
    this.editImage = false;
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
    this.imageExpandDialogRef = this.dialog.open(this.imageExpandDialog, {
      height: '90vh',
      width: '90vw'
    });

    this.imageExpandDialogRef.afterClosed().subscribe(
      v => {
        this.selectedImage = undefined;
        this.cropperFileBase64 = undefined;
        this.editImage = false;
      }
    );
  }

  public cameraSuccess(imageUri: string) {
    this.controlValue = new FileModel();
    this.controlValue.fileUrl = imageUri;
    this.updateValue();

    this.deviceService.getDiskFileAsBase64(imageUri, this.gotFile.bind(this));
  }

  public gotFile(fileObject: any) {
    this.controlValue.fileBase64 = fileObject.base64;
    // We need to write code here to assign the other variables coming from cordova
    this.updateValue();
  }

  public cameraError(message: any) {
    this.alertService.error(message);
  }

  // Image cropper functions
  public imageLoaded(event: LoadedImage) {
    this.alertService.debug('Image loaded');
  }

  public cropperReady(sourceImageDimensions: Dimensions) {
    this.alertService.debug('Cropper ready');
  }

  public imageCropped(event: ImageCroppedEvent) {
    this.selectedImage.fileBase64 = event.base64;
  }

  public unlockImage() {
    this.editImage = true;
  }

  public lockImage() {
    this.editImage = false;
  }

  public resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
    this.selectedImage = JSON.parse(JSON.stringify(this.formControl.value));
    this.cropperFileBase64 = this.selectedImage.fileBase64;
    this.editImage = false;
  }

  public saveImage() {
    this.controlValue = this.selectedImage;
    this.updateValue();
    this.imageExpandDialogRef.close();
  }

  public zoomInImage() {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  public zoomOutImage() {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  public rotateLeftImage() {
    this.canvasRotation--;
    this.flipAfterRotateImage();
  }

  public rotateRightImage() {
    this.canvasRotation++;
    this.flipAfterRotateImage();
  }

  public flipAfterRotateImage() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }

  public flipHorizontalImage() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH
    };
  }

  public flipVerticalImage() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV
    };
  }
}
