import {Injectable} from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import {Router} from '@angular/router';
import {finalize} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';

import {ParameterModel, FinRequest, HeaderModel, HttpCallModel, SelectItemModel} from '@finfra/core-models';

import {AlertService} from './alert.service';
import {UtilitiesService} from './utilities.service';
import {SessionService} from './session.service';
import {SpinnerService} from './spinner.service';
import {DateService} from './date.service';
import {ConvertObjectService} from './convert-object.service';
import {NavigationService} from './navigation.service';
import {OfflineStatusService} from '../offline-services/offline-status.service';
import {OfflineHttpCacheService} from '../offline-services/offline-http-cache.service';
import {ProcessOfflineService} from '../offline-services/process-offline.service';
import {DeviceService} from '../device-services/device.service';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  public deviceId: string | undefined;

  private spinnerShown = false;
  private showLoader = true;
  private urls: string[] = [];

  public showTimeoutAlert = false;
  private logoutInterval: any;
  private sessionTimeoutAlertInterval: any;
  public sessionTimeoutAlertTime: number | undefined;
  public logoutTime: number | undefined;
  private sessionTimeoutAlertWindow: any;
  public sessionPingUrl: string | undefined;
  public logoutFunction: any;
  public sessionTimeoutAlertWindowUrl: string | undefined;
  public sessionTimeoutAlertWindowUrlParams: string | undefined;

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private sessionService: SessionService,
    private alertService: AlertService,
    private utilitiesService: UtilitiesService,
    private spinnerService: SpinnerService,
    private dateService: DateService,
    private convertObjectService: ConvertObjectService,
    private navigationService: NavigationService,
    private offlineStatusService: OfflineStatusService,
    private offlineHttpCacheService: OfflineHttpCacheService,
    private processOfflineService: ProcessOfflineService,
    private deviceService: DeviceService
  ) {

  }

  public setLogout(): void {
    const currUser = this.sessionService.getUser();

    if (currUser !== undefined && currUser !== null && this.showTimeoutAlert) {
      if (this.logoutInterval !== undefined && this.logoutInterval !== null) {
        clearInterval(this.logoutInterval);
        this.logoutInterval = undefined;
      }

      this.logoutInterval = setInterval(this.onLogout.bind(this), this.logoutTime);
    }
  }

  public onLogout(): void {
    if (this.logoutFunction !== undefined && this.logoutFunction !== null) {
      this.logoutFunction();
    }

    clearInterval(this.logoutInterval);
    this.logoutInterval = undefined;

    if (this.sessionTimeoutAlertInterval !== undefined && this.sessionTimeoutAlertInterval !== null) {
      clearInterval(this.sessionTimeoutAlertInterval);
      this.sessionTimeoutAlertInterval = undefined;
    }

    if (this.sessionTimeoutAlertWindow !== undefined && this.sessionTimeoutAlertWindow !== null) {
      this.sessionTimeoutAlertWindow.close();
      this.sessionTimeoutAlertWindow = undefined;
    }
  }

  public setSessionTimeoutAlert(): void {
    const currUser = this.sessionService.getUser();

    if (currUser !== undefined && currUser !== null && this.showTimeoutAlert) {
      if (this.sessionTimeoutAlertInterval !== undefined && this.sessionTimeoutAlertInterval !== null) {
        clearInterval(this.sessionTimeoutAlertInterval);
        this.sessionTimeoutAlertInterval = undefined;
      }

      this.sessionTimeoutAlertInterval = setInterval(this.onSessionTimeoutAlert.bind(this), this.sessionTimeoutAlertTime);
      this.setLogout();
    }
  }

  public onSessionTimeoutAlert(): void {
    clearInterval(this.sessionTimeoutAlertInterval);
    this.sessionTimeoutAlertInterval = undefined;

    if (this.sessionTimeoutAlertWindow !== undefined && this.sessionTimeoutAlertWindow !== null) {
      this.sessionTimeoutAlertWindow.close();
      this.sessionTimeoutAlertWindow = undefined;
    }

    this.sessionTimeoutAlertWindow = window.open(this.sessionTimeoutAlertWindowUrl, '_blank', this.sessionTimeoutAlertWindowUrlParams);
    this.sessionTimeoutAlertWindow.window.onload = this.sessionTimeoutAlertLoad.bind(this);
  }

  public sessionTimeoutAlertLoad(): void {
    this.sessionTimeoutAlertWindow.setSessionPing(this.setSessionPing.bind(this));
  }

  public async setSessionPing(): Promise<boolean> {
    if (this.sessionPingUrl === undefined || this.sessionPingUrl === null) {
      return false;
    }

    this.sessionTimeoutAlertWindow.close();
    this.sessionTimeoutAlertWindow = undefined;

    const response = await this.callWS(
      new HttpCallModel(
        this.sessionPingUrl,
        'POST',
        'finfra-core',
        undefined)
    ).toPromise();

    if (response instanceof HttpErrorResponse) {
      this.alertService.showErrorMessage(response);
    }

    return true;
  }

  public setShowLoader(show: boolean): void {
    this.showLoader = show;
  }

  public formatParameters(parameterModel: ParameterModel): any {
    if (parameterModel.stringParameters) {
      parameterModel.stringParameters = this.utilitiesService.stringMapToObj(
        parameterModel.stringParameters
      );
    }

    if (parameterModel.longParameters) {
      parameterModel.longParameters = this.utilitiesService.longMapToObj(
        parameterModel.longParameters
      );
    }

    if (parameterModel.dateParameters) {
      parameterModel.dateParameters = this.utilitiesService.dateMapToObj(
        parameterModel.dateParameters
      );
    }

    if (parameterModel.booleanParameters) {
      parameterModel.booleanParameters = this.utilitiesService.booleanMapToObj(
        parameterModel.booleanParameters
      );
    }

    return parameterModel;
  }

  public sendHttpError(url: string, errorCode: string | undefined): Observable<HttpErrorResponse> {
    return new Observable<HttpErrorResponse>(
      observer => {
        if (errorCode === undefined) {
          errorCode = 'E_NO_ERROR';
        }

        const errorObject: any = new Object();
        errorObject.message = errorCode;

        const errorHeader: HttpHeaders = new HttpHeaders();
        errorHeader.set('status', '3003');
        errorHeader.set('message', errorCode);
        errorHeader.set('url', url);

        const error: HttpErrorResponse = new HttpErrorResponse({
          error: errorObject,
          headers: errorHeader,
          status: 3020,
          statusText: 'Webservice call error',
          url
        });

        observer.error(error);
        observer.complete();

        return {
          unsubscribe() {
          }
        };
      }
    );
  }

  public callWS(
    httpCallModel: HttpCallModel
  ): Observable<HttpResponse<any> | HttpErrorResponse> {
    if (httpCallModel.url.indexOf('file:') === -1) {
      this.navigationService.fetchLocation();
    }

    if (httpCallModel.backgroundProcess === undefined || httpCallModel.backgroundProcess === null) {
      httpCallModel.backgroundProcess = false;
    }

    let body: FinRequest = new FinRequest();
    body.finData = httpCallModel.requestBody;

    this.showSpinner(httpCallModel.url, httpCallModel.functionId, httpCallModel.backgroundProcess);

    const currUser = this.sessionService.getUser();

    let header = new HttpHeaders();

    if (httpCallModel.fetchFile === undefined) {
      httpCallModel.fetchFile = false;
    }

    if (httpCallModel.fileUpload === undefined) {
      httpCallModel.fileUpload = false;
    }

    if (!httpCallModel.fileUpload) {
      header = header.append('Content-Type', 'application/json');
    }

    if (!httpCallModel.fetchFile && !httpCallModel.fileUpload) {
      header = header.append('Accept', 'application/json');
    }

    header = header.append('Access-Control-Allow-Origin', '*');
    header = header.append(
      'Access-Control-Allow-Methods',
      'GET, POST, OPTIONS, DELETE'
    );
    header = header.append(
      'Access-Control-Allow-Headers',
      'Accept,Accept-Language,Content-Language,Content-Type'
    );
    header = header.append(
      'Access-Control-Expose-Headers',
      'Content-Length,Content-Range'
    );

    if (
      this.sessionService.getAuthToken() !== undefined &&
      this.sessionService.getAuthToken() !== null
    ) {
      header = header.append(
        'X-Auth-Token',
        this.sessionService.getAuthToken()
      );
    } else if (!this.offlineStatusService.offlineStatus && currUser !== undefined) {
      this.router.navigate(['web/auth/login']);
      return this.sendHttpError(httpCallModel.url, 'E_INVALID_SESSION');
    }

    if (
      this.sessionService.getSessionData('tenantId') !== undefined &&
      this.sessionService.getSessionData('tenantId') !== null
    ) {
      header = header.append(
        'Tenant-Id',
        this.sessionService.getSessionData('tenantId')
      );
    }
    const reqHeader: HeaderModel = new HeaderModel();

    reqHeader.versionNo = this.sessionService.getSessionData('version');
    reqHeader.userAgent = this.sessionService.getSessionData('userAgent');
    reqHeader.functionId = httpCallModel.functionId;
    reqHeader.tenantId = this.sessionService.getSessionData('tenantId');

    if (httpCallModel.applicationId) {
      reqHeader.applicationId = httpCallModel.applicationId;
    }

    if (currUser !== undefined) {
      reqHeader.userId = currUser.userId;
      reqHeader.sourceSystemUserId = currUser.userName;

      if (currUser.currentLegalEntityId !== undefined) {
        reqHeader.legalEntityId = currUser.currentLegalEntityId;
        reqHeader.legalEntityCode = currUser.currentLegalEntityCode;
      } else {
        reqHeader.legalEntityId = currUser.legalEntityId;
        reqHeader.legalEntityCode = currUser.legalEntityCode;
      }

      reqHeader.branchCode = currUser.currentBranchCode;
      reqHeader.language = currUser.userLang;
    }

    if (httpCallModel.legalEntityId) {
      reqHeader.legalEntityId = httpCallModel.legalEntityId;
    }

    if (
      this.navigationService.currentLocation !== undefined &&
      this.navigationService.currentLocation !== null &&
      this.navigationService.currentLocation.coords !== undefined &&
      this.navigationService.currentLocation.coords !== null
    ) {
      reqHeader.location =
        this.navigationService.currentLocation.coords.latitude +
        ',' +
        this.navigationService.currentLocation.coords.longitude;
    }

    if (this.deviceId) {
      reqHeader.deviceId = this.deviceId;
    }

    if (reqHeader.offlineRequest === undefined || reqHeader.offlineRequest === null) {
      reqHeader.offlineRequest = false;
    }

    if (httpCallModel.additionalHttpHeaders) {
      httpCallModel.additionalHttpHeaders.forEach((obj: any) => {
        if (obj.name && obj.value) {
          header = header.append(obj.name, obj.value);
        }
      });
    }

    if (httpCallModel.additionalFinfraHeaders) {
      reqHeader.additionalHeaders = {};

      httpCallModel.additionalFinfraHeaders.forEach(
        (finfraHeader: SelectItemModel) => {
          if (finfraHeader.name !== undefined && finfraHeader.name !== null && finfraHeader.value !== undefined && finfraHeader.value !== null) {
            if (!reqHeader.additionalHeaders) {
              reqHeader.additionalHeaders = {};
            }
            reqHeader.additionalHeaders[finfraHeader.name] = finfraHeader.value;
          }
        }
      );
    }

    reqHeader.applicationDate = this.dateService.getTodayDate();

    body.headerModel = reqHeader;

    if (httpCallModel.queryParameters) {
      body.parameterModel = this.formatParameters(httpCallModel.queryParameters);
    }

    body.pageModel = httpCallModel.pageModel;
    body.workflowModel = httpCallModel.workflowModel;

    if (body && body !== null && body !== '') {
      this.convertObjectService.convertDates(body);
    }
    // This is done after the date conversion as the date and time should go in the request
    body.headerModel.transactionDateTime = this.dateService.formatDateTimeServer(new Date());
    // for file upload
    if (httpCallModel.fileUpload && httpCallModel.fileList && httpCallModel.fileList.length > 0) {
      const formData = new FormData();
      for (const file of httpCallModel.fileList) {
        formData.append('files', file, file.name);
      }
      formData.append('finRequest', JSON.stringify(body));
      body = (formData as any);
    }

    let httpOptions: any;

    if (httpCallModel.fetchFile === undefined || httpCallModel.fetchFile === false) {
      httpOptions = {
        headers: header,
        observe: 'response',
        body,
        withCredentials: true,
      };
    } else {
      httpOptions = {
        headers: header,
        observe: 'response',
        body,
        withCredentials: true,
        responseType: httpCallModel.responseType || 'text',
      };
    }

    if (!this.offlineStatusService.offlineStatus) {
      this.setSessionTimeoutAlert();
      switch (httpCallModel.method) {
        case 'POST':
          return this.doPost(httpCallModel.url, httpOptions, body, httpCallModel.backgroundProcess);
        case 'GET':
          return this.doGet(httpCallModel.url, httpOptions, httpCallModel.backgroundProcess);
        case 'PUT':
          return this.doPut(httpCallModel.url, httpOptions, body, httpCallModel.backgroundProcess);
        case 'PATCH':
          return this.doPatch(httpCallModel.url, httpOptions, body, httpCallModel.backgroundProcess);
        case 'DELETE':
          return this.doDelete(httpCallModel.url, httpOptions, body, httpCallModel.backgroundProcess);
        default:
          return this.sendHttpError(httpCallModel.url, 'E_INVALID_METHOD');
      }
    } else {
      this.hideSpinner(httpCallModel.url, httpCallModel.backgroundProcess);
      return this.processOfflineService.callWs(httpCallModel.functionId, httpCallModel.method, httpCallModel.url, httpOptions, body);
    }
  }

  private doGet(url: string, httpOptions: any, backgroundProcess: boolean): Observable<any> {
    return this.httpClient.get<any>(url, httpOptions).pipe(
      catchError(this.handleError.bind(this, url, 'GET', undefined)),
      tap((data) => this.handleSuccess(url, 'GET', undefined, data)),
      finalize(() => {
        this.hideSpinner(url, backgroundProcess);
      })
    );
  }

  private doPost(url: string, httpOptions: any, body: any, backgroundProcess: boolean): Observable<any> {
    if (body === null || body === '') {
      throw new HttpErrorResponse({
        statusText: 'Required parameter body was null or undefined!',
      });
    }

    return this.httpClient.post<any>(url, body, httpOptions).pipe(
      catchError(this.handleError.bind(this, url, 'POST', body)),
      tap((data) => this.handleSuccess(url, 'POST', body, data)),
      finalize(() => {
        this.hideSpinner(url, backgroundProcess);
      })
    );
  }

  private doPut(url: string, httpOptions: any, body: any, backgroundProcess: boolean): Observable<any> {
    if (body === null || body === '') {
      throw new HttpErrorResponse({
        statusText: 'Required parameter body was null or undefined!',
      });
    }

    return this.httpClient.put<any>(url, body, httpOptions).pipe(
      catchError(this.handleError.bind(this, url, 'PUT', body)),
      tap((data) => this.handleSuccess(url, 'PUT', body, data)),
      finalize(() => {
        this.hideSpinner(url, backgroundProcess);
      })
    );
  }

  private doPatch(url: string, httpOptions: any, body: any, backgroundProcess: boolean): Observable<any> {
    if (body === null || body === '') {
      throw new HttpErrorResponse({
        statusText: 'Required parameter body was null or undefined!',
      });
    }

    return this.httpClient.patch<any>(url, body, httpOptions).pipe(
      catchError(this.handleError.bind(this, url, 'PATCH', body)),
      tap((data) => this.handleSuccess(url, 'PATCH', body, data)),
      finalize(() => {
        this.hideSpinner(url, backgroundProcess);
      })
    );
  }

  private doDelete(url: string, httpOptions: any, body: any, backgroundProcess: boolean): Observable<any> {
    if (body === null || body === '') {
      return this.doDeleteWithoutBody(url, httpOptions, backgroundProcess);
    } else {
      return this.doDeleteWithBody(url, httpOptions, backgroundProcess);
    }
  }

  private doDeleteWithoutBody(url: string, httpOptions: any, backgroundProcess: boolean): Observable<any> {
    return this.httpClient.delete<any>(url, httpOptions).pipe(
      catchError(this.handleError.bind(this, url, 'DELETE', undefined)),
      tap((data) => this.handleSuccess(url, 'DELETE', undefined, data)),
      finalize(() => {
        this.hideSpinner(url, backgroundProcess);
      })
    );
  }

  private doDeleteWithBody(url: string, httpOptions: any, backgroundProcess: boolean): Observable<any> {
    return this.httpClient.request<any>('DELETE', url, httpOptions).pipe(
      catchError(this.handleError.bind(this, url, 'DELETE', undefined)),
      tap((data) => this.handleSuccess(url, 'DELETE', undefined, data)),
      finalize(() => {
        this.hideSpinner(url, backgroundProcess);
      })
    );
  }

  private handleSuccess(path: string, method: string, requestData: any, responseData: any): void {
    this.offlineStatusService.setStatus('online');
    this.offlineHttpCacheService.cacheHttpData(path, method, requestData, responseData);

    if (responseData instanceof HttpResponse) {
      let errCode = '';

      if (responseData.body.code !== null) {
        errCode = responseData.body.code;
      } else if (responseData.body.errorCode !== null) {
        errCode = responseData.body.errorCode;
      }

      this.convertObjectService.convertStringToDates(responseData);

      if (errCode === '403') {
        // this.alertService.http('in timeout');
        const sessionId = this.alertService.getMessage('401');
        const navigationExtras = {
          queryParams: {message: sessionId},
        };

        this.sessionService.clearAllSessionData();
        this.router.navigate(['web/auth/login'], navigationExtras);
        throw new HttpErrorResponse({
          error: {message: this.alertService.getMessage('403'), status: 403},
        });
      } else if (errCode === '204') {
        throw new HttpErrorResponse({
          error: {message: this.alertService.getMessage('204'), status: 204},
        });
      } else if (errCode === '208') {
        throw new HttpErrorResponse({
          error: {
            message:
              this.alertService.getMessage('208') +
              responseData.body.data[0].responseCode +
              '!',
            status: 208,
          },
        });
      } else if (errCode === '400') {
        throw new HttpErrorResponse({
          error: {message: this.alertService.getMessage('400'), status: 400},
        });
      } else if (errCode === '500') {
        throw new HttpErrorResponse({
          error: {message: this.alertService.getMessage('500'), status: 500},
        });
      }
    }
  }

  private handleError(path: string, method: string, requestData: any, error: any): Observable<never> {
    this.offlineStatusService.setStatus('online');
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      // console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      // console.error(
      //     `Server returned code ${error.status}, ` +
      //     `body was: ${error.error}`);

      if (error.status === 403) {
        this.sessionService.clearAllSessionData();
        this.router.navigate(['web/auth/login']);
        error.message = 'E_403';
      } else if (error.status === 204) {
        error.message = 'E_204';
      } else if (error.status === 0) {
        error.message = 'E_0';

        if (path.indexOf('file:') === -1) {
          this.offlineStatusService.setStatus('offline');
        }
      } else if (error.status === 400) {
        error.message = 'E_400';
      } else if (error.status === 500) {
        error.message = 'E_500';
      } else if (error.status === 404) {
        error.message = 'E_404';
      } else if (error.status === 302) {
        error.message = 'E_302';
      }
    }

    // this.alert.http('HttpService Error completed');*/
    return throwError(error);
  }

  private showSpinner(url: string, functionId: string, backgroundProcess: boolean): void {
    if (backgroundProcess) {
      this.spinnerService.incrementBackgroundSpinnerCount();
    } else {
      if (this.showLoader === true && this.spinnerShown === false) {
        this.spinnerShown = true;
        this.spinnerService.incrementSpinnerCount();
        this.urls.push(url);
      } else if (this.showLoader === true) {
        this.spinnerService.incrementSpinnerCount();
        this.urls.push(url);
      } else if (this.showLoader === false) {
        this.showLoader = true;
      }
    }
  }

  private hideSpinner(url: string, backgroundProcess: boolean): boolean {
    if (backgroundProcess) {
      this.spinnerService.decrementBackgroundSpinnerCount();
      return true;
    }

    if (this.spinnerShown && this.showLoader === true) {
      if (this.urls.indexOf(url) !== -1) {
        this.spinnerService.decrementSpinnerCount();
        this.urls.filter((ur: string) => ur !== url);
      }

      if (this.spinnerService.getSpinnerCount() <= 0) {
        this.spinnerShown = false;
      }
    }

    return true;
  }

  public offlineSyncCall(url: string, method: string, httpOptions: any, body: any, backgroundProcess: boolean): Observable<any> {
    if (backgroundProcess === undefined || backgroundProcess === null) {
      backgroundProcess = false;
    }

    switch (method) {
      case 'POST':
        return this.doPost(url, httpOptions, body, backgroundProcess);
      case 'GET':
        return this.doGet(url, httpOptions, backgroundProcess);
      case 'PUT':
        return this.doPut(url, httpOptions, body, backgroundProcess);
      case 'PATCH':
        return this.doPatch(url, httpOptions, body, backgroundProcess);
      case 'DELETE':
        return this.doDelete(url, httpOptions, body, backgroundProcess);
      default:
        return this.sendHttpError(url, 'E_INVALID_METHOD');
    }
  }

  public setDeviceId(): void {
    this.deviceService.getDeviceId(this.onDeviceIdResponse.bind(this));
  }

  public onDeviceIdResponse(responseEvent: any): void {
    if (responseEvent && responseEvent !== null) {
      this.alertService.debug('Got device id response :' + responseEvent.deviceId);
      this.deviceId = responseEvent.deviceId;
    }
  }
}
