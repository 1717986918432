import { HeaderModel } from './Header.model';
import { WorkflowModel } from './Workflow.model';
import { FileModel } from './File.model';
import { PageModel } from './Page.model';
import { ParameterModel } from './Parameter.model';

export interface FinRequest {
  headerModel?: HeaderModel;
  pageModel?: PageModel;
  workflowModel?: WorkflowModel;
  parameterModel?: ParameterModel;
  filesModel?: FileModel[];
  finData?: Object;
}

export class FinRequest implements FinRequest {
  constructor(
    public headerModel?: HeaderModel,
    public pageModel?: PageModel,
    public workflowModel?: WorkflowModel,
    public parameterModel?: ParameterModel,
    public filesModel?: FileModel[],
    public finData?: Object,
  ) { }
}
