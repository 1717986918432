import { AbstractControl } from '@angular/forms';

export interface GenericEventModel {
  eventSource?: string;
  eventName?: string;
  eventObjectName?: string;
  eventObject?: any;
  eventType?: string;
  value?: any;
  jsonSchema?: any;
  formControl?: AbstractControl;
  formName?: string;
  arrayIndex?: number;
}

export class GenericEventModel implements GenericEventModel {
  constructor(
    public eventSource?: string,
    public eventName?: string,
    public eventObjectName?: string,
    public eventObject?: any,
    public eventType?: string,
    public value?: any,
    public jsonSchema?: any,
    public formControl?: AbstractControl,
    public formName?: string,
    public arrayIndex?: number,
  ) { }
}
