import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { SessionService, ConfigService } from '@finfra/core-services';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        private sessionService: SessionService,
        private configService: ConfigService,
        private router: Router
    ) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this.sessionService.isUserSet()) {
            return true;
        }

        this.router.navigate([this.configService.getConfig("web-end-points").noAccessPage]);
        return false;
    }

}
