import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { SessionService } from './session.service';
import { ConfigService } from './config.service';
import { UIDatabaseService } from '../offline-services/uidatabase.service';
import { OfflineStatusService } from '../offline-services/offline-status.service';
import { StorageService } from './storage.service';
import { AlertService } from './alert.service';
import { HttpCallModel, User } from '@finfra/core-models';
import { OpenUIDatabaseService } from '../offline-services/open-ui-database.service';
import { OfflineHttpCacheService } from '../offline-services/offline-http-cache.service';
import { HttpResponse } from '@angular/common/http';
import { CurrencyService } from './currency.service';



@Injectable({
  providedIn: 'root'
})
export class ConfigLoaderService {
  public configsToLoadJson: any;
  public userSpecificConfigsLoaded = false;

  constructor(
    private httpService: HttpService,
    private sessionService: SessionService,
    private configService: ConfigService,
    private uiDatabaseService: UIDatabaseService,
    private offlineStatusService: OfflineStatusService,
    private storageService: StorageService,
    private alertService: AlertService,
    private openUIDatabaseService: OpenUIDatabaseService,
    private offlineHttpCacheService: OfflineHttpCacheService,
    private currencyService: CurrencyService
  ) { }

  public invalidateUser(): void {
    this.userSpecificConfigsLoaded = false;
  }

  public initGlobalConfig(data: any): void {
    const currUser: User | undefined = this.sessionService.getUser();

    if (currUser !== undefined && currUser !== null) {
      if (currUser.offlineEnabled !== undefined && currUser.offlineEnabled !== null) {
        data.offlineEnabled = currUser.offlineEnabled;
      }
    }

    this.configService.setConfig('global', data);
    this.configService.setConfig('original-global', data);
  }

  public async loadConfigs(): Promise<boolean> {
    this.httpService.setDeviceId();
    this.uiDatabaseService.init();
    this.sessionService.setSessionData('version', this.configService.getConfig('global').version);
    this.offlineStatusService.offlineSupported = this.configService.getConfig('global').offlineSupported;
    this.offlineStatusService.autoOnline = this.configService.getConfig('global').autoOnline;
    this.offlineStatusService.autoOffline = this.configService.getConfig('global').autoOffline;

    this.storageService.init();

    this.alertService.setConsoleLogLevel();

    this.sessionService.setSessionData('userAgent', this.configService.getConfig('global').userAgent);

    this.sessionService.setSessionData('tenantId', this.configService.getConfig('global').tenantId);
    const globalConfig = this.configService.getConfig('global');

    this.configsToLoadJson = await this.httpService.callWS(
      new HttpCallModel(
        this.configService.getConfig('global').globalAssetsPath + 'configs/configsToLoad.json',
        'GET',
        'finfra-core',
        undefined)
    ).toPromise().catch(
      error => {
        this.alertService.showErrorMessage(error);
        return undefined;
      }
    );

    this.configsToLoadJson = this.configsToLoadJson.body;

    const executionArray: Promise<any>[] = [];

    if (globalConfig.offlineSupported) {
      executionArray.push(this.loadOfflineConfigs());
    }

    this.httpService.showTimeoutAlert = globalConfig.showTimeoutAlert;
    this.httpService.sessionTimeoutAlertTime = globalConfig.sessionTimeoutAlertTime;
    this.httpService.logoutTime = globalConfig.logoutTime;
    this.httpService.sessionPingUrl = globalConfig.sessionPingUrl;
    this.httpService.sessionTimeoutAlertWindowUrl = globalConfig.sessionTimeoutAlertWindowUrl;
    this.httpService.sessionTimeoutAlertWindowUrlParams = globalConfig.sessionTimeoutAlertWindowUrlParams;

    executionArray.push(this.loadConfigsWs());
    executionArray.push(this.loadI18nConfigsWs());
    const responses = await Promise.all(executionArray);
    return true;
  }

  public async loadOfflineConfigs(): Promise<boolean> {
    const offlineFunctions = await this.httpService.callWS(
      new HttpCallModel(
        this.configService.getConfig('global').globalAssetsPath + 'configs/offline-config/offline-functions.json',
        'GET',
        'finfra-core',
        undefined)
    ).toPromise().catch(
      error => {
        this.alertService.showErrorMessage(error);
        return undefined;
      }
    );

    if (offlineFunctions && offlineFunctions.status === 200 && offlineFunctions instanceof HttpResponse) {
      this.configService.setConfig('offline-functions', offlineFunctions.body);
    }

    const offlineCacheKeys = await this.httpService.callWS(
      new HttpCallModel(
        this.configService.getConfig('global').globalAssetsPath + 'configs/offline-config/cache-keys-config.json',
        'GET',
        'finfra-core',
        undefined)
    ).toPromise().catch(
      error => {
        this.alertService.showErrorMessage(error);
        return undefined;
      }
    );

    if (offlineCacheKeys && offlineCacheKeys.status === 200 && offlineCacheKeys instanceof HttpResponse) {
      this.configService.setConfig('cache-keys', offlineCacheKeys.body);
    }

    const offlineGetCacheConfig = await this.httpService.callWS(
      new HttpCallModel(
        this.configService.getConfig('global').globalAssetsPath + 'configs/offline-config/cache-get-config.json',
        'GET',
        'finfra-core',
        undefined)
    ).toPromise().catch(
        error => {
          this.alertService.showErrorMessage(error);
          return undefined;
        }
      );

    if (offlineGetCacheConfig && offlineGetCacheConfig.status === 200 && offlineGetCacheConfig instanceof HttpResponse) {
      this.configService.setConfig('offline-get-cache', offlineGetCacheConfig.body);
    }

    const offlinePostCacheConfig = await this.httpService.callWS(
      new HttpCallModel(
        this.configService.getConfig('global').globalAssetsPath + 'configs/offline-config/cache-post-config.json',
        'GET',
        'finfra-core',
        undefined)
    ).toPromise().catch(
        error => {
          this.alertService.showErrorMessage(error);
          return undefined;
        }
      );


    if (offlinePostCacheConfig && offlinePostCacheConfig.status === 200 && offlinePostCacheConfig instanceof HttpResponse) {
      this.configService.setConfig('offline-post-cache', offlinePostCacheConfig.body);
      offlinePostCacheConfig.body.forEach((config: any) => {
        this.offlineHttpCacheService.cacheHttpConfig.set(config.path, config);
      });
    }

    this.openUIDatabaseService.openAllDatabases();
    const message = await new Promise((resolve, reject) => {
      this.openUIDatabaseService.allDatabasesOpened.subscribe(
        message => {
          resolve(message);
        }
      );
    });

    return true;
  }

  public async loadConfigsWs(): Promise<boolean> {
    const configFilePromises: Promise<any>[] = [];
    let configFileResults: any[] = [];
    const globalConfig = this.configService.getConfig('global');

    for (let idx = 0; idx < this.configsToLoadJson.length; idx++) {
      if (this.configsToLoadJson[idx].i18n) {
        continue;
      }

      const path = globalConfig.globalAssetsPath + this.configsToLoadJson[idx].filePath;
      this.configsToLoadJson[idx].fullFilePath = path;

      configFilePromises.push(this.loadFile(path));
    }

    configFileResults = await Promise.all(configFilePromises);

    for (let idx = 0; idx < configFileResults.length; idx++) {
      for (let innerIdx = 0; innerIdx < this.configsToLoadJson.length; innerIdx++) {
        if (configFileResults[idx].url.indexOf(this.configsToLoadJson[innerIdx].fullFilePath) > -1) {
          this.configService.setConfig(this.configsToLoadJson[innerIdx].configName, configFileResults[idx].body);
        }
      }
    }

    return true;
  }

  public async loadI18nConfigsWs(): Promise<boolean> {
    if (this.userSpecificConfigsLoaded) {
      return true;
    }

    const configFilePromises: Promise<any>[] = [];
    let configFileResults: any[] = [];
    const globalConfig = this.configService.getConfig('global');

    let language: string;
    const re = new RegExp('\\$language\\$', 'g');
    const currentUser: User | undefined = this.sessionService.getUser();

    if (currentUser && currentUser.userLang) {
      language = currentUser.userLang;
      this.userSpecificConfigsLoaded = true;
    } else {
      language = 'en';
    }

    for (let idx = 0; idx < this.configsToLoadJson.length; idx++) {
      if (!this.configsToLoadJson[idx].i18n || (this.configsToLoadJson[idx].linkedToUser && currentUser === undefined)) {
        continue;
      }

      let path = globalConfig.globalAssetsPath + this.configsToLoadJson[idx].filePath;
      path = path.replace(re, language);
      this.configsToLoadJson[idx].fullFilePath = path;

      configFilePromises.push(this.loadFile(path));
    }

    configFileResults = await Promise.all(configFilePromises);

    for (let idx = 0; idx < configFileResults.length; idx++) {
      for (let innerIdx = 0; innerIdx < this.configsToLoadJson.length; innerIdx++) {
        if (configFileResults[idx].url.indexOf(this.configsToLoadJson[innerIdx].fullFilePath) > -1) {
          this.configService.setConfig(this.configsToLoadJson[innerIdx].configName, configFileResults[idx].body);
        }
      }
    }

    const currencyConfig = this.configService.getConfig('currency');
    if (currencyConfig){
      this.currencyService.init(currencyConfig);
    }else{
      this.alertService.error('currency config not found');
    }
    return true;
  }

  public loadFile(fileUrl: string): Promise<any> {
    return this.httpService.callWS(
      new HttpCallModel(
        fileUrl,
        'GET',
        'finfra-core',
        undefined)
      ).toPromise();
  }
}
