export interface PageModel {
  currentPageNumber?: number | undefined | null;
  recordsPerPage?: number | undefined | null;
  totalPages?: number | undefined | null;
  totalRecords?: number | undefined | null;
  sortingRequired?: boolean | undefined | null;
  sortBy?: string | undefined | null;
}

export class PageModel implements PageModel {
  constructor(
    public currentPageNumber?: number | undefined | null,
    public recordsPerPage?: number | undefined | null,
    public totalPages?: number | undefined | null,
    public totalRecords?: number | undefined | null,
    public sortingRequired?: boolean | undefined | null,
    public sortBy?: string | undefined | null,
  ) { }
}
