import { AbstractControl } from '@angular/forms';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { JsonSchemaFormService, hasOwn } from '@finfra/ajsf-core';
import { AjsfCoreWidgetsFunctionsService } from '../../../asjf-core-widgets.functions';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'finfra-material-button-widget',
  templateUrl: './finfra-material-button.component.html',
  styleUrls: ['./finfra-material-button.component.scss'],
})
export class FinfraMaterialButtonComponent implements OnInit, OnDestroy {
  widgetName: string = "FinfraMaterialButtonComponent";
  formControl: AbstractControl;
  controlName: string;
  controlValue: any;
  controlDisabled = false;
  boundControl = false;
  options: any;
  subProperties: any;
  @Input() layoutNode: any;
  @Input() layoutIndex: number[];
  @Input() dataIndex: number[];

  constructor(
    private jsf: JsonSchemaFormService,
    private ajsfCoreWidgetsFunctionsService: AjsfCoreWidgetsFunctionsService
  ) { }

  ngOnInit() {
    this.options = this.layoutNode.options || {};
    this.subProperties = this.layoutNode.subProperties || {};
    this.jsf.initializeControl(this);
    if (hasOwn(this.options, 'disabled')) {
      this.controlDisabled = this.options.disabled;
    } else if (this.jsf.formOptions.disableInvalidSubmit) {
      this.controlDisabled = !this.jsf.isValid;
      this.jsf.isValidChanges.subscribe(isValid => this.controlDisabled = !isValid);
    }
	
    if(this.options.buttonType === undefined || this.options.buttonType === null){
      this.options.buttonType = 'flat';
    }
  }

  ngOnDestroy(): void {
    this.jsf = undefined;
    this.formControl = undefined;
    this.controlName = undefined;
    this.controlValue = undefined;
    this.controlDisabled = undefined;
    this.boundControl = undefined;
    this.options = undefined;
    this.subProperties = undefined;
  }

  updateValue(event) {
    if (typeof this.options.onClick === 'function') {
      this.options.onClick(event);
    } else {
      this.jsf.updateValue(this, event.target.value);
    }

    this.ajsfCoreWidgetsFunctionsService.dispatchEvent(this);
  }
}
