import { IWidgetProperty } from "src/models/inner-models";

export class StructurePrepareHelper {

  static parseProperties(properties: IWidgetProperty[]) {
    return properties.reduce((acc, current) => {
      if (current.currentValue !== undefined) {
        acc[current.name] = current.currentValue;
      }
      return acc;
    }, {} as any);
  }
}
