import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ConfigService } from './config.service';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor(
    private datepipe: DatePipe,
    private configService: ConfigService,
    private sessionService: SessionService) {

  }

  public getDateFromString(dateString: string | undefined): Date | undefined {
    if (dateString === undefined || dateString === null) {
      return undefined;
    }

    return new Date(dateString);
  }

  public getToday(): string | undefined {
    let dt: Date;

    if (this.sessionService.getSessionData('applicationDate') === undefined) {
      dt = new Date();
    } else {
      const appDt: any = JSON.parse(this.sessionService.getSessionData('applicationDate'));
      dt = new Date();
      dt.setTime(appDt.applicationDate);
    }

    return this.formatDate(dt);
  }

  public getTodayDate(): Date | undefined {
    let dt: Date;

    if (this.sessionService.getSessionData('applicationDate') === undefined || this.sessionService.getSessionData('applicationDate') === null) {
      dt = new Date();
    } else {
      const appDt: any = JSON.parse(this.sessionService.getSessionData('applicationDate'));
      dt = new Date();

      if (appDt.applicationDate) {
        dt.setTime(appDt.applicationDate);
      }
    }

    return dt;
  }

  public getNextDate(): Date | undefined {
    const todayDate: Date | undefined = this.getTodayDate();

    if (todayDate === undefined || todayDate === null) {
      return undefined;
    }

    todayDate.setDate(todayDate.getDate() + 1);
    return todayDate;
  }

  public getPrevDate(): Date | undefined {
    const todayDate: Date | undefined = this.getTodayDate();

    if (todayDate === undefined || todayDate === null) {
      return undefined;
    }

    todayDate.setDate(todayDate.getDate() - 1);
    return todayDate;
  }

  public formatDate(dt: Date | undefined): string | undefined {
    if (!dt) {
      return undefined;
    }

    const ret = this.datepipe.transform(dt, this.configService.getConfig('global').dateFormat);

    if (ret === undefined || ret === null) {
      return undefined;
    }

    return ret;
  }

  public formatDateTime(dt: Date | undefined): string | undefined {
    if (!dt) {
      return undefined;
    }

    const ret = this.datepipe.transform(dt, this.configService.getConfig('global').dateTimeFormat);

    if (ret === undefined || ret === null) {
      return undefined;
    }

    return ret;
  }

  public formatTime(dt: Date | undefined): string | undefined {
    if (!dt) {
      return undefined;
    }

    const ret = this.datepipe.transform(dt, this.configService.getConfig('global').timeFormat);

    if (ret === undefined || ret === null) {
      return undefined;
    }

    return ret;
  }

  public formatDateTimeServer(dt: Date | undefined): string | undefined {
    if (!dt) {
      return undefined;
    }

    const ret = this.datepipe.transform(dt, this.configService.getConfig('global').dateTimeFormatServer);

    if (ret === undefined || ret === null) {
      return undefined;
    }

    return ret;
  }

  public formatDateServer(dt: Date): string | undefined {
    let df: string;

    if (this.configService.getConfig('global') === undefined ||
      this.configService.getConfig('global').dateFormatServer === undefined ||
      this.configService.getConfig('global').dateFormatServer === null ||
      this.configService.getConfig('global').dateFormatServer === '') {
      df = 'yyyy-MM-dd';
    } else {
      df = this.configService.getConfig('global').dateFormatServer;
    }

    const ret = this.datepipe.transform(dt, df);

    if (ret === undefined || ret === null) {
      return undefined;
    }

    return ret;
  }

  public getDateFormat(): string | undefined {
    return this.configService.getConfig('global').dateFormat;
  }

  public getDateFormatServer(): string | undefined {
    return this.configService.getConfig('global').dateFormatServer;
  }

  public getDateFormatCalendar(): string | undefined {
    return this.configService.getConfig('global').dateFormatCalendar;
  }

  public getCalendarYearRange(): string | undefined {
    return this.configService.getConfig('global').CalendarYearRange;
  }

  public getCalendarCurrentYearRange(): string | undefined {
    const todayDate: Date | undefined = this.getTodayDate();

    if (todayDate === undefined || todayDate === null) {
      return undefined;
    }

    return this.configService.getConfig('global').CalendarCurrentYearRange.replace(/CURRYEAR/g, todayDate.getFullYear());
  }

  public getCalendarDOBYearRange(): string | undefined {
    const todayDate: Date | undefined = this.getTodayDate();

    if (todayDate === undefined || todayDate === null) {
      return undefined;
    }

    return this.configService.getConfig('global').CalendarDOBYearRange.replace(/CURRYEAR/g, todayDate.getFullYear());
  }

  public getMonthDiff(startDate: Date, endDate: Date): number | undefined {
    if (startDate === undefined || endDate === undefined) {
      return 0;
    }

    let months: number;
    months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
    months -= startDate.getMonth() + 1;
    months += endDate.getMonth() + 1;
    return months <= 0 ? 0 : months;
  }

  public getDaysInMonth(dt: Date | undefined): number | undefined {
    if (dt === undefined || dt === null) {
      return undefined;
    }

    switch (dt.getMonth()) {
      case 0: return 31;
      case 1: if (dt.getFullYear() % 4 === 0) { return 29; } else { return 28; }
      case 2: return 31;
      case 3: return 30;
      case 4: return 31;
      case 5: return 30;
      case 6: return 31;
      case 7: return 31;
      case 8: return 30;
      case 9: return 31;
      case 10: return 30;
      case 11: return 31;
      default: return -1;
    }
  }

  public getDaysInYear(dt: Date | undefined): number | undefined {
    if (dt === undefined || dt === null) {
      return undefined;
    }

    if (dt.getFullYear() % 4 === 0) {
      return 366;
    } else {
      return 365;
    }
  }

  public getLastDayOfYear(dt: Date | undefined): Date | undefined {
    if (dt === undefined || dt === null) {
      return undefined;
    }

    const retDt: Date = new Date();

    retDt.setDate(31);
    retDt.setMonth(11);
    retDt.setFullYear(dt.getFullYear());

    return retDt;
  }

  public getLastDayOfMonth(dt: Date | undefined): Date | undefined {
    if (dt === undefined || dt === null) {
      return undefined;
    }

    const retDt: Date = new Date();
    let lastDay: number;

    switch (dt.getMonth()) {
      case 0: lastDay = 31; break;
      case 1: if (dt.getFullYear() % 4 === 0) { lastDay = 29; } else { lastDay = 28; } break;
      case 2: lastDay = 31; break;
      case 3: lastDay = 30; break;
      case 4: lastDay = 31; break;
      case 5: lastDay = 30; break;
      case 6: lastDay = 31; break;
      case 7: lastDay = 31; break;
      case 8: lastDay = 30; break;
      case 9: lastDay = 31; break;
      case 10: lastDay = 30; break;
      case 11: lastDay = 31; break;
      default: return undefined;
    }

    retDt.setMonth(dt.getMonth());
    retDt.setFullYear(dt.getFullYear());
    retDt.setDate(lastDay);

    return retDt;
  }

  public getDaysDiff360(startDate: Date, endDate: Date, methodToUse?: string): number | undefined {
    let smd = 31;
    let emd = 31;
    let sd = startDate.getDate();
    let ed = endDate.getDate();
    if (methodToUse === 'E') {
      sd = (sd === 31) ? 30 : sd;
      ed = (ed === 31) ? 30 : ed;
    }
    else {
      if (startDate.getMonth() === 1) {
        const dim = this.getDaysInMonth(startDate);

        if (dim === undefined) {
          return undefined;
        }

        smd = dim;
      }
      if (endDate.getMonth() === 1) {
        const dim = this.getDaysInMonth(endDate);

        if (dim === undefined) {
          return undefined;
        }

        emd = dim;
      }
      sd = (sd === smd) ? 30 : sd;
      if (sd === 30 || sd === smd) {
        ed = (ed === emd) ? 30 : ed;
      }
    }
    return 360 * (endDate.getFullYear() - startDate.getFullYear()) + 30 * (endDate.getMonth() - startDate.getMonth()) + (ed - sd);
  }

  // CustomeOnBoard
  public getPreviousDate(): Date | undefined {
    const todayDate = this.getTodayDate();

    if (todayDate === undefined || todayDate === null) {
      return undefined;
    }

    return new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate());
  }

  // Service Request
  public getDaysDiff(d1: Date, d2: Date): number {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const diffDays = Math.round(Math.abs((d1.getTime() - d2.getTime()) / (oneDay)));
    return diffDays;
  }

  public getSecondsDiff(d1: Date, d2: Date): number {
    return Math.round(Math.abs((d1.getTime() - d2.getTime()) / (1000)));
  }

  // Find the age
  public getAge(dob: Date): number | undefined {
    const todayDate = this.getTodayDate();

    if (todayDate === undefined || todayDate === null) {
      return undefined;
    }

    let age = todayDate.getFullYear() - dob.getFullYear();

    if (todayDate.getMonth() < (dob.getMonth())) {
      age--;
    }
    if (((dob.getMonth()) == todayDate.getMonth()) && (todayDate.getDate() < dob.getDate())) {
      age--;
    }

    return age;
  }
}
