import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  private static spinnerCount: number = 0;
  private static backgroundSpinnerCount: number = 0;
  public displayProgressSpinner: boolean = false;

  constructor() {

  }

  private hide(): void {
    this.displayProgressSpinner = false;
    SpinnerService.spinnerCount = 0;
  }

  private show(): void {
    this.displayProgressSpinner = true;
  }

  public getSpinnerCount(): number {
    return SpinnerService.spinnerCount;
  }

  public incrementSpinnerCount(): void {
    SpinnerService.spinnerCount++;

    if (SpinnerService.spinnerCount > 0) {
      this.show();
    }
  }

  public decrementSpinnerCount(): void {
    SpinnerService.spinnerCount--;

    if (SpinnerService.spinnerCount <= 0) {
      this.hide();

      if (SpinnerService.spinnerCount < 0) {
        SpinnerService.spinnerCount = 0;
      }
    }
  }

  public getBackgroundSpinnerCount(): number {
    return SpinnerService.backgroundSpinnerCount;
  }

  public incrementBackgroundSpinnerCount(): void {
    SpinnerService.backgroundSpinnerCount++;
  }

  public decrementBackgroundSpinnerCount(): void {
    SpinnerService.backgroundSpinnerCount--;

    if (SpinnerService.backgroundSpinnerCount < 0) {
      SpinnerService.backgroundSpinnerCount = 0;
    }
  }
}
