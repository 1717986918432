import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private appConfig: Map<string, any> = new Map<string, any>();

  constructor() { }

  public setConfig(configName: string, config: any) {
    if (configName === 'menu') {
      this.appConfig.set(configName, JSON.stringify(config));
    } else {
      this.appConfig.set(configName, config);
    }
  }

  public getConfig(configName: string): any {
    return this.appConfig.get(configName);
  }
}
