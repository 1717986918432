export interface FaceRecognitionModel {
  uniqueId?: string;
  catalogueId?: string;
  dataType?: string;
  externalFileId?: string;
  filename?: string;
  id?: string;
  metaData?: any;
  responseStatus?: string;
  fileBase64?: any;
  fileUrl?: string;
  file?: File;
  tags?: any;
  uploaded?: boolean;
  verified?: boolean;
  deltaVerified?: boolean;
  valid?: boolean;
}

export class FaceRecognitionModel implements FaceRecognitionModel {
  constructor(
    public uniqueId?: string,
    public catalogueId?: string,
    public dataType?: string,
    public externalFileId?: string,
    public filename?: string,
    public id?: string,
    public metaData?: any,
    public responseStatus?: string,
    public fileBase64?: any,
    public fileUrl?: string,
    public file?: File,
    public tags?: any,
    public uploaded?: boolean,
    public verified?: boolean,
    public deltaVerified?: boolean,
    public valid?: boolean,
  ) {
  }
}
