export interface FileModel {
  fileName?: string | undefined | null;
  fileType?: string | undefined | null;
  fileExtension?: string | undefined | null;
  fileUrl?: string | undefined | null;
  fileBase64?: string | undefined | null;
  fileLastModified?: number | undefined;
  fileLastModifiedDate?: Date | undefined;
  fileSize?: number | undefined;
  file?: File | undefined;
  fileBlob?: Blob | undefined;
  fileUploaded?: boolean;
  fileProcessed?: boolean;
  fileId?: string | undefined;
  fileUniqueId?: string | undefined;
  fileExternalId?: string | undefined;
  metaData?: any;
}

export class FileModel implements FileModel {
  constructor(
    public fileName?: string | undefined | null,
    public fileType?: string | undefined | null,
    public fileExtension?: string | undefined | null,
    public fileUrl?: string | undefined | null,
    public fileBase64?: string | undefined | null,
    public fileLastModified?: number | undefined,
    public fileLastModifiedDate?: Date | undefined,
    public fileSize?: number | undefined,
    public file?: File | undefined,
    public fileBlob?: Blob | undefined,
    public fileUploaded?: boolean,
    public fileProcessed?: boolean,
    public fileId?: string | undefined,
    public fileUniqueId?: string | undefined,
    public fileExternalId?: string | undefined,
    public metaData?: any,
  ) { }
}
