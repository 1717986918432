import { Injectable } from '@angular/core';
import { SessionService } from './session.service';
import { AlertService } from './alert.service';
import { ConfigLoaderService } from './config-loader.service';
import { ConfigService } from './config.service';
import { InjectionService } from './injection.service';
import { OfflineStatusService } from '../offline-services/offline-status.service';

@Injectable({
  providedIn: 'root'
})
export class InitializerService {
  public duplicateTab = false;

  constructor(
    private alertService: AlertService,
    private configService: ConfigService,
    private configLoaderService: ConfigLoaderService,
    private sessionService: SessionService,
    private injectionService: InjectionService,
    private offlineStatusService: OfflineStatusService
  ) { }

  public async initialize(globalConfig: any): Promise<boolean> {
    this.alertService.info('initializing finfra:: inside promise');
    this.configLoaderService.initGlobalConfig(globalConfig.default);

    localStorage.finfraOpened = Date.now();
    window.addEventListener('storage', this.checkDuplicateTabs.bind(this), false);

    await this.configLoaderService.loadConfigs();

    return true;
  }

  public checkDuplicateTabs(event: any): void {
    if (event.key === 'finfraOpened' && !this.duplicateTab) {
      localStorage.finfraAlreadyOpened = Date.now();
    }

    if (event.key === 'finfraAlreadyOpened') {
      alert(this.configService.getConfig('global').productName + ' is already open in another tab!');
      this.duplicateTab = true;
      window.close();
    }
  }

  public resetApp(): void {
    this.sessionService.clearAllSessionData();

    this.sessionService.setSessionData('version', this.configService.getConfig('global').version);
    this.sessionService.setSessionData('userAgent', this.configService.getConfig('global').userAgent);
    this.sessionService.setSessionData('tenantId',this.configService.getConfig('global').tenantId);

    this.offlineStatusService.setStatusInSession();

    const servicesToRun = this.injectionService.getInjectedServiceByType('app-reset');

    if (servicesToRun) {
      for (let idx = 0; idx < servicesToRun.length; idx++) {
        const functionName = servicesToRun[idx].entryFunctionName;

        if (functionName) {
          servicesToRun[idx].service[functionName]();
        }
      }
    }
  }
}
