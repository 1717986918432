import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { JsonSchemaFormService } from '@finfra/ajsf-core';
import { GenericEventModel } from '@finfra/core-models';
import { EventsService } from '@finfra/core-services';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'material-add-reference-widget',
  templateUrl: './material-add-reference.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class MaterialAddReferenceComponent implements OnInit, OnDestroy {
  options: any;
  itemCount: number;
  previousLayoutIndex: number[];
  previousDataIndex: number[];
  @Input() layoutNode: any;
  @Input() layoutIndex: number[];
  @Input() dataIndex: number[];

  constructor(
    private jsf: JsonSchemaFormService,
    private eventsService: EventsService
  ) { }

  ngOnInit() {
    this.options = this.layoutNode.options || {};

    //Profinch modifications starts
    this.jsf.addLayoutRefComponent(this.layoutNode.dataPointer, this);
    let formGroupEvent: GenericEventModel = new GenericEventModel();

    formGroupEvent.eventSource = "finfra-ajsf";
    formGroupEvent.formControl = this.jsf.formGroup;
    formGroupEvent.formName = this.jsf.ajsfFormName;
    formGroupEvent.eventName = "finfra-ajsf-add-reference";
    formGroupEvent.eventObject = this;

    this.eventsService.fireEvent(formGroupEvent);
    //Profinch modifications ends
  }

  ngOnDestroy() {
    this.jsf.removeLayoutRefComponent(this.layoutNode.dataPointer);
  }

  get showAddButton(): boolean {
    return !this.layoutNode.arrayItem ||
      this.layoutIndex[this.layoutIndex.length - 1] < this.options.maxItems;
  }

  addItem(event) {
    event.preventDefault();
    this.jsf.addItem(this);
  }

  get buttonText(): string {
    const parent: any = {
      dataIndex: this.dataIndex.slice(0, -1),
      layoutIndex: this.layoutIndex.slice(0, -1),
      layoutNode: this.jsf.getParentNode(this),
    };
    return parent.layoutNode.add ||
      this.jsf.setArrayItemTitle(parent, this.layoutNode, this.itemCount);
  }
}
