import { AbstractControl } from '@angular/forms';
import {Component, Inject, Input, OnDestroy, OnInit, Optional} from '@angular/core';
import { JsonSchemaFormService } from '@finfra/ajsf-core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { AjsfCoreWidgetsFunctionsService } from '../../../asjf-core-widgets.functions';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'finfra-wysiwyg-editor-widget',
  templateUrl: './finfra-wysiwyg-editor.component.html',
  styleUrls: ['./finfra-wysiwyg-editor.component.scss'],
})
export class FinfraWysiwygComponent implements OnInit, OnDestroy {
  widgetName = 'FinfraWysiwygComponent';
  formControl: AbstractControl;
  controlName: string;
  controlValue: any;
  controlDisabled = false;
  boundControl = false;
  options: any;
  subProperties: any;
  @Input() layoutNode: any;
  @Input() layoutIndex: number[];
  @Input() dataIndex: number[];

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: '',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    sanitize: false,
    toolbarHiddenButtons: [
      ['bold']
      ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ]
  };

  constructor(
    @Inject(MAT_FORM_FIELD_DEFAULT_OPTIONS) @Optional() public matFormFieldDefaultOptions,
    private jsf: JsonSchemaFormService,
    public ajsfCoreWidgetsFunctionsService: AjsfCoreWidgetsFunctionsService
  ) { }

  ngOnInit() {
    this.options = this.layoutNode.options || {};
    this.subProperties = this.layoutNode.subProperties || {};
    this.jsf.initializeControl(this , !this.options.readonly);
    if (!this.options.notitle && !this.options.description && this.options.placeholder) {
      this.options.description = this.options.placeholder;
    }
    if (this.options.readonly) {
      this.config.editable = false;
      this.config.enableToolbar = false;
      this.config.showToolbar = false;
    }
    if (this.formControl.value !== null || this.formControl.value !== undefined ) {
      this.controlValue = this.formControl.value;
    }

    this.config.placeholder = this.options?.notitle ? this.options?.placeholder : this.options?.title;
  }

  ngOnDestroy(): void {
    this.jsf = undefined;
    this.formControl = undefined;
    this.controlName = undefined;
    this.controlValue = undefined;
    this.controlDisabled = undefined;
    this.boundControl = undefined;
    this.options = undefined;
    this.subProperties = undefined;
  }

  updateValue(event) {
    this.controlValue = this.formControl.value;
    this.jsf.updateValue(this, event.target.outerHTML);
    this.ajsfCoreWidgetsFunctionsService.dispatchEvent(this);
  }
}
