import { Directive, Input, HostBinding } from '@angular/core';
import {MatFormField} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {MatSelect} from '@angular/material/select';


@Directive({
  selector: 'mat-form-field[provideReadonly]',
  host: {
    '[class.mat-form-field-readonly]': 'isReadonlyInput()'
  }
})
export class MatFormFieldReadonlyDirective {
  constructor(private _matFormField: MatFormField) { }

  public isReadonlyInput(): boolean {
    const ctrl = this._matFormField._control;
    if (ctrl instanceof MatInput) {
      return ctrl.readonly;
    } else if (ctrl instanceof MatSelect) {
      return ctrl.disabled;
    }
    return false;
  }
}
