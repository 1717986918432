import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BPMNStageNodeType, EDirection, ELocalStoreKeys, EStagesMode, FINAL_STAGE } from 'src/models/consts';
import { IStage, IStageTreeNode, IUIStageNodeExtended, IUiStageStructure } from 'src/models/inner-models';
import { LocalstorageService } from './localstorage.service';
import { ParseToJsonsService } from './parse-to-jsons.service';
import * as uniqid from 'uniqid';

@Injectable({
  providedIn: 'root'
})
export class DataSaverService {
  private Name = new BehaviorSubject<string>('');
  name = this.Name.asObservable();

  uiStagesStructure: Array<IUiStageStructure> = [];

  private stagesTree: Array<IStageTreeNode> | null = null;
  onStagesChanged = new BehaviorSubject<Array<IStageTreeNode> | null>(null);

  constructor(
    private localstore: LocalstorageService,
    private jsonParserService: ParseToJsonsService
  ) { }

  parseToJson(withExport: boolean = true) {
    if (!!this.stagesTree) {
      this.jsonParserService.parseData(
        {
          name: this.Name.value,
          stagesTree: this.stagesTree,
          uiStagesStructure: this.uiStagesStructure
        },
        withExport
      );
    }
  }

  addUiStageForm(uiStage: IUiStageStructure) {
    const uiStageIndex = this.findUiStageIndex(uiStage._id);
    if (uiStageIndex > -1) {
      this.uiStagesStructure[uiStageIndex] = uiStage;
    } else {
      this.uiStagesStructure.push(uiStage);
    }
    this.localstore.setItemStringified(ELocalStoreKeys.UI_STAGES, this.uiStagesStructure);
  }

  getUiStageForm(uiStageId: string) {
    const uiStageIndex = this.findUiStageIndex(uiStageId);
    return (uiStageIndex > -1) ? this.uiStagesStructure[uiStageIndex] : null;
  }

  removeUiStageForm(uiStageId: string) {
    const uiStageIndex = this.findUiStageIndex(uiStageId);
    this.uiStagesStructure.splice(uiStageIndex, 1);
  }

  findUiStageIndex(uiStageId: string) {
    return this.uiStagesStructure.findIndex(({_id}) => uiStageId === _id);
  }

  setUiStages() {
    const uiStages = this.localstore.getItemParsed(ELocalStoreKeys.UI_STAGES);
    if (uiStages) {
      this.uiStagesStructure = uiStages;
    }
  }

  saveName(name: string, needSave: boolean = true) {
    this.Name.next(name);
    if (needSave) {
      this.localstore.setItemStringified(ELocalStoreKeys.NAME, this.Name.value);
    }
  }

  saveParsedStages(stages: Array<IStage>) {
    this.stagesTree = stages.map((stage: IStage): IStageTreeNode => {
      const children = (stage.type === BPMNStageNodeType.TASK) ? {
        view: [],
        edit: []
      } : {
        view: []
      }
      return {
        ...stage,
        showOnlyIfStageIsCurrent: false,
        children
      }
    });
    this.stagesTree.push(FINAL_STAGE);

    this.onStagesChanged.next(this.stagesTree);
    this.localstore.setItemStringified(ELocalStoreKeys.STAGES_TREE, this.stagesTree);
  }

  saveStagesTree(tree: Array<IStageTreeNode>, needSave: boolean = true) {
    this.stagesTree = tree;
    this.onStagesChanged.next(this.stagesTree);
    if (needSave) {
      this.localstore.setItemStringified(ELocalStoreKeys.STAGES_TREE, this.stagesTree);
    }
  }

  getParentNode(parentId: string) {
    if (this.stagesTree) {
      const path = parentId.split('.');

      return this.stagesTree.find(stage => stage.id === path[0])
      ?.children[path[1] === EStagesMode.EDIT? EStagesMode.EDIT: EStagesMode.VIEW];
    }

    return;
  }

  getCurrentNodeIndex(uiStageId: string, parent?: Array<IUIStageNodeExtended>) {
    const parentNode = parent || this.getParentNode(uiStageId);
    
    const currentNodeIndexDraft: number | undefined = parentNode
    ?.findIndex((uiStage) => uiStage.id === uiStageId);

    const getCurrentNodeIndex = (index: number | undefined): number => {
      if (index === undefined) {
        return -1;
      }

      return index;
    }

    return getCurrentNodeIndex(currentNodeIndexDraft);
  }

  getUiStageByUniqId(id: string) {
    if (this.stagesTree) {
      for (let i = 0; i < this.stagesTree?.length; i++) {
        for (let modeStage of Object.values(this.stagesTree[i].children)) {
          const uiStage = modeStage?.find(({_id}) => _id === id);
          if (uiStage) {
            return uiStage;
          }
        }
      }
    }
    return null;
  }

  editStage(id: string, showOnlyIfStageIsCurrent: boolean) {
    if (!this.stagesTree) return;

    let node = this.stagesTree.find(stage => stage.id === id);
    if (!node) return;

    node.showOnlyIfStageIsCurrent = showOnlyIfStageIsCurrent;

    this.onStagesChanged.next(this.stagesTree);
    this.localstore.setItemStringified(ELocalStoreKeys.STAGES_TREE, this.stagesTree);
  }

  uiStageAdd(parentId: string, uiNamedId: string, viewMultiPuprosePanel: boolean, uiStageId?: string) { //TODO
    if (this.stagesTree) {
      let uiStage;
      const parentNode = this.getParentNode(parentId)

      if (parentNode && uiStageId) {
        const currentNodeIndex = this.getCurrentNodeIndex(uiStageId, parentNode);
        uiStage = {
          _id: parentNode[currentNodeIndex]._id,
          id: `${uiStageId.slice(0, uiStageId.lastIndexOf('.'))}.${uiNamedId.trim()}`,
          uiStageNamedId: uiNamedId,
          viewMultiPuprosePanel: viewMultiPuprosePanel
        }
        parentNode[currentNodeIndex] = uiStage;
      } else {
        uiStage = {
          _id: uniqid('uistage'),
          id: `${parentId}.${uiNamedId.trim()}`,
          uiStageNamedId: uiNamedId,
          viewMultiPuprosePanel: viewMultiPuprosePanel
        };
        parentNode?.push(uiStage);
      }
      this.onStagesChanged.next(this.stagesTree);
      this.localstore.setItemStringified(ELocalStoreKeys.STAGES_TREE, this.stagesTree);
      return uiStage;
    }
    return null;
  }

  copyUiStageData(sourceId: string, targetSource: IUIStageNodeExtended | null) {
    if (sourceId && targetSource) {
      const copiedUiStageData = this.getUiStageForm(sourceId);
      if (copiedUiStageData) {
        this.addUiStageForm({
          _id: targetSource._id,
          path: targetSource.id,
          elements: [...copiedUiStageData.elements],
          toolbar: [...copiedUiStageData.toolbar]
        })
      }
    }
  }

  uiStageRemove(uniqId: string, uiStageId: string) {
    this.removeUiStageForm(uniqId);
    const parentNode = this.getParentNode(uiStageId);

    const currentNodeIndex = this.getCurrentNodeIndex(uiStageId, parentNode)

    if (currentNodeIndex >= 0) {
      parentNode?.splice(currentNodeIndex, 1);

      this.onStagesChanged.next(this.stagesTree);
      this.localstore.setItemStringified(ELocalStoreKeys.STAGES_TREE, this.stagesTree);
    }
  }

  stageOrder(nodeID: string, direction: EDirection) {
    if (this.stagesTree) {
      const index = this.stagesTree?.findIndex((stage) => stage.id === nodeID);
      if (direction === EDirection.DOWN && index !== undefined && index < this.stagesTree?.length - 1) {
        [this.stagesTree[index], this.stagesTree[index + 1]] = [this.stagesTree[index + 1], this.stagesTree[index]];
        this.onStagesChanged.next(this.stagesTree);
        this.localstore.setItemStringified(ELocalStoreKeys.STAGES_TREE, this.stagesTree);
      }
      if (direction === EDirection.UP && index !== undefined && index > 0) {
        [this.stagesTree[index], this.stagesTree[index - 1]] = [this.stagesTree[index - 1], this.stagesTree[index]];
        this.onStagesChanged.next(this.stagesTree);
        this.localstore.setItemStringified(ELocalStoreKeys.STAGES_TREE, this.stagesTree);
      }
    }
  }

  uiStageOrder(nodeID: string, parentID: string, childrenType: string, direction: EDirection) {
    if (this.stagesTree) {
      const parentIndex = this.stagesTree.findIndex((stage) => stage.id === parentID);
      let childrenContainer: any = [];
      let childrenIndex;

      if (childrenType === 'view') {
        childrenContainer = this.stagesTree[parentIndex].children.view || [];
      }
      else {
        childrenContainer = this.stagesTree[parentIndex].children.edit || [];
      }

      childrenIndex = childrenContainer?.findIndex((stage: any) => stage.id === nodeID);

      if (direction === EDirection.DOWN && childrenIndex !== undefined && childrenIndex < childrenContainer?.length - 1) {
        [childrenContainer[childrenIndex], childrenContainer[childrenIndex + 1]] = [childrenContainer[childrenIndex + 1], childrenContainer[childrenIndex]];
      }
      if (direction === EDirection.UP && childrenIndex !== undefined && childrenIndex > 0) {
        [childrenContainer[childrenIndex], childrenContainer[childrenIndex - 1]] = [childrenContainer[childrenIndex - 1], childrenContainer[childrenIndex]];
      }

      this.onStagesChanged.next(this.stagesTree);
      this.localstore.setItemStringified(ELocalStoreKeys.STAGES_TREE, this.stagesTree);
    }
  }

  clearData() {
    this.localstore.removeItem(ELocalStoreKeys.UI_STAGES);
    this.localstore.removeItem(ELocalStoreKeys.STAGES_TREE);
    this.localstore.removeItem(ELocalStoreKeys.NAME);
    this.localstore.removeItem(ELocalStoreKeys.BPMN_FILE);
    this.jsonParserService.clearData();
    this.stagesTree = null;
    this.onStagesChanged.next(this.stagesTree);
    this.Name.next('');
    this.uiStagesStructure = [];
  }
}
