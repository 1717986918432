import { AbstractControl } from '@angular/forms';
import {Component, Inject, Input, OnDestroy, OnInit, Optional} from '@angular/core';
import { JsonSchemaFormService, buildTitleMap, isArray } from '@finfra/ajsf-core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { AjsfCoreWidgetsFunctionsService } from '../../../asjf-core-widgets.functions';
import {Subscription} from 'rxjs';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'finfra-material-autocomplete-widget',
  templateUrl: './finfra-material-autocomplete.component.html',
  styleUrls: ['./finfra-material-autocomplete.component.scss'],
})
export class FinfraMaterialAutocompleteComponent implements OnInit, OnDestroy {
  widgetName = 'FinfraMaterialAutocompleteComponent';
  formControl: AbstractControl;
  controlName: string;
  controlValue: any;
  controlDisabled = false;
  boundControl = false;
  options: any;
  subProperties: any;
  selectList: any[] = [];
  selectListControl: AbstractControl;
  isArray = isArray;
  nameTag: string;
  valueTag: string;
  filteredOptions: any[];
  formControlChangeSubscription: Subscription;
  @Input() layoutNode: any;
  @Input() layoutIndex: number[];
  @Input() dataIndex: number[];

  constructor(
    @Inject(MAT_FORM_FIELD_DEFAULT_OPTIONS) @Optional() public matFormFieldDefaultOptions,
    private jsf: JsonSchemaFormService,
    private ajsfCoreWidgetsFunctionsService: AjsfCoreWidgetsFunctionsService
  ) { }

  ngOnInit() {
    this.options = this.layoutNode.options || {};
    this.subProperties = this.layoutNode.subProperties || {};
    this.selectList = buildTitleMap(
      this.options.titleMap || this.options.enumNames,
      this.options.enum, !!this.options.required, !!this.options.flatList
    );
    this.jsf.initializeControl(this, !this.options.readonly);
    if (!this.options.notitle && !this.options.description && this.options.placeholder) {
      this.options.description = this.options.placeholder;
    }

    if (this.options.displayName === undefined || this.options.displayName === null) {
      this.options.displayName = true;
    }

    this.formControlChangeSubscription = this.formControl.valueChanges.subscribe(this.formControlChange.bind(this));

    this.nameTag = this.options.nameTag || 'name';
    this.valueTag = this.options.valueTag || 'value';

    this.assignSelectListControl();
  }

  ngOnDestroy(): void {
    this.jsf = undefined;
    this.formControl = undefined;
    this.controlName = undefined;
    this.controlValue = undefined;
    this.controlDisabled = undefined;
    this.boundControl = undefined;
    this.options = undefined;
    this.subProperties = undefined;

    if (this.formControlChangeSubscription) {
      this.formControlChangeSubscription.unsubscribe();
    }
  }

  formControlChange(value) {
    this.filteredOptions = this.filterValues(value);
  }

  filterValues(name: string): any[] {
    if (name === undefined || name === null || name.trim() === '') {
      if (this.selectListControl) {
        return this.selectListControl.value;
      } else {
        return this.selectList;
      }
    }

    const filterValue = name.toLowerCase();

    if (this.selectListControl) {
      return this.selectListControl.value.filter(option => option[this.nameTag].toLowerCase().indexOf(filterValue) >= 0 || option[this.valueTag].toLowerCase().indexOf(filterValue) >= 0);
    } else {
      return this.selectList.filter(option => option[this.nameTag].toLowerCase().indexOf(filterValue) >= 0 || option[this.valueTag].toLowerCase().indexOf(filterValue) >= 0);
    }
  }

  assignSelectListControl() {
    if (this.options.selectListPointer) {
      this.selectListControl = this.jsf.getControlService(this, this.jsf.formGroup, this.options.selectListPointer);
    }
  }

  updateValue() {
    this.options.showErrors = true;
    this.jsf.updateValue(this, this.formControl.value);
  }

  validate(event) {
    if (this.options.readonly !== undefined && this.options.readonly === true) {
      return;
    }

    const filterValues = this.filterValues(this.formControl.value);

    if (filterValues && filterValues.length === 1) {
      this.formControl.setValue(filterValues[0][this.valueTag]);
    } else {
      this.formControl.reset();
    }

    this.updateValue();
    this.ajsfCoreWidgetsFunctionsService.dispatchEvent(this);
  }

  displayFn(data: any): string {
    if (data) {
      const filterData = this.filterValues(data);

      return filterData[0][this.nameTag];
    } else {
      return '';
    }
  }
}
