export interface WorkflowModel {
  processId?: string | undefined | null;
  processInstanceId?: string | undefined | null;
  taskId?: string | undefined | null;
  stageId?: string | undefined | null;
  bpmnAPI?: string | undefined | null;
  allStagesComplete?: boolean | undefined | null;
  uiKey?: string | undefined | null;
  userAction?: string | undefined | null;
  comments?: string | undefined | null;
}

export class WorkflowModel implements WorkflowModel {
  constructor(
    public processId?: string | undefined | null,
    public processInstanceId?: string | undefined | null,
    public taskId?: string | undefined | null,
    public stageId?: string | undefined | null,
    public bpmnAPI?: string | undefined | null,
    public allStagesComplete?: boolean | undefined | null,
    public uiKey?: string | undefined | null,
    public userAction?: string | undefined | null,
    public comments?: string | undefined | null
  ) { }
}
