import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { UtilitiesService } from './utilities.service';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  sessionStoreType: string = "B";
  sessionStore: any = sessionStorage;
  sessionHeapData: Map<string, any> = new Map<string, any>();
  encryptData: boolean = true;

  sessionCache: Map<string, any> = new Map<string, any>();

  constructor(
    private configService: ConfigService,
    private utilitiesService: UtilitiesService
  ) {
    if (this.configService.getConfig("global")) {
      this.init();
    }
  }

  public init(): void {
    if (typeof (sessionStorage) === undefined || this.configService.getConfig("global").sessionStoreType === 'H') {
      this.sessionStore = new Map<string, any>();
      this.sessionStoreType = "H";
    } else {
      this.sessionStore = sessionStorage;
      this.sessionCache = new Map<string, any>();
      this.sessionStoreType = "B";
    }
  }

  private setSessionStorage(key: string, data: any): void {
    let keyHash: string | undefined;
    let dataHash: string | undefined;

    if (this.encryptData) {
      keyHash = this.utilitiesService.btoaUnicode(key);
      dataHash = this.utilitiesService.btoaUnicode(data);
    } else {
      keyHash = key;
      dataHash = data;
    }

    if (this.sessionStoreType === 'H') {
      this.sessionStore.set(keyHash, dataHash);
    } else {
      this.sessionStore.setItem(keyHash, dataHash);
      this.sessionCache.set(key, data);
    }
  }

  private getSessionStorage(key: string): any {
    let keyHash: string | undefined = this.utilitiesService.btoaUnicode(key);

    if (this.sessionStoreType === 'H') {
      return this.utilitiesService.atobUnicode(this.sessionStore.get(keyHash));
    } else {
      let response: any = this.sessionCache.get(key);
      if (response !== undefined && response !== null) {
        return response;
      }

      response = this.utilitiesService.atobUnicode(this.sessionStore.getItem(keyHash));

      this.sessionCache.set(key, response);
      return response;
    }
  }

  private removeSessionStorage(key: string): void {
    let keyHash: string | undefined = this.utilitiesService.btoaUnicode(key);

    if (this.sessionStoreType === 'H') {
      this.sessionStore.delete(keyHash);
    } else {
      this.sessionStore.removeItem(keyHash);
      this.sessionCache.delete(key);
    }
  }

  private clearSessionStorage(): void {
    if (this.sessionStoreType === 'H') {
      this.sessionStore.clear();
    } else {
      this.sessionStore.clear();
      this.sessionCache.clear();
    }
  }

  private setSessionHeap(key: string, data: any): void {
    let keyHash: string | undefined = this.utilitiesService.btoaUnicode(key);
    let dataHash: string | undefined = this.utilitiesService.btoaUnicode(data);

    if (keyHash) {
      this.sessionHeapData.set(keyHash, dataHash);
    }
  }

  private getSessionHeap(key: string): any {
    let keyHash: string | undefined = this.utilitiesService.btoaUnicode(key);

    if (keyHash === undefined) {
      return undefined;
    }

    if (this.sessionHeapData.get(keyHash) !== undefined || this.sessionHeapData.get(keyHash) !== null || this.sessionHeapData.get(keyHash) !== '') {
      return this.utilitiesService.atobUnicode(this.sessionHeapData.get(keyHash));
    } else {
      return undefined;
    }
  }

  private removeSessionHeap(key: string): void {
    let keyHash: string | undefined = this.utilitiesService.btoaUnicode(key);

    if (keyHash === undefined) {
      return;
    }

    if (this.sessionHeapData.get(keyHash) !== undefined) {
      this.sessionHeapData.delete(keyHash);
    }
  }

  private clearSessionHeap(): void {
    this.sessionHeapData.clear();
  }

  public storeData(type: string, key: string, data: any): boolean {
    switch (type) {
      case "session": this.setSessionStorage(key, data); break;
      case "sessionHeap": this.setSessionHeap(key, data); break;
    }

    return true;
  }

  public getData(type: string, key: string): any {
    switch (type) {
      case "session":
        return this.getSessionStorage(key);
      case "sessionHeap":
        return this.getSessionHeap(key);
    }

    return undefined;
  }

  public removeData(type: string, key: string): any {
    switch (type) {
      case "session": return this.removeSessionStorage(key);
      case "sessionHeap": return this.removeSessionHeap(key);
    }
  }

  public clearStorage(type: string): boolean {
    console.log('about to clear storage for ' + type)
    switch (type) {
      case "session": this.clearSessionStorage(); break;
      case "sessionHeap": this.clearSessionHeap(); break;
    }

    return true;
  }
}
