import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SystemComponent } from './system.component';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    component: SystemComponent,
    children: [
      {
        path: 'bpmn',
        loadChildren: () => import('./main-view/main-view.module').then(m => m.MainViewModule),
      },
      {
        path: 'form-editor',
        loadChildren: () => import('./form-editor/form-editor.module').then(m => m.FormEditorModule),
      },
      {
        path: 'preview',
        loadChildren: () => import('./preview/preview.module').then(m => m.PreviewModule)
      }
    ],
  },
  {
    path: '',
    redirectTo: 'bpmn',
    pathMatch: 'full'
  }
]

@NgModule({
  declarations: [
    SystemComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    SystemComponent
  ]
})
export class SystemModule { }
