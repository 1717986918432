import { Injectable } from '@angular/core';

import { StorageService } from './storage.service';
import { ConvertObjectService } from './convert-object.service';
import { User } from '@finfra/core-models';



@Injectable({
  providedIn: 'root',
})
export class SessionService {
  constructor(
    private storageService: StorageService,
    private convertObjectService: ConvertObjectService,
  ) { }

  public clearAllSessionData(): void {
    this.storageService.clearStorage('session');
  }

  public setUser(user: User): void {
    this.storageService.storeData('session', 'user', JSON.stringify(user));
  }

  public getUser(): User | undefined {
    const user: string = this.storageService.getData('session', 'user');

    if (user !== undefined) {
      return JSON.parse(user);
    } else {
      return undefined;
    }
  }

  public isUserSet(): boolean {
    try {
      if (this.storageService.getData('session', 'user') !== undefined) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }

  public setAuthToken(authToken: string): void {
    this.storageService.storeData('session', 'xat', authToken);
  }

  public getAuthToken(): string {
    return this.storageService.getData('session', 'xat');
  }

  public removeAuthToken(): void {
    this.storageService.removeData('session', 'xat');
  }

  public setTransferData(data: any): void {
    this.storageService.storeData('sessionHeap', 'Generic', JSON.stringify(data));
  }

  public getTransferData(): any {
    const temp = this.storageService.getData('sessionHeap', 'Generic');
    this.clearTransferData();
    if (temp !== undefined) {
      const data: any = JSON.parse(temp);
      this.convertObjectService.convertStringToDates(data);

      return data;
    } else {
      return undefined;
    }
  }

  public clearTransferData(): void {
    this.storageService.removeData('sessionHeap', 'Generic');
  }

  public clearHeapData(): void {
    this.storageService.clearStorage('sessionHeap');
  }

  public setSessionData(key: string, data: any): void {
    if (typeof data === 'string') {
      this.storageService.storeData('session', key, data);
    } else {
      this.storageService.storeData('session', key, JSON.stringify(data));
    }
  }

  public getSessionData(key: string): any {
    return this.storageService.getData('session', key);
  }

  public clearSessionData(key: string): void {
    return this.storageService.removeData('session', key);
  }
}
