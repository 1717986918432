import { Injectable } from '@angular/core';
import { TranslateService } from '@finfra/core-decorators';
import { GenericEventModel } from '@finfra/core-models';
import { EventsService } from '@finfra/core-services';


@Injectable({
  providedIn: 'root'
})
export class AjsfCoreWidgetsFunctionsService {

  constructor(
    private eventsService: EventsService,
    private translateService: TranslateService,
  ) {

  }

  public dispatchEvent(widgetObject: any, value?: any) {
    const eventObject: GenericEventModel = new GenericEventModel();

    eventObject.eventSource = 'finfra-ajsf';
    eventObject.formControl = widgetObject.formControl;
    eventObject.formName = widgetObject.jsf.ajsfFormName;
    eventObject.value = value || widgetObject.controlValue;
    eventObject.jsonSchema = widgetObject.layoutNode;
    eventObject.eventObjectName = widgetObject.controlName;
    eventObject.eventObject = value || widgetObject.controlValue;
    eventObject.eventName = widgetObject.widgetName;
    eventObject.arrayIndex = widgetObject.dataIndex;

    this.eventsService.fireEvent(eventObject);
  }

  public loadLabels(widgetObject: any) {
    if (!widgetObject.translateLabelsName) {
      return;
    }

    widgetObject.translateLabelsLoaded = false;

    this.translateService.load(
      widgetObject.translateLabelsName,
      'labels/finfra-widgets/' + widgetObject.translateLabelsName,
      this.afterLoadLabels.bind(this, widgetObject)
    );
  }

  public afterLoadLabels(widgetObject: any) {
    widgetObject.translateLabelsLoaded = true;

    if (widgetObject.changeDetectorRef) {
      widgetObject.changeDetectorRef.markForCheck();
    }
  }
}
