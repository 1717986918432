import {Injectable} from '@angular/core';
import {InjectionService} from '@finfra/core-services';
import {FinfraMaterialPasswordComponent} from './widgets/level-1/finfra-material-password/finfra-material-password.component';

import {
  FinfraMaterialInputComponent,
  FinfraMaterialButtonComponent,
  FinfraMaterialNumberComponent,
  FinfraMaterialTextareaComponent,
  FinfraMaterialButtonGroupComponent,
  FinfraMaterialCheckboxComponent,
  FinfraMaterialCheckboxesComponent,
  FinfraAmountComponent,
  FinfraImageComponent,
  FinfraImageCarouselComponent,
  FinfraMaterialSelectComponent,
  FinfraFileComponent,
  FinfraLabelComponent,
  FinfraMaterialRadiosComponent,
  FinfraMaterialDatepickerComponent,
  FinfraTableExpandableComponent,
  FinfraMaterialAutocompleteComponent,
  FinfraSolrSearchComponent,
  FinfraWebcamComponent,
  FinfraDocumentScannerComponent,
  FinfraReferenceNumbersComponent,
  FinfraOtpComponent,
  FinfraMaterialDaterangeComponent,
  FinfraDatagridComponent,
  FinfraObjectDifferenceComponent,
  FinfraDMSWidgetComponent,
  FinfraSubFunctionComponent,
  FinfraFaceRecognitionComponent,
  FinfraEmbedComponent,
  FinfraWysiwygComponent, FinfraDatagridLiteComponent,
} from './widgets/public_api';

@Injectable({
  providedIn: 'root'
})
export class AjsfCoreWidgetsService {

  constructor(
    private injectionService: InjectionService,
  ) {
    this.init();
  }

  public init() {
    this.injectionService.injectService(
      'external-widgets',
      'FinfraCoreAjsfWidgetInitializer',
      this,
      'injectCoreAjsfWidgets'
    );
  }

  public injectCoreAjsfWidgets(widgetLibraryService: any) {
    widgetLibraryService.registerWidget('text', FinfraMaterialInputComponent);
    widgetLibraryService.registerWidget('email', FinfraMaterialInputComponent);
    widgetLibraryService.registerWidget('mobile', FinfraMaterialInputComponent);
    widgetLibraryService.registerWidget('tel', FinfraMaterialInputComponent);
    widgetLibraryService.registerWidget('url', FinfraMaterialInputComponent);
    widgetLibraryService.registerWidget('number', FinfraMaterialNumberComponent);
    widgetLibraryService.registerWidget('range', FinfraMaterialNumberComponent);
    widgetLibraryService.registerWidget('password', FinfraMaterialPasswordComponent);
    widgetLibraryService.registerWidget('pin', FinfraMaterialPasswordComponent);
    widgetLibraryService.registerWidget('textarea', FinfraMaterialTextareaComponent);
    widgetLibraryService.registerWidget('wysiwyg-editor', FinfraWysiwygComponent);
    widgetLibraryService.registerWidget('button', FinfraMaterialButtonComponent);
    widgetLibraryService.registerWidget('button-group', FinfraMaterialButtonGroupComponent);
    widgetLibraryService.registerWidget('checkbox', FinfraMaterialCheckboxComponent);
    widgetLibraryService.registerWidget('checkboxes', FinfraMaterialCheckboxesComponent);
    widgetLibraryService.registerWidget('amount', FinfraAmountComponent);
    widgetLibraryService.registerWidget('image', FinfraImageComponent);
    widgetLibraryService.registerWidget('image-carousel', FinfraImageCarouselComponent);
    widgetLibraryService.registerWidget('select', FinfraMaterialSelectComponent);
    widgetLibraryService.registerWidget('file', FinfraFileComponent);
    widgetLibraryService.registerWidget('label', FinfraLabelComponent);
    widgetLibraryService.registerWidget('radios', FinfraMaterialRadiosComponent);
    widgetLibraryService.registerWidget('date', FinfraMaterialDatepickerComponent);
    widgetLibraryService.registerWidget('date-range', FinfraMaterialDaterangeComponent);
    widgetLibraryService.registerWidget('table-expandable', FinfraTableExpandableComponent);
    widgetLibraryService.registerWidget('autocomplete', FinfraMaterialAutocompleteComponent);
    widgetLibraryService.registerWidget('solr-search', FinfraSolrSearchComponent);
    widgetLibraryService.registerWidget('webcam', FinfraWebcamComponent);
    widgetLibraryService.registerWidget('document-scanner', FinfraDocumentScannerComponent);
    widgetLibraryService.registerWidget('reference-numbers', FinfraReferenceNumbersComponent);
    widgetLibraryService.registerWidget('otp', FinfraOtpComponent);
    widgetLibraryService.registerWidget('datagrid', FinfraDatagridComponent);
    widgetLibraryService.registerWidget('datagrid-lite', FinfraDatagridLiteComponent);
    widgetLibraryService.registerWidget('object-difference', FinfraObjectDifferenceComponent);
    widgetLibraryService.registerWidget('dms', FinfraDMSWidgetComponent);
    widgetLibraryService.registerWidget('sub-function', FinfraSubFunctionComponent);
    widgetLibraryService.registerWidget('face-recognition', FinfraFaceRecognitionComponent);
    widgetLibraryService.registerWidget('embed', FinfraEmbedComponent);

  }

}
