import {AbstractControl, FormGroup} from '@angular/forms';
import {ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit, Optional} from '@angular/core';
import {JsonSchemaFormService} from '@finfra/ajsf-core';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {AjsfCoreWidgetsFunctionsService} from '../../../asjf-core-widgets.functions';
import {HttpCallModel, OTPModel} from '@finfra/core-models';
import {AlertService, ConfigService, DateService, HttpService} from '@finfra/core-services';
import {HttpResponse} from '@angular/common/http';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'finfra-otp-widget',
  templateUrl: './finfra-otp.component.html',
  styleUrls: ['./finfra-otp.component.scss'],
})
export class FinfraOtpComponent implements OnInit, OnDestroy {
  widgetName = 'FinfraOtpComponent';
  formControl: FormGroup;
  functionIdFormControl: AbstractControl;
  controlName: string;
  controlValue: OTPModel;
  controlDisabled = false;
  boundControl = false;
  options: any;
  subProperties: any;
  autoCompleteList: string[] = [];
  @Input() layoutNode: any;
  @Input() layoutIndex: number[];
  @Input() dataIndex: number[];

  timer = 0;
  interval: any;

  translateLabelsName = 'finfra-otp';
  translateLabelsLoaded = false;

  constructor(
    @Inject(MAT_FORM_FIELD_DEFAULT_OPTIONS) @Optional() public matFormFieldDefaultOptions,
    private jsf: JsonSchemaFormService,
    private ajsfCoreWidgetsFunctionsService: AjsfCoreWidgetsFunctionsService,
    private alertService: AlertService,
    private httpService: HttpService,
    private configService: ConfigService,
    private dateService: DateService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.ajsfCoreWidgetsFunctionsService.loadLabels(this);
  }

  ngOnInit() {
    this.options = this.layoutNode.options || {};
    this.subProperties = this.layoutNode.subProperties || {};
    this.jsf.initializeControl(this);
    if (!this.options.notitle && !this.options.description && this.options.placeholder) {
      this.options.description = this.options.placeholder;
    }

    if (!this.controlValue) {
      this.initializeControl();
    }

    if (this.options.functionIdDataPointer) {
      this.functionIdFormControl = this.jsf.getControlService(this, this.jsf.formGroup, this.options.functionIdPointer);
    }

    this.formControl.get('otpValidated').disable();

    if (this.options.generateOtpOnLoad && !this.options.readonly) {
      this.generateOTP();
    }
  }

  ngOnDestroy(): void {
    this.jsf = undefined;
    this.formControl = undefined;
    this.controlName = undefined;
    this.controlValue = undefined;
    this.controlDisabled = undefined;
    this.boundControl = undefined;
    this.options = undefined;
    this.subProperties = undefined;
  }

  initializeControl() {
    this.controlValue = new OTPModel();
    this.controlValue.otpNumber = null;
    this.controlValue.otpReferenceNumber = null;
    this.controlValue.otpValidated = null;
    this.controlValue.otpCreationDate = null;
    this.controlValue.otpExpiryDate = null;
    this.updateValue();
  }

  updateValue() {
    this.jsf.updateValue(this, this.controlValue);
    this.ajsfCoreWidgetsFunctionsService.dispatchEvent(this);
  }

  validate(event) {
    if (this.options.readonly !== undefined && this.options.readonly === true) {
      return;
    }

    if (this.boundControl) {
      this.controlValue = this.formControl.value;
    }

    this.updateValue();
  }

  public generateOTP() {
    if (this.options.readonly) {
      return true;
    }

    if (!this.controlValue.otpValidated) {
      this.initializeControl();
    }

    let path: string;
    if (this.options.generateOtpUrl) {
      path = this.configService.getConfig('global').configApi + this.options.generateOtpUrl;
    } else {
      path = this.configService.getConfig('global').configApi + this.configService.getConfig('global').generateOtpUrl;
    }

    const requestBody: any = {};

    this.options.generateRequestTags.forEach(
      tag => {
        if (tag.dataPointer) {
          requestBody[tag.tag] = this.jsf.getControlService(this, this.jsf.formGroup, tag.dataPointer).value;
        } else {
          requestBody[tag.tag] = tag.value;
        }
      }
    );

    this.httpService.callWS(
      new HttpCallModel(
        path,
        this.options.method,
        this.functionIdFormControl ? this.functionIdFormControl.value : 'finfra-ajsf',
        requestBody)
    ).subscribe(
      result => {
        if (result && result instanceof HttpResponse) {
          if (result.status === 200 && result.body.responseStatus === 'SUCCESS' && result.body.finSuccessDetails) {
            const data: any = result.body.finData[0];
            this.controlValue.otpReferenceNumber = data.otpGUID;
            this.controlValue.otpValidated = null;
            this.controlValue.otpCreationDate = data.otpCreationDate;
            this.controlValue.otpExpiryDate = data.otpExpiryDate;
            this.updateValue();

            this.startTimer();
          }

          this.alertService.showAllMessages(result.body);
        } else {
          this.alertService.showErrorMessage(result);
        }
      },
      error => {
        this.alertService.showErrorMessage(error);
      }
    );
  }

  public validateOTP() {
    if (this.options.readonly) {
      return true;
    }

    this.pauseTimer();
    let path: string;
    if (this.options.validateOtpUrl) {
      path = this.configService.getConfig('global').configApi + this.options.validateOtpUrl;
    } else {
      path = this.configService.getConfig('global').configApi + this.configService.getConfig('global').validateOtpUrl;
    }
    const requestBody: any = {};

    this.options.validateRequestTags.forEach(
      tag => {
        if (tag.dataPointer) {
          requestBody[tag.tag] = this.jsf.getControlService(this, this.jsf.formGroup, tag.dataPointer).value;
        } else {
          requestBody[tag.tag] = tag.value;
        }
      }
    );

    requestBody.otp = this.controlValue.otpNumber;
    requestBody.otpGUID = this.controlValue.otpReferenceNumber;

    this.httpService.callWS(
      new HttpCallModel(
        path,
        this.options.method,
        this.functionIdFormControl ? this.functionIdFormControl.value : 'finfra-ajsf',
        requestBody)
    ).subscribe(
      result => {
        if (result && result instanceof HttpResponse) {
          if (result.status === 200 && result.body.responseStatus === 'SUCCESS') {
            this.alertService.showAllMessages(result.body);
            this.controlValue.otpValidated = true;
          } else {
            this.alertService.showAllMessages(result.body);
            this.controlValue.otpValidated = null;
            this.startTimer();
          }

          this.updateValue();
        } else {
          this.alertService.showErrorMessage(result);
        }
      },
      error => {
        this.alertService.showErrorMessage(error);
      }
    );
  }

  startTimer() {
    this.timer = this.dateService.getSecondsDiff(new Date(), this.controlValue.otpExpiryDate);
    this.interval = setInterval(this.updateTimer.bind(this), 1000);
    this.changeDetectorRef.markForCheck();
  }

  pauseTimer() {
    this.timer = 0;
    this.updateValue();
    clearInterval(this.interval);
    this.changeDetectorRef.markForCheck();
  }

  updateTimer() {
    const dt: Date = new Date();
    this.timer = this.dateService.getSecondsDiff(dt, this.controlValue.otpExpiryDate);

    if (this.controlValue) {
      if (this.timer <= 0 || dt.getTime() >= this.controlValue.otpExpiryDate.getTime()) {
        this.timer = 0;
        this.pauseTimer();
      }
    }

    this.changeDetectorRef.markForCheck();
  }
}
