import { Injectable } from "@angular/core";
import { UIDatabaseService } from "./uidatabase.service";
import { Subject } from "rxjs";


@Injectable({
  providedIn: 'root',
})
export class OpenUIDatabaseService {
  public cacheDbName: string = "finfra-offline-cache";
  public getCacheDbStore: string = "get-cache-store";
  public mappingStore: string = "mapping-store";
  public postCacheDbStore: string = "post-cache-store";

  public transactionDbName: string = "finfra-transaction-cache";
  public transactionDbStore: string = "transaction-store";

  public onlineCacheDbName: string = "finfra-online-cache";
  public onlineCacheDbStore: string = "cache-store";

  public allDatabasesOpened: Subject<any> = new Subject<any>();

  private databaseToOpen: number = 3;
  private databaseOpened: number = 0;

  constructor(
    private uiDatabaseService: UIDatabaseService,
  ) {

  }

  public openAllDatabases() {
    this.databaseOpened = 0;

    this.uiDatabaseService.openDatabase(
      this.cacheDbName,
      this.databaseOpenSuccess.bind(this),
      this.databaseOpenError.bind(this),
      this.createOfflineCacheDatabase.bind(this, this.cacheDbName)
    );

    this.uiDatabaseService.openDatabase(
      this.transactionDbName,
      this.databaseOpenSuccess.bind(this),
      this.databaseOpenError.bind(this),
      this.createTransactionCacheDatabase.bind(this, this.transactionDbName)
    );

    this.uiDatabaseService.openDatabase(
      this.onlineCacheDbName,
      this.databaseOpenSuccess.bind(this),
      this.databaseOpenError.bind(this),
      this.createOnlineCacheDatabase.bind(this, this.onlineCacheDbName)
    );
  }

  public databaseOpenSuccess(event: any) {
    this.databaseOpened++;

    if (this.databaseOpened === this.databaseToOpen) {
      this.allDatabasesOpened.next({ status: "OPENED" });
    }
  }

  public databaseOpenError(event: any) {
    this.databaseOpened++;

    if (this.databaseOpened === this.databaseToOpen) {
      this.allDatabasesOpened.next({ status: "OPENED" });
    }
  }

  public createOfflineCacheDatabase(dbName: string, event: any) {
    let cacheDbStore: any = new Object();
    cacheDbStore.dbStoreName = this.getCacheDbStore;
    cacheDbStore.keyPath = "path";
    cacheDbStore.indexes = [
      {
        "indexName": "storeType",
        "unique": false
      }
    ];

    this.uiDatabaseService.createObjectStore(dbName, cacheDbStore);

    let postCacheDbStore: any = new Object();
    postCacheDbStore.dbStoreName = this.postCacheDbStore;
    postCacheDbStore.keyPath = "path";
    postCacheDbStore.indexes = [
      {
        "indexName": "storeType",
        "unique": false
      }
    ];

    this.uiDatabaseService.createObjectStore(dbName, postCacheDbStore);

    let mappingStore: any = new Object();
    mappingStore.dbStoreName = this.mappingStore;
    mappingStore.keyPath = "path";
    mappingStore.indexes = [
      {
        "indexName": "store",
        "unique": false
      },
      {
        "indexName": "originalPath",
        "unique": false
      }
    ];

    this.uiDatabaseService.createObjectStore(dbName, mappingStore);
  }

  public createOnlineCacheDatabase(dbName: string, event: any) {
    let onlineCacheDbStore: any = new Object();
    onlineCacheDbStore.dbStoreName = this.onlineCacheDbStore;
    onlineCacheDbStore.indexes = [
      {
        "indexName": "cacheType",
        "unique": false
      },
      {
        "indexName": "cacheTime",
        "unique": false
      },
      {
        "indexName": "key",
        "unique": false
      },
      {
        "indexName": "value",
        "unique": false
      }
    ];

    this.uiDatabaseService.createObjectStore(dbName, onlineCacheDbStore);
  }

  public createTransactionCacheDatabase(dbName: string, event: any) {
    let transactionDbStore: any = new Object();
    transactionDbStore.dbStoreName = this.transactionDbStore;
    transactionDbStore.indexes = [
      {
        "indexName": "transactionReferenceNumber",
        "unique": false
      },
      {
        "indexName": "functionId",
        "unique": false
      },
      {
        "indexName": "application",
        "unique": false
      }
    ];

    this.uiDatabaseService.createObjectStore(dbName, transactionDbStore);
  }
}
