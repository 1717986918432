import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from './translate.service';

@Pipe({
    name: 'translateLabel'
})
export class TranslatePipe implements PipeTransform {
    constructor(
        private translateService: TranslateService
    ) {}

    transform(value: string, doc: string, translateLabelsLoaded: boolean, parameters?: any): string | undefined {
        if (translateLabelsLoaded) {
            let label = this.translateService.get(doc, value);

            if (label && parameters) {
              if (typeof parameters === 'string') {
                parameters = JSON.parse(parameters);
              }

              Object.keys(parameters).forEach(
                key => {
                  const re = new RegExp('\\$' + key + '\\$', 'g');
                  label = label?.replace(re, parameters[key]);
                }
              );
            }

            return label;
        } else {
            return value;
        }
    }
}
