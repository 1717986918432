import {AbstractControl, FormGroup} from '@angular/forms';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional
} from '@angular/core';
import {JsonSchemaFormService, isArray, getData} from '@finfra/ajsf-core';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {AjsfCoreWidgetsFunctionsService} from '../../../asjf-core-widgets.functions';
import {Observable, Subscription} from 'rxjs';
import {
  AlertService,
  ConfigService,
  HttpService,
  OfflineStatusService
} from '@finfra/core-services';
import {HttpResponse} from '@angular/common/http';
import {HttpCallModel, SelectItemModel} from '@finfra/core-models';
import {distinctUntilChanged} from 'rxjs/operators';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'finfra-solr-search-widget',
  templateUrl: './finfra-solr-search.component.html',
  styleUrls: ['./finfra-solr-search.component.scss'],
})
export class FinfraSolrSearchComponent implements OnInit, OnDestroy {
  widgetName = 'FinfraSolrSearchComponent';
  formControl: AbstractControl;
  functionIdFormControl: AbstractControl;
  controlName: string;
  controlValue: any;
  controlDisabled = false;
  boundControl = false;
  options: any;
  subProperties: any;
  isArray = isArray;
  fqControls: any[] = [];
  filteredOptions: any[];
  filteredOptionsObservable: Observable<any[]>;
  formControlChangeSubscription: Subscription;
  totalRecords = 0;
  solrData: any[];
  fullObject: any;
  isLoading = false;
  lastQueryString: string;
  lastStartRecord: number;
  totalRecordsFetched: number;
  previousSearchValue: any;
  skipValidate = false;
  @Input() layoutNode: any;
  @Input() layoutIndex: number[];
  @Input() dataIndex: number[];

  fqPointerGroupName: string;
  functionIdDataPointerGroupName: string;

  searchBy: string;
  searchByObj: any;
  filters: any[] = [];

  constructor(
    @Inject(MAT_FORM_FIELD_DEFAULT_OPTIONS) @Optional() public matFormFieldDefaultOptions,
    private jsf: JsonSchemaFormService,
    private ajsfCoreWidgetsFunctionsService: AjsfCoreWidgetsFunctionsService,
    private offlineStatusService: OfflineStatusService,
    private configService: ConfigService,
    private httpService: HttpService,
    private changeDetector: ChangeDetectorRef,
    private alertService: AlertService,
  ) {
  }

  ngOnInit() {
    this.options = this.layoutNode.options || {};
    this.subProperties = this.layoutNode.subProperties || {};
    this.jsf.initializeControl(this, !this.options.readonly);
    if (!this.options.notitle && !this.options.description && this.options.placeholder) {
      this.options.description = this.options.placeholder;
    }

    this.fqPointerGroupName = this.options.fqPointerGroupName;
    this.functionIdDataPointerGroupName = this.options.functionIdDataPointerGroupName;

    this.assignFqControls();

    if (this.formControl.value !== undefined && this.formControl.value !== null) {
      this.validate(null);
    }

    this.searchBy = this.options.searchBy;
    this.options.refreshLoaderColour = this.options.refreshLoaderColour || this.configService.getConfig('global').loaderColor;

    if (this.options.backgroundFetch === undefined || this.options.backgroundFetch === null) {
      this.options.backgroundFetch = true;
    }

    if (this.options.showHttpError === undefined || this.options.showHttpError === null) {
      this.options.showHttpError = true;
    }

    if (this.options.functionIdDataPointer) {
      this.functionIdFormControl = this.jsf.getControlService(this, this._findFormGroup(this.functionIdDataPointerGroupName), this.options.functionIdDataPointer);
    }

    this.formControlChangeSubscription = this.formControl.valueChanges.pipe(
      distinctUntilChanged()
    ).subscribe(this.formControlChanged.bind(this));
  }

  ngOnDestroy(): void {
    this.jsf = undefined;
    this.formControl = undefined;
    this.controlName = undefined;
    this.controlValue = undefined;
    this.controlDisabled = undefined;
    this.boundControl = undefined;
    this.options = undefined;
    this.subProperties = undefined;

    if (this.formControlChangeSubscription) {
      this.formControlChangeSubscription.unsubscribe();
    }
  }

  private _findFormGroup(formGroupName: string): FormGroup {
    if (!this.jsf.externalFormGroupRef || !formGroupName || formGroupName === 'self') {
      return this.jsf.formGroup;
    } else if (Array.isArray(this.jsf.externalFormGroupRef) && this.jsf.externalFormGroupRef.length > 0) {
      for (let idx = 0; idx < this.jsf.externalFormGroupRef.length; idx++) {
        if (this.jsf.externalFormGroupRef[idx].name === formGroupName) {
          return this.jsf.externalFormGroupRef[idx].fg;
        }
      }
    }
  }


  formControlChanged(value: any) {
    if (this.formControl.value !== this.controlValue) {
      this.validate(undefined);
    }
  }

  assignFqControls() {
    if (this.options.fqFields && Array.isArray(this.options.fqFields)) {
      this.options.fqFields.forEach(
        fqField => {
          this.fqControls.push({
            'fqFieldName': fqField.fqFieldName,
            'fqFieldControl': this.jsf.getControlService(this, this._findFormGroup(this.fqPointerGroupName), fqField.fqPointer),
            'likeSearch': fqField.likeSearch
          });
        }
      );
    }
  }

  async search(event: Event) {
    if (event) {
      if (event.defaultPrevented) {
        return;
      }

      event.preventDefault();
    }

    this.isLoading = true;
    this.changeDetector.markForCheck();
    this.filteredOptions = await this.filterValues();
    this.isLoading = false;
    this.changeDetector.markForCheck();
  }

  async filterValues(likeSearch?: boolean): Promise<any[]> {
    if (this.options.readonly !== undefined && this.options.readonly === true) {
      return null;
    }

    if (this.offlineStatusService.offlineStatus && !this.options.offlineValidate) {
      return null;
    }

    if (this.previousSearchValue !== undefined &&
      (this.previousSearchValue === this.formControl.value ||
        (this.previousSearchValue === '' && this.formControl.value === null) ||
        (this.previousSearchValue === null && this.formControl.value === '')
      ) &&
      Array.isArray(this.filteredOptions) &&
      this.filteredOptions.length > 0
    ) {
      return null;
    }

    this.previousSearchValue = this.formControl.value;

    let selectList: any[] = [];
    this.totalRecords = 0;

    if (likeSearch === undefined) {
      likeSearch = true;
    }

    let path: string = this.configService.getConfig('global').solrApi + this.options.url;
    let queryString: string;
    const re = new RegExp(' ', 'g');

    if (!this.searchBy) {
      this.searchBy = this.options.searchBy;
    }

    if (likeSearch) {
      if (this.formControl.value === undefined || this.formControl.value === null || this.formControl.value === '') {
        queryString = 'q=' + this.searchBy + ':*';
      } else {
        queryString = 'q=' + this.searchBy + ':*' + this.formControl.value.replace(re, '%5C ') + '*';
      }
    } else {
      if (this.options.validateBy) {
        if (this.formControl.value === undefined || this.formControl.value === null || this.formControl.value === '') {
          queryString = 'q=' + this.options.validateBy + ':*';
        } else {
          queryString = 'q=' + this.options.validateBy + ':' + this.formControl.value.replace(re, '%5C ');
        }
      } else {
        if (this.formControl.value === undefined || this.formControl.value === null || this.formControl.value === '') {
          queryString = 'q=' + this.searchBy + ':*';
        } else {
          queryString = 'q=' + this.searchBy + ':*' + this.formControl.value.replace(re, '%5C ') + '*';
        }
      }
    }

    if (this.fqControls && this.fqControls.length > 0) {
      this.fqControls.forEach(
        fqControl => {
          if (fqControl.likeSearch) {
            queryString = queryString + '&fq=' + fqControl.fqFieldName + ':*' + fqControl.fqFieldControl.value + '*';
          } else {
            queryString = queryString + '&fq=' + fqControl.fqFieldName + ':' + fqControl.fqFieldControl.value;
          }
        }
      );
    }

    if (Array.isArray(this.filters) && this.filters.length > 0) {
      this.filters.forEach(
        filter => {
          queryString = queryString + '&fq=' + filter.name + ':' + filter.value;
        }
      );
    }

    if (this.options.facet !== undefined && this.options.facet === true) {
      queryString = queryString + '&facet=on&facet.field=' + this.options.facetField + '&facet.pivot=' + this.options.facetField;
    }

    if (this.options.sortBy !== undefined) {
      if (this.options.sortBy.indexOf(' asc') === -1 && this.options.sortBy.indexOf(' desc') === -1) {
        this.options.sortBy = this.options.sortBy + ' asc';
      }

      queryString = queryString + '&sort=' + this.options.sortBy;
    }

    this.lastQueryString = queryString;
    this.lastStartRecord = +(this.options.startRecord !== undefined ? this.options.startRecord : 0);
    this.totalRecordsFetched = +(this.options.noOfRecords !== undefined ? this.options.noOfRecords : 10);

    queryString = queryString + '&start=' + this.lastStartRecord;
    queryString = queryString + '&rows=' + this.totalRecordsFetched;

    path = path + '?' + queryString;

    const httpCall = new HttpCallModel(
      path,
      this.options.method,
      this.functionIdFormControl ? this.functionIdFormControl.value : 'finfra-ajsf',
      undefined
    );

    httpCall.backgroundProcess = this.options.backgroundFetch;

    const results = await this.httpService.callWS(httpCall).toPromise().catch(
      error => {
        if (this.options.showHttpError) {
          this.alertService.showErrorMessage(error);
        }

        this.isLoading = false;
      }
    );

    if (results && results.status === 200 && results instanceof HttpResponse) {
      selectList = this.onPopulateTitleMap(results.body, false);
    }

    return selectList;
  }

  async loadMore() {
    if (+this.totalRecords <= +this.totalRecordsFetched) {
      return;
    }

    let path: string = this.configService.getConfig('global').solrApi + this.options.url;
    let selectList = [];

    this.lastStartRecord = this.totalRecordsFetched;

    let queryString = this.lastQueryString + '&start=' + (+this.lastStartRecord + +(this.options.noOfRecords ? this.options.noOfRecords : 10));
    queryString = queryString + '&rows=' + (this.options.noOfRecords ? this.options.noOfRecords : '10');

    path = path + '?' + queryString;

    const httpCall = new HttpCallModel(
      path,
      this.options.method,
      this.functionIdFormControl ? this.functionIdFormControl.value : 'finfra-ajsf',
      undefined
    );

    httpCall.backgroundProcess = this.options.backgroundFetch;

    const results = await this.httpService.callWS(httpCall).toPromise().catch(
      error => {
        if (this.options.showHttpError) {
          this.alertService.showErrorMessage(error);
        }
      }
    );

    if (results && results.status === 200 && results instanceof HttpResponse) {
      selectList = this.onPopulateTitleMap(results.body, true);
    }

    this.totalRecordsFetched = +this.totalRecordsFetched + +(this.options.noOfRecords ? this.options.noOfRecords : 10);
    if (this.totalRecordsFetched > this.totalRecords) {
      this.totalRecordsFetched = this.totalRecords;
    }

    this.filteredOptions = [...new Set([...this.filteredOptions, ...selectList])];
    this.changeDetector.markForCheck();
  }

  updateValue() {
    this.options.showErrors = true;
    this.jsf.updateValue(this, this.formControl.value);
    this.ajsfCoreWidgetsFunctionsService.dispatchEvent(this);
  }

  async validate(event) {
    if (this.options.readonly !== undefined && this.options.readonly === true) {
      return;
    }

    if (this.skipValidate) {
      this.skipValidate = false;
      return;
    }

    this.fullObject = null;

    if (this.formControl.value === undefined || this.formControl.value === null || this.formControl.value === '') {
      await this.search(null);
    }

    let valid = false;

    if (this.filteredOptions && Array.isArray(this.filteredOptions) && this.filteredOptions.length > 0) {
      const valueChecks = this.filteredOptions.filter(v => v.value !== null);
      valueChecks.forEach(
        filteredOption => {
          if (this.formControl.value === undefined || this.formControl.value === null) {
            if (filteredOption.value === this.formControl.value) {
              this.fullObject = filteredOption.fullObject;
              this.controlValue = filteredOption.value;
              this.formControl.setValue(filteredOption.value);
              valid = true;
            }
          } else if (filteredOption.value.toLowerCase() === this.formControl.value.toLowerCase()) {
            this.fullObject = filteredOption.fullObject;
            this.controlValue = filteredOption.value;
            this.formControl.setValue(filteredOption.value);
            valid = true;
          }
        }
      );
    } else {
      this.filteredOptions = await this.filterValues(false);

      if (this.filteredOptions && Array.isArray(this.filteredOptions) && this.filteredOptions.length > 0) {
        const valueChecks = this.filteredOptions.filter(v => v.value !== null);
        valueChecks.forEach(
          filteredOption => {
            if (this.formControl.value === undefined || this.formControl.value === null) {
              if (filteredOption.value === this.formControl.value) {
                this.fullObject = filteredOption.fullObject;
                this.controlValue = filteredOption.value;
                this.formControl.setValue(filteredOption.value);
                valid = true;
              }
            } else if (filteredOption.value.toLowerCase() === this.formControl.value.toLowerCase()) {
              this.fullObject = filteredOption.fullObject;
              this.controlValue = filteredOption.value;
              this.formControl.setValue(filteredOption.value);
              valid = true;
            }
          }
        );
      }
    }

    if (!valid) {
      this.formControl.reset();
      this.formControl.markAsDirty();
    }

    this.updateValue();
  }

  private onPopulateTitleMap(results: any, validateSearch: boolean): any[] {
    this.solrData = results;

    this.totalRecords = +getData(this.solrData, this.options.noOfRecordsPointer);

    if (this.options.rootPointer && this.options.rootTag !== '') {
      this.solrData = getData(this.solrData, this.options.rootPointer);
    }

    const selectList: any[] = [];

    if (this.options.defaultMap && Array.isArray(this.options.defaultMap)) {
      this.options.defaultMap.forEach(
        defaultValue => {
          const titleMapValue: any = {};
          titleMapValue.name = defaultValue.name;
          titleMapValue.value = defaultValue.value;
          titleMapValue.html = defaultValue.html;
          titleMapValue.fullObject = {};
          titleMapValue.fullObject.selectedValue = defaultValue.value;

          selectList.push(titleMapValue);
        }
      );
    }

    if (this.solrData && Array.isArray(this.solrData) && this.solrData.length > 0) {
      this.solrData.forEach(element => {
        let value: string = this.options.tags.valueTag;
        let name: string = this.options.tags.nameTag;
        let html: string = this.options.tags.htmlTag;
        const tagsUsed: string[] = this.options.tags.tagsUsed;

        tagsUsed.forEach(tag => {
          let tagValue = '';

          if (element[tag]) {
            tagValue = element[tag];
          }

          if (this.options.tags.tagDecoders && this.options.tags.tagDecoders[tag] && this.options.tags.tagDecoders[tag][tagValue]) {
            tagValue = this.options.tags.tagDecoders[tag][tagValue];
          }

          const re = new RegExp('\\$' + tag + '\\$', 'g');
          value = value.replace(re, tagValue);

          if (name) {
            name = name.replace(re, tagValue);
          }

          if (html) {
            if (!tagValue || tagValue === '') {
              tagValue = '&nbsp;';
            }

            html = html.replace(re, tagValue);
          }
        });

        const titleMapValue: any = {};
        titleMapValue.name = name;
        titleMapValue.value = value;
        titleMapValue.html = html;
        titleMapValue.fullObject = element;
        titleMapValue.fullObject.selectedValue = value;

        selectList.push(titleMapValue);
      });
    }

    if (!!!this.options.required && !validateSearch) {
      selectList.unshift({name: 'None', value: null});
    }

    return selectList;
  }

  optionSelected(event) {

  }

  optionClicked(optionSelected) {
    this.skipValidate = true;
    this.formControl.setValue(optionSelected.value);
    this.updateValue();
  }

  onFocusOut(event) {
    setTimeout(this.validate.bind(this, event), 500);
  }

  async processEnter(event) {
    if (this.formControl.value === undefined || this.formControl.value === null) {
      this.search(event);
      return;
    }

    const text: string = this.formControl.value;
    let processed = false;
    if (text.charAt(text.length - 1) === ';') {
      const elems: string[] = text.substring(0, text.length - 1).split(':');

      if (Array.isArray(this.options.filterByOptions)) {
        const filterBy = this.options.filterByOptions.find(filter => filter.name === elems[0]);

        if (filterBy) {
          if (!Array.isArray(this.filters)) {
            this.filters = [];
          }

          this.filters = this.filters.filter((x) => x.name !== filterBy.value);
          this.filters.push({name: filterBy.value, label: filterBy.name, value: elems[1]});
          this.formControl.setValue(null);
          processed = true;
        }
      }
    } else if (text.charAt(text.length - 1) === ':') {
      const searchTag = text.substring(0, text.length - 1);

      if (Array.isArray(this.options.searchByOptions)) {
        this.searchByObj = this.options.searchByOptions.find(filter => filter.name === searchTag);

        if (this.searchByObj) {
          this.searchBy = this.searchByObj.value;
          this.formControl.setValue(null);
          processed = true;
        }
      }
    }

    if (!processed) {
      this.search(event);
    } else {
      if (event) {
        event.preventDefault();
      }
    }
  }

  removeFilter(filter: SelectItemModel) {
    this.filters = this.filters.filter((x) => x.name !== filter.name);
    this.previousSearchValue = undefined;
  }

  removeSearchBy() {
    this.searchBy = this.options.searchBy;
    this.searchByObj = undefined;
    this.previousSearchValue = undefined;
  }
}
