import { AbstractControl } from '@angular/forms';
import { Component, Inject, Input, OnDestroy, OnInit, Optional, TemplateRef, ViewChild } from '@angular/core';
import { JsonSchemaFormService } from '@finfra/ajsf-core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { AlertService } from '@finfra/core-services';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { AjsfCoreWidgetsFunctionsService } from '../../../asjf-core-widgets.functions';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'finfra-image-widget',
  templateUrl: './finfra-image.component.html',
  styleUrls: ['./finfra-image.component.scss'],
})
export class FinfraImageComponent implements OnInit, OnDestroy {
  widgetName: string = "FinfraMaterialImageComponent";
  formControl: AbstractControl;
  controlName: string;
  controlValue: any;
  controlDisabled = false;
  boundControl = false;
  options: any;
  subProperties: any;
  autoCompleteList: string[] = [];
  selectedImage: any;
  @Input() layoutNode: any;
  @Input() layoutIndex: number[];
  @Input() dataIndex: number[];

  @ViewChild('imageExpandDialog', { static: false }) imageExpandDialog: TemplateRef<any>;

  constructor(
    @Inject(MAT_FORM_FIELD_DEFAULT_OPTIONS) @Optional() public matFormFieldDefaultOptions,
    private jsf: JsonSchemaFormService,
    private alertService: AlertService,
    private dialog: MatDialog,
    private domSanitizer: DomSanitizer,
    private ajsfCoreWidgetsFunctionsService: AjsfCoreWidgetsFunctionsService,
  ) {
  }

  ngOnInit() {
    this.options = this.layoutNode.options || {};
    this.subProperties = this.layoutNode.subProperties || {};
    this.jsf.initializeControl(this);
    if (!this.options.notitle && !this.options.description && this.options.placeholder) {
      this.options.description = this.options.placeholder;
    }
  }

  ngOnDestroy(): void {
    this.jsf = undefined;
    this.formControl = undefined;
    this.controlName = undefined;
    this.controlValue = undefined;
    this.controlDisabled = undefined;
    this.boundControl = undefined;
    this.options = undefined;
    this.subProperties = undefined;
  }

  updateValue(event) {
    this.jsf.updateValue(this, event.target.value);
  }

  expandImage() {
    this.dialog.open(this.imageExpandDialog, {
      height: '90vh',
    });
  }

  onImageClick(event) {
    this.alertService.info("FinfraImageComponent: on select change " + this.controlValue);
    this.alertService.info(event);

    if (this.boundControl) {
      this.selectedImage = this.formControl.value;
    } else {
      this.selectedImage = this.controlValue;
    }

    this.expandImage();
    this.ajsfCoreWidgetsFunctionsService.dispatchEvent(this);
  }

  getImageObject(src: string) {
    if (src === undefined || src === null) {
      return undefined;
    }

    if(this.options.base64 && (src.indexOf('data:') < 0 && src.indexOf('file:') < 0 && src.indexOf('content:') < 0)){
      src = 'data:image/png;base64,' + src;
    }
    else{
      src = src;
    }

    return this.domSanitizer.bypassSecurityTrustResourceUrl(src);
  }
}
