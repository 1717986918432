export interface OTPModel {
  otpNumber?: string | undefined | null;
  otpReferenceNumber?: string | undefined | null;
  otpValidated?: boolean | undefined | null;
  otpCreationDate?: Date | undefined | null;
  otpExpiryDate?: Date | undefined | null;
}

export class OTPModel implements OTPModel {
  constructor(
    public otpNumber?: string | undefined | null,
    public otpReferenceNumber?: string | undefined | null,
    public otpValidated?: boolean | undefined | null,
    public otpCreationDate?: Date | undefined | null,
    public otpExpiryDate?: Date | undefined | null,
  ) { }
}
