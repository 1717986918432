import { Component, Inject, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { JsonSchemaFormService } from '@finfra/ajsf-core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { AjsfCoreWidgetsFunctionsService } from '../../../asjf-core-widgets.functions';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'finfra-material-number-widget',
  templateUrl: './finfra-material-number.component.html',
  styleUrls: ['./finfra-material-number.component.scss'],
})
export class FinfraMaterialNumberComponent implements OnInit, OnDestroy {
  widgetName = 'FinfraMaterialNumberComponent';
  formControl: AbstractControl;
  controlName: string;
  controlValue: any;
  controlDisabled = false;
  boundControl = false;
  options: any;
  subProperties: any;
  allowNegative = true;
  allowDecimal = true;
  allowExponents = false;
  lastValidNumber = '';
  @Input() layoutNode: any;
  @Input() layoutIndex: number[];
  @Input() dataIndex: number[];

  constructor(
    @Inject(MAT_FORM_FIELD_DEFAULT_OPTIONS) @Optional() public matFormFieldDefaultOptions,
    private jsf: JsonSchemaFormService,
    private ajsfCoreWidgetsFunctionsService: AjsfCoreWidgetsFunctionsService
  ) { }

  ngOnInit() {
    this.options = this.layoutNode.options || {};
    this.subProperties = this.layoutNode.subProperties || {};
    this.jsf.initializeControl(this);
    if (this.layoutNode.dataType === 'integer') { this.allowDecimal = false; }
    if (!this.options.notitle && !this.options.description && this.options.placeholder) {
      this.options.description = this.options.placeholder;
    }
  }

  ngOnDestroy(): void {
    this.jsf = undefined;
    this.formControl = undefined;
    this.controlName = undefined;
    this.controlValue = undefined;
    this.controlDisabled = undefined;
    this.boundControl = undefined;
    this.options = undefined;
    this.subProperties = undefined;
  }

  updateValue() {
    this.jsf.updateValue(this, this.controlValue);
  }

  validate(event): boolean {
    if (this.options.readonly !== undefined && this.options.readonly === true) {
      return false;
    }

    this.controlValue = event.target.value;

    if (this.controlValue !== undefined && this.controlValue !== null) {
      if (this.options.maximum && this.controlValue > this.options.maximum) {
        this.controlValue = null;
        this.jsf.updateValue(this, null);
        return false;
      }

      if (this.options.minimum && this.controlValue < this.options.minimum) {
        this.controlValue = null;
        this.jsf.updateValue(this, null);
        return false;
      }
    } else {
      this.updateValue();
    }

    this.ajsfCoreWidgetsFunctionsService.dispatchEvent(this);
    return true;
  }
}
