import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatMomentDateModule } from '@angular/material-moment-adapter'; // Profinch modification - Changed from MatNativeDateModule to MatMomentDateModule
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FINFRA_CORE_WIDGETS_COMPONENTS } from './widgets/public_api';
import { AjsfCoreWidgetsService } from './asjf-core-widgets.service';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';
import { SecureGetModule, TranslateModule } from '@finfra/core-decorators';
import { ImageCropperModule } from 'ngx-image-cropper';
import { JsonSchemaFormModule } from '@finfra/ajsf-core';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { A11yModule } from '@angular/cdk/a11y';


export const ANGULAR_MATERIAL_MODULES = [
  MatAutocompleteModule, MatButtonModule, MatButtonToggleModule, MatCardModule,
  MatCheckboxModule, MatChipsModule, MatDatepickerModule, MatExpansionModule,
  MatFormFieldModule, MatIconModule, MatInputModule, MatMomentDateModule,
  MatRadioModule, MatSelectModule, MatSliderModule, MatSlideToggleModule,
  MatStepperModule, MatTabsModule, MatTooltipModule,
  MatToolbarModule, MatMenuModule, MatToolbarModule,
  MatTableModule, MatPaginatorModule, MatDialogModule,
];


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    TranslateModule,
    SecureGetModule,
    ImageCropperModule,
    JsonSchemaFormModule,
    AngularEditorModule,
    A11yModule,
    ...ANGULAR_MATERIAL_MODULES,
  ],
  declarations: [
    ...FINFRA_CORE_WIDGETS_COMPONENTS
  ],
  exports: [
    ...FINFRA_CORE_WIDGETS_COMPONENTS
  ],
  entryComponents: [
    ...FINFRA_CORE_WIDGETS_COMPONENTS
  ],
  providers: []
})

export class FinfraCoreAjsfWidgetsModule {
  constructor(private ajsfCoreWidgetsService: AjsfCoreWidgetsService) { }
}
