import {Component, Inject, Input, OnDestroy, OnInit, Optional} from '@angular/core';
import {AbstractControl, FormControl} from '@angular/forms';
import {JsonSchemaFormService} from '@finfra/ajsf-core';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {AjsfCoreWidgetsFunctionsService} from '../../../asjf-core-widgets.functions';
import {ConfigService, DateService} from '@finfra/core-services';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatDateFormats
} from '@angular/material/core';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';

// the `default as` syntax.
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import {default as _rollupMoment} from 'moment';
import {Subscription} from 'rxjs';

const moment = _rollupMoment || _moment;

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'finfra-material-datepicker-widget',
  templateUrl: './finfra-material-datepicker.component.html',
  styleUrls: ['./finfra-material-datepicker.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ]
})
export class FinfraMaterialDatepickerComponent implements OnInit, OnDestroy {
  widgetName = 'FinfraMaterialDatepickerComponent';
  formControl: AbstractControl;
  controlName: string;
  controlValue: string;
  dateValue: any;
  controlDisabled = false;
  boundControl = false;
  options: any;
  subProperties: any;
  autoCompleteList: string[] = [];
  datesFilterFunction: Function;
  dynFn: any;
  formControlChangeSubscription: Subscription;
  dateControlChangeSubscription: Subscription;
  @Input() layoutNode: any;
  @Input() layoutIndex: number[];
  @Input() dataIndex: number[];

  maximumControl: AbstractControl = new FormControl();
  minimumControl: AbstractControl = new FormControl();
  dateControl: FormControl = new FormControl();
  readonlyDateControl: FormControl = new FormControl();
  dateErrorMessage: string;

  constructor(
    @Inject(MAT_FORM_FIELD_DEFAULT_OPTIONS) @Optional() public matFormFieldDefaultOptions,
    @Inject(MAT_DATE_FORMATS) private matDateFormats: MatDateFormats,
    private jsf: JsonSchemaFormService,
    private ajsfCoreWidgetsFunctionsService: AjsfCoreWidgetsFunctionsService,
    private dateService: DateService,
    private configService: ConfigService
  ) {
  }

  ngOnInit() {
    this.datesFilterFunction = this.datesFilter.bind(this);

    this.matDateFormats.parse.dateInput = this.configService.getConfig('global').momentParseDateInput;
    this.matDateFormats.display.dateInput = this.configService.getConfig('global').momentDisplayDateInput;
    this.matDateFormats.display.monthYearLabel = this.configService.getConfig('global').momentDisplayMonthYearLabel;
    this.matDateFormats.display.dateA11yLabel = this.configService.getConfig('global').momentDisplayDateA11yLabel;
    this.matDateFormats.display.monthYearA11yLabel = this.configService.getConfig('global').momentDisplayMonthYearA11yLabel;

    this.options = this.layoutNode.options || {};
    this.subProperties = this.layoutNode.subProperties || {};
    this.jsf.initializeControl(this, !this.options.readonly);

    if (!this.options.notitle && !this.options.description && this.options.placeholder) {
      this.options.description = this.options.placeholder;
    }

    if (this.options.maximum) {
      if (this.options.maximum === 'today') {
        this.maximumControl.setValue(new Date(new Date().toDateString()));
      } else if (this.options.maximum === 'todayDiff') {
        const dt: Date = new Date(new Date().toDateString());
        dt.setDate(dt.getDate() - +this.options.maximumDifferenceDays);
        this.maximumControl.setValue(dt);
      } else if (this.options.maximum === 'applicationDate') {
        this.maximumControl.setValue(new Date(this.dateService.getTodayDate().toDateString()));
      } else if (this.options.maximum === 'applicationDateDiff') {
        const dt: Date = new Date(this.dateService.getTodayDate().toDateString());
        dt.setDate(dt.getDate() - +this.options.maximumDifferenceDays);
        this.maximumControl.setValue(dt);
      } else if (this.options.maximum === 'pointer') {
        this.maximumControl = this.jsf.getControlService(this, this.jsf.formGroup, this.options.maximumPointer);
      } else {
        this.maximumControl.setValue(new Date(this.options.maximum));
      }
    }

    if (this.options.minimum) {
      if (this.options.minimum === 'today') {
        this.minimumControl.setValue(new Date(new Date().toDateString()));
      } else if (this.options.minimum === 'todayDiff') {
        const dt: Date = new Date(new Date().toDateString());
        dt.setDate(dt.getDate() - +this.options.minimumDifferenceDays);
        this.minimumControl.setValue(dt);
      } else if (this.options.minimum === 'applicationDate') {
        this.minimumControl.setValue(new Date(this.dateService.getTodayDate().toDateString()));
      } else if (this.options.minimum === 'applicationDateDiff') {
        const dt: Date = new Date(this.dateService.getTodayDate().toDateString());
        dt.setDate(dt.getDate() - +this.options.minimumDifferenceDays);
        this.minimumControl.setValue(dt);
      } else if (this.options.minimum === 'pointer') {
        this.minimumControl = this.jsf.getControlService(this, this.jsf.formGroup, this.options.minimumPointer);
      } else {
        this.minimumControl.setValue(new Date(this.options.minimum));
      }
    }

    this.formControlChangeSubscription = this.formControl.valueChanges.subscribe(this.valueChange.bind(this));
    this.dateControlChangeSubscription = this.dateControl.valueChanges.subscribe(this.dateControlChanged.bind(this));

    this.assignDateControl();
  }

  ngOnDestroy(): void {
    this.jsf = undefined;
    this.formControl = undefined;
    this.controlName = undefined;
    this.controlValue = undefined;
    this.controlDisabled = undefined;
    this.boundControl = undefined;
    this.options = undefined;
    this.subProperties = undefined;

    if (this.formControlChangeSubscription) {
      this.formControlChangeSubscription.unsubscribe();
    }

    if (this.dateControlChangeSubscription) {
      this.dateControlChangeSubscription.unsubscribe();
    }
  }

  assignDateControl() {
    if (this.formControl.value) {
      if (this.formControl.value instanceof Date) {
        this.dateControl.setValue(moment([this.formControl.value.getFullYear(), this.formControl.value.getMonth(), this.formControl.value.getDate()]));
      } else {
        this.dateControl.setValue(moment([
          +this.formControl.value.substring(0, 4),
          +this.formControl.value.substring(5, 7) - 1,
          +this.formControl.value.substring(8, 10),
        ]));
      }
    } else {
      this.dateControl.setValue(null);
    }
  }

  dateControlChanged() {
    this.readonlyDateControl.setValue(this.dateControl.value?.format(this.configService.getConfig('global').momentDisplayDateInput));

    this.options.dateErrorMessage = this.dateControl.status === 'VALID' || this.dateControl.errors === undefined || this.dateControl.errors === null ? null : this.jsf.formatErrors(
      this.dateControl.errors,
      this.options.validationMessages
    );
  }

  valueChange() {
    if (!this.formControl.valid && this.formControl.value) {
      this.dateControl.setValue(null);
      this.setDate(null);
    } else {
      const dt = this.dateControl.value?.format(this.configService.getConfig('global').momentDateConverter);

      if (dt !== this.formControl.value) {
        this.assignDateControl();
      }
    }
  }

  updateValue(event: MatDatepickerInputEvent<any>) {
    this.options.showErrors = true;

    if (this.dateControl.status === 'INVALID') {
      this.dateControl.setValue(null);
    }

    let dt: Date = null;

    if (this.dateControl.value) {
      if (this.dateControl.value._d) {
        dt = this.dateControl.value._d;
      } else {
        dt = this.dateControl.value;
      }
    }

    this.setDate(dt);
  }

  setDate(dt: Date) {
    if (dt) {
      this.controlValue = this.dateService.formatDateServer(dt);
    } else {
      this.controlValue = null;
    }

    this.formControl.setValue(this.controlValue);
    this.ajsfCoreWidgetsFunctionsService.dispatchEvent(this);
  }

  datesFilter(dt: Date): boolean {
    if (!Array.isArray(this.options.datesFilter)) {
      if (this.options.datesFilter && typeof this.options.datesFilter.functionBody === 'string') {
        try {
          if (!this.dynFn) {
            this.dynFn = new Function(
              'model',
              'arrayIndices',
              this.options.datesFilter.functionBody
            );
          }

          return this.dynFn(this.dateControl.value, this.dataIndex);
        } catch (e) {
          console.error(
            'dates filter functionBody errored out on evaluation: ' +
            this.options.datesFilter.functionBody
          );
        }
      } else {
        return true;
      }
    } else {
      let tempDate: any = dt;

      if (tempDate._d) {
        tempDate = tempDate._d;
      }

      for (let idx = 0; idx < this.options.datesFilter.length; idx++) {
        if (tempDate.getDay() === this.options.datesFilter[idx]) {
          return false;
        }
      }

      return true;
    }
  }
}
