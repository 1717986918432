
import { FinResponseCode } from './FinResponseCode.model';

export interface FinResponseCodes {
  finSuccessDetails?: FinResponseCode[];
  finErrorDetails?: FinResponseCode[];
}

export class FinResponseCodes implements FinResponseCodes {
  constructor(
    public finSuccessDetails?: FinResponseCode[],
    public finErrorDetails?: FinResponseCode[],
  ) { }
}
