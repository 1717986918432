import { AbstractControl } from '@angular/forms';
import {Component, Inject, Input, OnDestroy, OnInit, Optional} from '@angular/core';
import { JsonSchemaFormService } from '@finfra/ajsf-core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { AjsfCoreWidgetsFunctionsService } from '../../../asjf-core-widgets.functions';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'finfra-material-textarea-widget',
  templateUrl: './finfra-material-textarea.component.html',
  styleUrls: ['./finfra-material-textarea.component.scss'],
})
export class FinfraMaterialTextareaComponent implements OnInit, OnDestroy {
  widgetName: string = "FinfraMaterialTextareaComponent";
  formControl: AbstractControl;
  controlName: string;
  controlValue: any;
  controlDisabled = false;
  boundControl = false;
  options: any;
  subProperties: any;
  @Input() layoutNode: any;
  @Input() layoutIndex: number[];
  @Input() dataIndex: number[];

  constructor(
    @Inject(MAT_FORM_FIELD_DEFAULT_OPTIONS) @Optional() public matFormFieldDefaultOptions,
    private jsf: JsonSchemaFormService,
    public ajsfCoreWidgetsFunctionsService: AjsfCoreWidgetsFunctionsService
  ) { }

  ngOnInit() {
    this.options = this.layoutNode.options || {};
    this.subProperties = this.layoutNode.subProperties || {};
    this.jsf.initializeControl(this);
    if (!this.options.notitle && !this.options.description && this.options.placeholder) {
      this.options.description = this.options.placeholder;
    }
  }

  ngOnDestroy(): void {
    this.jsf = undefined;
    this.formControl = undefined;
    this.controlName = undefined;
    this.controlValue = undefined;
    this.controlDisabled = undefined;
    this.boundControl = undefined;
    this.options = undefined;
    this.subProperties = undefined;
  }

  updateValue(event) {
    this.jsf.updateValue(this, event.target.value);
    this.ajsfCoreWidgetsFunctionsService.dispatchEvent(this);
  }
}
