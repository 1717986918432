import {HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {GenericEventModel, HttpCallModel, PageModel, ParameterModel, User} from '@finfra/core-models';
import {HttpService} from '../core-services/http.service';
import {AlertService} from '../core-services/alert.service';
import {ConfigService} from '../core-services/config.service';
import {SessionService} from '../core-services/session.service';
import {SpinnerService} from '../core-services/spinner.service';
import {EventsService} from '../core-services/events.service';
import {filter} from 'rxjs/operators';
import {Subscription} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FinfraMenuService {
  public menuDerived = false;
  public userAppsList: any[] | undefined;
  public currentApplicationId: string | undefined;
  public currentMenu: any | undefined;
  public currentApplicationMenu: any[] | undefined;
  public currentGroups: any[] | undefined;
  public currentApplication: any;
  public allApplications: any[] | undefined;
  public currentPath: string | undefined;

  public userMenu: Map<string, any> | undefined;
  public flattenedUserMenu: Map<string, any> | undefined;
  public flattenedUserMenuPath: Map<string, any> | undefined;

  public userMenuPreferences: any;
  public oldUserMenuPreferences: any;
  public frequentlyVisitedFunctions: any[] = [];
  private pushInterval: number | undefined;
  private pushTime: number | undefined;
  private routerNavStartSubscription: Subscription | undefined;
  private routerNavEndSubscription: Subscription | undefined;
  private eventsSubscription: Subscription | undefined;
  private currUser: User | undefined;

  public unSavedChanges = false;
  public unSavedPromptMessageLabel: string | undefined;
  public unSavedPromptConfirmLabel: string | undefined;
  public unSavedPromptCancelLabel: string | undefined;

  constructor(
    private sessionService: SessionService,
    private alertService: AlertService,
    private configService: ConfigService,
    private router: Router,
    private httpService: HttpService,
    private spinnerService: SpinnerService,
    private eventsService: EventsService,
  ) {
    this.routerNavStartSubscription = this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe(this.navigationStart.bind(this));
    this.routerNavEndSubscription = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(this.navigationEnd.bind(this));
    this.eventsSubscription = this.eventsService.listenEvents().subscribe(this.onMenuEvent.bind(this));
  }

  public navigationStart(event: any): void {
    if (event.navigationTrigger === 'popstate') {
      if (this.flattenedUserMenuPath) {
        const menu = this.flattenedUserMenuPath.get(event.url);

        if (menu) {
          this.currentMenu = menu;
          this.currentPath = menu.path;
          this.currentApplicationId = menu.applicationId;
          this.currentApplicationMenu = this.flattenedUserMenu?.get(menu.applicationId).functions;
          this.currentApplication = this.flattenedUserMenu?.get(menu.applicationId);
          this.groupMenus();
        } else {
          this.currentMenu = undefined;
          this.currentPath = undefined;
          this.currentApplicationId = undefined;
          this.currentApplicationMenu = undefined;
          this.currentApplication = undefined;
          this.frequentlyVisitedFunctions = [];
        }
      }
    }
  }

  public navigationEnd(event: any): void {
    this.unSavedChanges = false;

    if (!this.userMenuPreferences) {
      this.userMenuPreferences = {};
    }

    if (!this.userMenuPreferences.userFunctionCount) {
      this.userMenuPreferences.userFunctionCount = {};
    }

    if (this.currUser && this.userMenuPreferences && this.userMenuPreferences.userFunctionCount) {
      if (this.currentMenu && this.currentMenu.type === 'function') {
        const applicationId = this.currentMenu.applicationId;

        if (!this.userMenuPreferences.userFunctionCount) {
          this.userMenuPreferences.userFunctionCount = {};
        }

        let applicationData = this.userMenuPreferences.userFunctionCount[applicationId];

        if (!applicationData) {
          applicationData = {};
        }

        const count = applicationData[this.currentMenu.id];
        if (count !== undefined && count !== null) {
          applicationData[this.currentMenu.id] = (+count + 1);
        } else {
          applicationData[this.currentMenu.id] = 1;
        }

        this.userMenuPreferences.userFunctionCount[applicationId] = applicationData;

        if (!this.configService.getConfig('global').menuPushIntervalBased) {
          this.pushData();
        }
      }
    }
  }

  public init(functionId: string): void {
    this.currUser = this.sessionService.getUser();
    this.currentPath = this.router.url;

    if (this.currUser !== undefined && this.currUser !== null) {
      this.readAllApplicationsForUser(functionId).then();
    }
  }

  public destroy(): void {
    this.sessionService.clearSessionData('userAppsList');
    this.sessionService.clearSessionData('userMenu');
    this.sessionService.clearSessionData('flattenedUserMenu');
    this.sessionService.clearSessionData('flattenedUserMenuPath');
    this.userMenu = undefined;
    this.flattenedUserMenu = undefined;
    this.flattenedUserMenuPath = undefined;
    this.currentApplicationId = undefined;
    this.currentApplicationMenu = undefined;
    this.currentApplication = undefined;
    this.currentMenu = undefined;
    this.currentPath = undefined;
    this.userAppsList = undefined;
    this.currUser = undefined;
    this.userMenuPreferences = undefined;
    this.frequentlyVisitedFunctions = [];

    if (this.pushInterval) {
      clearInterval(this.pushInterval);
      this.pushInterval = undefined;
    }

    this.fireEvent('menu-destroyed', 'menu-destroyed');
  }

  private persistMenuToSession(): void {
    if (!this.userMenu) {
      this.userMenu = new Map<string, any>();
    }

    if (!this.flattenedUserMenu) {
      this.flattenedUserMenu = new Map<string, any>();
    }

    if (!this.flattenedUserMenuPath) {
      this.flattenedUserMenuPath = new Map<string, any>();
    }

    if (this.userMenu) {
      this.sessionService.setSessionData('userMenu', JSON.stringify([...this.userMenu]));
    } else {
      this.sessionService.clearSessionData('userMenu');
    }
  }

  private deriveFlattenedUserMenu(): void {
    this.flattenedUserMenu = new Map<string, string>();
    this.flattenedUserMenuPath = new Map<string, any>();

    this.userMenu?.forEach(
      menu => {
        this.flattenedUserMenu?.set(menu.id, menu);
        this.flattenedUserMenuPath?.set(menu.path, menu);

        if (menu.functions) {
          menu.functions.forEach(
            (fnc: any) => {
              this.flattenedUserMenu?.set(fnc.id, fnc);
              this.flattenedUserMenuPath?.set(fnc.path, fnc);
            }
          );
        }
      }
    );
  }

  private async readAllApplicationsForUser(functionId: string): Promise<boolean> {
    const currUser = this.sessionService.getUser();

    if (!currUser) {
      return false;
    }

    this.userAppsList = this.sessionService.getSessionData('userAppsList');

    if (typeof this.userAppsList === 'string') {
      this.userAppsList = JSON.parse(this.userAppsList);
    }

    if (!this.userAppsList) {
      const parameterModel: ParameterModel = new ParameterModel();
      parameterModel.stringParameters = new Map<string, string>();
      parameterModel.stringParameters.set('userId', currUser.userId);
      parameterModel.stringParameters.set('applicationType', 'Angular');

      const path = this.configService.getConfig('global').configApi + this.configService.getConfig('iam-api').getApplicationForUserGroupRole;
      const httpCallModel = new HttpCallModel(
        path,
        'POST',
        functionId,
        undefined,
        parameterModel,
      );

      httpCallModel.backgroundProcess = false;

      const allApplicationsOfUser = await this.httpService.callWS(httpCallModel).toPromise().catch(
        error => {
          this.alertService.showErrorMessage(error);
          return undefined;
        }
      );

      if (allApplicationsOfUser && allApplicationsOfUser.status === 200 && allApplicationsOfUser instanceof HttpResponse) {
        this.userAppsList = allApplicationsOfUser.body.finData;
        this.sessionService.setSessionData('userAppsList', this.userAppsList);
      }
    }

    return this.generateMenu();
  }

  private fireEvent(eventType: string, eventName: string): void {
    const eventObject = new GenericEventModel();

    eventObject.eventSource = 'finfra-menu';
    eventObject.eventName = eventName;
    eventObject.eventObjectName = eventName;
    eventObject.eventType = eventType;

    this.eventsService.fireEvent(eventObject);
  }

  private async generateMenu(): Promise<boolean> {
    if (this.sessionService.getSessionData('userMenu')) {
      this.userMenu = new Map(JSON.parse(this.sessionService.getSessionData('userMenu')));
      this.deriveFlattenedUserMenu();
    }

    if (!this.userMenu) {
      let menuJson = this.configService.getConfig('menu');
      if (typeof menuJson === 'string') {
        menuJson = JSON.parse(menuJson);
      }
      this.userMenu = new Map<string, any>();
      this.flattenedUserMenu = new Map<string, any>();
      this.flattenedUserMenuPath = new Map<string, any>();

      if (this.userAppsList) {
        this.userAppsList.forEach(
          userApp => {
            const appMenu = menuJson.filter((m: any) => m.id === userApp.applicationId)[0];

            if (appMenu) {
              appMenu.functions = [];
              appMenu.gotFunctions = false;
              appMenu.applicationId = appMenu.id;

              if (!this.userMenu) {
                this.userMenu = new Map<string, any>();
              }

              if (!this.flattenedUserMenu) {
                this.flattenedUserMenu = new Map<string, any>();
              }

              if (!this.flattenedUserMenuPath) {
                this.flattenedUserMenuPath = new Map<string, any>();
              }

              this.userMenu.set(appMenu.id, appMenu);
              this.flattenedUserMenu.set(appMenu.id, appMenu);
              this.flattenedUserMenuPath.set(appMenu.path, appMenu);

              this.persistMenuToSession();
            }
          }
        );
      }
    }

    const executionArray: Promise<any>[] = [];

    this.userMenu.forEach(
      (appMenu: any, appId: string) => {
        if (!appMenu.gotFunctions) {
          executionArray.push(this.readAllFunctionIdsForApp(appId));
        }
      }
    );

    const responses = await Promise.all(executionArray);

    if (this.flattenedUserMenu) {
      this.flattenedUserMenu.forEach(
        menu => {
          if (menu.path === this.currentPath) {
            this.currentMenu = menu;
            this.currentApplicationId = menu.applicationId;
            this.currentApplicationMenu = this.flattenedUserMenu?.get(menu.applicationId).functions;
            this.currentApplication = this.flattenedUserMenu?.get(menu.applicationId);
            this.deriveFrequentlyVisitedFunctions();
            this.groupMenus();
            this.fireEvent('application-set', 'application-set');
          }
        }
      );
    }

    return true;
  }

  private async readAllFunctionIdsForApp(applicationId: string): Promise<void> {
    const currUser = this.sessionService.getUser();

    if (!currUser) {
      return;
    }

    const parameterModel = new ParameterModel();

    parameterModel.stringParameters = new Map<string, string>();
    parameterModel.stringParameters.set('userId', currUser.userId);
    parameterModel.stringParameters.set('applicationId', applicationId);


    const path = this.configService.getConfig('global').configApi + this.configService.getConfig('iam-api').getFunctionForUserGroupRole;
    const httpCallModel = new HttpCallModel(
      path,
      'POST',
      applicationId,
      undefined,
      parameterModel,
    );

    httpCallModel.backgroundProcess = true;

    const userFunctionsForApp = await this.httpService.callWS(httpCallModel).toPromise().catch(
      error => {
        this.alertService.showErrorMessage(error);
        return undefined;
      }
    );

    if (userFunctionsForApp && userFunctionsForApp.status === 200 && userFunctionsForApp instanceof HttpResponse) {
      const userFunctionsList = userFunctionsForApp.body.finData;
      const menuList: any[] = [];
      let menuJson = this.configService.getConfig('menu');
      if (typeof menuJson === 'string') {
        menuJson = JSON.parse(menuJson);
      }
      let allFunctions: any[] | undefined;

      menuJson.forEach(
        (menu: any) => {
          if (menu.id === applicationId) {
            allFunctions = menu.functions;
          }
        }
      );

      if (!allFunctions) {
        return;
      }

      allFunctions.forEach(
        (allFnc: any) => {
          userFunctionsList.forEach(
            (userFnc: any) => {
              if (userFnc.functionId === allFnc.id && allFnc.showInMenu === true) {
                allFnc.applicationId = applicationId;
                menuList.push(allFnc);

                if (!this.flattenedUserMenu) {
                  this.flattenedUserMenu = new Map<string, any>();
                }

                if (!this.flattenedUserMenuPath) {
                  this.flattenedUserMenuPath = new Map<string, any>();
                }

                this.flattenedUserMenu.set(allFnc.id, allFnc);
                this.flattenedUserMenuPath.set(allFnc.path, allFnc);
              }
            }
          );
        }
      );

      if (this.userMenu) {
        this.userMenu.forEach(
          (appMenu: any, appId: string) => {
            if (applicationId === appMenu.id) {
              appMenu.functions = menuList;
              appMenu.gotFunctions = true;
            }
          }
        );
      }
    }

    this.persistMenuToSession();
  }

  public async promptUnsavedData(): Promise<boolean> {
    if (this.unSavedChanges) {
      this.alertService.debug('Unsaved changes');

      if (!this.unSavedPromptMessageLabel || !this.unSavedPromptConfirmLabel || !this.unSavedPromptCancelLabel) {
        return false;
      }

      const result = await this.alertService.showPrompt(this.unSavedPromptMessageLabel, this.unSavedPromptConfirmLabel, this.unSavedPromptCancelLabel);

      if (!result) {
        return false;
      }
    }

    return true;
  }

  private async navigateMenu(functionId: string, menu: any): Promise<boolean> {
    const unsaved = await this.promptUnsavedData();

    if (!unsaved) {
      return false;
    }

    if (functionId === 'LANDING') {
      this.currentApplicationId = undefined;
      this.currentMenu = undefined;
    }

    this.spinnerService.incrementSpinnerCount();

    if (menu && menu.key && menu.value) {
      menu = menu.value;
    }

    if (menu && menu.functions && !menu.gotFunctions) {
      await this.readAllFunctionIdsForApp(functionId);
    }

    if (menu) {
      if (menu.type === 'app') {
        this.currentApplicationId = menu.id;
        this.currentApplicationMenu = menu.functions;
        this.currentApplication = menu;
        this.deriveFrequentlyVisitedFunctions();
        this.groupMenus();
      }

      this.currentPath = menu.path;
      this.currentMenu = menu;
      await this.router.navigate([menu.path]);
    } else {
      this.currentPath = this.configService.getConfig('web-end-points').landingPage;
      this.currentMenu = undefined;
      this.currentApplicationMenu = undefined;
      this.currentApplication = undefined;
      this.frequentlyVisitedFunctions = [];
      await this.router.navigate([this.configService.getConfig('web-end-points').landingPage]);
    }

    this.spinnerService.decrementSpinnerCount();
    this.fireEvent('navigate-menu-complete', 'navigate-menu-complete');

    return true;
  }

  public async detectAndNavigate(id: string, menu: any): Promise<boolean> {
    if (menu.type === 'application') {
      return this.navigateToApp(id, menu);
    } else {
      return this.navigateMenu(id, menu);
    }
  }

  public deriveFrequentlyVisitedFunctions(): void {
    this.frequentlyVisitedFunctions = [];

    if (!this.userMenuPreferences) {
      return;
    }

    const userFunctionCount: any = this.userMenuPreferences.userFunctionCount;

    if (userFunctionCount && this.currentApplicationId) {
      let app = userFunctionCount[this.currentApplicationId];

      if (app) {
        const minFrequentlyVisitedFunctionsCount = +this.configService.getConfig('global').minFrequentlyVisitedFunctionsCount || 5;
        const frequentlyVisitedFunctionsTopCount = +this.configService.getConfig('global').frequentlyVisitedFunctionsTopCount;

        app = Object.keys(app)
          .sort((a, b) => +app[b] - +app[a])
          .reduce(
            (sortedObj, key) => ({
              ...sortedObj,
              [key]: app[key]
            }),
            {}
          );

        let idx = 1;
        Object.keys(app).forEach(
          key => {
            if (idx > frequentlyVisitedFunctionsTopCount) {
              return;
            }

            if (+app[key] >= minFrequentlyVisitedFunctionsCount) {
              this.frequentlyVisitedFunctions.push(this.flattenedUserMenu?.get(key));
            }

            idx++;
          }
        );
      }
    }
  }

  public async navigateToApp(functionId: string, app: any): Promise<boolean> {
    return this.navigateMenu(functionId, app);
  }

  public async navigateToAppForFunctionId(functionId: string): Promise<boolean> {
    let appMenu: any;
    appMenu = this.searchAppForFunctionId(functionId);

    return this.navigateMenu(functionId, appMenu);
  }

  public async navigate(functionId: string): Promise<boolean> {
    if (this.flattenedUserMenu === undefined || this.flattenedUserMenu === null) {
      return false;
    }

    if (functionId === 'LANDING') {
      return this.navigateMenu(functionId, undefined);
    }

    const fnc = this.flattenedUserMenu.get(functionId);

    if (fnc) {
      if (fnc.applicationId !== this.currentApplicationId) {
        await this.navigateToAppForFunctionId(functionId);
      }

      return this.navigateMenu(functionId, fnc);
    } else {
      return false;
    }
  }

  public async navigateToLoginPage(): Promise<boolean> {
    return this.router.navigate([this.configService.getConfig('web-end-points').loginPage]);
  }

  private searchAppForFunctionId(id: string): any {
    if (this.flattenedUserMenu === undefined || this.flattenedUserMenu === null) {
      return undefined;
    }

    const fnc = this.flattenedUserMenu.get(id);
    const app = this.flattenedUserMenu.get(fnc.applicationId);

    return app;
  }

  public searchFunctionId(id: string): any {
    if (this.flattenedUserMenu === undefined || this.flattenedUserMenu === null) {
      return undefined;
    }

    const fnc = this.flattenedUserMenu.get(id);

    return fnc;
  }

  public getMenuDescription(id: string): string {
    return this.flattenedUserMenu?.get(id)?.description;
  }

  public sortMenu(): void {
    if (!this.userMenuPreferences || !this.userMenuPreferences.userMenuSort) {
      return;
    }
  }

  public onMenuEvent(event: GenericEventModel): void {
    if (event.eventName === 'finfra-menu-listen') {
      if (event.eventType === 'push-data') {
        this.pushData();
      }
    }
  }

  public async pushData(): Promise<void> {
    if (!this.currUser) {
      if (this.pushInterval) {
        clearInterval(this.pushInterval);
        this.pushInterval = undefined;
      }

      return;
    }

    if (this.oldUserMenuPreferences && JSON.stringify(this.oldUserMenuPreferences) === JSON.stringify(this.userMenuPreferences)) {
      return;
    }

    const path = this.configService.getConfig('global').configApi + this.configService.getConfig('iam-api').updateUserPreference;
    this.oldUserMenuPreferences = JSON.parse(JSON.stringify(this.userMenuPreferences));

    const httpCallModel = new HttpCallModel(
      path,
      'POST',
      'finfra-menu', // function id
      [
        {
          key: 'user-menu-preferences',
          value: JSON.stringify(this.userMenuPreferences),
          userId: this.currUser.userId
        }
      ]
    );

    httpCallModel.backgroundProcess = true;

    const result = await this.httpService.callWS(httpCallModel).toPromise();

    if (result && result.status === 200 && result instanceof HttpResponse) {
      this.alertService.debug('Saved user menu preference');
    } else {
      this.alertService.showErrorMessage(result);
    }
  }

  public userMenuPreferenceInit(preferences: any): void {
    this.currUser = this.sessionService.getUser();

    this.userMenuPreferences = {};

    if (Array.isArray(preferences)) {
      preferences.forEach(
        pref => {
          if (pref.key === 'user-menu-preferences') {
            this.userMenuPreferences = pref.value;

            if (typeof this.userMenuPreferences === 'string') {
              this.userMenuPreferences = JSON.parse(this.userMenuPreferences);
            }

            if (!this.userMenuPreferences) {
              this.userMenuPreferences = {};
            }

            if (!this.userMenuPreferences.userFunctionCount) {
              this.userMenuPreferences.userFunctionCount = {};
            }

            if (!this.userMenuPreferences.userMenuSort) {
              this.userMenuPreferences.userMenuSort = {};
            }
          }
        }
      );
    }

    if (this.pushInterval) {
      clearInterval(this.pushInterval);
      this.pushInterval = undefined;
    }

    if (this.configService.getConfig('global').menuPushIntervalBased) {
      if (this.configService.getConfig('global')) {
        this.pushTime = +this.configService.getConfig('global').userMenuPreferencePushTime;
      }

      this.pushInterval = setInterval(this.pushData.bind(this), this.pushTime);
    }

    if (this.currentApplicationId) {
      this.deriveFrequentlyVisitedFunctions();
    }
  }

  public groupMenus(menu?: any[]): void {
    if (!this.currentApplicationMenu) {
      return;
    }

    if (!menu) {
      menu = JSON.parse(JSON.stringify(this.currentApplicationMenu));
    }

    if (!Array.isArray(menu)) {
      return;
    }

    this.currentApplicationMenu = [];
    this.currentGroups = [];

    for (const appMenu of menu) {
      if (!appMenu.groupBy) {
        this.currentApplicationMenu.push(appMenu);
      } else {
        if (!Array.isArray(appMenu.groupBy)) {
          this.processMenuRecord(appMenu);
          this.currentGroups.push(appMenu.groupBy);
        }
      }
    }

    this.currentGroups = this.currentGroups.filter((tag, index, array) => array.findIndex(t => t.id === tag.id) === index);
  }

  private processMenuRecord(appMenu: any): void {
    if (!this.currentApplicationMenu) {
      return;
    }

    let menuItem: any;
    let existingRecord = false;
    this.currentApplicationMenu.forEach(
      apm => {
        if (apm.id === appMenu.groupBy.id) {
          menuItem = apm;
          existingRecord = true;
        }
      }
    );

    if (!menuItem) {
      menuItem = {
        id: appMenu.groupBy.id,
        type: 'sub-menu',
        showInMenu: true,
        description: appMenu.groupBy.description,
        path: appMenu.groupBy.path,
        expanded: appMenu.groupBy.expanded,
        functions: []
      };
    }

    menuItem.functions.push(appMenu);

    if (!existingRecord) {
      this.currentApplicationMenu.push(menuItem);
    }
  }
}
