import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import {AlertModel, FinResponse} from '@finfra/core-models';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { ComponentType } from '@angular/cdk/portal';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  consoleLogLevelName = 'debug';
  consoleLogLevel: any;
  consoleLogEnabledLevels: string[] = [];

  alertComponent: any | undefined;
  promptComponent: ComponentType<any> | undefined;

  pendingAlerts: AlertModel[] = [];

  alertHistory: AlertModel[] = [];

  messageLevels: any[] = [
    {
      name: 'off',
      value: 0
    },
    {
      name: 'error',
      value: 1
    },
    {
      name: 'warn',
      value: 2
    },
    {
      name: 'info',
      value: 3
    },
    {
      name: 'http',
      value: 4
    },
    {
      name: 'debug',
      value: 5
    }
  ];

  constructor(
    private configService: ConfigService,
    public dialog: MatDialog,
  ) {
    this.consoleLogEnabledLevels = [];
    this.messageLevels.forEach(level => {
      if (level.name === this.consoleLogLevelName) {
        this.consoleLogLevel = level;
      }
    });

    this.messageLevels.forEach(level => {
      if (level.value <= this.consoleLogLevel.value) {
        this.consoleLogEnabledLevels.push(level.name);
      }
    });

    this.requestNotificationPermission();
  }

  public requestNotificationPermission(): void {
    if ('Notification' in window) {
      if (Notification && Notification.permission !== 'granted') {
        Notification.requestPermission().then((permission) => {
          this.debug('Got notification permission');
        });
      }
    }
  }

  public setConsoleLogLevel(): void {
    this.consoleLogLevelName = this.configService.getConfig(
      'global'
    ).logConsole;
    this.consoleLogEnabledLevels = [];

    if (this.consoleLogLevelName === undefined) {
      this.consoleLogLevelName = 'off';
      this.consoleLogLevel = {
        name: 'off',
        value: 0
      };
    } else {
      this.messageLevels.forEach(level => {
        if (level.name === this.consoleLogLevelName) {
          this.consoleLogLevel = level;
        }
      });

      this.messageLevels.forEach(level => {
        if (level.value <= this.consoleLogLevel.value) {
          this.consoleLogEnabledLevels.push(level.name);
        }
      });
    }
  }

  ////////////////////////////////////////
  public getCaller(): string {
    return '';
  }

  public debug(message: any): boolean {
    if (this.consoleLogEnabledLevels.indexOf('debug') === -1) {
      return false;
    }

    let fnc = '';

    try {
      fnc = this.getCaller();
    } catch (e) {
      fnc = '';
    }

    this.logMessageDetailed('debug', fnc, message);
    return true;
  }

  public error(message: any): boolean {
    if (this.consoleLogEnabledLevels.indexOf('error') === -1) {
      return false;
    }

    let fnc = '';

    try {
      fnc = this.getCaller();
    } catch (e) {
      fnc = '';
    }

    this.logMessageDetailed('error', fnc, message);
    return true;
  }

  public warn(message: any): boolean {
    if (this.consoleLogEnabledLevels.indexOf('warn') === -1) {
      return false;
    }

    let fnc = '';

    try {
      fnc = this.getCaller();
    } catch (e) {
      fnc = '';
    }

    this.logMessageDetailed('warn', fnc, message);
    return true;
  }

  public info(message: any): boolean {
    if (this.consoleLogEnabledLevels.indexOf('info') === -1) {
      return false;
    }

    let fnc = '';

    try {
      fnc = this.getCaller();
    } catch (e) {
      fnc = '';
    }

    this.logMessageDetailed('info', fnc, message);
    return true;
  }

  public http(message: any): boolean {
    if (this.consoleLogEnabledLevels.indexOf('http') === -1) {
      return false;
    }

    let fnc = '';

    try {
      fnc = this.getCaller();
    } catch (e) {
      fnc = '';
    }

    this.logMessageDetailed('http', fnc, message);
    return true;
  }

  /////////////////////////////////////

  public logConsoleError(err: any): boolean {
    if (this.consoleLogEnabledLevels.length === 0) {
      return false;
    }

    this.logError('error', '', err);
    return true;
  }

  public logError(logLevel: string, componentName: string, err: any): boolean {
    if (this.consoleLogEnabledLevels.length === 0) {
      return false;
    } else if (this.consoleLogEnabledLevels.indexOf(logLevel) === -1) {
      return false;
    }

    this.logMessageDetailed('error', componentName, 'Encountered an error!');
    console.error(err);

    return true;
  }

  public logConsoleMessage(msg: any): boolean {
    if (this.consoleLogEnabledLevels.length === 0) {
      return false;
    }

    this.logMessageDetailed('debug', '', msg);

    return true;
  }

  public logMessage(componentName: string, msg: any): boolean {
    return this.logMessageDetailed('debug', componentName, msg);
  }

  public logMessageDetailed(logLevel: string, componentName: string, msg: any): boolean {
    if (this.consoleLogEnabledLevels.length === 0) {
      return true;
    } else if (this.consoleLogEnabledLevels.indexOf(logLevel) === -1) {
      return false;
    }

    if (logLevel && logLevel === 'error') {
      if (typeof msg === 'string') {
        console.error('[' + logLevel + '] ' + componentName + ' - ' + msg);
      } else {
        console.error('[' + logLevel + '] ' + componentName + ' - ');
        console.error(msg);
      }
    } else if (logLevel && logLevel === 'warn') {
      if (typeof msg === 'string') {
        console.warn('[' + logLevel + '] ' + componentName + ' - ' + msg);
      } else {
        console.warn('[' + logLevel + '] ' + componentName + ' - ');
        console.warn(msg);
      }
    } else if (logLevel && logLevel === 'info') {
      if (typeof msg === 'string') {
        console.info('[' + logLevel + '] ' + componentName + ' - ' + msg);
      } else {
        console.info('[' + logLevel + '] ' + componentName + ' - ');
        console.info(msg);
      }
    } else {
      if (typeof msg === 'string') {
        console.log('[' + logLevel + '] ' + componentName + ' - ' + msg);
      } else {
        console.log('[' + logLevel + '] ' + componentName + ' - ');
        console.log(msg);
      }
    }

    return true;
  }

  public getMessage(code: string): any {
    return this.configService.getConfig('responseCodes')[code];
  }

  ///////////////////////////////
  public showNotification(
    notificationType: string,
    messageCode: string,
    messageText: string,
    pushToPending?: boolean
  ): boolean {
    this.showComponentMessage(messageCode, messageText, notificationType, pushToPending);
    return true;
  }

  public hideNotification(): void {
    if (this.alertComponent) {
      this.alertComponent.hideAllAlerts();
    }
  }

  public populateParameters(parameters: Map<string, string> | undefined, message: string): string {
    if (parameters !== undefined && parameters !== null && parameters.size > 0) {
      parameters.forEach((value: string, key: string) => {
        if (value === undefined || value === null) {
          value = this.configService.getConfig('global').nullParameterValue;
        }

        const re = new RegExp('\\$' + key + '\\$', 'g');
        message = message.replace(re, value);
      });
    }

    return message;
  }

  public showAllMessages(finResponse: FinResponse, parameters?: Map<string, string>): void {
    const responseCodes = this.configService.getConfig('responseCodes');

    if (finResponse instanceof HttpResponse) {
      finResponse = finResponse.body;
    }

    if (
      finResponse.finErrorDetails &&
      Array.isArray(finResponse.finErrorDetails)
    ) {
      if (
        finResponse.finErrorDetails.length &&
        finResponse.finErrorDetails.length > 0
      ) {
        finResponse.finErrorDetails.forEach(error => {
          if (responseCodes !== undefined && responseCodes[error.code]) {
            let responseMessage: string = responseCodes[error.code];
            responseMessage = this.populateParameters(parameters, responseMessage);

            this.showNotification(
              'error',
              error.code,
              responseMessage
            );
          } else {
            this.showNotification('error', error.code, error.message);
          }
        });
      }
    } else if (finResponse.finErrorDetails) {
      if (responseCodes !== undefined && responseCodes[finResponse.finErrorDetails.code]) {
        let responseMessage: string = responseCodes[finResponse.finErrorDetails.code];
        responseMessage = this.populateParameters(parameters, responseMessage);

        this.showNotification(
          'error',
          finResponse.finErrorDetails.code,
          responseMessage
        );
      } else {
        this.showNotification(
          'error',
          finResponse.finErrorDetails.code,
          finResponse.finErrorDetails.code
        );
      }
    }

    if (
      finResponse.finSuccessDetails &&
      Array.isArray(finResponse.finSuccessDetails)
    ) {
      if (
        finResponse.finSuccessDetails.length &&
        finResponse.finSuccessDetails.length > 0
      ) {
        finResponse.finSuccessDetails.forEach(success => {
          if (responseCodes !== undefined && responseCodes[success.code]) {
            let responseMessage: string = responseCodes[success.code];
            responseMessage = this.populateParameters(parameters, responseMessage);

            this.showNotification(
              'success',
              success.code,
              responseMessage
            );
          } else {
            this.showNotification('success', success.code, success.message);
          }
        });
      }
    } else if (finResponse.finSuccessDetails) {
      if (responseCodes !== undefined && responseCodes[finResponse.finSuccessDetails.code]) {
        let responseMessage: string = responseCodes[finResponse.finSuccessDetails.code];
        responseMessage = this.populateParameters(parameters, responseMessage);

        this.showNotification(
          'success',
          finResponse.finSuccessDetails.code,
          responseMessage
        );
      } else {
        this.showNotification(
          'success',
          finResponse.finSuccessDetails.code,
          finResponse.finSuccessDetails.code
        );
      }
    }
  }

  public showMessage(messageCode: string, type: string, parameters?: Map<string, string>): void {
    let responseMessage: string;
    const responseCodes = this.configService.getConfig('responseCodes');

    if (responseCodes !== undefined && responseCodes !== null) {
      responseMessage = responseCodes[messageCode];
    } else {
      responseMessage = messageCode;
    }

    responseMessage = this.populateParameters(parameters, responseMessage);

    this.showNotification(type, messageCode, responseMessage);
  }

  public showErrorMessage(message: any): void {
    if (message instanceof HttpErrorResponse) {
      if (message.error && message.error !== null && message.error.message) {
        this.showMessage(message.error.message, 'error');
      } else {
        this.showMessage(message.message, 'error');
      }
    }
  }

  ///////////////////// Browser notifications

  public showBrowserNotification(
    id: string,
    title: string,
    message: string,
    type: string,
    callback?: any,
    callbackData?: any
  ): void {
    if ('Notification' in window) {
      const notification = new Notification(title, {
        tag: id,
        icon: this.configService.getConfig('global').finfraLogoUrl,
        badge: this.configService.getConfig('global').finfraLogoUrl,
        body: message,
        requireInteraction: this.configService.getConfig('global').requireInteraction
      });

      notification.onclick = function() {
        if (callback !== undefined) {
          if (callbackData !== undefined) {
            callback(id, notification, callbackData);
          } else {
            callback(id, notification);
          }
        }
      };
    } else {
      this.showNotification('info', message, message);
    }
  }

  public showComponentMessage(
    messageCode: string | undefined,
    messageText: string | undefined,
    notificationType: string,
    pushToPending: boolean | undefined
  ): void {
    const alert: AlertModel = new AlertModel();
    alert.alertType = notificationType.toLowerCase();
    alert.alertMessage = messageText;
    alert.alertCode = messageCode;
    alert.duration = this.configService.getConfig('global').notificationDuration;
    alert.showCode = false;

    if (messageText === undefined || messageText === null || messageText === '') {
      alert.showCode = true;
      alert.alertMessage = messageCode;
    }

    if (this.alertComponent && !pushToPending) {
      this.alertComponent.showAlert(alert);
    } else {
      this.pendingAlerts.push(alert);
    }
  }

  public async showPrompt(alertMessage: string, confirmLabel: string, cancelLabel: string): Promise<boolean> {
    if (!this.promptComponent) {
      return false;
    }

    const dialogRef = this.dialog.open(this.promptComponent, {
      data: {alertMessage, confirmLabel, cancelLabel}
    });

    const data = await dialogRef.afterClosed().toPromise();
    return data.result;
  }
}
