import { Injectable } from "@angular/core";
import { GenericEventModel } from '@finfra/core-models';
import { EventsService } from '../core-services/events.service';
import { SessionService } from "../core-services/session.service";

@Injectable({
  providedIn: 'root'
})
export class OfflineStatusService {
  public offlineStatus: boolean = false;
  public onlineHook: Function | undefined;
  public offlineHook: Function | undefined;
  public autoOnline: boolean = false;
  public autoOffline: boolean = false;
  public offlineSupported: boolean = false;
  public networkStatus: boolean = true;

  private setOnlineStatusFunction: any;
  private setOfflineStatusFunction: any;

  constructor(
    private sessionService: SessionService,
    private eventsService: EventsService
  ) {
    this.offlineStatus = this.sessionService.getSessionData("offlineStatus");

    if (this.offlineStatus === undefined || this.offlineStatus === null) {
      this.setOnline();
    }

    if (typeof this.offlineStatus === "string") {
      if (this.offlineStatus === "false") {
        this.offlineStatus = false;
      } else {
        this.offlineStatus = true;
      }
    }

    this.setOnlineStatusFunction = this.setOnlineStatus.bind(this);
    this.setOfflineStatusFunction = this.setOfflineStatus.bind(this);
    this.registerEvent();
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.deRegister();
  }

  ngAfterViewInit() {

  }

  public deRegister() {
    window.removeEventListener("offline", this.setOfflineStatusFunction, false);
    window.removeEventListener("online", this.setOnlineStatusFunction, false);
    this.onlineHook = undefined;
    this.offlineHook = undefined;
  }

  public registerEvent() {
    window.addEventListener("offline", this.setOfflineStatusFunction, false);
    window.addEventListener("online", this.setOnlineStatusFunction, false);
  }

  public setOnlineStatus(event: any) {
    this.setStatus("online");
  }

  public setOfflineStatus(event: any) {
    this.setStatus("offline");
  }

  public setStatus(status?: string) {
    if (status === "online") {
      this.networkStatus = true;
    } else {
      this.networkStatus = false;
    }

    if (!this.offlineSupported) {
      return;
    }

    switch (status) {
      case "online":
        if (this.offlineStatus === false) {
          break;
        }

        if (this.onlineHook) {
          this.onlineHook(this.setOnline.bind(this));
        } else {
          if (this.autoOnline) {
            this.setOnline();
          }
        }

        break;
      case "offline":
        if (this.offlineStatus === true) {
          break;
        }

        if (this.offlineHook) {
          this.offlineHook(this.setOffline.bind(this));
        } else {
          if (this.autoOffline) {
            this.setOffline();
          }
        }

        break;
      default: if (window.navigator.onLine) {
        this.setOnline();
      } else {
        this.setOffline();
      }
    }
  }

  public setStatusInSession() {
    this.sessionService.setSessionData("offlineStatus", this.offlineStatus);
  }

  public setOffline() {
    this.offlineStatus = true;
    this.sessionService.setSessionData("offlineStatus", this.offlineStatus);
    this.throwEvent();
  }

  public setOnline() {
    this.offlineStatus = false;
    this.sessionService.setSessionData("offlineStatus", this.offlineStatus);
    this.throwEvent();
  }

  public toggleOfflineStatus() {
    if (this.offlineStatus) {
      this.setOnline();
    } else {
      this.setOffline();
    }
  }

  public getCurrentStatus(): boolean {
    if (window.navigator.onLine) {
      return true;
    } else {
      return false;
    }
  }

  private throwEvent() {
    let eventObject: GenericEventModel = new GenericEventModel();

    eventObject.eventSource = "finfra-core-lib";
    eventObject.formControl = undefined;
    eventObject.value = this.offlineStatus;
    eventObject.jsonSchema = undefined;
    eventObject.eventObjectName = "offline-status";
    eventObject.eventObject = this.offlineStatus;
    eventObject.eventName = "offline-status";

    this.eventsService.fireEvent(eventObject);
  }
}
