import { AbstractControl } from '@angular/forms';
import { Component, Inject, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { JsonSchemaFormService } from '@finfra/ajsf-core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { AjsfCoreWidgetsFunctionsService } from '../../../asjf-core-widgets.functions';
import { AlertService, ConfigService, HttpService } from '@finfra/core-services';
import { Observable, Subscription, timer } from 'rxjs';
import { getControl, getData } from '@finfra/ajsf-core';
import { HttpCallModel } from '@finfra/core-models';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'finfra-label-widget',
  templateUrl: './finfra-label.component.html',
  styleUrls: ['./finfra-label.component.scss'],
})
export class FinfraLabelComponent implements OnInit, OnDestroy {
  widgetName = 'FinfraLabelComponent';
  formControl: AbstractControl;
  controlName: string;
  controlValue: string;
  controlDisabled = false;
  boundControl = false;
  options: any;
  subProperties: any;
  autoCompleteList: string[] = [];
  @Input() layoutNode: any;
  @Input() layoutIndex: number[];
  @Input() dataIndex: number[];

  refreshTimer: Observable<any>;
  refreshSubscription: Subscription;
  timerFired = 1;

  constructor(
    @Inject(MAT_FORM_FIELD_DEFAULT_OPTIONS) @Optional() public matFormFieldDefaultOptions,
    private jsf: JsonSchemaFormService,
    private ajsfCoreWidgetsFunctionsService: AjsfCoreWidgetsFunctionsService,
    private alertService: AlertService,
    private configService: ConfigService,
    private httpService: HttpService,
  ) {
  }

  ngOnInit() {
    this.options = this.layoutNode.options || {};
    this.subProperties = this.layoutNode.subProperties || {};
    this.jsf.initializeControl(this);
    if (!this.options.notitle && !this.options.description && this.options.placeholder) {
      this.options.description = this.options.placeholder;
    }

    if (this.options.autorefresh) {
      this.options.refreshStopCount = this.options.refreshStopCount || 5;
      this.options.refreshLoaderColour = this.options.refreshLoaderColour || this.configService.getConfig('global').loaderColor;
      this.startTimer();
      this.refreshLabelData();
    }
  }

  ngOnDestroy(): void {
    this.stopTimer();
    this.jsf = undefined;
    this.formControl = undefined;
    this.controlName = undefined;
    this.controlValue = undefined;
    this.controlDisabled = undefined;
    this.boundControl = undefined;
    this.options = undefined;
    this.subProperties = undefined;
  }

  updateValue() {
    this.jsf.updateValue(this, this.controlValue);
    this.ajsfCoreWidgetsFunctionsService.dispatchEvent(this);
  }

  throwEvent(value: any) {
    this.alertService.info('FinfraLabelComponent: on label click ' + this.controlValue);
    this.ajsfCoreWidgetsFunctionsService.dispatchEvent(this, value);
  }

  public startTimer() {
    this.timerFired = 1;
    this.refreshTimer = timer(0, this.options.refreshInterval || this.configService.getConfig('global').autoRefreshTime);
    this.refreshSubscription = this.refreshTimer.subscribe(this.onTimer.bind(this));
  }

  public onTimer(seconds) {
    this.timerFired++;
    this.refreshLabelData();

    if (this.timerFired > this.options.refreshStopCount) {
      this.stopTimer();
    }
  }

  private stopTimer() {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }

    this.refreshTimer = undefined;
  }

  public async refreshLabelData(): Promise<boolean> {
    if (this.options.readonly !== undefined && this.options.readonly === true) {
      return false;
    }

    if (!this.options.searchBy) {
      return false;
    }

    this.alertService.info('FinfraLabelComponent: in to build the title map');
    this.alertService.info(this.layoutNode);
    this.alertService.info(this.layoutIndex);

    let path: string = this.layoutNode.options.url;

    if (this.configService.getConfig('global') && this.configService.getConfig('global').configApi) {
      path = this.configService.getConfig('global').configApi + path;
    }
    this.alertService.info('FinfraLabelComponent: About to call ' + path);

    let queryString = 'q=*:*&';

    if (this.options.extraQueryOption) {
      queryString = queryString + this.options.extraQueryOption + '&';
    }

    this.options.searchBy.forEach(
      searchBy => {
        const searchTagValue: string = getControl(this.jsf.formGroup, searchBy.pointer).value;

        if (searchBy.likeSearch) {
          queryString = queryString + 'fq=' + searchBy.tag + ':*' + searchTagValue + '*&';
        } else {
          queryString = queryString + 'fq=' + searchBy.tag + ':' + searchTagValue + '&';
        }
      }
    );

    if (this.options.facet !== undefined && this.options.facet === true) {
      queryString =
        queryString +
        '&facet=on&facet.field=' +
        this.options.facetField +
        '&facet.pivot=' +
        this.options.facetField;
    }

    if (this.options.startRecord !== undefined) {
      queryString = queryString + '&start=' + this.options.startRecord;
    }

    if (this.options.noOfRecord !== undefined) {
      queryString = queryString + '&rows=' + this.options.noOfRecord;
    }

    if (this.options.sortBy !== undefined) {
      if (
        this.options.sortBy.indexOf(' asc') === -1 &&
        this.options.sortBy.indexOf(' desc') === -1
      ) {
        this.options.sortBy = this.options.sortBy + ' asc';
      }

      queryString = queryString + '&sort=' + this.options.sortBy;
    }

    path = path + '?' + queryString;

    const result = await this.getList(
      path,
      'finfra-ajsf',
      this.options.method
    );

    if (result && result.status === 200 && result instanceof HttpResponse) {
      this.alertService.info('FinfraLabelComponent: got data');
      this.alertService.info(result);

      let data: any = result.body;

      if (this.options.rootDataPointer && this.options.rootDataPointer !== '') {
        data = getData(data, this.options.rootDataPointer);
      }

      if (data && data.length > 0) {
        this.controlValue = '';
        this.updateValue();

        data.forEach(element => {
          let content = this.options.contentTags.htmlContent;

          this.options.contentTags.tagsUsed.forEach(tag => {
            let tagValue = getData(element, tag);

            if (content) {
              if (!tagValue || tagValue === '') {
                tagValue = '&nbsp;';
              }

              const re = new RegExp('\\$' + tag + '\\$', 'g');
              content = content.replace(re, tagValue);
            }
          });

          this.controlValue = this.controlValue + content;
          this.updateValue();
        });
      }

      this.throwEvent(result.body);
    } else {
      this.alertService.error('FinfraLabelComponent: got error during get title map');
      this.alertService.error(result);
      this.alertService.showErrorMessage(result);
      return false;
    }

    this.alertService.info('FinfraLabelComponent: populated title map');

    return true;
  }

  public getList(
    path: string,
    functionId: string,
    method: string
  ): Promise<HttpResponse<any> | HttpErrorResponse> {
    return this.httpService.callWS(
      new HttpCallModel(
        path,
        method,
        functionId,
        undefined
      )
    ).toPromise();
  }
}
