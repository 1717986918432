import { HeaderModel } from './Header.model';
import { WorkflowModel } from './Workflow.model';
import { FileModel } from './File.model';
import { PageModel } from './Page.model';
import { FinResponseCodes } from './FinResponseCodes.model';

export interface FinResponse {
  headerModel?: HeaderModel;
  redirect?: boolean;
  workflowModel?: WorkflowModel;
  filesModel?: FileModel[];
  finData?: any;
  pageModel?: PageModel;
  finResponseCodes?: FinResponseCodes;
  finSuccessDetails?: any;
  finErrorDetails?: any;
}

export class FinResponse implements FinResponse {
  constructor(
    public headerModel?: HeaderModel,
    public redirect?: boolean,
    public workflowModel?: WorkflowModel,
    public filesModel?: FileModel[],
    public finData?: any,
    public pageModel?: PageModel,
    public finResponseCodes?: FinResponseCodes,
    public finSuccessDetails?: any,
    public finErrorDetails?: any,
  ) { }
}
