import { Injectable } from '@angular/core';
import { AlertService } from '../core-services/alert.service';
import { v4 as uuidv4 } from 'uuid';
import { UtilitiesService } from '../core-services/utilities.service';
import { SpinnerService } from '../core-services/spinner.service';

declare var window: any;

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  private onExtensionInitializedFunction: any;

  public extensionInitialized = false;
  public pendingEvents: any[] = [];

  private callbackList: Map<string, any> = new Map<string, any>();

  constructor(
    private alertService: AlertService,
    private utilitiesService: UtilitiesService,
    private spinnerService: SpinnerService
  ) {
    this.onExtensionInitializedFunction = this.onExtensionInitialized.bind(this);
    document.addEventListener('extension-initialised', this.onExtensionInitializedFunction);
  }

  public onExtensionInitialized(event?: any): void {
    this.alertService.debug('In after extension initialization');
    document.removeEventListener('extension-initialised', this.onExtensionInitializedFunction);
    this.extensionInitialized = true;

    this.alertService.debug('Pending events:' + this.pendingEvents.length);
    this.pendingEvents.forEach(
      event => {
        document.dispatchEvent(event);
      }
    );

    this.pendingEvents = [];
  }

  public sendCommand(commandValue: string, callback: any, additionalTags?: any): void {
    const id: string = uuidv4();
    const onCommandResponseFunction: any = this.onCommandResponse.bind(this);

    const callbackObject: any = {
      id,
      bindFunction: onCommandResponseFunction,
      callback
    };

    this.callbackList.set(id, callbackObject);

    const eventObject: any = {
      detail: {
        command: {
          command: commandValue,
          id
        },
        responseEvent: 'browser-extension-response'
      }
    };

    if (additionalTags !== undefined && additionalTags !== null) {
      Object.keys(additionalTags).forEach(
        key => {
          eventObject.detail.command[key] = additionalTags[key];
        }
      );
    }

    document.addEventListener('browser-extension-response', onCommandResponseFunction);
    const customEvent: CustomEvent = new CustomEvent('browser-extension', eventObject);
    document.dispatchEvent(customEvent);

    if (!this.extensionInitialized) {
      this.alertService.debug('Caching event');
      this.pendingEvents.push(customEvent);
    }
  }

  public onCommandResponse(responseEvent: any): void {
    this.alertService.debug('Got browser response :' + JSON.stringify(responseEvent.detail));

    let response: any = responseEvent.detail;

    if (typeof response === 'string') {
      response = JSON.parse(response);
    }

    if (typeof response.data === 'string') {
      response.data = JSON.parse(response.data);
    }

    const id: string = response.data.id;
    const callbackObject: any = this.callbackList.get(id);

    document.removeEventListener('browser-extension-response', callbackObject.bindFunction);

    callbackObject.callback(response.data);
  }

  public getDeviceId(callback: any): void {
    this.alertService.debug('About to fetch device id');
    if (window.cordova && window.device) {
      const response: any = {
        deviceId: window.device.uuid
      };

      callback(response);
    } else {
      this.sendCommand('getDeviceId', callback);
    }
  }

  public getDiskFileAsBase64(path: string, callback: any, errorCallback?: any): void {
    if (window.cordova) {
      window.resolveLocalFileSystemURL(path,
        (fileEntry: any) => {
          fileEntry.file((file: any) => {
            const reader: any = new FileReader();
            reader.onloadend = (event: any) => {
              const content: any = reader.result;

              const fileObject: any = {
                path,
                base64: content,
                part: 1,
                totalParts: 1
              };

              callback(fileObject);
            };
            reader.readAsDataURL(file);
          });
        },
        (error: any) => {
          if (errorCallback) {
            errorCallback(error);
          }
        }
      );
    } else {
      const fileObject: any = {
        fileId: uuidv4(),
        path
      };

      const additionalTags: any = {
        fileId: fileObject.fileId,
        file: fileObject.path,
        part: 0,
        deleteFile: false,
        deleteFolder: false
      };

      this.spinnerService.incrementSpinnerCount();
      this.getFilePart(fileObject, additionalTags, callback);
    }
  }

  public async getFilePart(fileObject: any, additionalTags: any, callback: any): Promise<void> {
    await this.utilitiesService.delay(2000);
    this.sendCommand('getDocument', this.gotFile.bind(this, fileObject, callback), additionalTags);
  }

  public gotFile(fileObject: any, callback: any, scannedFile: any): void {
    if (fileObject.base64 === undefined || fileObject.base64 === null) {
      fileObject.base64 = '';
    }

    fileObject.base64 = fileObject.base64 + scannedFile.b64;
    fileObject.part = scannedFile.part;
    fileObject.totalParts = scannedFile.totalParts;

    if (scannedFile.part < scannedFile.totalParts) {
      const additionalTags: any = {
        fileId: fileObject.fileId,
        file: fileObject.path,
        part: scannedFile.part,
        deleteFile: false,
        deleteFolder: false
      };

      this.getFilePart(fileObject, additionalTags, callback);
    } else {
      this.spinnerService.decrementSpinnerCount();
      if (callback) {
        callback(fileObject);
      }
    }
  }
}
