import { Injectable } from '@angular/core';
import { ConfigService } from '../core-services/config.service';
import { Observable, Subscriber } from 'rxjs';
import { HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { OpenUIDatabaseService } from './open-ui-database.service';
import { UIDatabaseService } from './uidatabase.service';
import { EncryptionService } from '../core-services/encryption.service';
import { InjectionService } from '../core-services/injection.service';

@Injectable({
  providedIn: 'root',
})
export class ProcessOfflineService {
  private cacheDbName = 'finfra-offline-cache';
  private mappingStore = 'mapping-store';

  private transactionDbName = 'finfra-transaction-cache';
  private transactionDbStore = 'transaction-store';

  constructor(
    private configService: ConfigService,
    private openUIDatabasesService: OpenUIDatabaseService,
    private uiDatabaseService: UIDatabaseService,
    private encryptionService: EncryptionService,
    private injectionService: InjectionService,
  ) {
    this.openUIDatabasesService.openAllDatabases();
  }

  public callWs(functionId: string, method: string, url: string, httpOptions: any, requestBody: any): Observable<any> {
    if (requestBody.headerModel.offlineRequest) {
      return this.returnError(url, 'E_ALREADY_OFFLINE');
    }

    const cacheConfig: any = this.configService.getConfig('offline-functions');

    if (cacheConfig === undefined || cacheConfig === null) {
      return this.returnError(url, 'E_NO_OFFLINE_CONFIG');
    }

    if (cacheConfig[functionId]) {
      let methodConfig: any;

      cacheConfig[functionId].forEach(
        (config: any) => {
          if (config.method === method) {
            methodConfig = config;
          }
        }
      );

      if (methodConfig === undefined) {
        return this.returnError(url);
      } else {
        if (methodConfig.cache) {
          let finalResponse: any;
          methodConfig.cacheConfigs.forEach(
            (cacheConfig: any) => {
              if (cacheConfig.url === url) {
                if (cacheConfig.serviceName) {
                  const service: any = this.injectionService.getInjectedServiceByTypeAndName('process-cache-service', cacheConfig.serviceName);

                  const insertData: any = new Object();
                  insertData.functionId = functionId;

                  if (requestBody.headerModel) {
                    insertData.application = requestBody.headerModel.application;
                  }

                  if (requestBody.headerModel.transactionReferenceNumber) {
                    insertData.transactionReferenceNumber = requestBody.headerModel.transactionReferenceNumber;
                  } else if (requestBody.finData) {
                    insertData.transactionReferenceNumber = requestBody.finData.transactionReferenceNumber;
                  }

                  insertData.method = method;
                  insertData.url = url;
                  insertData.httpOptions = httpOptions;
                  insertData.requestBody = requestBody;
                  insertData.cacheConfig = cacheConfig;
                  insertData.retryCount = 0;
                  insertData.requestType = 'normalHttp';

                  let subscriber: any;
                  const response: Observable<any> = new Observable<any>(
                    observer => {
                      subscriber = observer;
                      service.service[cacheConfig.functionName](insertData, subscriber, this.serviceCallbackSucess.bind(this), this.serviceCallbackError.bind(this));
                    }
                  );

                  finalResponse = response;
                  return;
                }
              }
            }
          );

          if (finalResponse !== undefined && finalResponse !== null) {
            return finalResponse;
          }
        }

        if (methodConfig.getResponseFromCache) {
          if (url.indexOf('?') > -1) {
            const urlComponents: string[] = url.split('?');
            url = urlComponents[0];
            requestBody.urlComponents = urlComponents;
          }

          if (methodConfig.cacheConfigs === undefined || methodConfig.cacheConfigs === null || methodConfig.cacheConfigs.length === 0) {
            let subscriber: any;
            const response: Observable<any> = new Observable<any>(
              observer => {
                subscriber = observer;
                this.uiDatabaseService.fetchDataByIndex(this.cacheDbName, this.mappingStore, 'originalPath', url, this.gotKey.bind(this, subscriber, url, requestBody), this.transactionError.bind(this, subscriber, url));
              }
            );

            return response;
          } else {
            let finalResponse: Observable<any> | undefined;
            let subscriber: any;

            methodConfig.cacheConfigs.forEach(
              (cacheConfig: any) => {
                if (cacheConfig.url === url) {
                  if (cacheConfig.serviceName) {
                    const service: any = this.injectionService.getInjectedServiceByTypeAndName('process-cache-service', cacheConfig.serviceName);

                    finalResponse = new Observable<any>(
                      observer => {
                        subscriber = observer;
                        service.service[cacheConfig.functionName](null, subscriber, this.serviceCallbackSucess.bind(this), this.serviceCallbackError.bind(this));
                      }
                    );
                  } else {
                    finalResponse = new Observable<any>(
                      observer => {
                        subscriber = observer;
                        this.uiDatabaseService.fetchDataByIndex(this.cacheDbName, this.mappingStore, 'originalPath', url, this.gotKey.bind(this, subscriber, url, requestBody), this.transactionError.bind(this, subscriber, url));
                      }
                    );

                    return;
                  }
                }
              }
            );

            if (finalResponse === undefined || finalResponse === null) {
              finalResponse = new Observable<any>(
                observer => {
                  subscriber = observer;
                  this.uiDatabaseService.fetchDataByIndex(this.cacheDbName, this.mappingStore, 'originalPath', url, this.gotKey.bind(this, subscriber, url, requestBody), this.transactionError.bind(this, subscriber, url));
                }
              );
            }

            return finalResponse;
          }
        }
      }
    } else {
      return this.returnError(url);
    }

    return this.returnError(url);
  }

  public serviceCallbackSucess(subscriber: any, insertData: any) {
    if (insertData.cacheConfig.cache) {
      this.uiDatabaseService.insertData(this.transactionDbName, this.transactionDbStore, insertData, false, this.onInsertSuccess.bind(this, subscriber, insertData), this.onInsertError.bind(this, subscriber, insertData));
    } else {
      const successHeader: HttpHeaders = new HttpHeaders();
      successHeader.set('status', '200');
      successHeader.set('statusText', 'OK');
      successHeader.set('url', insertData.url);
      successHeader.set('ok', 'true');

      if (insertData.response === undefined || insertData.response === null) {
        insertData.response = new Object();
        insertData.response.status = 'SUCCESS';
      }

      const response: HttpResponse<any> = new HttpResponse<any>(
        {
          body: insertData.response,
          headers: successHeader,
          status: 200,
          statusText: 'OK',
          url: insertData.url
        }
      );

      subscriber.next(response);
      subscriber.complete();
      subscriber.unsubscribe();
    }
  }

  public serviceCallbackError(subscriber: any, insertData: any) {
    this.onInsertError(subscriber, insertData, undefined);
  }

  public onInsertSuccess(subscriber: Subscriber<any>, insertData: any, event: any) {
    const successHeader: HttpHeaders = new HttpHeaders();
    successHeader.set('status', '200');
    successHeader.set('statusText', 'OK');
    successHeader.set('url', insertData.url);
    successHeader.set('ok', 'true');

    if (insertData.response === undefined || insertData.response === null) {
      insertData.response = new Object();
      insertData.response.status = 'SUCCESS';
    }

    const response: HttpResponse<any> = new HttpResponse<any>(
      {
        body: insertData.response,
        headers: successHeader,
        status: 200,
        statusText: 'OK',
        url: insertData.url
      }
    );

    subscriber.next(response);
    subscriber.complete();
    subscriber.unsubscribe();
  }

  public onInsertError(subscriber: Subscriber<any>, insertData: any, event: any) {
    let errorObject: any = new Object();

    if (insertData.error) {
      errorObject = insertData.error;
    } else {
      errorObject.message = 'E_TRANSACTION_FAILED';
    }

    const errorHeader: HttpHeaders = new HttpHeaders();
    errorHeader.set('status', '3007');
    errorHeader.set('message', 'E_TRANSACTION_FAILED');
    errorHeader.set('url', insertData.url);

    const error: HttpErrorResponse = new HttpErrorResponse({
      error: errorObject,
      headers: errorHeader,
      status: 3007,
      statusText: 'Offline response',
      url: insertData.url
    });

    subscriber.error(error);
    subscriber.complete();
    subscriber.unsubscribe();
  }

  public returnError(url: string, errorCode?: string): Observable<any> {
    return new Observable(
      observer => {
        if (errorCode === undefined || errorCode === null) {
          errorCode = 'E_APP_OFFLINE';
        }

        const errorObject: any = new Object();
        errorObject.message = errorCode;

        const errorHeader: HttpHeaders = new HttpHeaders();
        errorHeader.set('status', '3003');
        errorHeader.set('message', errorCode);
        errorHeader.set('url', url);

        const error: HttpErrorResponse = new HttpErrorResponse({
          error: errorObject,
          headers: errorHeader,
          status: 3003,
          statusText: 'Offline response',
          url
        });

        observer.error(error);
        observer.complete();

        return { unsubscribe() { } };
      }
    );
  }

  public async gotKey(subscriber: Subscriber<any>, url: string, requestBody: any, event: any) {
    if (event.target.result === undefined) {
      const errorObject: any = new Object();
      errorObject.message = 'E_OFFLINE_DATA_NOT_FOUND';

      const errorHeader: HttpHeaders = new HttpHeaders();
      errorHeader.set('status', '3006');
      errorHeader.set('message', 'E_OFFLINE_DATA_NOT_FOUND');
      errorHeader.set('url', url);

      const error: HttpErrorResponse = new HttpErrorResponse({
        error: errorObject,
        headers: errorHeader,
        status: 3006,
        statusText: 'Offline response',
        url
      });

      subscriber.error(error);
      subscriber.complete();
      subscriber.unsubscribe();
    } else {
      let data: any;

      if (Array.isArray(event.target.result)) {
        data = event.target.result[0];
      } else {
        data = event.target.result;
      }

      if (data.addBodyToPath) {
        const reqBody: any = requestBody;
        reqBody.headerModel.applicationDate = undefined;
        reqBody.headerModel.versionNo = undefined;
        reqBody.headerModel.tillId = undefined;
        reqBody.headerModel.transactionReferenceNumber = undefined;
        reqBody.headerModel.applicationReferenceNumber = undefined;
        reqBody.headerModel.customerCbsId = undefined;
        reqBody.headerModel.txnStatus = undefined;
        reqBody.headerModel.deviceId = undefined;
        reqBody.headerModel.transactionDateTime = undefined;
        reqBody.headerModel.location = undefined;

        if (data.nullifyFunctionId) {
          reqBody.headerModel.functionId = undefined;
        }

        url = url + await this.encryptionService.hash(reqBody);
      }

      this.uiDatabaseService.fetchDataByKey(this.cacheDbName, event.target.result.store, url, this.gotData.bind(this, subscriber, url, requestBody), this.transactionError.bind(this, subscriber, url));
    }
  }

  public gotData(subscriber: Subscriber<any>, url: string, requestBody: any, event: any) {
    if (event.target.result === undefined || event.target.result === null) {
      const errorObject: any = new Object();
      errorObject.message = 'E_NO_OFFLINE_DATA';

      const errorHeader: HttpHeaders = new HttpHeaders();
      errorHeader.set('status', '3005');
      errorHeader.set('message', 'E_NO_OFFLINE_DATA');
      errorHeader.set('url', url);

      const error: HttpErrorResponse = new HttpErrorResponse({
        error: errorObject,
        headers: errorHeader,
        status: 3005,
        statusText: 'Offline response',
        url
      });

      subscriber.error(error);
      subscriber.complete();
      subscriber.unsubscribe();
    } else {
      let data: any = event.target.result.data;

      if (typeof data === 'string') {
        data = JSON.parse(data);
      }

      if (requestBody.urlComponents) {
        this.parseResponse(requestBody, data, event.target.result.valueTag);
      }

      const successHeader: HttpHeaders = new HttpHeaders();
      successHeader.set('status', '200');
      successHeader.set('statusText', 'OK');
      successHeader.set('url', url);
      successHeader.set('ok', 'true');

      const response: HttpResponse<any> = new HttpResponse<any>(
        {
          body: (data.body ? data.body : data),
          headers: successHeader,
          status: 200,
          statusText: 'OK',
          url
        }
      );

      subscriber.next(response);
      subscriber.complete();
      subscriber.unsubscribe();
    }
  }

  public transactionError(subscriber: Subscriber<any>, url: string, event: any) {
    const errorObject: any = new Object();
    errorObject.message = 'E_NO_OFFLINE_KEY';

    const errorHeader: HttpHeaders = new HttpHeaders();
    errorHeader.set('status', '3004');
    errorHeader.set('message', 'E_NO_OFFLINE_KEY');
    errorHeader.set('url', url);

    const error: HttpErrorResponse = new HttpErrorResponse({
      error: errorObject,
      headers: errorHeader,
      status: 3004,
      statusText: 'Offline response',
      url
    });

    subscriber.error(error);
    subscriber.complete();
    subscriber.unsubscribe();
  }

  public parseResponse(requestBody: any, responseData: any, valueTag: any, ) {
    const urlComponents: string[] = requestBody.urlComponents;
    let queryParamters: string[];

    if (urlComponents[1].indexOf('&') > -1) {
      queryParamters = urlComponents[1].split('&');
    } else {
      queryParamters = [urlComponents[1]];
    }

    let data: any[] = (responseData.body ? responseData.body : responseData);
    let idx = 0;

    data.forEach(
      record => {
        record.filterIndex = idx;
        idx++;
      }
    );

    let sortBy: string | undefined;
    let sortDirection: string | undefined;

    queryParamters.forEach(
      parameter => {
        const detailedParamters: string[] = parameter.split('=');

        if (detailedParamters[0] === 'sort') {
          if (detailedParamters[1].indexOf(' ') > -1) {
            sortBy = detailedParamters[1].split(' ')[0];
            sortDirection = detailedParamters[1].split(' ')[1];

            if (sortDirection !== 'asc' && sortDirection !== 'desc') {
              sortDirection = 'asc';
            }
          } else {
            sortBy = detailedParamters[1];
            sortDirection = 'asc';
          }
        } else if (detailedParamters[0] === 'q' || detailedParamters[0] === 'fq') {
          const tags: string[] = detailedParamters[1].split(':');
          let searchOption = -1;

          let re: any = new RegExp('\\(', 'g');
          tags[1] = tags[1].replace(re, '');
          re = new RegExp('\\)', 'g');
          tags[1] = tags[1].replace(re, '');

          if (tags[1] === '**' || tags[1] === '*') {
            searchOption = 0;
          } else if (tags[1].endsWith('*') && tags[1].startsWith('*')) {
            searchOption = 1;
          } else if (tags[1].endsWith('*')) {
            searchOption = 2;
          } else if (tags[1].startsWith('*')) {
            searchOption = 3;
          } else {
            searchOption = 4;
          }

          let searchTagValues: string[];

          if (tags[1].indexOf(' OR ') > -1) {
            searchTagValues = tags[1].split(' OR ');
          } else {
            searchTagValues = [tags[1]];
          }

          searchTagValues.forEach(
            tagValue => {
              data.forEach(
                record => {
                  let value: any = record[tags[0]];
                  const isValueArray: boolean = Array.isArray(value);
                  let searchValue: string = tagValue;

                  const re: any = new RegExp('\\*', 'g');
                  searchValue = searchValue.replace(re, '').toLowerCase();

                  if (typeof value !== 'string' && !isValueArray) {
                    value = JSON.stringify(value);
                  }

                  if (value) {

                    if (searchOption === 0) {
                      // Do nothing
                    } else if (searchOption === 1) {
                      if (!isValueArray) {
                        if (value.toLowerCase().indexOf(searchValue) === -1) {
                          data = data.filter(rec => rec.filterIndex !== record.filterIndex);
                        }
                      } else {
                        let found = false;
                        value.forEach(
                          (v: string) => {
                            if (found) {
                              return;
                            }

                            if (v.toLowerCase().indexOf(searchValue) > -1) {
                              found = true;
                              return;
                            }
                          }
                        );

                        if (!found) {
                          data = data.filter(rec => rec.filterIndex !== record.filterIndex);
                        }
                      }
                    } else if (searchOption === 2) {
                      if (!isValueArray) {
                        if (!value.toLowerCase().endsWith(searchValue)) {
                          data = data.filter(rec => rec.filterIndex !== record.filterIndex);
                        }
                      } else {
                        let found = false;
                        value.forEach(
                          (v: string) => {
                            if (found) {
                              return;
                            }

                            if (v.toLowerCase().endsWith(searchValue)) {
                              found = true;
                              return;
                            }
                          }
                        );

                        if (!found) {
                          data = data.filter(rec => rec.filterIndex !== record.filterIndex);
                        }
                      }
                    } else if (searchOption === 3) {
                      if (!isValueArray) {
                        if (!value.toLowerCase().startsWith(searchValue)) {
                          data = data.filter(rec => rec.filterIndex !== record.filterIndex);
                        }
                      } else {
                        let found = false;
                        value.forEach(
                          (v: string) => {
                            if (found) {
                              return;
                            }

                            if (v.toLowerCase().startsWith(searchValue)) {
                              found = true;
                              return;
                            }
                          }
                        );

                        if (!found) {
                          data = data.filter(rec => rec.filterIndex !== record.filterIndex);
                        }
                      }
                    } else if (searchOption === 4) {
                      if (!isValueArray) {
                        if (value.toLowerCase() !== searchValue) {
                          data = data.filter(rec => rec.filterIndex !== record.filterIndex);
                        }
                      } else {
                        let found = false;
                        value.forEach(
                          (v: string) => {
                            if (found) {
                              return;
                            }

                            if (v.toLowerCase() === searchValue) {
                              found = true;
                              return;
                            }
                          }
                        );

                        if (!found) {
                          data = data.filter(rec => rec.filterIndex !== record.filterIndex);
                        }
                      }
                    }
                  } else {
                    data = data.filter(rec => rec.filterIndex !== record.filterIndex);
                  }
                }
              );
            }
          );
        }
      }
    );

    if (sortBy !== undefined && sortBy !== null) {
      data = data.sort(
        (d1, d2) => {
          if (sortBy === undefined || sortBy === null) {
            return 0;
          }

          if (sortDirection === 'asc') {
            if (d1[sortBy] > d2[sortBy]) {
              return 1;
            }

            if (d1[sortBy] > d2[sortBy]) {
              return -1;
            }
          } else {
            if (d1[sortBy] > d2[sortBy]) {
              return -1;
            }

            if (d1[sortBy] > d2[sortBy]) {
              return 1;
            }
          }

          return 0;
        }
      );
    }

    if (valueTag !== undefined && valueTag !== null) {
      responseData.body = new Object();

      if (valueTag.indexOf('#') > -1) {
        let wrappedData: any = data;
        const valueTagsArray: string[] = valueTag.split('#');

        for (let idx: number = valueTagsArray.length - 1; idx >= 0; idx--) {
          let obj: Object[] = [];

          if (valueTagsArray[idx].indexOf('~') > -1) {
            const t: string[] = valueTagsArray[idx].split('~');

            if (t[1] === 'number') {
              obj = [];

              for (let idx = 0; idx < +t[0]; idx++) {
                obj[idx] = new Object();
              }

              obj[+t[0]] = wrappedData;
            } else {
              obj[+valueTagsArray[idx]] = wrappedData;
            }
          } else {
            obj[+valueTagsArray[idx]] = wrappedData;
          }
          wrappedData = obj;
        }

        responseData.body = wrappedData;
      } else {
        responseData.body[valueTag] = data;
      }
    } else {
      responseData.body = data;
    }
  }

  public callFileWs(functionId: string, method: string, url: string, httpOptions: any, requestBody: any): Observable<any> {
    if (requestBody.headerModel.offlineRequest) {
      return this.returnError(url, 'E_ALREADY_OFFLINE');
    }

    const cacheConfig: any = this.configService.getConfig('offline-functions');

    if (cacheConfig === undefined || cacheConfig === null) {
      return this.returnError(url, 'E_NO_OFFLINE_CONFIG');
    }

    const req: any = {};
    requestBody.finData.forEach((value: any, key: any) => {
      // Reflect.has in favor of: object.hasOwnProperty(key)
      if (!Reflect.has(req, key)) {
        req[key] = value;
        return;
      }
      if (!Array.isArray(req[key])) {
        req[key] = [req[key]];
      }
      req[key].push(value);
    });

    requestBody.finData = req;
    httpOptions.body = req;

    let subscriber: any;
    const response: Observable<any> = new Observable<any>(
      observer => {
        subscriber = observer;
        if (requestBody.finData.files) {
          const fileObject: any = {
            name: requestBody.finData.files.name,
            size: requestBody.finData.files.size,
            type: requestBody.finData.files.type,
            lastModified: requestBody.finData.files.lastModified,
            lastModifiedDate: requestBody.finData.files.lastModifiedDate,
            b64: ''
          };

          const reader: FileReader = new FileReader();
          reader.onload = this.postFileParse.bind(this, functionId, method, url, httpOptions, requestBody, cacheConfig, fileObject, reader, subscriber);

          reader.readAsDataURL(requestBody.finData.files);
        }
      }
    );

    return response;
  }

  public postFileParse(functionId: string, method: string, url: string, httpOptions: any, requestBody: any, cacheConfig: any, fileObject: any, reader: any, subscriber: any): any {
    fileObject.b64 = reader.result;
    requestBody.finData.files = fileObject;
    httpOptions.body.files = fileObject;

    if (cacheConfig[functionId]) {
      let methodConfig: any;

      cacheConfig[functionId].forEach(
        (config: any) => {
          if (config.method === method) {
            methodConfig = config;
          }
        }
      );

      if (methodConfig === undefined) {
        return this.returnError(url);
      } else {
        if (methodConfig.cache) {
          let finalResponse: boolean | undefined = false;
          methodConfig.cacheConfigs.forEach(
            (cacheConfig: any) => {
              if (cacheConfig.url === url) {
                if (cacheConfig.serviceName) {
                  const service: any = this.injectionService.getInjectedServiceByTypeAndName('process-cache-service', cacheConfig.serviceName);

                  const insertData: any = new Object();
                  insertData.functionId = functionId;

                  if (requestBody.headerModel) {
                    insertData.application = requestBody.headerModel.application;
                  }

                  if (requestBody.headerModel.transactionReferenceNumber) {
                    insertData.transactionReferenceNumber = requestBody.headerModel.transactionReferenceNumber;
                  } else if (requestBody.finData) {
                    insertData.transactionReferenceNumber = requestBody.finData.transactionReferenceNumber;
                  }

                  insertData.method = method;
                  insertData.url = url;
                  insertData.httpOptions = httpOptions;
                  insertData.requestBody = requestBody;
                  insertData.cacheConfig = cacheConfig;
                  insertData.retryCount = 0;
                  insertData.requestType = 'filePartUpload';

                  service.service[cacheConfig.functionName](insertData, subscriber, this.serviceCallbackSucess.bind(this), this.serviceCallbackError.bind(this));

                  finalResponse = true;
                  return;
                }
              }
            }
          );

          if (finalResponse) {
            return finalResponse;
          }
        }

        if (methodConfig.getResponseFromCache) {
          if (url.indexOf('?') > -1) {
            const urlComponents: string[] = url.split('?');
            url = urlComponents[0];
            requestBody.urlComponents = urlComponents;
          }

          if (methodConfig.cacheConfigs === undefined || methodConfig.cacheConfigs === null || methodConfig.cacheConfigs.length === 0) {
            let subscriber: any;
            const response: Observable<any> = new Observable<any>(
              observer => {
                subscriber = observer;
                this.uiDatabaseService.fetchDataByIndex(this.cacheDbName, this.mappingStore, 'originalPath', url, this.gotKey.bind(this, subscriber, url, requestBody), this.transactionError.bind(this, subscriber, url));
              }
            );

            return response;
          } else {
            let finalResponse: Observable<any> | undefined;
            let subscriber: any;

            methodConfig.cacheConfigs.forEach(
              (cacheConfig: any) => {
                if (cacheConfig.url === url) {
                  if (cacheConfig.serviceName) {
                    const service: any = this.injectionService.getInjectedServiceByTypeAndName('process-cache-service', cacheConfig.serviceName);

                    finalResponse = new Observable<any>(
                      observer => {
                        subscriber = observer;
                        service.service[cacheConfig.functionName](null, subscriber, this.serviceCallbackSucess.bind(this), this.serviceCallbackError.bind(this));
                      }
                    );
                  } else {
                    finalResponse = new Observable<any>(
                      observer => {
                        subscriber = observer;
                        this.uiDatabaseService.fetchDataByIndex(this.cacheDbName, this.mappingStore, 'originalPath', url, this.gotKey.bind(this, subscriber, url, requestBody), this.transactionError.bind(this, subscriber, url));
                      }
                    );

                    return;
                  }
                }
              }
            );

            if (finalResponse === undefined || finalResponse === null) {
              finalResponse = new Observable<any>(
                observer => {
                  subscriber = observer;
                  this.uiDatabaseService.fetchDataByIndex(this.cacheDbName, this.mappingStore, 'originalPath', url, this.gotKey.bind(this, subscriber, url, requestBody), this.transactionError.bind(this, subscriber, url));
                }
              );
            }

            return finalResponse;
          }
        }
      }
    } else {
      const errorObject: any = new Object();
      errorObject.message = 'E_APP_OFFLINE';

      const errorHeader: HttpHeaders = new HttpHeaders();
      errorHeader.set('status', '3010');
      errorHeader.set('message', 'E_APP_OFFLINE');
      errorHeader.set('url', url);

      const error: HttpErrorResponse = new HttpErrorResponse({
        error: errorObject,
        headers: errorHeader,
        status: 3010,
        statusText: 'Offline response',
        url
      });

      subscriber.error(error);
      subscriber.complete();
    }
  }
}
